import * as actionTypes from "./actionTypes";
import axiosDashboard from "../../axios-dashboard";
import idIndicadores from "../../assets/shared/idIndicadores";
import { storageMonedaId, storageToken, storageEmpresaId, storageVendedorId } from "../../assets/shared/sessionData";
import { enqueueSnackbar } from './snackbar';

export const loadVentasMesComparativo = (idCliente) => {
  return (dispatch) => {
    idCliente
      ? dispatch(loadVentasMesComparativoPorClienteStart())
      : dispatch(loadVentasMesComparativoStart());

    const authStr = 'Bearer '.concat(sessionStorage.getItem("token"));
    const idVendedor = sessionStorage.getItem("vendedorId");

    axiosDashboard
      .get(
        `/ventas/VentasDelMesEnteroComparativo?idMoneda=${storageMonedaId()}&idVendedor=${idVendedor}${
          idCliente ? `&idCliente=${idCliente}` : ""
        }&idEmpresa=${parseInt(
          sessionStorage.getItem("empresaId"),
          10
        )}&indicadorId=${idIndicadores.ventasDelMes}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const { montoMesActual, montoMesAnterior } = response.data;
        idCliente
          ? dispatch(
              loadVentasMesComparativoPorClienteSuccess(
                montoMesActual,
                montoMesAnterior
              )
            )
          : dispatch(
              loadVentasMesComparativoSuccess(montoMesActual, montoMesAnterior)
            );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadVentasMesComparativoFail(error));
          } else {
            dispatch(loadVentasMesComparativo(idCliente));
          }
        } else {
          dispatch(loadVentasMesComparativoFail(error));
        }
      });
  };
};

export const clearVentasMesComparativo = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_MES_COMPARATIVO,
  };
};

const loadVentasMesComparativoStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_MES_COMPARATIVO_START,
  };
};

const loadVentasMesComparativoSuccess = (montoMesActual, montoMesAnterior) => {
  return {
    type: actionTypes.LOAD_VENTAS_MES_COMPARATIVO_SUCCESS,
    montoMesActual,
    montoMesAnterior,
  };
};

export const clearVentasMesComparativoPorCliente = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_MES_COMPARATIVO_POR_CLIENTE,
  };
};

const loadVentasMesComparativoPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_MES_COMPARATIVO_POR_CLIENTE_START,
  };
};

const loadVentasMesComparativoPorClienteSuccess = (
  montoMesActualPorCliente,
  montoMesAnteriorPorCliente
) => {
  return {
    type: actionTypes.LOAD_VENTAS_MES_COMPARATIVO_POR_CLIENTE_SUCCESS,
    montoMesActualPorCliente,
    montoMesAnteriorPorCliente,
  };
};

const loadVentasMesComparativoFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_MES_COMPARATIVO_FAIL,
    error,
  };
};

export const loadPedidosPendientes = (idCliente) => {
  return (dispatch) => {
    idCliente
      ? dispatch(loadPedidosPendientesPorClienteStart())
      : dispatch(loadPedidosPendientesStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idVendedor = sessionStorage.getItem("vendedorId");

    axiosDashboard
      .get(
        `/ventas/PedidosPendientes?idVendedor=${idVendedor}${
          idCliente ? `&idCliente=${idCliente}` : ""
        }&idMoneda=${storageMonedaId()}&idEmpresa=${parseInt(
          sessionStorage.getItem("empresaId"),
          10
        )}&indicadorId=${idIndicadores.cantidadPedidosPendientes}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const { cantidad, total, promedio30Dias } = response.data;
        if (idCliente) {
          dispatch(
            loadPedidosPendientesPorClienteSuccess(
              cantidad,
              total,
              promedio30Dias
            )
          );
        } else {
          dispatch(
            loadPedidosPendientesSuccess(cantidad, total, promedio30Dias)
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadPedidosPendientesFail(error));
          } else {
            dispatch(loadPedidosPendientes(idCliente));
          }
        } else {
          dispatch(loadPedidosPendientesFail(error));
        }
      });
  };
};

export const clearPedidosPendientes = () => {
  return {
    type: actionTypes.CLEAR_PEDIDOS_PENDIENTES,
  };
};

export const clearPedidosPendientesPorCliente = () => {
  return {
    type: actionTypes.CLEAR_PEDIDOS_PENDIENTES_POR_CLIENTE,
  };
};

const loadPedidosPendientesStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_START,
  };
};

const loadPedidosPendientesPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_POR_CLIENTE_START,
  };
};

const loadPedidosPendientesSuccess = (cantidad, total, prom30Dias) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_SUCCESS,
    cantidad,
    total,
    prom30Dias,
  };
};

const loadPedidosPendientesPorClienteSuccess = (
  cantidadPorCliente,
  totalPorCliente,
  prom30DiasPorCliente
) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS,
    cantidadPorCliente,
    totalPorCliente,
    prom30DiasPorCliente,
  };
};

const loadPedidosPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_FAIL,
    error,
  };
};

export const loadPedidosMesComparativo = (idCliente) => {
  return (dispatch) => {
    idCliente
      ? dispatch(loadPedidosMesComparativoPorClienteStart())
      : dispatch(loadPedidosMesComparativoStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosDashboard
      .get(
        `/ventas/PedidosDelMesComparativo?idVendedor=${sessionStorage.getItem(
          "vendedorId"
        )}${
          idCliente ? `&idCliente=${idCliente}` : ""
        }&idMoneda=${storageMonedaId()}&idEmpresa=${parseInt(
          sessionStorage.getItem("empresaId"),
          10
        )}&indicadorId=${idIndicadores.pedidosDelMesComparativo}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const {
          transaccionesMesAnterior,
          totalMesAnterior,
          transaccionesMesActual,
          totalMesActual,
          porcentajeMesActual,
          porcentajeTotal,
        } = response.data;
        if (idCliente) {
          dispatch(
            loadPedidosMesComparativoPorClienteSuccess(
              totalMesActual,
              totalMesAnterior
            )
          );
        } else {
          dispatch(
            loadPedidosMesComparativoSuccess(
              transaccionesMesAnterior,
              totalMesAnterior,
              transaccionesMesActual,
              totalMesActual,
              porcentajeMesActual,
              porcentajeTotal
            )
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadPedidosMesComparativoFail(error));
          } else {
            dispatch(loadPedidosMesComparativo(idCliente));
          }
        } else {
          dispatch(loadPedidosMesComparativoFail(error));
        }
      });
  };
};

export const clearPedidosMesComparativo = () => {
  return {
    type: actionTypes.CLEAR_PEDIDOS_MES_COMPARATIVO,
  };
};

export const clearPedidosMesComparativoPorCliente = () => {
  return {
    type: actionTypes.CLEAR_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE,
  };
};

const loadPedidosMesComparativoStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_START,
  };
};

const loadPedidosMesComparativoPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE_START,
  };
};

const loadPedidosMesComparativoSuccess = (
  transMesAnt,
  totalMesAnt,
  transMesAct,
  totalMesAct,
  porcTrans,
  porcTotal
) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS,
    transMesAnt,
    totalMesAnt,
    transMesAct,
    totalMesAct,
    porcTrans,
    porcTotal,
  };
};

const loadPedidosMesComparativoPorClienteSuccess = (
  totalMesActualPorCliente,
  totalMesAnteriorPorCliente
) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE_SUCCESS,
    totalMesActualPorCliente,
    totalMesAnteriorPorCliente,
  };
};

const loadPedidosMesComparativoFail = (error) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_FAIL,
    error,
  };
};

export const loadObjetivoVentas = (
  idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10)
) => {
  return (dispatch) => {
    dispatch(loadObjetivoVentasStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosDashboard
      .get(
        `/ventas/ObjetivoDeVentas?idVendedor=${sessionStorage.getItem(
          "idVendedor"
        )}&idEmpresa=${idEmpresa}&idMoneda=${storageMonedaId()}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const { objetivo, montoActual, porcentaje } = response.data;
        dispatch(loadObjetivoVentasSuccess(objetivo, montoActual, porcentaje));
      })
      .catch((error) => {
        if (error.response.status === 500 || error.response.status === 404) {
          dispatch(loadObjetivoVentasFail(error));
        } else {
          dispatch(loadObjetivoVentas(idEmpresa));
        }
      });
  };
};

const loadObjetivoVentasStart = () => {
  return {
    type: actionTypes.LOAD_OBJETIVO_VENTAS_START,
  };
};

const loadObjetivoVentasSuccess = (objetivo, montoActual, porcentaje) => {
  return {
    type: actionTypes.LOAD_OBJETIVO_VENTAS_SUCCESS,
    objetivo,
    montoActual,
    porcentaje,
  };
};

const loadObjetivoVentasFail = (error) => {
  return {
    type: actionTypes.LOAD_OBJETIVO_VENTAS_FAIL,
    error,
  };
};

export const loadEstadosPedidosPendientes = (idCliente) => {
  return (dispatch) => {
    idCliente
      ? dispatch(loadEstadosPedidosPendientesPorClienteStart())
      : dispatch(loadEstadosPedidosPendientesStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    let total = 0;

    axiosDashboard
      .get(
        `/ventas/EstadosPedidosPendientes?idVendedor=${sessionStorage.getItem(
          "vendedorId"
        )}${
          idCliente ? `&idCliente=${idCliente}` : ""
        }&idMoneda=${storageMonedaId()}&idEmpresa=${parseInt(
          sessionStorage.getItem("empresaId"),
          10
        )}&indicadorId=${idIndicadores.estadosPedidosPendientes}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        response.data.map((pedido) => {
          return (total += pedido.monto);
        });
        if (idCliente) {
          dispatch(
            loadEstadosPedidosPendientesPorClienteSuccess(response.data, total)
          );
        } else {
          dispatch(loadEstadosPedidosPendientesSuccess(response.data, total));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadEstadosPedidosPendientesFail(error));
          } else {
            dispatch(loadEstadosPedidosPendientes(idCliente));
          }
        } else {
          dispatch(loadEstadosPedidosPendientesFail(error));
        }
      });
  };
};

export const clearEstadosPedidosPendientes = () => {
  return {
    type: actionTypes.CLEAR_ESTADOS_PEDIDOS_PENDIENTES,
  };
};

export const clearEstadosPedidosPendientesPorCliente = () => {
  return {
    type: actionTypes.CLEAR_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE,
  };
};

const loadEstadosPedidosPendientesStart = () => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_START,
  };
};

const loadEstadosPedidosPendientesPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE_START,
  };
};

const loadEstadosPedidosPendientesSuccess = (
  estadosPedidosPendientes,
  total
) => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS,
    estadosPedidosPendientes,
    total,
  };
};

const loadEstadosPedidosPendientesPorClienteSuccess = (
  estadosPedidosPendientesPorCliente,
  totalPorCliente
) => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS,
    estadosPedidosPendientesPorCliente,
    totalPorCliente,
  };
};

const loadEstadosPedidosPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL,
    error,
  };
};

export const loadFacturasDelMesInteranual = (idCliente) => {
  return (dispatch) => {
    idCliente
      ? dispatch(loadFacturasDelMesInteranualPorClienteStart())
      : dispatch(loadFacturasDelMesInteranualStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idVendedor = sessionStorage.getItem("vendedorId");

    axiosDashboard
      .get(
        `/ventas/EvolucionDeVentas?idVendedor=${idVendedor}${
          idCliente ? `&idCliente=${idCliente}` : ""
        }&idEmpresa=${parseInt(
          sessionStorage.getItem("empresaId"),
          10
        )}&indicadorId=${idIndicadores.facturasDelMesInteranual}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const { periodoActual, periodoAnterior } = response.data;
        let meses = [];
        periodoActual.map((factura) => meses.push(factura.mes));

        if (idCliente) {
          dispatch(
            loadFacturasDelMesInteranualPorClienteSuccess(
              periodoActual,
              periodoAnterior,
              meses
            )
          );
        } else {
          dispatch(
            loadFacturasDelMesInteranualSucces(
              periodoActual,
              periodoAnterior,
              meses
            )
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadFacturasDelMesInteranualFail(error));
          } else {
            dispatch(loadFacturasDelMesInteranual(idCliente));
          }
        } else {
          dispatch(loadFacturasDelMesInteranualFail(error));
        }
      });
  };
};

export const clearFacturasDelMesInteranual = () => {
  return {
    type: actionTypes.CLEAR_FACTURAS_DEL_MES_INTERANUAL,
  };
};

export const clearFacturasDelMesInteranualPorCliente = () => {
  return {
    type: actionTypes.CLEAR_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE,
  };
};

const loadFacturasDelMesInteranualStart = () => {
  return {
    type: actionTypes.LOAD_FACTURAS_DEL_MES_INTERANUAL_START,
  };
};

const loadFacturasDelMesInteranualPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE_START,
  };
};

const loadFacturasDelMesInteranualSucces = (
  periodoActual,
  periodoAnterior,
  meses
) => {
  return {
    type: actionTypes.LOAD_FACTURAS_DEL_MES_INTERANUAL_SUCCESS,
    periodoActual,
    periodoAnterior,
    meses,
  };
};

const loadFacturasDelMesInteranualPorClienteSuccess = (
  periodoActualPorCliente,
  periodoAnteriorPorCliente,
  meses
) => {
  return {
    type: actionTypes.LOAD_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE_SUCCESS,
    periodoActualPorCliente,
    periodoAnteriorPorCliente,
    meses,
  };
};

const loadFacturasDelMesInteranualFail = (error) => {
  return {
    type: actionTypes.LOAD_FACTURAS_DEL_MES_INTERANUAL_FAIL,
    error,
  };
};

export const loadPedidosDelMesInteranual = (idCliente) => {
  return (dispatch) => {
    idCliente
      ? dispatch(loadPedidosDelMesInteranualPorClienteStart())
      : dispatch(loadPedidosDelMesInteranualStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idVendedor = sessionStorage.getItem("vendedorId");
    axiosDashboard
      .get(
        `/ventas/EvolucionDePedidos?idVendedor=${idVendedor}${
          idCliente ? `&idCliente=${idCliente}` : ""
        }&idEmpresa=${parseInt(
          sessionStorage.getItem("empresaId"),
          10
        )}&indicadorId=${idIndicadores.pedidosDelMesInteranual}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const { periodoActual, periodoAnterior } = response.data;
        let meses = [];
        periodoActual.map((factura) => meses.push(factura.mes));

        if (idCliente) {
          dispatch(
            loadPedidosDelMesInteranualPorClienteSuccess(
              periodoActual,
              periodoAnterior,
              meses
            )
          );
        } else {
          dispatch(
            loadPedidosDelMesInteranualSucces(
              periodoActual,
              periodoAnterior,
              meses
            )
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadPedidosDelMesInteranualFail(error));
          } else {
            dispatch(loadPedidosDelMesInteranual(idCliente));
          }
        } else {
          dispatch(loadPedidosDelMesInteranualFail(error));
        }
      });
  };
};

export const clearPedidosDelMesInteranual = () => {
  return {
    type: actionTypes.CLEAR_PEDIDOS_DEL_MES_INTERANUAL,
  };
};

export const clearPedidosDelMesInteranualPorCliente = () => {
  return {
    type: actionTypes.CLEAR_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE,
  };
};

const loadPedidosDelMesInteranualStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_DEL_MES_INTERANUAL_START,
  };
};

const loadPedidosDelMesInteranualPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE_START,
  };
};

const loadPedidosDelMesInteranualSucces = (
  periodoActual,
  periodoAnterior,
  meses
) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_DEL_MES_INTERANUAL_SUCCESS,
    periodoActual,
    periodoAnterior,
    meses,
  };
};

const loadPedidosDelMesInteranualPorClienteSuccess = (
  periodoActualPorCliente,
  periodoAnteriorPorCliente,
  meses
) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE_SUCCESS,
    periodoActualPorCliente,
    periodoAnteriorPorCliente,
    meses,
  };
};

const loadPedidosDelMesInteranualFail = (error) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_DEL_MES_INTERANUAL_FAIL,
    error,
  };
};

export const loadFacturasPendientes = (idCliente) => {
  return (dispatch) => {
    if(idCliente)
      dispatch(loadFacturasPendientesPorClienteStart());
    else
      dispatch(loadFacturasPendientesStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();
    const idVendedor = storageVendedorId();

    axiosDashboard
      .get(
        `/ventas/DeudoresPorVentas?idVendedor=${idVendedor}&idMoneda=${idMoneda}&idEmpresa=${
          idEmpresa}&indicadorId=${idIndicadores.facturasPendientes}${
            idCliente ? `&idCliente=${idCliente}` : ""
          }`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const deudas = response.data;
        let totalDeuda = 0;
        response.data.map((deuda) => (totalDeuda += deuda.value));
        if(idCliente)
          dispatch(loadFacturasPendientesPorClienteSuccess(deudas, totalDeuda));
        else
          dispatch(loadFacturasPendientesSuccess(deudas, totalDeuda));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            if(idCliente)
              dispatch(loadFacturasPendientesPorClienteFail(error));
            else
              dispatch(loadFacturasPendientesFail(error));
          } else {
              dispatch(loadFacturasPendientes(idCliente));
          }
        } else {
          if(idCliente)
            dispatch(loadFacturasPendientesPorClienteFail(error));
          else
            dispatch(loadFacturasPendientesFail(error));
        }
      });
  };
};

export const clearFacturasPendientes = () => {
  return {
    type: actionTypes.CLEAR_FACTURAS_PENDIENTES,
  };
};

export const clearFacturasPendientesPorCliente = () => {
  return {
    type: actionTypes.CLEAR_FACTURAS_PENDIENTES_POR_CLIENTE,
  };
};

const loadFacturasPendientesStart = () => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_START,
  };
};

const loadFacturasPendientesSuccess = (facturas, totalFacturas) => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_SUCCESS,
    facturas,
    totalFacturas,
  };
};

const loadFacturasPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_FAIL,
    error,
  };
};

const loadFacturasPendientesPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_POR_CLIENTE_START,
  };
};

const loadFacturasPendientesPorClienteSuccess = (facturas, totalFacturas) => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_POR_CLIENTE_SUCCESS,
    facturas,
    totalFacturas,
  };
};

const loadFacturasPendientesPorClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_POR_CLIENTE_FAIL,
    error,
  };
};

export const loadComprobantesFacturasPendientes = (idCliente) => {
  return (dispatch) => {

    if(idCliente)
      dispatch(loadComprobantesFacturasPendientesPorClienteStart());
    else
      dispatch(loadComprobantesFacturasPendientesStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idVendedor = sessionStorage.getItem("vendedorId");
    const idMoneda = sessionStorage.getItem("monedaId");
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

    axiosDashboard
      .get(
        `/ventas/comprobantesFacturasPendientes?idVendedor=${idVendedor}&idMoneda=${idMoneda}&idEmpresa=${
          idEmpresa}&indicadorId=${idIndicadores.comprobantesFacturasPendientes}${idCliente ? `&idCliente=${idCliente}` : ""}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        if(idCliente)
          dispatch(loadComprobantesFacturasPendientesPorClienteSuccess(response.data));
        else
          dispatch(loadComprobantesFacturasPendientesSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadComprobantesFacturasPendientesFail(error));
            if(idCliente)
              dispatch(loadComprobantesFacturasPendientesPorClienteFail(error));
            else
              dispatch(loadComprobantesFacturasPendientesFail(error));
          } else {
            dispatch(loadComprobantesFacturasPendientes(idCliente));
          }
        } else {
          if(idCliente)
            dispatch(loadComprobantesFacturasPendientesPorClienteFail(error));
          else
            dispatch(loadComprobantesFacturasPendientesFail(error));
        }
      });
  };
};

export const clearComprobantesFacturasPendientes = () => {
  return {
    type: actionTypes.CLEAR_COMPROBANTES_FACTURAS_PENDIENTES,
  };
};

export const clearComprobantesFacturasPendientesPorCliente = () => {
  return {
    type: actionTypes.CLEAR_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE,
  };
};

const loadComprobantesFacturasPendientesStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START,
  };
};

const loadComprobantesFacturasPendientesPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_START,
  };
};

const loadComprobantesFacturasPendientesSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS,
    comprobantes,
  };
};

const loadComprobantesFacturasPendientesPorClienteSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_SUCCESS,
    comprobantes,
  };
};

const loadComprobantesFacturasPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL,
    error,
  };
};

const loadComprobantesFacturasPendientesPorClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_FAIL,
    error,
  };
};

export const loadComprobantesPedidosPendientes = (idCliente) => {
  return (dispatch) => {
    if(idCliente)
      dispatch(loadComprobantesPedidosPendientesPorClienteStart());
    else
      dispatch(loadComprobantesPedidosPendientesStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idVendedor = sessionStorage.getItem("vendedorId");
    const idMoneda = sessionStorage.getItem("monedaId");
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

    axiosDashboard
      .get(
        `/ventas/comprobantesPedidosPendientes?idVendedor=${idVendedor}&idMoneda=${idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.comprobantesPedidosPendientes}${idCliente ? `&idCliente=${idCliente}` : ""}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        if(idCliente)
          dispatch(loadComprobantesPedidosPendientesPorClienteSuccess(response.data));
        else
          dispatch(loadComprobantesPedidosPendientesSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            if(idCliente)
              dispatch(loadComprobantesPedidosPendientesPorClienteFail(error));
            else
              dispatch(loadComprobantesPedidosPendientesFail(error));
          } else {
            dispatch(loadComprobantesPedidosPendientes(idCliente));
          }
        } else {
          if(idCliente)
            dispatch(loadComprobantesPedidosPendientesPorClienteFail(error));
          else
            dispatch(loadComprobantesPedidosPendientesFail(error));
        }
      });
  };
};

export const clearComprobantesPedidosPendientes = () => {
  return {
    type: actionTypes.CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES,
  };
};

export const clearComprobantesPedidosPendientesPorCliente = () => {
  return {
    type: actionTypes.CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE,
  };
};

const loadComprobantesPedidosPendientesStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START,
  };
};

const loadComprobantesPedidosPendientesPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_START,
  };
};

const loadComprobantesPedidosPendientesSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS,
    comprobantes,
  };
};

const loadComprobantesPedidosPendientesPorClienteSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS,
    comprobantes,
  };
};

const loadComprobantesPedidosPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL,
    error,
  };
};

const loadComprobantesPedidosPendientesPorClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_FAIL,
    error,
  };
};

export const loadVentasAlClienteEvolutivo = (idCliente) => {
  return (dispatch) => {
    dispatch(loadVentasAlClienteEvolutivoStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idVendedor = sessionStorage.getItem("vendedorId");
    const idMoneda = sessionStorage.getItem("monedaId");
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

    axiosDashboard
      .get(
        `/ventas/VentasAlClienteEvolutivo?idCliente=${idCliente}&idMoneda=${
          idMoneda}&idEmpresa=${idEmpresa}&idVendedor=${
          idVendedor}&indicadorId=${idIndicadores.ventasAlClienteEvolutivo}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const { ventasPorMes } = response.data;
        let meses = [];
        ventasPorMes.map((venta) => meses.push(venta.mes));
        dispatch(loadVentasAlClienteEvolutivoSuccess(ventasPorMes, meses));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadVentasAlClienteEvolutivoFail(error));
          } else {
            dispatch(loadVentasAlClienteEvolutivo(idCliente));
          }
        } else {
          dispatch(loadVentasAlClienteEvolutivoFail(error));
        }
      });
  };
};

export const clearVentasAlClienteEvolutivo = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_AL_CLIENTE_EVOLUTIVO,
  };
};

const loadVentasAlClienteEvolutivoStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_START,
  };
};

const loadVentasAlClienteEvolutivoSuccess = (ventas, meses) => {
  return {
    type: actionTypes.LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_SUCCESS,
    ventas,
    meses,
  };
};

const loadVentasAlClienteEvolutivoFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_FAIL,
    error,
  };
};

export const loadVentasDelMesPorArticuloDeLaMarca = (idVendedor, marcaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelMesPorArticuloDeLaMarca?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idMarca=${marcaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelRubroDelVendedor = (idVendedor, rubroId, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelMesPorArticuloDelRubro?mes=${mes}&anio=${anio}&idVendedor=${idVendedor}&idRubro=${rubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelRubro
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelRubro = (idVendedor, rubroId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelMesPorArticuloDelRubro?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idRubro=${rubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelSubrubro = (idVendedor, subrubroId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelMesPorArticuloDelSubrubro?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idSubrubro=${subrubroId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorCategoriaDelCliente = (idVendedor, categoriaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasPorClienteDeLaCategoria?idCategoria=${categoriaId}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorClienteEnLaPrivincia = (idVendedor, provinciaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelMesPorClienteEnLaProvincia?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idProvincia=${provinciaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorClienteEnLaZona = (idVendedor, zonaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelMesPorClienteEnLaZona?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idZona=${zonaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorClienteEnLaZona
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelArticuloPorCliente = (idVendedor, idArticlo, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelArticuloPorCliente?mes=${mes}&anio=${anio}&idArticulo=${idArticlo}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelArticuloPorCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelCliente = (idVendedor, idCliente, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelMesPorArticuloDelCliente?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idCliente=${idCliente.trim()}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelMesPorArticuloDelCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDeLaPrivincia = (idVendedor, provinciaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelMesPorArticuloEnLaProvincia?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idProvincia=${provinciaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDeLaZona = (idVendedor, zonaId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelMesPorArticuloEnLaZona?mes=${mes}&anio=${anio}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idZona=${zonaId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasDelMesPorArticuloDelVendedor = (vendedorId, mes, anio, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasDelMesPorArticuloDelVendedor?mes=${mes}&anio=${anio}&idVendedor=${vendedorId}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorMesPorCategoriaDeCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadVentasPorClienteDeLaCategoria = (idVendedor, idCateg, mes, anio, success, failError) => {
  return (dispatch) => {
    !success && dispatch(loadVentasPorClienteDeLaCategoriaStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboard
      .get(
        `/ventas/VentasPorClienteDeLaCategoria?idCategoria=${idCateg}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&mes=${mes}&anio=${anio}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasPorClienteDeLaCategoria
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (!success) {
          dispatch(loadVentasPorClienteDeLaCategoriaSuccess(response.data));
        } else {
          return success(response.data);
        }
      })
      .catch((error) => {
        if (!failError) {
          dispatch(loadVentasPorClienteDeLaCategoriaFail(error));
        } else {
          return failError(error);
        }
      });
  };
};

export const clearVentasPorClienteDeLaCategoria = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA
  };
};

const loadVentasPorClienteDeLaCategoriaStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_START
  };
};

const loadVentasPorClienteDeLaCategoriaSuccess = (ventas) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_SUCCESS,
    ventas
  };
};

const loadVentasPorClienteDeLaCategoriaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_FAIL,
    error
  };
};

export const loadVentasDelArticuloAlCliente = (idVendedor, idArticlo, idCliente, mes, anio, success, failError) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    const formatMes = parseInt(mes, 10);

    axiosDashboard
      .get(
        `/ventas/VentasDelArticuloAlCliente?mes=${formatMes}&anio=${anio}&idArticulo=${idArticlo}&idCliente=${idCliente}${
          idVendedor ? `&idVendedor=${idVendedor}` : ''
        }&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.ventasDelArticuloAlCliente
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return failError(error);
      });
  };
};

export const clearEvolucionVentasPorCategoriaDeCliente = () => {
  return {
    type: actionTypes.CLEAR_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE
  };
};

export const loadVentasPorVendedor = (idCliente) => {
  return (dispatch) => {
    idCliente
      ? dispatch(loadVentasPorVendedorPorClienteStart())
      : dispatch(loadVentasPorVendedorStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idVendedor = sessionStorage.getItem("vendedorId");

    axiosDashboard
      .get(
        `/ventas/ventasPorVendedor?idVendedor=${idVendedor}${
          idCliente ? `&idCliente=${idCliente}` : ""
        }&idMoneda=${storageMonedaId()}&idEmpresa=${parseInt(
          sessionStorage.getItem("empresaId"),
          10
        )}&indicadorId=${idIndicadores.cantidadPedidosPendientes}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const { unidadesVendidas, ventas, cantidad } = response.data;

        if (idCliente)
          dispatch(loadVentasPorVendedorPorClienteSuccess(unidadesVendidas, ventas, cantidad));
        else
          dispatch(loadVentasPorVendedorSuccess(unidadesVendidas, ventas));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            if(idCliente)
              dispatch(loadVentasPorVendedorPorClientesFail(error));
            else
              dispatch(loadVentasPorVendedorFail(error));
          } else {
            dispatch(loadVentasPorVendedor(idCliente));
          }
        } else {
          if(idCliente)
              dispatch(loadVentasPorVendedorPorClientesFail(error));
            else
              dispatch(loadVentasPorVendedorFail(error));
        }
      });
  };
};

export const clearVentasPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR,
  };
};

export const clearVentasPorVendedorPorCliente = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_CLIENTE,
  };
};

const loadVentasPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_START,
  };
};

const loadVentasPorVendedorPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_START,
  };
};

const loadVentasPorVendedorSuccess = (unidadesVendidas, ventas, cantidad) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_SUCCESS,
    unidadesVendidas,
    ventas,
    cantidad,
  };
};

const loadVentasPorVendedorPorClienteSuccess = (unidadesVendidas, ventas, cantidad) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_SUCCESS,
    unidadesVendidas,
    ventas,
    cantidad,
  };
};

const loadVentasPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_FAIL,
    error,
  };
};

const loadVentasPorVendedorPorClientesFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_FAIL,
    error,
  };
};

export const loadDetalleComprobante = (idSucursal, idSistema, idComproba, nroReferencia, success, errorFail, cobranza = false) => {
  return (dispatch) => {
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();
    const authStr = 'Bearer '.concat(storageToken());

    axiosDashboard
      .get(
        `/ventas/DetalleComprobante?idSucursal=${idSucursal}&idSistema=${idSistema}&idComproba=${idComproba}&nroReferencia=${nroReferencia}&idMoneda=${
          idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasAlCliente}&cobranza=${cobranza}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const loadPedidosVencidos = (idCliente) => {
  return (dispatch) => {
    idCliente
      ? dispatch(loadPedidosVencidosPorClienteStart())
      : dispatch(loadPedidosVencidosStart());
    const authStr = "Bearer ".concat(storageToken());
    const idVendedor = storageVendedorId();
    const idEmpresa = storageEmpresaId();
    const idMoneda = storageMonedaId();

    axiosDashboard
      .get(
        `/ventas/PedidosVencidos?idVendedor=${idVendedor}${
          idCliente ? `&idCliente=${idCliente}` : ""
        }&idMoneda=${idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.cantidadPedidosVencidos}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const { cantidad, importe } = response.data;
        if (idCliente) {
          dispatch(loadPedidosVencidosPorClienteSuccess(cantidad, importe));
        } else {
          dispatch(loadPedidosVencidosSuccess(cantidad, importe));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadPedidosVencidosFail(error));
          } else {
            dispatch(loadPedidosVencidos(idCliente));
          }
        } else {
          dispatch(loadPedidosVencidosFail(error));
        }
      });
  };
};

export const clearPedidosVencidos = (inClientInfo) => {
  return inClientInfo 
  ? { type: actionTypes.CLEAR_PEDIDOS_VENCIDOS_POR_CLIENTE, } 
  : { type: actionTypes.CLEAR_PEDIDOS_VENCIDOS, };
};

const loadPedidosVencidosStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_VENCIDOS_START,
  };
};

const loadPedidosVencidosPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_START,
  };
};

const loadPedidosVencidosSuccess = (cantidad, importe) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_VENCIDOS_SUCCESS,
    cantidad,
    importe,
  };
};

const loadPedidosVencidosPorClienteSuccess = (cantidadPorCliente, importePorCliente) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_SUCCESS,
    cantidadPorCliente,
    importePorCliente,
  };
};

const loadPedidosVencidosFail = (error) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_VENCIDOS_FAIL,
    error,
  };
};

export const enviarGraficosPorMail = (data, success) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': authStr
    };

    axiosDashboard
      .post(`/notificaciones/EnviarGraficosPorMail`, data, {
        headers: headers,
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Gráficos enviados por mail con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );

        return success && success();
      })
      .catch((error) => {
        let errorMessage = `Error al enviar los gráficos por mail, intente nuevamente.`;   
        if (error.response && error.response.data){
          errorMessage = error.response.data;
        }

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  }
};