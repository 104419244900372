import React, { createContext, useReducer } from 'react';
import { CarritoReducer, sumItems, ResumenVacio } from '../reducers/carrito';

export const CarritoContext = createContext();

const storage = sessionStorage.getItem('carrito') ? JSON.parse(sessionStorage.getItem('carrito')) : [];

const storageResumen = sessionStorage.getItem('resumenCarrito') 
    ? JSON.parse(sessionStorage.getItem('resumenCarrito')) 
    : ResumenVacio();

const initialState = { 
    carritoItems: storage, 
    ...sumItems(storage), 
    checkout: false,
    update: false,
    resumen: storageResumen,
 };

const CarritoContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(CarritoReducer, initialState)

    const increase = payload => {
        dispatch({type: 'INCREASE', payload})
    }

    const decrease = payload => {
        dispatch({type: 'DECREASE', payload})
    }

    const updateCantidad = (payload, cantidad) => {
        dispatch({type: 'UPDATE_CANT', payload, cantidad});
    }

    const addArticulo = payload => {
        dispatch({type: 'ADD_ITEM', payload})
    }

    const removeArticulo = payload => {
        dispatch({type: 'REMOVE_ITEM', payload})
    }

    const updateResumen = resumen => {
        dispatch({type: 'UPDATE_RESUMEN', resumen})
    }

    const clearCarrito = () => {
        dispatch({type: 'CLEAR'})
    }

    const handleCheckout = () => {
        dispatch({type: 'CHECKOUT'})
    }

    const updateCarrito = () => {
        dispatch({type: 'UPDATE'});
    }

    const clearUpdateCarrito = () => {
        dispatch({type: 'CLEAR_UPDATE'});
    }

    const contextValues = {
        removeArticulo,
        addArticulo,
        increase,
        decrease,
        updateCantidad,
        clearCarrito,
        handleCheckout,
        updateCarrito,
        clearUpdateCarrito,
        updateResumen,
        ...state
    } 

    return ( 
        <CarritoContext.Provider value={contextValues} >
            { children }
        </CarritoContext.Provider>
     );
}
 
export default CarritoContextProvider;
