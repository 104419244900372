import React from "react";
import { connect } from "react-redux";
import { formatNumber } from "../../../../assets/shared/formatNumber";
import { storageMonedaSigno } from "../../../../assets/shared/sessionData";
import PieChart from "../../../Charts/PieChart";

const PieFacturasPendientes = (props) => {
  const { facturas, totalFacturas, loading, facturasPorCliente, totalFacturasPorCliente,
    loadingPorCliente, noBorder, clientSelected, inClientInfo, height } = props;

  const chartSinDatos = inClientInfo 
  ? facturasPorCliente.length === 0 || (facturasPorCliente.length === 1 && facturasPorCliente[0].value === 0)
  : facturas.length === 0 || (facturas.length === 1 && facturas[0].value === 0);

  const labels = inClientInfo 
    ? facturasPorCliente.map((factura) => factura.name)
    : facturas.map((factura) => factura.name);
  const series = inClientInfo 
    ? facturasPorCliente.map((factura) => Math.abs(factura.value))
    : facturas.map((factura) => Math.abs(factura.value));
  const loading2 = inClientInfo ? loadingPorCliente : loading;
  let total = inClientInfo ? totalFacturasPorCliente : totalFacturas;

  if(total === undefined)
    total = 0;

  return (
    <PieChart
      title={`Facturas Pendientes`}
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(total)}`}
      labels={labels}
      series={series}
      imgSinDatos={chartSinDatos}
      loading={loading2}
      legendPosition={'bottom'}
      legendHorizontalAlign={"left"}
      chartType="donut"
      height={height}
      width={'100%'}
      noClient={!clientSelected && inClientInfo}
      maxWidthImgSinResult={330}
      noBorder={noBorder}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    facturas: state.ventas.facturasPendientes.facturas,
    totalFacturas: state.ventas.facturasPendientes.totalFacturas,
    estaCargado: state.ventas.facturasPendientes.estaCargado,
    loading: state.ventas.facturasPendientes.loading,
    facturasPorCliente: state.ventas.facturasPendientes.facturasPorCliente,
    totalFacturasPorCliente: state.ventas.facturasPendientes.totalFacturasPorCliente,
    loadingPorCliente: state.ventas.facturasPendientes.loadingPorCliente,
    estaCargadoPorCliente: state.ventas.facturasPendientes.estaCargadoPorCliente,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PieFacturasPendientes);