import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../store/actions';
import Table from '../../../UI/Table/Table';
import { MTableHeader } from 'material-table';
import { storageMonedaSigno } from '../../../../assets/shared/sessionData';
import { formatNumber, isEven } from '../../../../assets/shared/formatNumber';
import ModalDetallesComprobante from '../ModalDetallesComprobante/ModalDetallesComprobante';

const TableComprobantesVentasDelArticuloAlCliente = (props) => {
  const [comprobantesState, setComprobantesState] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const mes = props.mes;
    const anio = props.anio;
    props.onLoadComprobantes(
      props.vendedorId,
      props.articleId,
      props.clienteId,
      mes,
      anio,
      (comprobantes) => {
        setComprobantesState(comprobantes);
        setLoading(false);
        props.successRequest && props.successRequest(comprobantes.length);
      },
      (error) => {
        setComprobantesState([]);
        setLoading(false);
      }
    );
  }, []);

  const cellStyles = {
    fontSize: 'smaller',
    paddingBottom: '0px',
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const headerStyle = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(205 205 205)',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const columns = [
    {
      title: 'Fecha',
      field: 'fecha',
      align: 'left',
      width: 10,
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => `${moment(rowData.fecha).format('DD/MM/YY')}`
    },
    {
      title: 'Comprobante',
      field: 'comprobante',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle
    },
    {
      title: 'Ref.',
      field: 'referencia',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle
    },
    {
      title: 'Num. Externo',
      field: 'numeroExterno',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle
    },
    {
      title: 'Importe',
      field: 'monto',
      align: 'right',
      cellStyle: (cellData) => {
        return {
          ...cellStyles,
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: headerStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    },
    {
      title: 'Ver',
      field: 'id',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => {
        return <ModalDetallesComprobante comprobante={{ ...rowData, nombreComprobante: rowData.comprobante }} />;
      }
    }
  ];

  const data = () => {
    return comprobantesState.map((comprobante) => {
      return {
        fecha: comprobante.fecha,
        comprobante: comprobante.comprobante,
        referencia: comprobante.referencia,
        numeroExterno: comprobante.numeroExterno,
        monto: comprobante.monto,
        idComproba: comprobante.idComproba,
        idSistema: comprobante.idSistema,
        idSucursal: comprobante.idSucursal
      };
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Table
        columns={columns}
        style={{ width: props.customWidth ? props.customWidth : '100%', marginTop: 0, marginBottom: '15px' }}
        data={data()}
        loading={loading}
        components={{
          Header: (props) => (
            <MTableHeader {...props}>
              {(props.columns[0].tableData.width = '20px')}
              {(props.columns[1].tableData.width = '250px')}
              {(props.columns[2].tableData.width = '20px')}
              {(props.columns[3].tableData.width = window.screen.width > 1600 ? ' 175px' : '165px')}
              {(props.columns[4].tableData.width = window.screen.width > 1600 ? ' 100px' : '135px')}
              {(props.columns[5].tableData.width = '35px')}
            </MTableHeader>
          )
        }}
        customOptions={{
          actionsColumnIndex: -1,
          grouping: false,
          draggable: false,
          toolbar: false,
          showTitle: false,
          detailPanelType: 'single',
          detailPanelColumnAlignment: 'right',
          paging: false,
          maxBodyHeight: 200,          
          rowStyle: (rowData) => ({
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadComprobantes: (idVendedor, idArticulo, idCliente, mes, anio, success, errorFail) => {
      dispatch(
        actions.loadVentasDelArticuloAlCliente(idVendedor, idArticulo, idCliente, mes, anio, success, errorFail)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComprobantesVentasDelArticuloAlCliente);
