export default class DatosFacturacionModel {
  nombre;
  direccion;
  codPostal;
  localidad;
  condicionPagoId;
  provinciaId;
  expresoId;
  zonaId;
  listaPrecioId;

  setFromCliente(cliente){

    if(cliente !== null && cliente !== undefined){
      this.nombre = cliente.razonSocial.trimEnd();

      if(cliente.direccionFiscal !== null && cliente.direccionFiscal !== null){
        this.direccion = cliente.direccionFiscal.direccion;
        this.codPostal = cliente.direccionFiscal.codigoPostal;
        this.localidad = cliente.direccionFiscal.localidad;
        this.provinciaId = cliente.direccionFiscal.provinciaId;
        this.zonaId = cliente.direccionFiscal.zonaId;
      }

      if(cliente.condicionDePago !== null && cliente.condicionDePago !== null){
        this.condicionPagoId = cliente.condicionDePago.idCondicion;
      }

      if(cliente.listaDePrecios !== null && cliente.listaDePrecios !== null){
        this.listaPrecioId = cliente.listaDePrecios.idLista;
      }

      if(cliente.expreso !== null && cliente.expreso !== null){
        this.expresoId = cliente.expreso.idExpreso;
      }
    }
  }
}