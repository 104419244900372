import React, { useEffect } from "react";
import { connect } from "react-redux";
import { inc } from "semver";
import { storageClientId, storageVendedorId } from "../../../../assets/shared/sessionData";

import * as actions from "../../../../store/actions";
import TextChart from "../../../Charts/TextChart";

const TextoPedidosVencidos = (props) => {
  const {
    onLoadChart,
    clientSelected,
    inClientInfo,
    error,
  } = props;

  const vendedorSelected = storageVendedorId();
  const clienteId = storageClientId();
  const chartSinDatos = error || (!inClientInfo && !vendedorSelected);
  const loading = inClientInfo ? props.loadingPorCliente : props.loading;
  const cantidad = inClientInfo ? props.cantidadPorCliente : props.cantidad;
  const importe = inClientInfo ? props.importePorCliente : props.importe;
  const estaCargado = inClientInfo ? props.estaCargadoPorCliente : props.estaCargado;

  useEffect(() => {
    if (!estaCargado && vendedorSelected) {
      if(inClientInfo && clientSelected)
        onLoadChart(clienteId);
      else
        onLoadChart();
    }
  }, [onLoadChart, inClientInfo, estaCargado, vendedorSelected, clienteId]);

  return (
    <TextChart
      title={`Pedidos fuera de fecha de entrega`}
      bigAmount={`${cantidad ? cantidad : 0}`}
      noClient={!clientSelected && inClientInfo}
      imgSinDatos={error || chartSinDatos} 
      smallAmount={importe}
      smallAmountTooltip={`Total`}
      loading={loading}
      smallCurrency
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.pedidosVencidos.loading,
    estaCargado: state.ventas.pedidosVencidos.estaCargado,
    cantidad: state.ventas.pedidosVencidos.cantidad,    
    importe: state.ventas.pedidosVencidos.importe,

    loadingPorCliente: state.ventas.pedidosVencidos.loadingPorCliente,
    estaCargadoPorCliente: state.ventas.pedidosVencidos.estaCargadoPorCliente,
    cantidadPorCliente: state.ventas.pedidosVencidos.cantidadPorCliente,
    importePorCliente: state.ventas.pedidosVencidos.importePorCliente,

    error: state.ventas.pedidosVencidos.error,
    clientSelected: state.clientes.clienteById.clientSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idCliente) => dispatch(actions.loadPedidosVencidos(idCliente)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoPedidosVencidos);
