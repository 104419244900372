import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions";

import Menu from "../../../UI/Menu/Menu";
import Dialog from "../../../UI/Dialog/Dialog";
import ComprobantesVentas from "../ComprobantesVentas";
import SettingsIcon from "@material-ui/icons/Settings";
import Button from "../../../UI/Button/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import classes from "./DialogComprobantes.css";
import { Typography } from "@material-ui/core";
import ComprobantesPedidos from "../ComprobantesPedidos";

const MenuComprobantes = (props) => {
  const listItems = [
    {
      id: "ventas",
      title: "Comprobantes de ventas",
    },
    {
      id: "pedidos",
      title: "Comprobantes de pedidos",
    },
  ];

  const [dialogState, setDialogState] = useState({
    show: false,
    content: "",
    title: "",
  });

  const [comprobantesSeleccionados, setComprobantesSeleccionados] = useState(
    []
  );

  useEffect(() => {
    setComprobantesSeleccionados([]);
    return () => {
      setComprobantesSeleccionados([]);
    };
  }, [dialogState.show, dialogState.content]);

  const showContent = () => {
    switch (dialogState.content) {
      case "ventas":
        return <ComprobantesVentas onChangeValue={handleChangeFiltros} />;

      case "pedidos":
        return <ComprobantesPedidos onChangeValue={handleChangeFiltros} />;

      default:
        return null;
    }
  };

  const handleChangeFiltros = (value) => {
    comprobantesSeleccionados.filter((val) => val.eiffelId === value.eiffelId)
      .length > 0
      ? removeItemFromArr(comprobantesSeleccionados, value)
      : comprobantesSeleccionados.push(value);
  };

  const removeItemFromArr = (arr, item) => {
    var i = arr.indexOf(item);

    if (i !== -1) {
      arr.splice(i, 1);
    }
  };

  const handleCloseDialog = () => {
    setDialogState({
      show: false,
      content: "",
      title: "",
    });
  };

  const onItemClick = (selectedItem) => {
    setDialogState({
      show: true,
      content: selectedItem.id,
      title: selectedItem.title,
    });
  };

  const onGuardarCambiosClick = () => {
    switch (dialogState.content) {
      case "ventas":
        return props.onPostComprobantesVentas(comprobantesSeleccionados);

      case "pedidos":
        return props.onPostComprobantesPedidos(comprobantesSeleccionados);

      default:
        return null;
    }
  };

  const enableGuardarCambiosButton = !props.loadingPostComprobantes;

  return (
    <div>
      <Menu
        title={
          <SettingsIcon
            style={{
              color: "white",
              cursor: "pointer",
              borderRadius: "5px",
              fontSize: props.iconSize,
            }}
          />
        }
        listItems={listItems}
        onItemClick={(selectedItem) => onItemClick(selectedItem)}
      />
      <Dialog
        title={
          <div classes={classes.titleContent} style={{ width: "100%" }}>
            <div className={classes.titleTexto}>
              <Typography variant="h5">{dialogState.title}</Typography>
              <IconButton
                aria-label="close"
                onClick={() => handleCloseDialog()}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.subtitleTexto}>
              <Typography variant="caption">
                {
                  "Seleccione los comprobantes que desea tomar en cuenta para el resultado de los indicadores"
                }
              </Typography>
            </div>
          </div>
        }
        fullWidth={false}
        show={dialogState.show}
        body={
          <div
            className={classes.bodyDialog}
            style={
              props.loadingBodyDialog
                ? { display: "flex", justifyContent: "center" }
                : undefined
            }
          >
            {showContent()}
          </div>
        }
        onClose={handleCloseDialog}
        actions={
          <div style={{ display: "flex" }}>
            <Button
              disabled={!enableGuardarCambiosButton}
              onClick={() => {
                onGuardarCambiosClick();
              }}
            >
              Guardar cambios
            </Button>
          </div>
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingPostComprobantes: state.configuraciones.postComprobantes.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPostComprobantesVentas: (comprobantes) =>
      dispatch(actions.postComprobantesVentas(comprobantes)),
    onPostComprobantesPedidos: (comprobantes) =>
      dispatch(actions.postComprobantesPedidos(comprobantes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuComprobantes);
