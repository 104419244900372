// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DetailTiemposReq__eyeButton__nF-d2 {\r\n  cursor: pointer;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eyeButton": "DetailTiemposReq__eyeButton__nF-d2"
};
export default ___CSS_LOADER_EXPORT___;
