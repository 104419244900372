import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as actions from "../../../store/actions";
import { Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ArticuloImageUpload from "./ArticuloImageUpload";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    margin: '10px',
    maxWidth: '850px'
  },
  galleryRoot: {
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListTile:{
    width: '200px',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  image:{
    width: '100%',
    height: '100%',
  },
  title:{
    fontSize: '20px',
    marginBottom: '10px',
  },
  images:{    
    marginTop: '10px',
    marginBottom: '10px',
  },
  upload:{    
    marginTop: '10px',
    marginBottom: '10px',
  }
}));

const ArticuloImages = (props) => {
  
  const { 
    images,
    idArticulo, 
    updateImages,
    removeImages,
  } = props;

  const classes = useStyles();
  
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const onUpdateImages = (newImages) => {
    updateImages(newImages);
    forceUpdate();
  };   

  const onEliminarImagen = (imagen) => {
    const requestData = {
      idArticulo: idArticulo,
      imagenes: [imagen],
    }

    removeImages(requestData);

    let filteredImages = images.filter(image => image.id !== imagen.id);
    onUpdateImages(filteredImages);
  }

  const gallery = (galleryImages) => { 
    
    return (
      <div className={classes.images}>
        <div className={classes.title}>
          <span>Galería</span>
        </div>
        <div className={classes.galleryRoot}>
          <GridList className={classes.gridList} cellHeight={150} cols={4.5}>
            {galleryImages.map((tile) => (
              <GridListTile className={classes.gridListTile} key={tile.id}>
                <img className={classes.image} src={tile.url} alt={tile.name} />
                <GridListTileBar
                  title={tile.name}
                  actionIcon={
                    <IconButton 
                      aria-label={`Delete ${tile.name}`} 
                      className={classes.icon}
                      onClick={() => onEliminarImagen(tile)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))}
          </GridList>
        </div>
      </div>
    );
  }

  const addImages = () => {
    return (
      <div className={classes.upload}>
        <div className={classes.title}>
          <span>Agregar imágenes</span>
          </div>      
            <ArticuloImageUpload
              idArticulo={idArticulo} 
              artImages={images}
              updateImages={onUpdateImages}
            />
          <div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          {gallery(images)}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12}>
          {addImages()}
        </Grid>
      </Grid>      
    </div>
  )
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    removeImages: (data) => dispatch(actions.removeImages(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticuloImages);