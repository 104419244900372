import * as actionTypes from "./actionTypes";

export const updateUltimaActualizacionFotoDelDia = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA,
    fechaHora,
  };
};

export const clearUltimaActualizacionFotoDelDia = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA,
  };
};

export const updateUltimaActualizacionCliente = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_CLIENTE,
    fechaHora,
  };
};

export const clearUltimaActualizacionCliente = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_CLIENTE,
  };
};

export const updateUltimaActualizacionCatalogo = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_CATALOGO,
    fechaHora,
  };
};

export const clearUltimaActualizacionCatalogo = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_CATALOGO,
  };
};

export const updateUltimaActualizacionTiemposRequest = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST,
    fechaHora,
  };
};

export const clearUltimaActualizacionTiemposRequest = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST,
    fechaHora,
  };
};

export const updateUltimaActualizacionChangeLogs = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_CHANGE_LOGS,
    fechaHora,
  };
};

export const clearUltimaActualizacionChangeLogs = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_CHANGE_LOGS,
    fechaHora,
  };
};

export const updateUltimaActualizacionAnalisisDeVentas = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_VENTAS,
    fechaHora,
  };
};

export const clearUltimaActualizacionAnalisisDeVentas = (fechaHora) => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_VENTAS,
    fechaHora,
  };
};