import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber, isEven } from '../../../../assets/shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../assets/shared/sessionData';

import classes from './TablaVentasPorClienteDeLaCategoria.css';
import TablaVentasPorArticuloDeLaSeleccion from '../TablaVentasPorArticuloDeLaSeleccion/TablaVentasPorArticuloDeLaSeleccion';

const TablaVentasPorClienteDeLaCategoria = (props) => {
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.itemExpanded.mes;
  const anio = props.itemExpanded.anio;
  useEffect(() => {
    setLoading(true);
    props[dataOptions[props.dataSelectedId].onLoadKey](
      props.vendedorId,
      props.itemExpanded && props.itemExpanded.id,
      mes,
      anio,
      (response) => {
        setVentasLocal(response);
        setLoading(false);
        props.endRequest && props.endRequest(response.length);
      },
      () => {
        setVentasLocal([]);
        setLoading(false);
      }
    );
  }, []);

  const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoria de cliente',
      onLoadKey: 'onLoadVentasPorCliente'
    },
    provincia: {
      title: 'Provincia',
      onLoadKey: 'onLoadVentasPorArticulosDeLaProvincia'
    },
    zona: {
      title: 'Zona',
      onLoadKey: 'onLoadVentasPorClientesEnLaZona'
    },
    vendedor: {
      title: 'Vendedor',
      onLoadKey: 'onLoadVentasPorClienteDelVendedor'
    }
  };

  const columns = [
    {
      title: 'Cliente',
      field: 'cliente',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      }
    },
    {
      title: ``,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: ``,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        cliente: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion
      };
    });
  };

  const tableRef = useRef();

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 39 * indexRow;
      props.endRequest && props.endRequest();
    }
  };

  return (
    <div className={classes.tablaVentasPorClienteDeLaCategoria}>
      <Table
        tableRef={tableRef}
        style={{ width: '95%', marginTop: 0, marginBottom: '10px' }}
        title=""
        columns={columns}
        loading={loading}
        data={data()}
        detailPanel={(rowData) => {
          setTimeout(() => {
            autoScroll(rowData);
          }, 100);

          return (
            <TablaVentasPorArticuloDeLaSeleccion
              endRequest={() => autoScroll(rowData)}
              clientesPrimero
              vendedorId={props.vendedorId}
              dataSelectedId={props.dataSelectedId}
              itemExpanded={rowData}
              mes={mes}
              anio={anio}
            />
          );
        }}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 345,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasPorCliente: (idVendedor, idCategoria, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasPorClienteDeLaCategoria(idVendedor, idCategoria, mes, anio, success, errorFail)),

    onLoadVentasPorArticulosDeLaProvincia: (idVendedor, idProvincia, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorClienteEnLaPrivincia(idVendedor, idProvincia, mes, anio, success, errorFail)),

    onLoadVentasPorClientesEnLaZona: (idVendedor, idZona, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorClienteEnLaZona(idVendedor, idZona, mes, anio, success, errorFail)),

    onLoadVentasPorClienteDelVendedor: (idVendedor, undef, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorClienteDelVendedor(mes, anio, idVendedor, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasPorClienteDeLaCategoria);
