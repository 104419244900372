import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import TextChart from "../../../Charts/TextChart";

import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc,
} from "../../../../assets/shared/fechas";

const TextoVentasMensuales = (props) => {
  const {
    onLoadChart,
    loading,
    loadingPorCliente,
    mesActual,
    mesAnterior,
    clientSelected,
    inClientInfo,
    mesActualPorCliente,
    mesAnteriorPorCliente,
    estaCargado,
    error,
    ejecucionDePagina,
  } = props;

  const vendedorSelected = sessionStorage.getItem("vendedorId");
  const chartSinDatos = error || (!inClientInfo && !vendedorSelected);

  useEffect(() => {
    if (!inClientInfo && !estaCargado && vendedorSelected) {
      onLoadChart();
    }
  }, [onLoadChart, inClientInfo, estaCargado, vendedorSelected]);

  const fechaTitulo = `${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()}`;
  const isDisabledChart = props.estaOculto;

  return (
    <TextChart
      title={`Ventas del mes ${fechaTitulo}`}
      bigAmount={inClientInfo ? mesActualPorCliente : mesActual}
      bigAmountTooltip={`Período ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} `}
      smallAmount={inClientInfo ? mesAnteriorPorCliente : mesAnterior}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} al ${fechaFinPeriodoMesAnteriorFunc()}`}
      loading={inClientInfo ? loadingPorCliente : loading}
      porcentaje
      bigCurrency
      smallCurrency
      disabled={isDisabledChart}
      border={props.border && !props.error}
      noClient={!clientSelected && inClientInfo}
      imgSinDatos={chartSinDatos}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.ventasMesComparativo.loading,
    loadingPorCliente: state.ventas.ventasMesComparativo.loadingPorCliente,
    error: state.ventas.ventasMesComparativo.error,
    estaCargado: state.ventas.ventasMesComparativo.estaCargado,
    mesActual: state.ventas.ventasMesComparativo.montoMesActual,
    mesAnterior: state.ventas.ventasMesComparativo.montoMesAnterior,
    mesActualPorCliente: state.ventas.ventasMesComparativo.montoMesActualPorCliente,
    mesAnteriorPorCliente: state.ventas.ventasMesComparativo.montoMesAnteriorPorCliente,
    clientSelected: state.clientes.clienteById.clientSelected,
    clienteId: state.clientes.clienteById.cliente.idCliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: () => dispatch(actions.loadVentasMesComparativo()),
    onUnmountChart: () => dispatch(actions.unmountClienteById()),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Ventas del mes', ['venta']))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextoVentasMensuales);
