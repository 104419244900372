import { Typography } from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { storageVendedorId } from "../../../assets/shared/sessionData";

import * as actions from "../../../store/actions";

import Spinner from "../Spinner/Spinner";
import FormControl from "@material-ui/core/FormControl";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { Fragment } from "react";

const VendedorDisplay = (props) => {
  const { 
    vendedores, 
    onLoadVendedores, 
  } = props;

  const [vendedor, setVendedor] = useState(null);

  const onLoadSuccess = useCallback((response) =>{
    const items = response.data;
    const item = items.find((i) => i.idVendedor === storageVendedorId());

    if(item !== null && item !== undefined)
      setVendedor(item.nombre);
  }, []);

  useEffect(() => {
    if (vendedores.length === 0) 
      onLoadVendedores(onLoadSuccess);
  }, [vendedores, onLoadVendedores, onLoadSuccess]);

  const displayName = (name) => {
    return name ? (
      <Fragment>
        <AssignmentIndIcon />
          <FormControl style={{ margin: "0px 10px 0px 10px", color: "white" }}>
            <Typography>
              {vendedor}
            </Typography>
          </FormControl>
      </Fragment>
    ) : <Fragment></Fragment>
  }

  return props.loadingVendedores ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: "flex", alignItems: "center" }}>
      {displayName(vendedor)}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingVendedores: state.vendedores.vendedores.loading,
    vendedores: state.vendedores.vendedores.vendedores,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVendedores: (success) => dispatch(actions.loadVendedores(success)),   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendedorDisplay);