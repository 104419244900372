// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalDetallesComprobante__infoComprobanteContainer__c6p4L {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  flex-direction: column;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.ModalDetallesComprobante__infoComprobanteRow__2Jqj8 {\r\n  display: flex;\r\n}\r\n\r\n.ModalDetallesComprobante__titleDescriptionContainer__9NW6l {\r\n  padding: 0;\r\n}\r\n\r\n.ModalDetallesComprobante__title__1JJ5_ {\r\n  font-family: 'Roboto';\r\n  font-size: small;\r\n  font-weight: normal;\r\n  color: #474747ad;\r\n  margin-bottom: 5px !important;\r\n}\r\n\r\n.ModalDetallesComprobante__description__1FXdI {\r\n  box-sizing: border-box;\r\n  font-family: 'Roboto';\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n}\r\n\r\n.ModalDetallesComprobante__descriptionSmall__12IRP {\r\n  box-sizing: border-box;\r\n  font-family: 'Roboto';\r\n  font-size: 12px;\r\n  font-weight: 500;\r\n}\r\n\r\n.ModalDetallesComprobante__titleTotales__1oVzb {\r\n  font-size: small;\r\n  font-weight: 500;\r\n  margin-bottom: 10px !important;\r\n  text-align: right;\r\n}\r\n\r\n.ModalDetallesComprobante__montoTotales__1tYM0 {\r\n  text-align: right;\r\n}\r\n\r\n.ModalDetallesComprobante__TitleCard__PyKFq {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n  background: rgb(251, 175, 64);\r\n  background: linear-gradient(90deg, rgba(251, 175, 64, 1) 0%, rgba(241, 91, 41, 1) 100%);\r\n  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#fbaf40\",endColorstr=\"#f15b29\",GradientType=1);\r\n  margin: -15px -15px 15px -15px;\r\n  padding-bottom: 10px;\r\n  padding-left: 15px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoComprobanteContainer": "ModalDetallesComprobante__infoComprobanteContainer__c6p4L",
	"infoComprobanteRow": "ModalDetallesComprobante__infoComprobanteRow__2Jqj8",
	"titleDescriptionContainer": "ModalDetallesComprobante__titleDescriptionContainer__9NW6l",
	"title": "ModalDetallesComprobante__title__1JJ5_",
	"description": "ModalDetallesComprobante__description__1FXdI",
	"descriptionSmall": "ModalDetallesComprobante__descriptionSmall__12IRP",
	"titleTotales": "ModalDetallesComprobante__titleTotales__1oVzb",
	"montoTotales": "ModalDetallesComprobante__montoTotales__1tYM0",
	"TitleCard": "ModalDetallesComprobante__TitleCard__PyKFq"
};
export default ___CSS_LOADER_EXPORT___;
