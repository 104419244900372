import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  comprobantePedido: {
    comprobante: null,
    loading: false,
    error: null,
    estaCargado: false,
  },
  sendPedido: {
    pedido: null,
    loading: false,
    error: false,
    estaEnviado: false,
  }
}

//#region load Comprobante Pedido
const loadComprobantePedidoStart = (state) => {
  return updateObject(state, {
    comprobantePedido: updateObject(state.comprobantePedido, {
      loading: true,
      estaCargado: false,
    }),
  });
};

const loadComprobantePedidoSuccess = (state, comprobante) => {
  return updateObject(state, {
    comprobantePedido: updateObject(state.comprobantePedido, {
      comprobante,
      estaCargado: true,
      loading: false,
    }),
  });
};

const loadComprobantePedidoFail = (state, error) => {
  return updateObject(state, {
    comprobantePedido: updateObject(state.comprobantePedido, {
      error,
      loading: false,
      estaCargado: false,
    }),
  });
};

//#endregion

//#region Send Pedido
const sendPedidoStart = (state) => {
  return updateObject(state, {
    sendPedido: updateObject(state.sendPedido, {
      loading: true,
      estaEnviado: false,
      error: false,
    }),
  });
};

const sendPedidoSuccess = (state, pedido) => {
  return updateObject(state, {
    sendPedido: updateObject(state.sendPedido, {
      pedido,
      error: false,
      estaEnviado: true,
      loading: false,
    }),
  });
};

const sendPedidoFail = (state, error) => {
  return updateObject(state, {
    sendPedido: updateObject(state.sendPedido, {
      error: true,
      loading: false,
      estaEnviado: true,
    }),
  });
};

//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.LOAD_COMPROBANTE_PEDIDO_START:
      return loadComprobantePedidoStart(state);
    case actionTypes.LOAD_COMPROBANTE_PEDIDO_SUCCESS:
      return loadComprobantePedidoSuccess(state, action.result);
    case actionTypes.LOAD_COMPROBANTE_PEDIDO_FAIL:
      return loadComprobantePedidoFail(state, action.error);

    case actionTypes.SEND_PEDIDO_START:
      return sendPedidoStart(state);
    case actionTypes.SEND_PEDIDO_SUCCESS:
      return sendPedidoSuccess(state, action.result);
    case actionTypes.SEND_PEDIDO_FAIL:
      return sendPedidoFail(state, action.error);

    default:
      return state;
  }
};

export default reducer;