import moment from 'moment';
import { storageFechaFija } from './sessionData';

export const fechaActualFunc = () => {
  const fechaFija = moment(storageFechaFija(), 'MM-DD-YYYY hh:mm:ss');
  const fechaActual = !fechaFija._isValid ? new Date() : fechaFija;
  return new Date(fechaActual);
};

export const fechaInicioPeriodoMesActualFunc = () => {
  return `01/${moment(fechaActualFunc()).format('MM/YY')}`;
};

export const fechaFinPeriodoMesActualFunc = () => {
  return moment(fechaActualFunc()).format('DD/MM/YY');
};

export const fechaInicioPeriodoMesAnteriorFunc = () => {
  return `01/${moment(fechaActualFunc()).subtract(1, 'month').format('MM/YY')}`;
};

export const fechaFinPeriodoMesAnteriorFunc = () => {
  return `${moment(fechaActualFunc()).subtract(1, 'month').format('DD/MM/YY')}`;
};

export const fechaFinPeriodoUltimoDiaMesAnteriorFunc = () => {
  return `${moment(fechaActualFunc()).subtract(1, 'month').endOf('month').format('DD/MM/YY')}`;
};

export const compareFechaActualFechaFija = () => {
  const actualDate = moment(new Date()).format('DD/MM/yyyy');
  const fechaFijaDate = moment(fechaActualFunc()).format('DD/MM/yyyy');

  return actualDate === fechaFijaDate;
};
