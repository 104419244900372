import React, { useState, useContext, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import AgregarCarritoButton from "../AgregarCarritoButton/AgregarCarritoButton";
import { CarritoContext } from '../../../store/contexts/carrito';
import { errorListaDePreciosVacia } from "../../../assets/shared/errorMessages";
import { esValido } from "../../../assets/shared/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  text: {
    textAlign: "center",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px"
    },
    "& .MuiInputBase-input": {
      textAlign: 'center',
      minWidth: '35px',
      paddingTop: '13px',
      paddingBottom: '13px',
      paddingRight: '4px',
      paddingLeft: '4px',
      width: '35px',
      height: '18px',
    }
  },
  buttonLeft: {
    fontSize: "large",
    minWidth: '35px',
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "0px",

    "& .MuiButton-label":{
      minWidth: '11px',
    }
  },
  buttonRight: {
    fontSize: "large",
    minWidth: '35px',
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "4px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "4px",

    "& .MuiButton-label":{
      minWidth: '11px',
    }
  }, 
  actionIcon: {
    color: '#00BCD4'
  }
}));

const AgregarCarritoItem = (props) => {
  const {
    articulo,
    listasDePrecios,
    filtroListaPrecio,
  } = props;

  const { 
    addArticulo, 
    removeArticulo, 
    increase, 
    decrease,
    carritoItems,
    updateCantidad,
  } = useContext(CarritoContext);

  const [added, setAdded] = useState(false);
  const [items, setItems] = useState(1);
  
  const onLeftButtonClick = () => {
    let newValue = items - 1;

    if(newValue <= 0){
      onRemoveClick();
    }else{
      setItems(newValue);
      decrease(articulo);
    }
  }

  const onRightButtonClick = () => {
    setItems(items + 1);
    increase(articulo);
  }

  const onRemoveClick = useCallback(() =>{
    setItems(0);
    setAdded(false);
    removeArticulo(articulo);
  }, [setItems, setAdded, removeArticulo]);
  
  const onItemChange = useCallback((e) => {
    let newValue = Number(e.target.value);

    if(newValue <= 0){
      onRemoveClick();
    }else{
      updateCantidad(articulo, newValue);
      setItems(newValue);
    }
  }, [articulo, updateCantidad, setItems, onRemoveClick]);

  const onAddClick = () => {
    if(!esValido(filtroListaPrecio) || listasDePrecios === null || listasDePrecios === undefined){
      alert(errorListaDePreciosVacia);
      return;
    }    

    let listaDePrecio = listasDePrecios.find(precio => precio.listaDePreciosId === filtroListaPrecio);
  
    if(listaDePrecio !== null && listaDePrecio !== undefined){
      articulo.precio = listaDePrecio.monto;
    }

    setItems(1);
    setAdded(true);
    addArticulo(articulo);
  }

  useEffect(() => {
    if(!added){
      let item = carritoItems.find(carritoItem => carritoItem.idArticulo === articulo.idArticulo);

      if(item !== null && item !== undefined){
        setAdded(true);
        setItems(item.cantidad);
      }
    }
  }, [added, carritoItems, articulo]);

  return added 
    ? (
      <AgregarItemButton 
        items={items}
        onLeftButtonClick={onLeftButtonClick}
        onRightButtonClick={onRightButtonClick}
        onChange={onItemChange}
        onRemove={onRemoveClick} 
      />
    )
    : (
      <AgregarCarritoButton onAddClick={onAddClick} />
    );
}

const AgregarItemButton = ({items, onLeftButtonClick, onRightButtonClick, onChange, onRemove }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={2} md={3} xl={2}>
        <Button 
          variant="contained" 
          onClick={onLeftButtonClick} 
          className={classes.buttonLeft}>
          -
        </Button>      
      </Grid> 
      <Grid item xs={3} md={3} xl={2} style={{ marginTop: '1px'}}>
        <TextField 
          className={classes.text} 
          value={items} 
          variant="outlined" 
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={3} md={3} xl={2}>
        <Button 
          variant="contained"
          className={classes.buttonRight} 
          onClick={onRightButtonClick}
        >
          +
        </Button>
      </Grid>
      <Grid item xs={1} md={1} xl={2}></Grid>
      <Grid item xs={2} md={1} xl={2}>
        {items >= 1 && 
          <IconButton  className={classes.buttonLeft} onClick={onRemove}>
            <DeleteIcon className={classes.actionIcon} />
          </IconButton>
        }
      </Grid>
      <Grid item xs={1} md={1} xl={2}></Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    filtroListaPrecio: state.articulos.filtrosArticulosPorCliente.listaPrecioId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgregarCarritoItem);