import React from "react";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      color: "white",
    },
  },
  rootDefault: {
    "& > *": {
      margin: theme.spacing(1),
    },
  }
}));

const Buton = (props) => {
  const classes = useStyles();
  const color = props.color ? props.color : "primary";
  const variant = props.variant ? props.variant : "contained";
  const className = props.color && props.color === "default" 
    ? classes.rootDefault 
    : classes.root;
  const icon = props.startIcon ? props.startIcon : <div></div>;
  const size = props.size ? props.size : "medium";

  return (
    <div className={className} style={props.rootStyle}>
      <Button
        style={props.style}
        type={props.submit ? "submit" : "button"}
        variant={variant}
        color={color}
        disabled={props.disabled || props.loading}
        onClick={props.onClick}
        startIcon={icon}
        size={size}
      >
        {props.loading ? <CircularProgress size={24} color={color} /> : props.children}
      </Button>
    </div>
  );
};

export default Buton;
