// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DialogComprobantes__titleContent__2f8qu {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-right: 5px;\r\n}\r\n\r\n.DialogComprobantes__titleTexto__gwdOY {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n.DialogComprobantes__subtitleTexto__robgu {\r\n  margin-top: -10px;\r\n  margin-left: 2px;\r\n  margin-bottom: 10px;\r\n  margin-right: 5px;\r\n}\r\n\r\n.DialogComprobantes__titleEmpresas__2ficT {\r\n}\r\n\r\n.DialogComprobantes__bodyDialog__ZXNnd {\r\n  align-items: center;\r\n  display: flex;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContent": "DialogComprobantes__titleContent__2f8qu",
	"titleTexto": "DialogComprobantes__titleTexto__gwdOY",
	"subtitleTexto": "DialogComprobantes__subtitleTexto__robgu",
	"titleEmpresas": "DialogComprobantes__titleEmpresas__2ficT",
	"bodyDialog": "DialogComprobantes__bodyDialog__ZXNnd"
};
export default ___CSS_LOADER_EXPORT___;
