import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = (props) => {
  let size = 150;

  if (props.size && props.size === 'small') size = 50;

  return (
    <CircularProgress
      style={
        ({
          marginTop: size / 4,
          color: props.color && props.color
        },
        props.personalSize && { width: props.personalSize, height: props.personalSize })
      }
      size={size}
    />
  );
};

export default Spinner;
