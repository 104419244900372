export const storageFechaFija = () => {
  return sessionStorage.getItem("fechaFija");
};

export const storageToken = () => {
  return sessionStorage.getItem("token");
};

export const buildBearerToken = () => {
  return `Bearer ${storageToken()}`;
};

export const storageExpirationDate = () => {
  return sessionStorage.getItem("expirationDate");
};

export const storageMultiempresa = () => {
  return sessionStorage.getItem("multiempresa");
};

export const storageEmpresaId = () => {
  return parseInt(sessionStorage.getItem("empresaId"), 10);
};

export const storageUserEmail = () => {
  return sessionStorage.getItem("userEmail");
};

export const storageMonedaId = () => {
  return sessionStorage.getItem("monedaId");
};

export const storageMonedaSigno = () => {
  return sessionStorage.getItem("monedaSigno");
};

export const storageVendedorId = () => {
  const vendedorId = sessionStorage.getItem("vendedorId");
  return vendedorId ? vendedorId : "";
};

export const storageClientId = () => {
  const clienteId = sessionStorage.getItem("clienteId");
  return clienteId ? clienteId : "";
};

export const storageListaPreciosId = () => {
  const value = sessionStorage.getItem("listaPrecioId");
  return value ? value : "";
};

export const storageListaPreciosIdCliente = () => {
  const value = sessionStorage.getItem("listaPrecioIdCliente");
  return value ? value : "";
};

export const userDefault = () => {
  return sessionStorage.getItem("default") === "true";
};

export const userConsultor = () => {
  return sessionStorage.getItem("consultor") === "true";
};

export const userVendedor = () => {
  return sessionStorage.getItem("vendedor") === "true";
};

export const userAdministrador = () => {
  return sessionStorage.getItem("administrador") === "true";
};

export const userAdministradorAplicaciones = () => {
  return sessionStorage.getItem("administradorAplicaciones") === "true";
};

export const userGauss = () => {
  return sessionStorage.getItem("gauss") === "true";
};

export const userDemo = () => {
  return sessionStorage.getItem("demo") === "true";
};

export const userCliente = () => {
  return sessionStorage.getItem("cliente") === "true";
};

export const userFullName = () => {
  return sessionStorage.getItem("userFullName");
};

export const getUserInfo = () => {
  return JSON.parse(sessionStorage.getItem("userInfo"));
};

export const storageEmpresaNombre = () => {
  return sessionStorage.getItem("empresaNombre");
};

export const storageSeccionNombre = () => {
  return sessionStorage.getItem("seccionNombre");
};

export const storageIsVendedor = () => {
  return sessionStorage.getItem("isVendedor") === "true";
};
