import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import CloseIcon from '@material-ui/icons/Close';

import { Grid, IconButton, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import classes from './ModalDetallesComprobante.css';
import { storageMonedaSigno } from '../../../../assets/shared/sessionData';
import { formatNumberWithMonedaSigno } from '../../../../assets/shared/formatNumber';
import TableDetallesComprobante from './TablaDetallesComprobante';
import AccordionObservacionesComprobante from './AccordionObservacionesComprobante';
import moment from 'moment';
import Spinner from '../../../UI/Spinner/Spinner';
import TableDetallesRecibo from './TablaDetallesRecibo';

const ModalDetallesComprobante = (props) => {
  const { onShowModal, onChangeModalBody, onLoadDetallesComprobante, comprobante } = props;
  const { idComproba, idSucursal, idSistema, fecha, proveedor, referencia, numeroExterno, cobranza } = comprobante;
  
  const buildInfoTotal = (data) => {
    const titlePorcBonif1 = data.porcentajeBonificacion1 ? `B. 1 (${data.porcentajeBonificacion1})` : 'B. 1 (0.00)';
    const titlePorcBonif2 = data.porcentajeBonificacion2 ? `B. 2 (${data.porcentajeBonificacion2})` : 'B. 2 (0.00)';

    return (
      <Grid container>
        <Grid container item xs={11} justify="flex-start" spacing={4}>
          <TitleMontoTotales title="Subtotal" monto={data.subtotal} simbolo={data.simboloMoneda}/>
          <TitleMontoTotales title={titlePorcBonif1} monto={data.bonificacion1} simbolo={data.simboloMoneda}/>
          <TitleMontoTotales title={titlePorcBonif2} monto={data.bonificacion2} simbolo={data.simboloMoneda}/>
          <TitleMontoTotales title="Subtotal" monto={data.subtotalBonificado} simbolo={data.simboloMoneda}/>
          <TitleMontoTotales title="Impuestos" monto={data.impuestos} simbolo={data.simboloMoneda}/>
        </Grid>
        <Grid container item xs={1} justify="flex-end">
          <TitleMontoTotales title="Total" monto={data.importe} simbolo={data.simboloMoneda}/>
        </Grid>
      </Grid>
    );
  };

  const TitleMontoTotales = ({ title, monto, xs, simbolo} = props) => {
    return (
      <Grid item xs={xs} className={classes.titleDescriptionContainer}>
        <Grid item className={classes.titleTotales}>
          {title}
        </Grid>
        <Grid item className={classes.montoTotales}>
          {formatNumberWithMonedaSigno(monto, simbolo)}
        </Grid>
      </Grid>
    );
  };

  const InfoComprobanteContainer = (props) => {
    return (
      <Grid className={classes.infoComprobanteContainer} container spacing={1}>
        {props.children}
      </Grid>
    );
  };

  const InfoComprobanteRow = (props) => {
    return (
      <Grid className={classes.infoComprobanteRow} container item xs={12} spacing={5}>
        {props.children}
      </Grid>
    );
  };

  const InfoTitleDescription = ({ xs, title, description, descriptionSmall, style } = props) => {
    return (
      <Grid style={style} item xs={xs} className={classes.titleDescriptionContainer}>
        <Grid item className={classes.title}>
          {title}
        </Grid>
        <Grid item className={classes.description}>
          {description}
        </Grid>
        <Grid item className={classes.descriptionSmall}>
          {descriptionSmall}
        </Grid>
      </Grid>
    );
  };

  const buildInfoComprobante = (data) => {
    return (
      <InfoComprobanteContainer>
        <InfoComprobanteRow>
          <InfoTitleDescription title={proveedor ? 'Proveedor' : 'Cliente'} description={proveedor ? proveedor : data.cliente.trim()} xs={3} />
          <InfoTitleDescription title="Comprobante" description={data.comprobante} xs={2} />
          <InfoTitleDescription title="Número" description={numeroExterno} xs={3} />
          <InfoTitleDescription title="Fecha" description={moment(fecha).format('DD/MM/YY')} xs={1} />
          <InfoTitleDescription title="Cond. de pago" description={data.condicionPago} xs={3} />
        </InfoComprobanteRow>
        <InfoComprobanteRow>
          <InfoTitleDescription title="Dirección" descriptionSmall={data.direccion.trim()} xs={3} />
          <InfoTitleDescription title="Localidad" descriptionSmall={data.localidad.trim()} xs={2} />
          <InfoTitleDescription title="Provincia" descriptionSmall={data.provincia.trim()} xs={3} />
          <InfoTitleDescription title="Referencia" description={referencia} xs={1} />
          <InfoTitleDescription title="Estado comprobante" descriptionSmall={data.estado} xs={3} />
        </InfoComprobanteRow>
      </InfoComprobanteContainer>
    );
  };

  const buildModalHeader = () => {
    return (
      <div className={classes.TitleCard}>
        <p style={{ fontWeight: 'bold', marginBottom: '1px' }}>DETALLE DE COMPROBANTE</p>
        <div>
          <IconButton aria-label="close" onClick={() => props.onCloseShowModal()}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  const buildModalFooter = (data) => {
    let monto = 0; 
    if(comprobante.debe)
      monto = formatNumberWithMonedaSigno(0, storageMonedaSigno()) === comprobante.debe ? comprobante.haber : comprobante.debe
    else
      monto = formatNumberWithMonedaSigno(comprobante.monto, storageMonedaSigno())
    return (
      <InfoComprobanteContainer>
        <InfoComprobanteRow>
          <InfoTitleDescription title="Confección" descriptionSmall={moment(data.fechaConfeccion).format('DD/MM/YY')} xs={1} />
          <InfoTitleDescription title="Cotización" descriptionSmall={`${formatNumberWithMonedaSigno(1, data.simboloMoneda)} = ${formatNumberWithMonedaSigno(data.cotizacion, '$')}`} xs={1.25} />
          <InfoTitleDescription title={`Total ${storageMonedaSigno()}`} descriptionSmall={`${monto}`}/>
          {/* <InfoTitleDescription title="Usuario" descriptionSmall={'USUARIO TEST'} xs={3} /> */}
        </InfoComprobanteRow>
      </InfoComprobanteContainer>
    );
  };

  const bodyComprobante = (data) => {
    return (
      <Fragment>
        {buildModalHeader()}
        {buildInfoComprobante(data)}
        <div style={{ marginBottom: '10px' }} />

        {data.observaciones && data.observaciones.length !== 0 && (
          <AccordionObservacionesComprobante observaciones={data.observaciones} />
        )}
        <TableDetallesComprobante articulos={data.detalleComprobante} simbolo={data.simboloMoneda}/>
        <div style={{ marginBottom: '10px' }} />
        {buildInfoTotal(data)}

        <hr />
        {buildModalFooter(data)}
      </Fragment>
    )
  }

  //#region Detalle Recibos
  const buildInfoRecibo = (data) => {
    return (
      <InfoComprobanteContainer>
        <InfoComprobanteRow>
          <InfoTitleDescription title='Cliente' description={proveedor ? proveedor : data.cliente.trim()} xs={3} />
          <InfoTitleDescription title="Comprobante" description={data.comprobante} xs={2} />
          <InfoTitleDescription title="Número" description={numeroExterno} xs={3} />
          <InfoTitleDescription title="Fecha" description={moment(fecha).format('DD/MM/YY')} xs={1} />
        </InfoComprobanteRow>
        <InfoComprobanteRow>
          <InfoTitleDescription title="Dirección" descriptionSmall={data.direccion.trim()} xs={3} />
          <InfoTitleDescription title="Localidad" descriptionSmall={data.localidad.trim()} xs={2} />
          <InfoTitleDescription title="Provincia" descriptionSmall={data.provincia.trim()} xs={3} />
          <InfoTitleDescription title="Referencia" description={referencia} xs={1} />
          <InfoTitleDescription title="Estado comprobante" descriptionSmall={data.estado} xs={3} />
        </InfoComprobanteRow>
      </InfoComprobanteContainer>
    );
  };

  const bodyRecibo = (data) => {
    return (
      <Fragment>
        {buildModalHeader()}
        {buildInfoRecibo(data)}
        <div style={{ marginBottom: '10px' }} />

        {data.observaciones && data.observaciones.length !== 0 && (
          <AccordionObservacionesComprobante observaciones={data.observaciones} />
        )}
        <TableDetallesRecibo articulos={data.detalleComprobante} />
        <div style={{ marginBottom: '10px' }} />
        {buildInfoTotal(data)}

        <hr />
        {buildModalFooter(data)}
      </Fragment>
    )
  }

  //#endregion

  const ModalBody = (data) => {
    return data 
    ? cobranza ? bodyRecibo(data) : bodyComprobante(data)
    : (
      <div style={{ textAlign: 'center' }}>
        No se pudo cargar el comprobante solicitado. Por favor, intente más tarde.
      </div>
    );
  };

  const onLoadComprobanteSuccess = (detallesComprobante) => {
    setTimeout(() => {
      onChangeModalBody(ModalBody(detallesComprobante));
    }, 1000);
  }

  const onShowModalClick = () => {
    onShowModal(
      <div style={{ textAlign: 'center', minHeight: '170px' }}>
        <Spinner />
      </div>
    );
    onLoadDetallesComprobante(
      idSucursal,
      idSistema,
      idComproba,
      referencia,
      cobranza,
      onLoadComprobanteSuccess,
      (error) => {
        onChangeModalBody(<div style={{ textAlign: 'center' }}>Error al cargar los detalles del comprobante</div>);
      }
    );
  };

  return (
    <div style={{ textAlign: 'right' }}>
      <Tooltip title="Ver detalles">
        <IconButton size="small" onClick={() => onShowModalClick()}>
          <VisibilityIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};
const mapStateToProps = (state) => ({
  showModal: state.modals.globalModal.show
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseShowModal: () => dispatch(actions.showGlobalModal(false, '', undefined, undefined, true, true , 'lg')),
    onShowModal: (body) => dispatch(actions.showGlobalModal(true, '', body, undefined, true, true, 'lg')),
    onChangeModalBody: (newBody) => dispatch(actions.changeGlobalModalBody(newBody)),
    onLoadDetallesComprobante: (idSucursal, idSistema, idComproba, nroReferencia, cobranza, success, errorFail) =>
      dispatch(actions.loadDetalleComprobante(idSucursal, idSistema, idComproba, nroReferencia, success, errorFail, cobranza))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetallesComprobante);