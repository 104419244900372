import { fechaActualFunc } from '../../assets/shared/fechas';
import { storageVendedorId } from '../../assets/shared/sessionData';
import * as actions from "./index";


export const actualizarIndicadoresFotoDelDia = () => {
  return (dispatch) => {
    dispatch(actions.loadVentasMesComparativo());
    dispatch(actions.loadPedidosPendientes());
    dispatch(actions.loadPedidosMesComparativo());
    dispatch(actions.loadFacturasDelMesInteranual());
    dispatch(actions.loadPedidosDelMesInteranual());
    dispatch(actions.loadComisionComparativo());
    dispatch(actions.loadComisionEvolutivo());
    dispatch(actions.loadFacturasPendientes());
    dispatch(actions.loadComprobantesFacturasPendientes());
    dispatch(actions.loadEstadosPedidosPendientes());
    dispatch(actions.loadComprobantesPedidosPendientes());
    dispatch(actions.loadPedidosVencidos());
  };
};

export const clearIndicadoresFotoDelDia = () => {
  return (dispatch) => {
    dispatch(actions.clearVentasMesComparativo());
    dispatch(actions.clearPedidosPendientes());
    dispatch(actions.clearPedidosMesComparativo());
    dispatch(actions.clearEstadosPedidosPendientes());
    dispatch(actions.clearFacturasDelMesInteranual());
    dispatch(actions.clearPedidosDelMesInteranual());
    dispatch(actions.clearComisionComparativo());
    dispatch(actions.clearComisionEvolutivo());
    dispatch(actions.clearFacturasPendientes());   
    dispatch(actions.clearComprobantesFacturasPendientes());
    dispatch(actions.clearComprobantesPedidosPendientes());
    dispatch(actions.clearPedidosVencidos(false));
    dispatch(actions.clearPedidosVencidos(true));
  };
};

export const actualizarIndicadoresCliente = (idCliente) => {
  const idVendedor = storageVendedorId();

  return (dispatch) => {
    dispatch(actions.loadClienteById(idCliente, () => {}));
    dispatch(actions.loadVentasMesComparativo(idCliente));
    dispatch(actions.loadPedidosPendientes(idCliente));
    dispatch(actions.loadPedidosMesComparativo(idCliente));
    dispatch(actions.loadFacturasDelMesInteranual(idCliente));
    dispatch(actions.loadUltimaCompraByCliente(idCliente));
    dispatch(actions.loadFacturasPendientes(idCliente));
    dispatch(actions.loadEstadosPedidosPendientes(idCliente));
    dispatch(actions.loadEvolucionDeVentasMensualDelVendedor(idVendedor, idCliente));
    dispatch(actions.loadVentasPorVendedor(idCliente));
    dispatch(actions.loadChequesEnCartera(idCliente));
    dispatch(actions.loadChequesRechazados(idCliente));
    dispatch(actions.loadListadoChequesEnCartera(idCliente));
    dispatch(actions.loadListadoChequesRechazados(idCliente));
    dispatch(actions.loadLineChequesEnCartera(idCliente));
    dispatch(actions.loadComprobantesPedidosPendientes(idCliente));
    dispatch(actions.loadComprobantesFacturasPendientes(idCliente));
    dispatch(actions.loadCuentaCorrienteCliente(idCliente));
  };
};

export const clearIndicadoresCliente = () => {
  return (dispatch) => {
    dispatch(actions.unmountClienteById());
    dispatch(actions.clearVentasMesComparativoPorCliente());
    dispatch(actions.clearPedidosPendientesPorCliente());
    dispatch(actions.clearPedidosMesComparativoPorCliente());
    dispatch(actions.clearEstadosPedidosPendientesPorCliente());
    dispatch(actions.clearFacturasDelMesInteranualPorCliente());
    dispatch(actions.clearPedidosDelMesInteranualPorCliente());
    dispatch(actions.clearChequesRechazados());
    dispatch(actions.clearVentasAlClienteEvolutivo());
    dispatch(actions.clearUltimaCompraByCliente()); 
    dispatch(actions.clearFacturasPendientesPorCliente());
    dispatch(actions.clearComprobantesPedidosPendientesPorCliente());
    dispatch(actions.clearComprobantesFacturasPendientesPorCliente());
    dispatch(actions.clearEvolucionDeVentasMensualDelVendedorPorCliente());
    dispatch(actions.clearVentasPorVendedorPorCliente());
    dispatch(actions.clearPedidosVencidos(true));
    dispatch(actions.clearListadoChequesEnCartera());
    dispatch(actions.clearListadoChequesRechazados());
    dispatch(actions.clearCuentaCorrienteCliente());
    dispatch(actions.clearLineChequesEnCartera());
    dispatch(actions.clearLineChequesRechazados());
  };
};

export const actualizarTiemposReq = () => {
  return (dispatch) => {
    dispatch(actions.loadTiemposRequest());
  };
};

export const clearTiemposReq = () => {
  return (dispatch) => {
    dispatch(actions.clearTiemposReq());
  };
};

export const actualizarCatalogo = () => {
  return (dispatch) => {
    dispatch(actions.loadArticulosBusqueda());
    dispatch(actions.clearArticulosBusqueda());
    dispatch(actions.cambiarFiltroMarca(""));
    dispatch(actions.cambiarFiltroRubro(""));
    dispatch(actions.cambiarFiltroSubrubro(""));
    dispatch(actions.clearFiltrosArticulos());
    dispatch(actions.paginacionArticulos(0, 50));
  };
};

export const clearCatalogo = (inClientInfo = false) => {
  return (dispatch) => {
    dispatch(actions.clearArticulosStockPrecio(inClientInfo));
    dispatch(actions.clearArticulosBusqueda(inClientInfo));
    dispatch(actions.clearArticulosCodigoBarras(inClientInfo));
    dispatch(actions.cambiarFiltroMarca("", inClientInfo));
    dispatch(actions.cambiarFiltroRubro("", inClientInfo));
    dispatch(actions.cambiarFiltroSubrubro("", inClientInfo));
    dispatch(actions.cambiarFiltroListaPrecio("", inClientInfo));
    dispatch(actions.cambiarFiltroDeposito("TODOS", inClientInfo));
    dispatch(actions.clearFiltrosArticulos(inClientInfo));
    dispatch(actions.paginacionArticulos(0, 50, inClientInfo));
  };
};

export const actualizarChangeLogs = () => {
  return (dispatch) => {
    dispatch(actions.loadChangeLogs());
  };
};

export const clearChangeLogs = () => {
  return (dispatch) => {
    dispatch(actions.clearChangeLogs());
  };
};

export const actualizarAnalisisVentas = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { ejecucionAnalisisVentas } = state.ejecucionPaginas;
    const idVendedor = storageVendedorId();

    if (ejecucionAnalisisVentas) {
      const mes = fechaActualFunc().getMonth() + 1;
      const anio = fechaActualFunc().getFullYear();
      dispatch(actions.seleccionFechaVentasDelMesAnioDelVendedorPor(mes, anio));
      dispatch(actions.seleccionDataVentasDelMesDelVendedorPor('categoriaDeCliente'));
      dispatch(actions.seleccionDataVentasDelMesDeVendedoresPor('categoriaDeCliente'));

      dispatch(actions.loadEvolucionDeVentasMensualDelVendedor(idVendedor));
      dispatch(actions.loadVentasPorCategoriaDeClienteDelVendedor(idVendedor, mes, anio));
      dispatch(actions.loadEvolucionVentasDelVendedorPorCategoriaDeCliente(idVendedor));
      dispatch(actions.clearEvolucionVentasDelVendedorPorProvincias());
      dispatch(actions.clearEvolucionVentasDelVendedorPorMarcas());
      dispatch(actions.clearEvolucionVentasDelVendedorPorRubros());
      dispatch(actions.clearEvolucionVentasDelVendedorPorSubrubro());
      dispatch(actions.clearEvolucionVentasDelVendedorPorZonas());

      dispatch(actions.clearVentasDelMesPorMarcaDelVendedor());
      dispatch(actions.clearVentasDelMesPorRubroDelVendedor());
      dispatch(actions.clearVentasDelMesPorSubrubroDelVendedor());
      dispatch(actions.clearVentasDelMesPorProvinciaDelVendedor());
      dispatch(actions.clearVentasDelMesPorZonaDelVendedor());
    }
  };
};

export const clearAnalisisVentas = () => {
  return (dispatch) => {
    dispatch(actions.seleccionDataVentasDelMesDeVendedoresPor('categoriaDeCliente'));
    dispatch(actions.seleccionDataVentasDelMesDelVendedorPor('categoriaDeCliente'));

    dispatch(actions.clearEvolucionDeVentasMensualDelVendedor());
    dispatch(actions.clearVentasPorCategoriaDeClienteDelVendedor());
    dispatch(actions.clearVentasDelMesPorMarcaDelVendedor());
    dispatch(actions.clearVentasDelMesPorRubroDelVendedor());
    dispatch(actions.clearVentasDelMesPorSubrubroDelVendedor());
    dispatch(actions.clearVentasDelMesPorProvinciaDelVendedor());
    dispatch(actions.clearVentasDelMesPorZonaDelVendedor());
    dispatch(actions.clearVentasDeVendedoresDelMesPorMarca());
    dispatch(actions.clearVentasDeVendedoresDelMesPorRubro());
    dispatch(actions.clearVentasDeVendedoresDelMesPorSubrubro());
    dispatch(actions.clearVentassDeVendedoresDelMesPorProvincia());
    dispatch(actions.clearVentasDeVendedoresDelMesPorZona());
    dispatch(actions.clearVentasDeVendedoresDelMesPorVendedor());
    dispatch(actions.clearVentasPorVendedorPorCategoriaDeClienteDelMes());
    dispatch(actions.clearEvolucionDeVentasMensualDeVendedores());
    dispatch(actions.clearVentasPorVendedorPorMarcaDelMes());
    dispatch(actions.clearVentasPorVendedorPorRubroDelMes());
    dispatch(actions.clearVentasPorVendedorPorSubrubroDelMes());
    dispatch(actions.clearVentasPorVendedorPorProvinciaDelMes());
    dispatch(actions.clearVentasPorVendedorPoZonaDelMes());
    dispatch(actions.clearEvolucionVentasPorCategoriaDeCliente());
  };
};