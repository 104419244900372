import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';

import classes from "./FotoDelDia.css";

import EstadoPedidosPendientes from "../../components/Indicadores/ventas/PieEstadoPedidosPendientes/PieEstadoPedidosPendientes";
import PedidosPendientes from "../../components/Indicadores/ventas/TextoPedidosPendientes/TextoPedidosPendientes";
import PedidosPendientesComparativo from "../../components/Indicadores/ventas/TextoPedidosPendientesComparativo/TextoPedidosPendientesComparativo";
import VentasMensuales from "../../components/Indicadores/ventas/TextoVentasMensuales/TextoVentasMensuales";

import EmpresaSelectorButton from "../../components/UI/EmpresaSelector/EmpresaSelectorButton";
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';

import TablaFacturasPendientes from "../../components/Indicadores/ventas/TablaFacturasPendientes/TablaFacturasPendientes";
import PieFacturasPendientes from "../../components/Indicadores/ventas/PieFacturasPendientes/PieFacturasPendientes";
import TablaPedidosPendientes from "../../components/Indicadores/ventas/TablaPedidosPendientes/TablaPedidosPendientes";
import TextoPedidosVencidos from "../../components/Indicadores/ventas/TextoPedidosVencidos/TextoPedidosVencidos";

import { storageMultiempresa, storageVendedorId } from "../../assets/shared/sessionData";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  combinedChartsCard: {
    display: 'inline',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      margin: '5px 0px 0px 0px',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      backgroundColor: 'white',
      boxShadow: '0px 3px 6px #00000029',
    }
  },
  gridItem: {  
    [theme.breakpoints.up('md')]: {
      paddingInline: '5px',
    },   
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },
  piePendiente: {
    margin: '0px',
    [theme.breakpoints.down('md')]: {
      marginTop: '5px',
      marginBottom: '10px',
      backgroundColor: 'white',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      boxShadow: '0px 3px 6px #00000029',
    }
  },
}));

const FotoDelDia = (props) => {
  sessionStorage.setItem('seccionNombre', 'Foto del día');    
  const inPhone = window.screen.width <= 750;
  
  const classes2 = useStyles();
  const { ejecucionFotoDelDia, onLoadChart, estaCargadoPedidos, 
    estaCargadoFacturas, loadingPedidos, loadingFacturas } = props;

  const [idVendedor] = useState(storageVendedorId());
  const isMultiemp = storageMultiempresa() === 'true';

  useEffect(() => {
    if(ejecucionFotoDelDia && idVendedor !== null && idVendedor !== undefined
      && !estaCargadoPedidos && !estaCargadoFacturas && !loadingPedidos && !loadingFacturas){
      onLoadChart();
    }
  }, [
    idVendedor,
    ejecucionFotoDelDia,
    onLoadChart,
    estaCargadoFacturas,
    estaCargadoPedidos,
  ]);

  const renderFila1 = () => {
    return (
      <Grid item container >
        <Grid item xs={12} md={3} className={classes2.gridItem} style={{ paddingLeft: '0px' }}>
          <VentasMensuales border />
        </Grid>
        <Grid item xs={12} md={3} className={classes2.gridItem}>
          <PedidosPendientesComparativo border />
        </Grid>
        <Grid item xs={12} md={3} className={classes2.gridItem}>
          <PedidosPendientes border />
        </Grid>
        <Grid item xs={12} md={3} className={classes2.gridItem} style={{ paddingRight: '0px' }}>
          <TextoPedidosVencidos border/>
        </Grid> 
      </Grid>
    )
  }

  const renderFila2 = () => {
    return (
      <Grid item container className={classes2.combinedChartsCard}>
        <Grid item xs={12} md={3} lg={3} className={classes2.piePendiente}>
          <EstadoPedidosPendientes noBorder height={inPhone ? "350px" : "100%"} />
        </Grid>
        <Grid item xs={12} md={9} lg={9} className={classes2.gridItem}>
          <TablaPedidosPendientes/>
        </Grid>
      </Grid>
    )
  }

  const renderFila3 = () => {
    return (
      <Grid item container className={classes2.combinedChartsCard}>
        <Grid item xs={12} md={3} lg={3} className={classes2.piePendiente}>
          <PieFacturasPendientes noBorder height={inPhone ? "350px" : "100%"} />
        </Grid>
        <Grid item xs={12} md={9} lg={9} className={classes2.gridItem}>
          <TablaFacturasPendientes/>
        </Grid>
      </Grid>
    )
  }

  return (
    <Fragment>
      <div className={classes.fotoDelDia}>
        {isMultiemp && <EmpresaSelectorButton setEmpresasessionStorage />}
        {!ejecucionFotoDelDia ? (
          <BoxEjecutarPaginaInfo />
        ) : (
          <Grid container>
            {renderFila1()}
            {renderFila2()}
            {renderFila3()}                
          </Grid>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ejecucionFotoDelDia: state.ejecucionPaginas.ejecucionFotoDelDia,
    estaCargadoPedidos: state.ventas.comprobantesPedidosPendientes.estaCargado,
    estaCargadoFacturas: state.ventas.comprobantesFacturasPendientes.estaCargado,
    loadingPedidos: state.ventas.comprobantesPedidosPendientes.loading,
    loadingFacturas: state.ventas.comprobantesFacturasPendientes.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUltimaActualizacion: () => dispatch(actions.updateUltimaActualizacionFotoDelDia(moment(Date.now()).format('DD/MM/YYYY HH:mm'))),
    onLoadChart: () => {
      dispatch(actions.loadFacturasPendientes());
      dispatch(actions.loadComprobantesFacturasPendientes());
      dispatch(actions.loadEstadosPedidosPendientes());
      dispatch(actions.loadComprobantesPedidosPendientes());
    },  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FotoDelDia);