import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Typography from "@material-ui/core/Typography";
import MonedaSelector from "../UI/MonedaSelector/MonedaSelector";
import MenuUsuario from "../MenuUsuario/MenuUsuario";
import MenuApps from "../MenuApps/MenuApps";
import UltimaActualizacion from "../UI/UltimaActualizacion/UltimaActualizacion";
import ButtonEjecucionPagina from "../EjecucionPagina/ButtonEjecucionPagina";
import ButtonTomarEnCuentaInflacion from "../Configuraciones/TomarEnCuentaInflacion/ButtonTomarEnCuentaInflacion";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FechaFijaSelector from "../Configuraciones/FechaFijaSelector/FechaFijaSelector";
import MenuComprobantes from "../Configuraciones/Comprobantes/MenuComprobantes/MenuComprobantes";
import VendedorSelector from "../UI/VendedorSelector/VendedorSelector";
import VendedorDisplay from "../UI/VendedorSelector/VendedorDisplay";
import { storageIsVendedor } from "../../assets/shared/sessionData";

const useStyles = makeStyles((theme) => ({
  toolbarUsuario: {
    display: "none",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  mobileToolbarUsuario: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  UsuarioLog: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  MonedaSelector: {
    color: "white",
    marginLeft: "10px",
    marginRight: "10px",
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

const ToolbarComponents = (props) => {
  const classes = useStyles();
  const { onSeleccionarMoneda } = props;
  const isAdmin = sessionStorage.getItem("isAdmin") === "true";

  const handleMonedaSelectedChange = (moneda) => {
    if (moneda !== null && moneda !== undefined)
      onSeleccionarMoneda(moneda.idMoneda);

    handleClose();
  };

  const monedaSelect = () => {
    return (
      <div style={{ color: "white", marginLeft: "10px", marginRight: "10px" }}>
        <MonedaSelector
          letraColor="white"
          monedaSigno
          monedaSelected={(moneda) => handleMonedaSelectedChange(moneda)}
        />
      </div>
    );
  };

  //#region  Toolbar Usuario
  const toolbarUsuario = () => {
    console.log({ isAdmin });
    console.log({ abreDesplegable: isAdmin || !storageIsVendedor() });
    console.log({ storageIsVendedor: storageIsVendedor() });
    return (
      <Fragment>
        <ButtonEjecucionPagina />
        <ButtonTomarEnCuentaInflacion puedeModificarInflacion />
        {monedaSelect()}
        {isAdmin || !storageIsVendedor() ? (
          <VendedorSelector closeToolbar={() => {}} />
        ) : (
          <VendedorDisplay />
        )}
        <FechaFijaSelector />
        <UltimaActualizacion
          ultPath={global.window.location.href}
          iconSize="25px"
        />
        <MenuComprobantes />
        <MenuApps />
        <div className={classes.UsuarioLog}>
          <MenuUsuario />
        </div>
      </Fragment>
    );
  };

  //#endregion

  //#region Mobile Toolbar Usuario
  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMore = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const mobileToolbarUsuario = () => {
    return (
      <Fragment>
        <ButtonEjecucionPagina />
        <MenuComprobantes />
        <MoreVertIcon
          onClick={handleClick}
          style={{ color: "white", fontSize: "1.8rem", cursor: "pointer" }}
        />
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={openMore}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              backgroundColor: "RGB(81,81,81)",
            },
          }}
        >
          <MenuItem className={classes.menuItem}>
            <ButtonTomarEnCuentaInflacion />
          </MenuItem>
          <MenuItem className={classes.menuItem} style={{ color: "white" }}>
            <Typography style={{ marginInline: "10px" }}>Moneda: </Typography>
            {monedaSelect()}
          </MenuItem>
          <MenuItem className={classes.menuItem} style={{ color: "white" }}>
            <Typography style={{ marginInline: "10px" }}>Vendedor: </Typography>
            {isAdmin || !storageIsVendedor() ? (
              <VendedorSelector closeToolbar={() => handleClose()} />
            ) : (
              <VendedorDisplay />
            )}
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <FechaFijaSelector iconSize="25px" color={"white"} />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <UltimaActualizacion
              ultPath={global.window.location.href}
              iconSize="25px"
              color={"white"}
            />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <MenuApps iconSize="25px" />
          </MenuItem>
        </Menu>
      </Fragment>
    );
  };

  //#endregion

  return (
    <Fragment>
      <div className={classes.toolbarUsuario}>{toolbarUsuario()}</div>

      <div className={classes.mobileToolbarUsuario}>
        {mobileToolbarUsuario()}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSeleccionarMoneda: (monedaId) =>
      dispatch(actions.seleccionarMoneda(monedaId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarComponents);
