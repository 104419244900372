import React, { useState, Fragment, useEffect, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import { Grid, Divider, Typography, FormControl, InputLabel, Select, TextField, Card, CardHeader, CardContent, Collapse, IconButton, MenuItem, } from "@material-ui/core";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Buton from "../UI/Button/Button";

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
import * as actions from "../../store/actions";
import { CarritoContext } from "../../store/contexts/carrito";
import { storageClientId } from "../../assets/shared/sessionData";
import { errorListaDePreciosVacia, errorCamposRequeridos } from "../../assets/shared/errorMessages";
import { esValido } from "../../assets/shared/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  title:{
    fontSize: "medium", 
    fontWeight: "bold", 
  },
  subTitle:{
    fontSize: "small", 
    fontWeight: "bold",
    marginRight: "5px",
  },
  clienteTitle: {
    fontSize: "medium", 
  },
  cardHeader:{
    paddingBlock: '10px',
    paddingTop: '10px',
    paddingBottom: '5px'
  },
  cardContent:{
    paddingBlock: '10px',
    paddingTop: '10px',
    paddingBottom: '5px'
  },
  input: {
    width: '100%',    
    "& input:disabled": {
      color: '#828282',
    },
    "& .MuiInputBase-input": {
      fontSize: "small", 
    },
    "& MuiInputLabel-animated": {
      fontSize: "medium", 
    },
    "label:.Mui-disabled": {
      color: '#828282',
    }
  },
  selector: {
    width: '100%',
    textAlign: 'start',
    fontSize: 'small',
    "& .MuiInputBase-input": {
      fontSize: "small", 
    }
  },
  options:{
    fontSize: 'small',
  },
  direccionContainer: {
    marginTop: '7px',
  },
  buttons:{
    width: '100%',
  },
}));

const CabeceraPedido = (props) => { 
  const classes = useStyles(); 
  const { onLoadClienteCabeceraById, clienteCabecera, filtroListaPrecio, comprobante } = props;
  const { carritoItems } = useContext(CarritoContext); 
  const [expanded, setExpanded] = useState(false);
  const [clienteValido, setClienteValido] = useState(true);  

  const loadClienteCabeceraInfo = useCallback((idCliente) => {
    onLoadClienteCabeceraById(idCliente, () => {});
  }, [onLoadClienteCabeceraById]);  

  const checkClienteValido = useCallback(() => {
    clienteCabecera.condicionDePagoValido = esValido(clienteCabecera.condicionDePago);
    clienteCabecera.condicionDeIvaValido = esValido(clienteCabecera.condicionDeIva);
    clienteCabecera.listaDePreciosValido = esValido(clienteCabecera.listaDePrecios);
    clienteCabecera.vencimientoValido = esValido(clienteCabecera.vencimiento);
    clienteCabecera.mailValido = esValido(clienteCabecera.mail);
    clienteCabecera.cuitValido = esValido(clienteCabecera.cuit);
    clienteCabecera.expresoValido = esValido(clienteCabecera.expreso);

    if(clienteCabecera.contacto === null || clienteCabecera.contacto === undefined){
      clienteCabecera.contactoValido = false;
      clienteCabecera.contacto = {
        nombreValido: false,
        telefonoValido: false,
        emailValido: false,
      }
    }else{
      clienteCabecera.contacto.nombreValido = esValido(clienteCabecera.contacto.nombre);
      clienteCabecera.contacto.telefonoValido = esValido(clienteCabecera.contacto.telefono);
      clienteCabecera.contacto.emailValido = esValido(clienteCabecera.contacto.email);
      clienteCabecera.contactoValido = clienteCabecera.contacto.nombreValido && 
        clienteCabecera.contacto.telefonoValido && clienteCabecera.contacto.emailValido;
    }

    if(clienteCabecera.direccionFiscal === null || clienteCabecera.direccionFiscal === undefined){
      clienteCabecera.direccionFiscalValido = false;
      clienteCabecera.direccionFiscal = {
        direccionValido: false,
        localidadValido: false,
        provinciaValido: false,
        zonaValido: false,
        codigoPostalValido: false
      }
    }else{
      clienteCabecera.direccionFiscal.direccionValido = esValido(clienteCabecera.direccionFiscal.direccion);
      clienteCabecera.direccionFiscal.localidadValido = esValido(clienteCabecera.direccionFiscal.localidad);
      clienteCabecera.direccionFiscal.provinciaValido = esValido(clienteCabecera.direccionFiscal.provinciaId);
      clienteCabecera.direccionFiscal.zonaValido = esValido(clienteCabecera.direccionFiscal.zonaId);
      clienteCabecera.direccionFiscal.codigoPostalValido = esValido(clienteCabecera.direccionFiscal.codigoPostal);
      clienteCabecera.direccionFiscalValido = clienteCabecera.direccionFiscal.direccionValido && clienteCabecera.direccionFiscal.localidadValido &&
      clienteCabecera.direccionFiscal.provinciaValido && clienteCabecera.direccionFiscal.zonaValido && clienteCabecera.direccionFiscal.codigoPostalValido;
    }

    const valido = clienteCabecera.condicionDePagoValido && clienteCabecera.condicionDeIvaValido && clienteCabecera.listaDePreciosValido &&
      clienteCabecera.vencimientoValido && clienteCabecera.mailValido && clienteCabecera.cuitValido && clienteCabecera.expresoValido && 
      clienteCabecera.contactoValido && clienteCabecera.direccionFiscalValido;

    setClienteValido(valido);
    return valido;
  }, [clienteCabecera]);

  useEffect(() => {
    if(clienteCabecera === null || clienteCabecera === undefined){
      loadClienteCabeceraInfo(storageClientId());
    }else{
      checkClienteValido();
    }      
  }, [clienteCabecera, loadClienteCabeceraInfo, checkClienteValido]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const tituloCliente = () => {
    return (
      <Fragment>
        <Grid 
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item xs={6} md={1}>            
            <Typography
              className={classes.subTitle}
              variant="subtitle2"
              align="left"
            >
              Código Cliente:
            </Typography>
            <span className={classes.clienteTitle}>
              { clienteCabecera && clienteCabecera.idCliente }
            </span>
          </Grid>
          <Grid item xs={6} md={3} >
            <Typography
              className={classes.subTitle}
              variant="subtitle2"
              align="left"
            >
              Nombre Cliente:
            </Typography>
            <span className={classes.clienteTitle}>
              { clienteCabecera && clienteCabecera.nombreFantasia }
            </span>
          </Grid>
          <Grid item xs={6} md={2}>            
            <Typography
              className={classes.subTitle}
              variant="subtitle2"
              align="left"
            >
              Lista de precios:
            </Typography>
            <span className={classes.clienteTitle}>
              { clienteCabecera && clienteCabecera.listaDePrecios.nombre }
            </span>
          </Grid>
          <Grid item xs={6} md={2}>            
            <Typography
              className={classes.subTitle}
              variant="subtitle2"
              align="left"
            >
              Depósito:
            </Typography>
            <span className={classes.clienteTitle}>
              { comprobante && comprobante.deposito }
            </span>
          </Grid>
          <Grid item xs={6} md={1}>            
            <Typography
              className={classes.subTitle}
              variant="subtitle2"
              align="left"
            >
              Moneda:
            </Typography>
            <span className={classes.clienteTitle}>
              { clienteCabecera && clienteCabecera.listaDePrecios.moneda }
            </span>
          </Grid>
          <Grid item xs={10} md={2} >
            {!clienteValido && <Typography
              style={{ color: 'red', fontSize: 'small'}}
              className={classes.subTitle}
              variant="subtitle2"
              align="left"
            >
              {errorCamposRequeridos}
            </Typography>}
            {!clienteCabecera.listaDePreciosValido && <Typography
              style={{ color: 'red', fontSize: 'small'}}
              className={classes.subTitle}
              variant="subtitle2"
              align="left"
            >
              {errorListaDePreciosVacia}
            </Typography>}
          </Grid>
          <Grid item xs={2} md={1} style={{ textAlign: 'end' }}>
            <IconButton
              className={clsx(classes.expand, { [classes.expandOpen]: expanded, })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              >
                <ExpandMoreIcon />
            </IconButton>
          </Grid>
          {expanded &&
            <Grid item xs={12} md={12}>            
              <Divider />
            </Grid>
          }
        </Grid>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Card style={{ width: '100%' }}>
        <CardHeader
          className={classes.cardHeader}
          title={tituloCliente()}
        />
        <Collapse
          in={expanded}
          style={{ textAlign: "-webkit-center" }}
          timeout="auto"
          unmountOnExit
        >
          <CardContent
            className={classes.cardContent}
          >
            <DetalleCliente 
              clienteCabecera={clienteCabecera}
              articulos={carritoItems}
              filtroListaPrecio={filtroListaPrecio} 
              validarDatos={checkClienteValido}
            />
          </CardContent>
        </Collapse>
      </Card>
    </Fragment>    
  );
};

const DetalleCliente = (props) => {
  const classes = useStyles();
  const { clienteCabecera, articulos, filtroListaPrecio, validarDatos } = props; 
  const [editMode, setEditMode] = useState(false);
  const [editListaDePrecios, setEditListaDePrecios] = useState(false);
  const [idCliente, setIdCliente] = useState("");
  const [direccionFiscal, setDireccionFiscal] = useState();
  const [condicionDePago, setCondicionDePago] = useState({id: ''});
  const [condicionesDePagos, setCondicionesDePagos] = useState();
  const [fecha] = useState(new Date());
  const [listaDePrecio, setListaDePrecio] = useState({id: ''});
  const [listaDePrecios, setListasDePrecios] = useState();
  const [expreso, setExpreso] = useState();
  const [vencimiento, setVencimiento] = useState();
  const [tipoIva, setTipoIva] = useState({id: ''});
  const [tiposIvas, setTiposIvas] = useState();
  const [sucursal, setSucursal] = useState({ id: 'select', nombre: 'Sucursal' });
  const [sucursales, setSucursales] = useState();
  const [horarioEntrega, setHorarioEntrega] = useState('');
  const [direccionEntrega, setDireccionEntrega] = useState();
  const [mail, setMail] = useState();
  const [cuit, setCuit] = useState();
  const [localidades, setLocalidades] = useState();
  const [contacto, setContacto] = useState();

  const fillClienteCabeceraInfo = useCallback((clienteCabeceraInfo) => {
    if(clienteCabeceraInfo !== null && clienteCabeceraInfo !== undefined){
      setDireccionFiscal(clienteCabeceraInfo.direccionFiscal);
      setCondicionDePago(clienteCabeceraInfo.condicionDePago);
      setCondicionesDePagos(clienteCabeceraInfo.condicionesDePago);
      setListasDePrecios(clienteCabeceraInfo.listasDePrecios);
      setLocalidades(clienteCabeceraInfo.localidades);

      if(filtroListaPrecio !== null && filtroListaPrecio !== undefined && filtroListaPrecio !== ""){
        let listaFiltro = clienteCabeceraInfo.listasDePrecios.find(list => list.idLista === filtroListaPrecio);
        setListaDePrecio(listaFiltro);
        clienteCabecera.listaDePrecios = listaFiltro;
      }else{
        setListaDePrecio(clienteCabeceraInfo.listaDePrecios);
      }
      
      setExpreso(clienteCabeceraInfo.expreso);
      setTipoIva(clienteCabeceraInfo.condicionDeIva);
      setTiposIvas(clienteCabeceraInfo.condicionesDeIva);

      if(clienteCabeceraInfo.sucursal === null || clienteCabeceraInfo.sucursal === undefined){
        setSucursal({ id: 'select', nombre: 'Sucursal' });
      }else{
        setSucursal(clienteCabeceraInfo.sucursal);
      }

      setSucursales(clienteCabeceraInfo.sucursales);
      setHorarioEntrega(clienteCabeceraInfo.horarioEntrega);
      setDireccionEntrega(clienteCabeceraInfo.direccionEntrega);
      setIdCliente(clienteCabeceraInfo.idCliente);
      setMail(clienteCabeceraInfo.mail);
      setCuit(clienteCabeceraInfo.cuit);
      setVencimiento(clienteCabeceraInfo.vencimiento);
      setContacto(clienteCabeceraInfo.contacto);
    }
  }, [
    filtroListaPrecio,
    clienteCabecera,
    setDireccionFiscal,
    setCondicionDePago,
    setCondicionesDePagos, 
    setListaDePrecio, 
    setListasDePrecios, 
    setExpreso, 
    setTipoIva, 
    setTiposIvas, 
    setSucursal, 
    setSucursales, 
    setHorarioEntrega, 
    setDireccionEntrega,
    setIdCliente,
    setMail,
    setCuit,
    setVencimiento,
    setLocalidades,
    setContacto,
  ]);

  useEffect(() => {
    if(clienteCabecera !== null && clienteCabecera !== undefined && clienteCabecera.idCliente !== idCliente){
      fillClienteCabeceraInfo(clienteCabecera);
    }
  }, [clienteCabecera, idCliente, fillClienteCabeceraInfo]);

  const onCondicionPagoChange = (event) => {
    let id = event.target.value;
    let item = condicionesDePagos.find(i => i.id === id);
    if(item === null || item === undefined){
      item = { id: id };
    }
    setCondicionDePago(item);
  }

  const onListaDePreciosChange = (event) => {
    let id = event.target.value;
    let item = listaDePrecios.find(i => i.id === id);
    setListaDePrecio(item);
    validarDatos();
  }

  const onSucursalChange = (event) => {
    let id = event.target.value;
    let item = sucursales.find(i => i.id === id);
    if(item === null || item === undefined){
      item = { id: id, expreso: "" };
    }
    setSucursal(item);
    setExpreso(item.expreso);
    setDireccionEntrega(item.direccion);
  }

  const onDireccionFiscalChange = (direccion) => {
    setDireccionFiscal(direccion);
  }

  const onDireccionEntregaChange = (direccion) => {
    setDireccionEntrega(direccion);
  }

  const onEditarClick = () => {
    setEditMode(true);

    if(articulos.length !== 0){
      setEditListaDePrecios(false);
    }else{
      setEditListaDePrecios(true);
    }
  }

  const onGuardarClick = () => {
    clienteCabecera.direccionFiscal = direccionFiscal;
    clienteCabecera.condicionDePago = condicionDePago;
    clienteCabecera.listaDePrecios = listaDePrecio;
    clienteCabecera.expreso = expreso;
    clienteCabecera.sucursal = sucursal;
    clienteCabecera.horarioEntrega = horarioEntrega;
    clienteCabecera.direccionEntrega = direccionEntrega;
    clienteCabecera.mail = mail;
    clienteCabecera.cuit = cuit;
    clienteCabecera.vencimiento = vencimiento;
    clienteCabecera.contacto = contacto;

    setEditMode(false);
    setEditListaDePrecios(false);
    validarDatos();
  }

  const onCancelarClick = () =>{
    fillClienteCabeceraInfo(clienteCabecera);
    setEditMode(false);
    setEditListaDePrecios(false);
    validarDatos();
  }

  const onHorarioEntregaChange = useCallback((e) => {
    let value = e.target.value;
    setHorarioEntrega(value);
  }, [setHorarioEntrega]);

  return (
    <Grid container spacing={1} 
      alignContent='flex-end' 
      alignItems='flex-end' 
      justify="space-around">      
      <Grid item xs={12} md={12}>
        <CabeceraHeader 
          editMode={editMode}
          onEdit={() => onEditarClick()}
          onCancel={() => onCancelarClick()}
          onSave={() => onGuardarClick()}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <CustomSelector
          idInput={'condPagoInput'}
          idLabel={'condPagoLabel'}
          idSelect={'condPagoSelect'}
          label={'Condición Pago'}
          required={true} 
          value={condicionDePago}
          items={condicionesDePagos}
          onChange={(e) => onCondicionPagoChange(e)}
          disabled={!editMode}
          seleccione
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <CustomSelector
          idInput={'listaPrecioInput'}
          idLabel={'listaPrecioLabel'}
          idSelect={'listaPrecioSelect'}
          label={'Lista de Precio'}
          required={true} 
          value={listaDePrecio}
          items={listaDePrecios}
          onChange={(e) => onListaDePreciosChange(e)}
          disabled={!editListaDePrecios} 
        />
      </Grid>    
      <Grid item xs={6} md={3}>
        <CustomSelector
          idInput={'tipoIvaInput'}
          idLabel={'tipoIvaLabel'}
          idSelect={'tipoIvaSelect'}
          label={'Tipo Iva'}
          required={true}
          value={tipoIva}
          items={tiposIvas}
          disabled={true} 
          seleccione
        />
      </Grid>
      <Grid item xs={6} md={3}
        container spacing={1}
      >
        <Grid item xs={6} md={6}>
          <CustomDatePicker 
            label={"Fecha"} 
            required={true} 
            value={fecha} 
            disabled={true} 
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <CustomDatePicker 
            label={"Vencimiento"}
            required={true} 
            value={vencimiento}
            onChange={(e) => setVencimiento(e)} 
            disabled={!editMode} 
          />
        </Grid>
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          className={classes.input}
          type="text"
          label={"Mail"}
          required={true}
          value={mail && mail.trimEnd()}
          error={!esValido(mail)}
          onChange={(e) => setMail(e.target.value)}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          className={classes.input}
          type="text"
          label={"CUIT"}
          required={true}
          value={cuit && cuit.trimEnd()}
          error={clienteCabecera && !clienteCabecera.cuitValido}
          disabled={true}
        />
      </Grid>            
      <Grid item xs={6} md={3}>
        <CustomSelector
          idInput={'sucursalInput'}
          idLabel={'sucursalLabel'}
          idSelect={'sucursalSelect'}
          label={'Sucursal'}
          required={false}
          value={sucursal}
          items={sucursales}
          onChange={(e) => onSucursalChange(e)}
          disabled={!editMode}
          seleccione 
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          className={classes.input}
          type="text"
          label={"Expreso"}
          required={true} 
          value={expreso && expreso.nombreFantasia && expreso.nombreFantasia.trimEnd()}          
          error={clienteCabecera && !clienteCabecera.expresoValido}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Contacto 
          item={contacto}
          cargos={clienteCabecera && clienteCabecera.cargos}
          onChange={(item) => setContacto(item)}
          required={true}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={12} md={12} className={classes.direccionContainer}>
        <InputLabel 
          id="direccionFiscal"
          style={{ textAlign: 'start' }}
        >
          Dirección Fiscal
        </InputLabel>
        <Direccion 
          item={direccionFiscal}
          zonas={clienteCabecera && clienteCabecera.zonas}
          provincias={clienteCabecera && clienteCabecera.provincias}
          localidades={clienteCabecera && clienteCabecera.localidades}
          required={true} 
          onChange={(item) => onDireccionFiscalChange(item)}
          disabled={!editMode} />
      </Grid>
      <Grid item xs={12} md={9} className={classes.direccionContainer}>
        <InputLabel 
          id="direccionEntrega"
          style={{ textAlign: 'start' }}
        >
          Dirección Entrega
        </InputLabel>
        <DireccionEntrega 
          item={direccionEntrega}
          itemLocalidades={localidades} 
          onChange={(item) => onDireccionEntregaChange(item)}
          disabled={!editMode} />
      </Grid>      
      <Grid item xs={6} md={3}>
        <TextField
          className={classes.input}
          type="text"
          label={"Horario de Entrega"}
          required={false}
          value={horarioEntrega}
          onChange={(e) => onHorarioEntregaChange(e)}
          disabled={!editMode}
        />
      </Grid>
    </Grid>
  );
}

const Direccion = (props) => {
  const classes = useStyles();
  const { item, zonas, provincias, onChange, disabled } = props;
  const [direccion, setDireccion] = useState('');
  const [localidad, setLocalidad] = useState('');
  const [codigoPostal, setCodigoPostal] = useState('');
  const [provincia, setProvincia] = useState({id: ''});
  const [zona, setZona] = useState({id: ''});  

  const setSelect = useCallback((items, value, setValue) => {
    let item = items.find(i => i.id === value);
    setValue(item ? item : {id: ''});
  }, []);

  useEffect(() => {
    if(item !== null && item !== undefined){
      setDireccion(item.direccion !== null && item.direccion !== undefined ? item.direccion : "");
      setLocalidad(item.localidad !== null && item.localidad !== undefined ? item.localidad : "");
      setCodigoPostal(item.codigoPostal !== null && item.codigoPostal !== undefined ? item.codigoPostal : "");
      setSelect(zonas, item.zonaId, setZona);
      setSelect(provincias, item.provinciaId, setProvincia);
    }
  }, [ item, direccion, zonas, provincias, setSelect, setZona, setProvincia, setLocalidad, ]);

  const updateDireccion = (direccion, localidad, provincia, codigoPostal, zona) => {
    let newItem = {
      codigoPostal,
      codigoPostalValido: esValido(codigoPostal),
      direccion,
      direccionValido: esValido(direccion),
      localidad: localidad,
      localidadId: '',
      localidadValido: esValido(localidad),
      provincia: provincia && provincia.nombre,
      provinciaId: provincia && provincia.id,
      provinciaValido: true,
      zona: zona && zona.nombre,
      zonaId: zona && zona.id,
      zonaValido: true,
    };

    onChange(newItem);
  }
  
  const onDireccionChange = (event) => {
    let value = event.target.value;
    setDireccion(value);
    updateDireccion(value, localidad, provincia, codigoPostal, zona);
  }

  const onLocalidadChange = (event) => {
    let value = event.target.value;
    setLocalidad(value);
    updateDireccion(direccion, value, provincia, codigoPostal, zona);
  }

  const onProvinciaChange = (event) => {
    let value = event.target.value;
    let item = provincias.find(i => i.id === value);
    setProvincia(item);
    updateDireccion(direccion, localidad, item, codigoPostal, zona);
  }

  const onCodigoPostalChange = (event) => {
    let value = event.target.value;
    setCodigoPostal(value);
    updateDireccion(direccion, localidad, provincia, value, zona);
  }

  const onZonaChange = (event) => {
    let value = event.target.value;
    let item = zonas.find(i => i.id === value);
    setZona(item);
    updateDireccion(direccion, localidad, provincia, codigoPostal, item);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={3}>
        <TextField
          className={classes.input}
          type="text"
          label={"Dirección"}          
          required={true} 
          value={direccion && direccion.trimEnd()}
          error={item && !item.direccionValido}
          onChange={(e) => onDireccionChange(e)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          className={classes.input}
          type="text"
          label={"Localidad"}
          required={true}
          value={localidad && localidad.trimEnd()}
          error={item && !item.localidadValido}
          onChange={(e) => onLocalidadChange(e)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <CustomSelector
          idInput={'zonaInput'}
          idLabel={'zonaLabel'}
          idSelect={'zonaSelect'}
          label={'Zona'}
          required={true}
          value={zona}
          items={zonas}
          error={item && !item.zonaValido} 
          onChange={(e) => onZonaChange(e)}
          disabled={disabled}
          seleccione
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <CustomSelector
          idInput={'provinciaInput'}
          idLabel={'provinciaLabel'}
          idSelect={'provinciaSelect'}
          label={'Provincia'}
          required={true}
          value={provincia}
          items={provincias}
          error={item && !item.provinciaValido} 
          onChange={(e) => onProvinciaChange(e)}
          disabled={disabled}
          seleccione
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField
          className={classes.input}
          type="text"
          label={"Cód.Postal"}
          required={true}
          value={codigoPostal && codigoPostal.trimEnd()}
          error={item && !item.codigoPostalValido}
          onChange={(e) => onCodigoPostalChange(e)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

const DireccionEntrega = (props) => {
  const classes = useStyles();
  const { item, itemLocalidades, onChange, disabled } = props;
  const [direccion, setDireccion] = useState("");
  const [localidad, setLocalidad] = useState({id: "select"});
  const [localidades, setLocalidades] = useState([]);
  const [codigoPostal, setCodigoPostal] = useState();  

  useEffect(() => {
    if(item !== null && item !== undefined){
      let localidadItem = {};

      if(item.localidadId !== null && item.localidadId !== undefined){
        localidadItem = {
          id: item.localidadId,
          nombre: item.localidad
        }
        setLocalidad(localidadItem);
      }       

      setDireccion(item.direccion !== null && item.direccion !== undefined ? item.direccion : "");
      setLocalidades(itemLocalidades !== null && itemLocalidades !== undefined ? itemLocalidades : []);
      setCodigoPostal(item.codigoPostal !== null && item.codigoPostal !== undefined ? item.codigoPostal : "");
    }
  }, [item, itemLocalidades]);

  const updateDireccion = (direccion, localidad, codigoPostal) => {
    let newItem = {
      direccion,
      localidadId: localidad.id,
      codigoPostal
    };

    onChange(newItem);
  }
  
  const onDireccionChange = (event) => {
    let value = event.target.value;
    setDireccion(value);
    updateDireccion(value, localidad, codigoPostal);
  }

  const onLocalidadChange = (event) => {
    let value = event.target.value;
    let localidadItem = localidades.find(i => i.id === value);

    if(localidadItem === null || localidadItem === undefined){
      localidadItem = { id: value };
    }

    setLocalidad(localidadItem);
    updateDireccion(direccion, localidadItem, codigoPostal);
  }

  const onCodigoPostalChange = (event) => {
    let value = event.target.value;
    setCodigoPostal(value);
    updateDireccion(direccion, localidad, value);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={4}>
        <TextField
          className={classes.input}
          type="text"
          label={"Dirección"} 
          value={direccion}
          onChange={(e) => onDireccionChange(e)}
          disabled={disabled}
          required={true}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <CustomSelector
          idInput={'localidadEntregaInput'}
          idLabel={'localidadEntregaLabel'}
          idSelect={'localidadEntregaSelect'}
          label={'Localidad'}
          value={localidad}
          items={localidades}
          onChange={(e) => onLocalidadChange(e)}
          disabled={disabled}
          seleccione 
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          className={classes.input}
          type="text"
          label={"Cód.Postal"}
          value={codigoPostal}
          onChange={(e) => onCodigoPostalChange(e)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

const CustomSelector = (props) => {
  const classes = useStyles();
  const { idInput, idLabel, idSelect, label, value, items, onChange, disabled, required, error, seleccione } = props; 

  return (
    <FormControl 
      style={{ color: "black", width: "100%" }}
      error={required && !esValido(value)}
    >
      <InputLabel id={idInput} required={required}>{label}</InputLabel>
      <Select
        className={classes.selector}
        labelId={idLabel}
        required={required}
        id={idSelect}
        placeholder={label}
        value={value && value.id}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        error={error}
      >
        {seleccione && 
          <MenuItem 
            className={classes.options}
            key="select" 
            value="select">
            SELECCIONE...
          </MenuItem>
        }
        {items && items.map((item, index) => (
          <MenuItem
            className={classes.options} 
            key={item.id + index} 
            value={item.id} 
          >
            {item.nombre && item.nombre.trimEnd().toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const CustomDatePicker = (props) => {
  const classes = useStyles();
  const { label, value, disabled, onChange, required, showTime } = props;  
  const [id] = useState(`date-picker-${label}`);
  const [minimo] = useState(new Date());

  const onChangeValue = (e) => {
    esValido(e);
    onChange(e);
  }

  const showOnlyDate = () => {
    return (
      <KeyboardDatePicker
        className={classes.input}
        disableToolbar
        variant="inline"
        format="DD/MM/YYYY"
        margin="normal"
        id={id}
        label={label}
        required={required}
        value={value && moment(value)}
        disabled={disabled}
        minDate={minimo}
        onChange={(e) => onChangeValue(e)}
        error={required && !esValido(value)}
        KeyboardButtonProps={{
          'aria-label': 'Cambiar fecha',
        }}
      />
    );
  }

  const showDateTime = () => {
    return (
      <KeyboardDateTimePicker 
        className={classes.input}
        disableToolbar
        autoOk
        ampm={false}
        disablePast={true}
        variant="inline"
        format="DD/MM/YYYY kk:mm"
        margin="normal"
        id={id}
        label={label}
        required={required}
        value={value && moment(value)}
        disabled={disabled}
        minDate={minimo}
        onChange={(e) => onChangeValue(e)}
        error={required && !esValido(value)}
        KeyboardButtonProps={{
          'aria-label': 'Cambiar fecha',
        }}
        invalidDateMessage={<span></span>}
      />
    );
  }

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={"es"}
    >
      {showTime ? showDateTime() : showOnlyDate()}      
    </MuiPickersUtilsProvider>
  );
}

const Contacto = (props) => {
  const classes = useStyles();
  const { item, cargos, onChange, disabled, required } = props;
  const [nombre, setNombre] = useState("");
  const [nombreValido, setNombreValido] = useState(true);
  const [telefono, setTelefono] = useState("");
  const [telefonoValido, setTelefonoValido] = useState(true);
  const [email, setEmail] = useState("");
  const [mailValido, setMailValido] = useState(true);
  const [cargo, setCargo] = useState({ id: 'select' });  

  useEffect(() => {
    if(item !== null && item !== undefined){
      setNombre(item.nombre !== null && item.nombre !== undefined ? item.nombre : "");
      setNombreValido(esValido(item.nombre));
      setTelefono(item.telefono !== null && item.telefono !== undefined ? item.telefono : "");
      setTelefonoValido(esValido(item.telefono));
      setEmail(item.email !== null && item.email !== undefined ? item.email : "");
      setMailValido(esValido(item.email));
      const cargoItem = item.cargo !== null && item.cargo !== undefined ? { id: item.cargo } : { id: 'select' };
      setCargo(cargoItem);
    }
  }, [item, setNombre, setTelefono, setEmail, setCargo]);

  const updateContacto = (nombre, telefono, email, cargo) => {
    let newItem = {
      nombre,
      telefono,
      email,
      cargo
    };

    onChange(newItem);
  }
  
  const onNombreChange = (event) => {
    let value = event.target.value;
    setNombre(value);    
    updateContacto(value, telefono, email, cargo);
  }

  const onTelefonoChange = (event) => {
    let value = event.target.value;
    setTelefono(value);
    updateContacto(nombre, value, email, cargo);
  }

  const onEmailChange = (event) => {
    let value = event.target.value;
    setEmail(value);
    updateContacto(nombre, telefono, value, cargo);
  }

  const onCargoChange = (event) => {
    let value = event.target.value;
    setCargo(value);
    updateContacto(nombre, telefono, email, value);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={3}>
        <TextField
          className={classes.input}
          type="text"
          label={"Nombre Contacto"}          
          required={required} 
          value={nombre && nombre.trimEnd()}
          error={required && !nombreValido}
          onChange={(e) => onNombreChange(e)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          className={classes.input}
          type="text"
          label={"Teléfono Contacto"}
          required={required}
          value={telefono && telefono.trimEnd()}
          error={required && !telefonoValido}
          onChange={(e) => onTelefonoChange(e)}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          className={classes.input}
          type="text"
          label={"Email Contacto"}
          required={required}
          value={email && email.trimEnd()}
          error={required && !mailValido}
          onChange={(e) => onEmailChange(e)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <CustomSelector
          idInput={'cargoInput'}
          idLabel={'cargoLabel'}
          idSelect={'cargoSelect'}
          label={'Cargo'}
          required={false}
          value={cargo}
          items={cargos}
          onChange={(e) => onCargoChange(e)}
          disabled={disabled} 
          seleccione
        />
      </Grid>
    </Grid>
  );
}

const CabeceraHeader = (props) => {
  const { editMode, onEdit, onCancel, onSave } = props;

  return editMode ?
    (
      <Grid 
        container
        spacing={1}
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Grid item xs={3} md={2} lg={2}>
          <Buton 
            style={{ width: '100%' }}
            size="small"
            startIcon={<SaveOutlinedIcon />}
            onClick={() => onSave()}     
          >
            Guardar
          </Buton>
        </Grid>
        <Grid item xs={3} md={2} lg={2}>
          <Buton
            style={{ width: '100%' }}
            variant="outlined" 
            color="default" 
            size="small"
            startIcon={<CancelOutlinedIcon />}
            onClick={() => onCancel(false)}     
          >
            Cancelar
          </Buton>
        </Grid>
      </Grid>
    ) : (
      <Grid 
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Grid item xs={3} md={2} lg={2}>
          <Buton 
            style={{ width: '100%' }}
            size="small"
            startIcon={<EditOutlinedIcon />}
            onClick={() => onEdit()}     
          >
            Editar
          </Buton>
        </Grid>
      </Grid>
    );
}

const mapStateToProps = (state) => {
  return {
    comprobante: state.comprobantes.comprobantePedido.comprobante,
    clienteCabecera: state.clientes.clienteCabeceraById.cliente,
    loading: state.clientes.clienteCabeceraById.loading,
    filtroListaPrecio: state.articulos.filtrosArticulosPorCliente.listaPrecioId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadClienteCabeceraById: (idCliente, success) => dispatch(actions.loadClienteCabeceraById(idCliente, success)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CabeceraPedido);