/*eslint-disable */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import * as actions from "../../../store/actions";

import Spinner from "../Spinner/Spinner";
import { storageMonedaId, storageMonedaSigno, } from "../../../assets/shared/sessionData";
import { Tooltip, FormControl, MenuItem, Select, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '3px',
  }
}));

const MonedaSelector = (props) => {
  const classes = useStyles();
  const { monedaId } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const handleTooltip = bool => {
      setTooltipOpen(bool)
  }

  const [monedaIdSelected, setMonedaIdSelected] = useState(
    storageMonedaId() !== null ? storageMonedaId() : monedaId ? monedaId : ""
  );

  useEffect(() => {
    if (props.monedas.length === 0) props.onLoadMonedas();
  }, []);

  const onMonedaChange = (e) => {
    setMonedaIdSelected(e.target.value);
    props.monedaSelected(
      props.monedas.filter((moneda) => moneda.idMoneda === e.target.value)[0]
    );
  };

  return props.loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.monedaSigno && <div style={{ fontSize: '20px' }}>{storageMonedaSigno()}</div>}
      <FormControl style={{ margin: "0px 10px 0px 10px" }}>
        <Tooltip
          open={tooltipOpen}
          arrow
          title={
            <Typography style={{ textAlign: 'left', font: 'normal normal normal 14px/19px Roboto', color: '8D8C8C' }}>
              Seleccione una moneda
            </Typography>
          }
          classes={{
            tooltip:classes.tooltip,
          }}
        >
          <Select
            style={{ minWidth: 120, color: props.letraColor }}
            labelId="monedaLabel"
            id="monedaSelect"
            value={props.monedas.length !== 0 ? monedaIdSelected : ""}
            onChange={(event) => onMonedaChange(event)}
            onMouseEnter={() => {handleTooltip(true)}}
            onMouseLeave={() => {handleTooltip(false)}}
            onOpen={() => {handleTooltip(false)}}
          >
            {props.monedas.map((moneda, index) => (
              <MenuItem key={index} value={moneda.idMoneda}>
                {moneda.nombre}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.monedas.loading,
    monedas: state.monedas.monedas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadMonedas: () => dispatch(actions.loadMonedas()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonedaSelector);
/*eslint-enable */
