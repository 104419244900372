import * as actionTypes from "./actionTypes";
import axiosVendedores from "../../axios-vendedores";
import axiosPedidos from "../../axios-pedidos";
import { enqueueSnackbar } from './snackbar';

export const loadComprobantePedido = (idPlataforma) => {
  return (dispatch) => {
    dispatch(loadComprobantePedidoStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

    const headers = {
      'Authorization': authStr,
      'Id-Empresa': idEmpresa,
    }

    axiosVendedores
      .get(
        `/notadepedido/configuracioncomprobante?idEmpresa=${idEmpresa}&idPlataforma=${idPlataforma}`,
        {
          headers: headers,
        })
      .then((response) => {
        dispatch(loadComprobantePedidoSuccess(response.data));
      })
      .catch((error) => {  
        if (error.response && error.response.data){          
          console.log(error.response.data);
        }
        
        dispatch(loadComprobantePedidoFail(error));
      });
  };
};

const loadComprobantePedidoStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTE_PEDIDO_START,
  };
};

const loadComprobantePedidoSuccess = (result) => {
  return {
    type: actionTypes.LOAD_COMPROBANTE_PEDIDO_SUCCESS,
    result,
  };
};

const loadComprobantePedidoFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTE_PEDIDO_FAIL,
    error,
  };
};

export const sendPedido = (pedido, success, onError) => {
  return (dispatch) => {
    dispatch(sendPedidoStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);
    const headers = {
      'Authorization': authStr,
      'Content-Type': 'application/json',
      'Id-Empresa': idEmpresa,
    };

    axiosPedidos
      .post(`/notasDePedido`, pedido, {
        headers: headers,
      })
      .then((response) => {
        dispatch(sendPedidoSuccess(response.data));
        return success(Promise.resolve);
      })
      .catch((error) => {
        let errorMessage = `Error al grabar el pedido, intente nuevamente`;   
        if (error.response && error.response.data){
          if(error.response.data.errors){
            errorMessage = `Error al grabar el pedido, verifique los campos requeridos e intente nuevamente`;
          }else{
            errorMessage = error.response.data;
          }
        }

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        dispatch(sendPedidoFail(error)); 
        return onError(Promise.resolve);
      });
  };
};

const sendPedidoStart = () => {
  return {
    type: actionTypes.SEND_PEDIDO_START,
  };
};

const sendPedidoSuccess = (result) => {
  return {
    type: actionTypes.SEND_PEDIDO_SUCCESS,
    result,
  };
};

const sendPedidoFail = (error) => {
  return {
    type: actionTypes.SEND_PEDIDO_FAIL,
    error,
  };
};