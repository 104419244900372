import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import TextChart from "../../../Charts/TextChart";

const TextoCantidadVentas = (props) => {
  const {
    onLoadChart,
    cantidad,
    loading,
    loadingPorCliente,
    clientSelected,
    inClientInfo,
    clienteId,
    estaCargado,
    cantidadPorCliente,
    error,
    title,
  } = props;

  const vendedorSelected = sessionStorage.getItem("vendedorId");
  const chartSinDatos = error || (!inClientInfo && !vendedorSelected);

  useEffect(() => {
    if (estaCargado && vendedorSelected) {
      if(inClientInfo && clientSelected)
        onLoadChart(clienteId);
      else
        onLoadChart();
    }
  }, [
    onLoadChart, 
    inClientInfo,
    estaCargado, 
    vendedorSelected,
    clientSelected,
    clienteId,
  ]);

  return (
    <TextChart
      title={`${title ? title : `Cantidad de ventas`}`}
      bigAmount={`${inClientInfo ? cantidadPorCliente : cantidad}`}
      smallAmount={`${inClientInfo ? cantidadPorCliente : cantidad}`}
      noClient={!clientSelected && inClientInfo}
      loading={inClientInfo ? loadingPorCliente : loading}
      imgSinDatos={error || chartSinDatos}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.ventas.ventasPorVendedor.error,
    estaCargado: state.ventas.ventasPorVendedor.estaCargado,
    loading: state.ventas.ventasPorVendedor.loading,
    cantidad: state.ventas.ventasPorVendedor.cantidad,
    unidadesVendidas: state.ventas.ventasPorVendedor.unidadesVendidas,
    total: state.ventas.ventasPorVendedor.ventas,
    loadingPorCliente: state.ventas.ventasPorVendedor.loadingPorCliente,
    cantidadPorCliente: state.ventas.ventasPorVendedor.cantidadPorCliente,
    unidadesVendidasPorCliente: state.ventas.ventasPorVendedor.unidadesVendidasPorCliente,
    totalPorCliente: state.ventas.ventasPorVendedor.ventasPorCliente,
    clientSelected: state.clientes.clienteById.clientSelected,
    clienteId: state.clientes.clienteById.cliente.idCliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idCliente) => dispatch(actions.loadVentasPorVendedor(idCliente)),
    onUnmountChart: () => dispatch(actions.unmountClienteById()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextoCantidadVentas);
