import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as actions from "../../../store/actions";
import { Tooltip, Typography } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 10px", 
    display: "flex", 
    alignItems: "center",
    justifyContent: 'flex-start',
  },
  tooltip: {
    textAlign: "left",
    font: "normal normal normal 14px/19px Roboto",
    color: "8D8C8C",
  },
  icon: {
    fontSize: "1.8rem",
    cursor: "pointer",
    marginRight: "10px",
    paddingTop: 0,
    paddingBottom: 0
  },
  iconMobile: {    
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: 'white',
    }
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "3px",
  },
  popper: {
    zIndex: 1500,
  },
}))(Tooltip);

const UltimaActualizacion = (props) => {
  const classes = useStyles();
  const {
    clienteSelected,
    ultimaActualizacionFotoDelDia,
    ultimaActualizacionCliente,
    ultimaActualizacionCatalogo,
    ultimaActualizacionTiemposReq,
    ultimaActualizacionChangeLogs,
    ultimaActualizacionAnalisisDeVentas,
    onActualizarIndicadoresFotoDelDia,
    onUpdateUltimaActualizacionFotoDelDia,
    onActualizarIndicadoresCliente,
    onUpdateUltimaActualizacionCliente,
    onActualizarCatalogo,
    onUpdateUltimaActualizacionCatalogo,
    onActualizarTiemposReq,
    onUpdateUltimaActualizacionTiemposReq,
    onActualizarChangeLogs,
    onUpdateUltimaActualizacionChangeLogs,
    onActualizarAnalisisDeVentas,
    onUpdateUltimaActualizacionAnalisisDeVentas,
  } = props;

  const pathNamePages = {
    fotoDelDia: "/fotoDelDia",
    home: "/",
    cliente: "/cliente",
    tiemposReq: "/tiemposReq",
    catalogo: "/catalogo",
    changeLogs: "/changeLogs",
    analisisDeVentas: '/analisisventas',
  };

  const urlRef = global.window.location.pathname;
  const dateActual = () => {
    return  moment(Date.now()).format("DD/MM/YYYY HH:mm");
  }

  const actualizarFechaHora = (page) => {
    switch (page) {
      case pathNamePages.fotoDelDia:
        return (
          ultimaActualizacionFotoDelDia === "" &&
          onUpdateUltimaActualizacionFotoDelDia(dateActual())
        );

      case pathNamePages.home:
        return (
          ultimaActualizacionFotoDelDia === "" &&
          onUpdateUltimaActualizacionFotoDelDia(dateActual())
        );

      case pathNamePages.cliente:
        return (
          ultimaActualizacionCliente === "" &&
          onUpdateUltimaActualizacionCliente(dateActual())
        );

      case pathNamePages.tiemposReq:
        return (
          ultimaActualizacionTiemposReq === "" &&
          onUpdateUltimaActualizacionTiemposReq(dateActual())
        );

      case pathNamePages.catalogo:
        return (
          ultimaActualizacionCatalogo === "" &&
          onUpdateUltimaActualizacionCatalogo(dateActual())
        );

      case pathNamePages.changeLogs:
        return (
          ultimaActualizacionChangeLogs === "" &&
          onUpdateUltimaActualizacionChangeLogs(dateActual())
        );

      case pathNamePages.analisisDeVentas:
        return (
          ultimaActualizacionAnalisisDeVentas === "" &&
          onUpdateUltimaActualizacionAnalisisDeVentas(dateActual())
        );     

      default:
        break;
    }
  };

  const infoUltimaActualizacion = () => {
    switch (urlRef) {
      case pathNamePages.fotoDelDia:
        return ultimaActualizacionFotoDelDia;

      case pathNamePages.home:
        return ultimaActualizacionFotoDelDia;

      case pathNamePages.cliente:
        return ultimaActualizacionCliente;

      case pathNamePages.tiemposReq:
        return ultimaActualizacionTiemposReq;

      case pathNamePages.catalogo:
        return ultimaActualizacionCatalogo;

      case pathNamePages.changeLogs:
        return ultimaActualizacionChangeLogs;

      case pathNamePages.analisisDeVentas:
        return ultimaActualizacionAnalisisDeVentas;

      default:
        break;
    }
  };

  const onActualizarIndicadoresClick = () => {
    switch (urlRef) {
      case pathNamePages.fotoDelDia:
        return onActualizarIndicadoresFotoDelDiaSwitch();

      case pathNamePages.home:
        return onActualizarIndicadoresFotoDelDiaSwitch();

      case pathNamePages.cliente:
        return onActualizarIndicadoresClienteSwitch();

      case pathNamePages.tiemposReq:
        return onActualizarTiemposReqSwitch();

      case pathNamePages.catalogo:
        return onActualizarCatalogoSwitch();

      case pathNamePages.changeLogs:
        return onActualizarChangeLogsSwitch();

      case pathNamePages.analisisDeVentas:
        return onActualizarAnalisisDeVentasSwitch();

      default:
        break;
    }
  };

  const onActualizarIndicadoresFotoDelDiaSwitch = () => {
    onUpdateUltimaActualizacionFotoDelDia(dateActual());
    onActualizarIndicadoresFotoDelDia();
  };

  const onActualizarIndicadoresClienteSwitch = () => {
    if (clienteSelected.idCliente) {
      onUpdateUltimaActualizacionCliente(dateActual());
      onActualizarIndicadoresCliente(clienteSelected.idCliente);
    }
  };

  const onActualizarTiemposReqSwitch = () => {
    onUpdateUltimaActualizacionTiemposReq(dateActual());
    onActualizarTiemposReq();
  };

  const onActualizarCatalogoSwitch = () => {
    onUpdateUltimaActualizacionCatalogo(dateActual());
    onActualizarCatalogo();
  };

  const onActualizarChangeLogsSwitch = () => {
    onUpdateUltimaActualizacionChangeLogs(dateActual());
    onActualizarChangeLogs();
  };

  const onActualizarAnalisisDeVentasSwitch = () => {
    onUpdateUltimaActualizacionAnalisisDeVentas(dateActual());
    onActualizarAnalisisDeVentas();
  }

  useEffect(() => {
    actualizarFechaHora(urlRef);
    infoUltimaActualizacion();
  }, [urlRef]);

  return (
    <div className={classes.root} onClick={(e) => onActualizarIndicadoresClick(e)}>
      <LightTooltip
        arrow
        title={
          <Typography className={classes.tooltip}>
            Última actualización {infoUltimaActualizacion()}
          </Typography>
        }
      >
        <AccessTimeIcon
          className={classes.icon}
          style={{
            color: props.color, 
            fontSize: props.iconSize,
          }}          
        />
      </LightTooltip>
      <div className={classes.iconMobile}>
        <Typography>
          Última actualización:
        </Typography>
        <Typography>
          {infoUltimaActualizacion()}
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clienteSelected: state.clientes.clienteById.cliente,
    clientSelected: state.clientes.clienteById.clientSelected,
    ultimaActualizacionFotoDelDia: state.ultimasActualizaciones.fotoDelDia,
    ultimaActualizacionCliente: state.ultimasActualizaciones.cliente,
    ultimaActualizacionCatalogo: state.ultimasActualizaciones.catalogo,
    ultimaActualizacionTiemposReq: state.ultimasActualizaciones.tiemposReq,
    ultimaActualizacionChangeLogs: state.ultimasActualizaciones.changeLogs,
    ultimaActualizacionAnalisisDeVentas: state.ultimasActualizaciones.analisisDeVentas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //#region Foto del dia
    onActualizarIndicadoresFotoDelDia: (indicadoresOcultos) =>
      dispatch(actions.actualizarIndicadoresFotoDelDia(indicadoresOcultos)),
    onUpdateUltimaActualizacionFotoDelDia: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionFotoDelDia(fechaHora)),
    //#endregion

    //#region Cliente
    onActualizarIndicadoresCliente: (idCliente) =>
      dispatch(actions.actualizarIndicadoresCliente(idCliente)),
    onUpdateUltimaActualizacionCliente: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionCliente(fechaHora)),
    //#endregion

    //#region Catalogo
    onActualizarCatalogo: () => dispatch(actions.actualizarCatalogo()),
    onUpdateUltimaActualizacionCatalogo: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionCatalogo(fechaHora)),
    //#endregion

    //#region Tiempos req
    onActualizarTiemposReq: () => dispatch(actions.actualizarTiemposReq()),
    onUpdateUltimaActualizacionTiemposReq: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionTiemposRequest(fechaHora)),
    //#endregion

    //#region Change logs
    onActualizarChangeLogs: () => dispatch(actions.actualizarChangeLogs()),
    onUpdateUltimaActualizacionChangeLogs: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionChangeLogs(fechaHora)),
    //#endregion

    //#region Analisis de ventas
    onActualizarAnalisisDeVentas: () => dispatch(actions.actualizarAnalisisVentas()),
    onUpdateUltimaActualizacionAnalisisDeVentas: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionAnalisisDeVentas(fechaHora)),
    //#endregion
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UltimaActualizacion);
