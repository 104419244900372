// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LineEvolucionVentasDelVendedorPorSeleccion__SelectRoot__1_lnE {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n\r\n.LineEvolucionVentasDelVendedorPorSeleccion__LineEvolucionVentasMensualContainer__UZ97R {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n}\r\n\r\n.LineEvolucionVentasDelVendedorPorSeleccion__TooltipContainer__CDADR {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 10px;\r\n}\r\n\r\n.LineEvolucionVentasDelVendedorPorSeleccion__Title__2GQvQ {\r\n  align-self: center;\r\n}\r\n\r\n.LineEvolucionVentasDelVendedorPorSeleccion__IconSelectRange__1FG_E {\r\n  opacity: 0.6;\r\n}\r\n\r\n.LineEvolucionVentasDelVendedorPorSeleccion__Divider__3QIxW {\r\n  height: 1px;\r\n  background-color: black;\r\n  opacity: 0.5;\r\n}\r\n\r\n.LineEvolucionVentasDelVendedorPorSeleccion__Body__2x85V {\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  margin-top: 10px;\r\n}\r\n\r\n.LineEvolucionVentasDelVendedorPorSeleccion__TextoContainer__ZYWln {\r\n  display: flex;\r\n  align-items: center;\r\n  width: 100%;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.LineEvolucionVentasDelVendedorPorSeleccion__Circle__19c-K {\r\n  width: 13px;\r\n  height: 13px;\r\n  border-radius: 50%;\r\n  margin-right: 10px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectRoot": "LineEvolucionVentasDelVendedorPorSeleccion__SelectRoot__1_lnE",
	"LineEvolucionVentasMensualContainer": "LineEvolucionVentasDelVendedorPorSeleccion__LineEvolucionVentasMensualContainer__UZ97R",
	"TooltipContainer": "LineEvolucionVentasDelVendedorPorSeleccion__TooltipContainer__CDADR",
	"Title": "LineEvolucionVentasDelVendedorPorSeleccion__Title__2GQvQ",
	"IconSelectRange": "LineEvolucionVentasDelVendedorPorSeleccion__IconSelectRange__1FG_E",
	"Divider": "LineEvolucionVentasDelVendedorPorSeleccion__Divider__3QIxW",
	"Body": "LineEvolucionVentasDelVendedorPorSeleccion__Body__2x85V",
	"TextoContainer": "LineEvolucionVentasDelVendedorPorSeleccion__TextoContainer__ZYWln",
	"Circle": "LineEvolucionVentasDelVendedorPorSeleccion__Circle__19c-K"
};
export default ___CSS_LOADER_EXPORT___;
