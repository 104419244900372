const Storage = (carritoItems) => {
    sessionStorage.setItem('carrito', JSON.stringify(carritoItems.length > 0 ? carritoItems: []));
}

const StorageResumen = (resumen) => {
    sessionStorage.setItem('resumenCarrito', JSON.stringify(resumen));
}

export const ResumenVacio = () => {
    let resumen = {
        totalItems: 0,
        subTotal1: 0.00,
        porcBonif1: 0.00,
        bonificacion1: 0.00,
        porcBonif2: 0.00,
        bonificacion2: 0.00,
        subTotal2: 0.00,
        impuestos: 0.00,
        listaImpuestos: [],
        total: 0.00
    };

    return resumen;
}

export const sumItems = carritoItems => {
    Storage(carritoItems);
    let itemCount = carritoItems.reduce((total, articulo) => total + articulo.cantidad, 0);
    let total = carritoItems.reduce((total, articulo) => total + articulo.precio * articulo.cantidad, 0).toFixed(2);
    return { itemCount, total }
}

export const saveResumen = resumen => {
    StorageResumen(resumen);
    return { resumen }
}

export const CarritoReducer = (state, action) => {
    switch (action.type) {
        case "ADD_ITEM":
            if (!state.carritoItems.find(item => item.idArticulo === action.payload.idArticulo)) {
                state.carritoItems.push({
                    ...action.payload,
                    cantidad: action.payload.cantidad !== undefined && 
                        action.payload.cantidad > 0 
                        ? action.payload.cantidad 
                        : 1,
                    total: 0
                });
            } 

            return {
                ...state,
                ...sumItems(state.carritoItems),
                carritoItems: [...state.carritoItems],
                update: true,
            }
        case "REMOVE_ITEM":
            return {
                ...state,
                ...sumItems(state.carritoItems.filter(item => item.idArticulo !== action.payload.idArticulo)),
                carritoItems: [...state.carritoItems.filter(item => item.idArticulo !== action.payload.idArticulo)],
                update: true,
            }
        case "INCREASE":
            var item = state.carritoItems.find(i => i.idArticulo === action.payload.idArticulo);
            item.cantidad++;

            return {
                ...state,
                ...sumItems(state.carritoItems),
                carritoItems: [...state.carritoItems],
                update: true,
            }
        case "DECREASE":
            var item1 = state.carritoItems.find(i => i.idArticulo === action.payload.idArticulo);            
            item1.cantidad--;

            return {
                ...state,
                ...sumItems(state.carritoItems),
                carritoItems: [...state.carritoItems],
                update: true,
            }
        case "UPDATE_CANT":
            var item2 = state.carritoItems.find(i => i.idArticulo === action.payload.idArticulo);            
            item2.cantidad = action.cantidad;
    
            return {
                ...state,
                ...sumItems(state.carritoItems),
                carritoItems: [...state.carritoItems],
                update: true,
            }
        case "CHECKOUT":
            return {
                carritoItems: [],
                checkout: true,
                ...sumItems([]),
                update: true,
                ...saveResumen(ResumenVacio())
            }
        case "CLEAR":
            return {
                carritoItems: [],
                checkout: false,
                ...sumItems([]),
                update: true,
                ...saveResumen(ResumenVacio())
            }
        case "UPDATE":
            return {
                ...state,
                update: true,
            }
        case "UPDATE_RESUMEN":
            return {
                ...state,
                ...saveResumen(action.resumen)
            }
        case "CLEAR_UPDATE":
            return {
                ...state,
                update: false,
            }
        default:
            return state;
    }
}

export default CarritoReducer;