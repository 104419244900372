import { TextField } from "@material-ui/core";
import React, { useState, Fragment } from "react";
import classes from "./MultipleEmails.css";

const MultipleEmails = (props) => {
  const {
    items,
    setItems,
    errorItems,
    errorItemsMessage,
    disabled,
  } = props;

  const [value, setValue] = useState("");
  const [error, setError] = useState(null);

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      const newValue = value.trim();

      if (newValue && isValid(newValue)) {
        evt.preventDefault();

        setItems([...items, value]);
        setValue("");
      }
    }
  };

  const handleChange = (evt) => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = (item) => {
    const filterItems = items.filter(i => i !== item);
    setItems(filterItems);
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !isInList(email));

      setItems([...items, ...toBeAdded]);
    }
  };

  const isValid = (email) => {
    let errorEmail = null;

    if (isInList(email)) {
      errorEmail = `${email} ya fue agregado.`;
    }

    if (!isEmail(email)) {
      errorEmail = `${email} no es una dirección de mail válida.`;
    }

    if (errorEmail) {
      setError(errorEmail);
      return false;
    }

    return true;
  }

  const isInList = (email) => {
    return items.includes(email);
  }

  const isEmail = (email) => {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  }

  return (
    <Fragment>
      <TextField 
        className={error ? [classes.input, classes.hasError].join(' ') : classes.input}
        label="Destinatarios"
        variant="outlined"
        size="small"
        value={value}
        placeholder="Ingrese dirección de correo electrónico..."
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onPaste={handlePaste}
        required={true}
        error={errorItems}
        helperText={errorItems && errorItemsMessage}
        disabled={disabled}
      />
      {error && <p className={classes.error}>{error}</p>}

      {items.map(item => (
        <div className={classes.tagItem} key={item}>
          {item}
          <button
            type="button"
            className={classes.button}
            onClick={() => handleDelete(item)}
          >
            &times;
          </button>
        </div>
      ))}      
    </Fragment>
  );
}

export default MultipleEmails;