import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import classes from './Tabs.css';

const FullWidthTabs = (props) => {
  const { customFirstPage, flagResetIndex } = props;
  const [indexSelected, setIndexSelected] = useState(customFirstPage ? customFirstPage : 0);

  useEffect(() => {
    setIndexSelected(0);
  }, [flagResetIndex]);

  return (
    <div>
      <Tabs
        variant={'fullWidth'}
        classes={{
          scroller: classes.TabsScroller,
          flexContainer: classes.TabsContainer,
          indicator: classes.TabsIndicator
        }}
        value={indexSelected}
        onChange={(e, value) => setIndexSelected(value)}
      >
        {props.tabs.map((tab, key) => {
          return <Tab key={key} style={{ width: '100%', minHeight: 0 }} label={tab} />;
        })}
      </Tabs>
      {props.views[indexSelected]}
    </div>
  );
};

export default FullWidthTabs;