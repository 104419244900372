// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PieVentasDelVendedorPorSeleccion__LabelVerPor__3Sfa8 {\r\n  font-weight: bold;\r\n}\r\n\r\n.PieVentasDelVendedorPorSeleccion__SelectRoot__1TvF5 {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LabelVerPor": "PieVentasDelVendedorPorSeleccion__LabelVerPor__3Sfa8",
	"SelectRoot": "PieVentasDelVendedorPorSeleccion__SelectRoot__1TvF5"
};
export default ___CSS_LOADER_EXPORT___;
