import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { esES } from "@material-ui/core/locale";
import CarritoContextProvider from "./store/contexts/carrito";
import store from "./store";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "./index.css";

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: "#00bcd4",
      },
      secondary: {
        main: "#b2ebf2",
      },
      black: {
        main: "#333333",
      },
    },
    zIndex: {
      tooltip: 1150,
    },
  },
  esES
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider autoHideDuration={1500}>
          <CarritoContextProvider>
            <App />
          </CarritoContextProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
