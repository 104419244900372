import React, { useState, useEffect , useCallback, useContext } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider, TextField } from "@material-ui/core";
import { CarritoContext } from '../../store/contexts/carrito';
import { roundAccurately } from '../../assets/shared/formatNumber';
import * as actions from "../../store/actions";
import NumberFormatCustom from '../UI/Input/NumberFormatCustom';

const useStyles = makeStyles((theme) => ({  
  title:{
    fontSize: "large", 
    fontWeight: 'bold',
  },
  labels:{
    fontSize: "revert", 
  },
  row:{
    display: 'flex',
    justifyContent: "space-between",
    alignItems:"center",
  },
  text: {
    textAlign: "center",
    "& .MuiInputBase-input": {
      textAlign: 'end',
      minWidth: '30px',
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingRight: '4px',
      paddingLeft: '4px',
      height: '0px',
    }
  }
}));

const ResumenPedido = (props) => {
  const {
    listaDePrecio,
    clienteCabecera,
    comprobante,
    estaCargado,
    loadComprobantePedido,
  } = props;

  const { 
    itemCount,
    carritoItems,
    update,
    updateCarrito,
    clearUpdateCarrito,
    resumen,
    updateResumen, 
  } = useContext(CarritoContext);

  const [articulos, setArticulos] = useState();
  const [totalItems, setTotalItems] = useState(0);
  const [subTotal, setSubTotal] = useState(0.00);
  const [porcBonif1, setPorcBonif1] = useState();
  const [bonificacion1, setBonificacion1] = useState(0.00);
  const [porcBonif2, setPorcBonif2] = useState();
  const [bonificacion2, setBonificacion2] = useState(0.00);
  const [subTotalBonificacion, setSubTotalBonificacion] = useState(0.00);
  const [impuestos, setImpuestos] = useState();
  const [total, setTotal] = useState(0.00);
  const [decimalesPrecio, setDecimalesPrecio] = useState(2);
  const [decimalesCantidad, setDecimalesCantidad] = useState(0);
  const [enterosPrecio, setEnterosPrecio] = useState(12);
  const [enterosCantidad, setEnterosCantidad] = useState(5);

  const classes = useStyles();

  const getListaIncluyeIva = useCallback(() => {
    let incluyeIva = false;

    if(clienteCabecera !== null && clienteCabecera !== undefined){
      let itemCliente = clienteCabecera.listasDePrecios.find(list => list.id === listaDePrecio);

      if(itemCliente !== null && itemCliente !== undefined) {
        incluyeIva = itemCliente.ivaIncluido;
      }
    }

    return incluyeIva;
  }, [
    clienteCabecera,
    listaDePrecio,
  ]);

  const getComprobanteLiquidaIva = useCallback(() => {
    let incluyeIva = false;

    if(comprobante !== null && comprobante !== undefined){
      incluyeIva = comprobante.liquidaIva;
    }

    return incluyeIva;
  }, [comprobante,]);    

  const calcularSinIva = useCallback((precio, iva) => {
    let num = precio <= 0 ? precio : (1 - iva / 100) * precio;
    return roundAccurately(num, decimalesPrecio);
  }, [decimalesPrecio]);
  
  const calcularPrecioBonificado = useCallback((precio, porcentaje, decimalesPrecio) =>{
    let num = precio * porcentaje / 100;
    return roundAccurately(num, decimalesPrecio);
  }, []);

  const calcularPorcentaje = useCallback((precio, porcentaje, decimalesPrecio) => {
    let num = precio <= 0 ? 0 : precio * porcentaje / 100;
    return roundAccurately(num, decimalesPrecio);
  }, []);

  const calcularTotalItem = useCallback((precio, bonificacion, cantidad, decimalesPrecio) => {   
    const num = bonificacion === null || bonificacion === undefined || bonificacion === 0
      ? precio
      : (1 - bonificacion / 100) * precio;
  
    let totalNum = num * cantidad;
    return roundAccurately(totalNum, decimalesPrecio);
  }, []);  

  const calcularSubTotal = useCallback((items, incluyeIva) => {
    let value = 0;

    if(items !== null && items !== undefined){
      items.forEach((item) => { value += item.total; });
    }

    return roundAccurately(value, decimalesPrecio);
  }, [decimalesPrecio]);

  const calcularImpuestos = useCallback((items, incluyeIva, liquidaIva) => {
    let value = 0;
    let impuestos = [];

    if(items !== null && items !== undefined){    
      items.forEach((item) => {
        const precio = incluyeIva ? calcularSinIva(item.precio, item.porcentajeIva) : item.precio;
        const total = calcularTotalItem(precio, item.bonificacion, item.cantidad, 2);
        const totalBonif1 = total * (100 - porcBonif1) / 100;
        const totalBonif2 = totalBonif1 * (100 - porcBonif2) / 100;
        const baseImponible = roundAccurately(totalBonif2, 2);

        let impuesto = impuestos.find(imp => imp.alicuotaIvaId === item.idAlicuotaIva);

        if(impuesto === null || impuesto === undefined){
          impuesto = {
            alicuotaIvaId: item.idAlicuotaIva,
            baseImponible: baseImponible,
            porcentaje: item.porcentajeIva,
            importeIva: 0
          };

          impuestos.push(impuesto);
        }else{
          impuesto.baseImponible += baseImponible;
        }
      });

      impuestos.forEach((item) => {
        let iva = calcularPorcentaje(item.baseImponible, item.porcentaje, 2);
        item.importeIva = iva;
        value += iva;
      });
    }
    
    var result = { 
      total: liquidaIva ? roundAccurately(value, decimalesPrecio) : 0,
      impuestos: impuestos
    };

    return result;
  }, [
    decimalesPrecio, 
    porcBonif1, 
    porcBonif2, 
    calcularPorcentaje, 
    calcularSinIva, 
    calcularTotalItem,
  ]);

  //Comprobante Sin Iva, Precio sin IVA
  const calcularIvaCaso1 = useCallback((items) => {
    //Calcular Subtotal 1
    let resultSubTot = calcularSubTotal(items, false);
    setSubTotal(resultSubTot.toFixed(2));

    //Calcular bonificación 1
    const resultBonif1 = calcularPrecioBonificado(resultSubTot, porcBonif1, 2);
    setBonificacion1(resultBonif1.toFixed(2));
    const subTotalBonif1 = roundAccurately(resultSubTot - resultBonif1, 2);

    //Calcular Bonificación 2
    const resultBonif2 = calcularPrecioBonificado(subTotalBonif1, porcBonif2, 2);
    setBonificacion2(resultBonif2.toFixed(2));

    //Calcular Subtotal 2
    const resultSubTotBonif = roundAccurately(resultSubTot - resultBonif1 - resultBonif2, 2);
    setSubTotalBonificacion(resultSubTotBonif.toFixed(2));

    //Calcular Impuestos
    const resultImpuestos = calcularImpuestos(items, false, false);
    setImpuestos(resultImpuestos.total.toFixed(2));

    //Calcular Total
    const resultTotal = roundAccurately(resultSubTotBonif + resultImpuestos.total, 2);
    setTotal(resultTotal.toFixed(2));

    //Se actualiza el resumen del carrito
    let resumenTemp = resumen;    
    resumenTemp.totalItems = totalItems;
    resumenTemp.subTotal1 = resultSubTot;
    resumenTemp.porcBonif1 = porcBonif1;
    resumenTemp.bonificacion1 = resultBonif1;
    resumenTemp.porcBonif2 = porcBonif2;
    resumenTemp.bonificacion2 = resultBonif2;
    resumenTemp.subTotal2 = resultSubTotBonif;
    resumenTemp.impuestos = resultImpuestos;
    resumenTemp.listaImpuestos = resultImpuestos.impuestos;
    resumenTemp.total = resultTotal;
    updateResumen(resumenTemp);

  }, [calcularImpuestos, calcularPrecioBonificado, calcularSubTotal, porcBonif1, porcBonif2, resumen, 
    totalItems, updateResumen]);

  //Comprobante Con Iva, Precio sin IVA
  const calcularIvaCaso2 = useCallback((items) => {
    //Calcular Subtotal 1
    let resultSubTot = calcularSubTotal(items, false);
    setSubTotal(resultSubTot.toFixed(2));

    //Calcular bonificación 1
    const resultBonif1 = calcularPrecioBonificado(resultSubTot, porcBonif1, 2);
    setBonificacion1(resultBonif1.toFixed(2));
    const subTotalBonif1 = roundAccurately(resultSubTot - resultBonif1, 2);

    //Calcular Bonificación 2
    const resultBonif2 = calcularPrecioBonificado(subTotalBonif1, porcBonif2, 2);
    setBonificacion2(resultBonif2.toFixed(2));

    //Calcular Subtotal 2
    const resultSubTotBonif = roundAccurately(resultSubTot - resultBonif1 - resultBonif2, 2);
    setSubTotalBonificacion(resultSubTotBonif.toFixed(2));

    //Calcular Impuestos
    const resultImpuestos = calcularImpuestos(items, false, true);
    setImpuestos(resultImpuestos.total.toFixed(2));

    //Calcular Total
    const resultTotal = roundAccurately(resultSubTotBonif + resultImpuestos.total, 2);
    setTotal(resultTotal.toFixed(2));

    //Se actualiza el resumen del carrito
    let resumenTemp = resumen;    
    resumenTemp.totalItems = totalItems;
    resumenTemp.subTotal1 = resultSubTot;
    resumenTemp.porcBonif1 = porcBonif1;
    resumenTemp.bonificacion1 = resultBonif1;
    resumenTemp.porcBonif2 = porcBonif2;
    resumenTemp.bonificacion2 = resultBonif2;
    resumenTemp.subTotal2 = resultSubTotBonif;
    resumenTemp.impuestos = resultImpuestos;
    resumenTemp.listaImpuestos = resultImpuestos.impuestos;
    resumenTemp.total = resultTotal;
    updateResumen(resumenTemp);
  }, [calcularImpuestos, calcularSubTotal, porcBonif1, porcBonif2, resumen, 
    totalItems, updateResumen, calcularPrecioBonificado]);

  //Comprobante Sin Iva, Precio con IVA  
  const calcularIvaCaso3 = useCallback((items) => {
    //Calcular Subtotal 1
    let resultSubTot = calcularSubTotal(items, false);
    setSubTotal(resultSubTot.toFixed(2));

    //Calcular bonificación 1
    const resultBonif1 = calcularPrecioBonificado(resultSubTot, porcBonif1, 2);
    setBonificacion1(resultBonif1.toFixed(2));
    const subTotalBonif1 = roundAccurately(resultSubTot - resultBonif1, 2);

    //Calcular Bonificación 2
    const resultBonif2 = calcularPrecioBonificado(subTotalBonif1, porcBonif2, 2);
    setBonificacion2(resultBonif2.toFixed(2));

    //Calcular Subtotal 2
    const resultSubTotBonif = roundAccurately(resultSubTot - resultBonif1 - resultBonif2, 2);
    setSubTotalBonificacion(resultSubTotBonif.toFixed(2));

    //Calcular Impuestos
    const resultImpuestos = calcularImpuestos(items, true, false);
    setImpuestos(resultImpuestos.total.toFixed(2));

    //Calcular Total
    const resultTotal = roundAccurately(resultSubTotBonif + resultImpuestos.total, 2);
    setTotal(resultTotal.toFixed(2));

    //Se actualiza el resumen del carrito
    let resumenTemp = resumen;    
    resumenTemp.totalItems = totalItems;
    resumenTemp.subTotal1 = resultSubTot;
    resumenTemp.porcBonif1 = porcBonif1;
    resumenTemp.bonificacion1 = resultBonif1;
    resumenTemp.porcBonif2 = porcBonif2;
    resumenTemp.bonificacion2 = resultBonif2;
    resumenTemp.subTotal2 = resultSubTotBonif;
    resumenTemp.impuestos = resultImpuestos;
    resumenTemp.listaImpuestos = resultImpuestos.impuestos;
    resumenTemp.total = resultTotal;
    updateResumen(resumenTemp);
  }, [calcularImpuestos, calcularSubTotal, porcBonif1, porcBonif2, resumen, 
    totalItems, updateResumen, calcularPrecioBonificado]);

  //Comprobante con Iva, Precio con IVA  
  const calcularIvaCaso4 = useCallback((items) => {

  }, []);

  const calcularDatosResumen = useCallback((items) => {
    setTotalItems(itemCount && itemCount.toFixed(decimalesCantidad));   

    const listaIncluyeIva = getListaIncluyeIva();
    const comprobaLiquidaIva = getComprobanteLiquidaIva();

    if(listaIncluyeIva){
      if(comprobaLiquidaIva){
        calcularIvaCaso4(items);
      }else{
        calcularIvaCaso3(items);
      }
    }else{
      if(comprobaLiquidaIva){
        calcularIvaCaso2(items);
      }else{
        calcularIvaCaso1(items);
      }
    }

    clearUpdateCarrito();
  }, [
    itemCount,
    clearUpdateCarrito,
    getListaIncluyeIva,
    getComprobanteLiquidaIva,
    calcularIvaCaso1,
    calcularIvaCaso2,
    calcularIvaCaso3,
    calcularIvaCaso4,
    decimalesCantidad,
  ]);
  
  const onPorcBonif1Change = (value) => {
    let newValue = Number(value);
    setPorcBonif1(newValue);               
    updateCarrito();
  }

  const onPorcBonif2Change = (value) => {
    let newValue = Number(value);
    setPorcBonif2(newValue);        
    updateCarrito();
  }

  useEffect(() => {
    if(comprobante === null || comprobante === undefined){
      loadComprobantePedido("JL");
    }else{
      setDecimalesPrecio(comprobante.decimalesPrecio);
      setDecimalesCantidad(comprobante.decimalesCantidad);
      setEnterosPrecio(comprobante.enterosPrecio);
      setEnterosCantidad(comprobante.enterosCantidad);
    }
  }, [comprobante, loadComprobantePedido]);

  const setupPorcBonif1 = useCallback(() => {
    let porc = 0.00;
    if(resumen !== undefined && resumen.porcBonif1 !== undefined){
      porc = Number(resumen.porcBonif1);
    }else{
      if(clienteCabecera !== undefined && clienteCabecera.bonificaciones !== undefined 
        && clienteCabecera.bonificaciones.length === 2){        
        porc = Number(clienteCabecera.bonificaciones[0].porcentaje);
      }
    }

    setPorcBonif1(porc);
  }, [resumen, clienteCabecera]);

  const setupPorcBonif2 = useCallback(() => {
    let porc = 0.00;
    if(resumen !== undefined && resumen.porcBonif2 !== undefined){
      porc = Number(resumen.porcBonif2);
    }else{
      if(clienteCabecera !== undefined && clienteCabecera.bonificaciones !== undefined 
        && clienteCabecera.bonificaciones.length === 2){        
        porc = Number(clienteCabecera.bonificaciones[1].porcentaje);
      }
    }

    setPorcBonif2(porc);
  }, [resumen, clienteCabecera]);

  useEffect(() => {
    if(porcBonif1 === undefined) {
      setupPorcBonif1();
    }

    if(porcBonif2 === undefined){
      setupPorcBonif2();
    }
  }, [porcBonif1, porcBonif2, setupPorcBonif1, setupPorcBonif2]);

  useEffect(() => { 
    if(articulos === null || articulos === undefined){
      setArticulos(carritoItems);
      calcularDatosResumen(carritoItems);
    }
  }, [articulos, carritoItems, setArticulos, calcularDatosResumen]);

  useEffect(() => { 
    if(update){
      setArticulos(carritoItems);
      calcularDatosResumen(carritoItems);
    }
  }, [update, carritoItems, calcularDatosResumen]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography className={classes.title}>
            {'Resumen'}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12} className={classes.row}>
          <Typography className={classes.labels}>
            {'Total Items'}
          </Typography>
          <Typography className={classes.labels}>
            {totalItems}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} className={classes.row}>
          <Typography className={classes.labels}>
            {'Subtotal'}
          </Typography>
          <Typography className={classes.labels}>
            {subTotal}
          </Typography>
        </Grid>
        <Grid 
          item xs={12} md={12} xl={12}
          className={classes.row}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={6} md={6} xl={6} container>
            <Grid item xs={6} md={6} xl={6} >
              <Typography className={classes.labels}>
                {"Bonificación 1 "}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4} xl={4} >
              <TextField 
                className={classes.text}
                value={porcBonif1}
                size="small"
                inputProps={{ 
                  name:"porcBonif1Input",
                  maxLength: enterosPrecio + 2, 
                  decimalScale: 2,
                  max: 100,
                  min: 0.00
                }}
                InputProps={{ inputComponent: NumberFormatCustom }}
                onChange={(e) => onPorcBonif1Change(e.target.value)} 
              />
            </Grid>
            <Grid item xs={2} md={2} xl={2} >
              <Typography className={classes.labels}>
                {" %"}
              </Typography>
            </Grid>
          </Grid>
          <Grid 
            item xs={6} md={6} xl={6}
            style={{ textAlign: 'end' }}
          >
            <Typography className={classes.labels}>
              {bonificacion1}
            </Typography>
          </Grid>
        </Grid>
        
        <Grid 
          item xs={12} md={12} xl={12}
          className={classes.row}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={6} md={6} xl={6} container>
            <Grid item xs={6} md={6} xl={6} >
              <Typography className={classes.labels}>
                {"Bonificación 2 "}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4} xl={4} >
              <TextField 
                className={classes.text}
                value={porcBonif2}
                size="small"
                inputProps={{ 
                  name:"porcBonif2Input",
                  maxLength: enterosPrecio + 2, 
                  decimalScale: 2,
                  max: 100,
                  min: 0.00
                }}
                InputProps={{ inputComponent: NumberFormatCustom }}
                onChange={(e) => onPorcBonif2Change(e.target.value)} 
              />
            </Grid>
            <Grid item xs={2} md={2} xl={2} >
              <Typography className={classes.labels}>
                {" %"}
              </Typography>
            </Grid>
          </Grid>
          <Grid 
            item xs={6} md={6} xl={6}
            style={{ textAlign: 'end' }}
          >
            <Typography className={classes.labels}>
              {bonificacion2}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} className={classes.row}>
          <Typography className={classes.labels}>
            {'Sub Total'}
          </Typography>
          <Typography className={classes.labels}>
            {subTotalBonificacion}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} className={classes.row}>
          <Typography className={classes.labels}>
            {'Impuestos'}
          </Typography>
          <Typography className={classes.labels}>
            {impuestos}
          </Typography>
        </Grid>
        <Grid item  xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12} className={classes.row}>
          <Typography className={classes.title}>
            {'Total'}
          </Typography>
          <Typography className={classes.title}>
            {total}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({  
  listaDePrecio: state.articulos.filtrosArticulosPorCliente.listaPrecioId, 
  clienteCabecera: state.clientes.clienteCabeceraById.cliente,
  comprobante: state.comprobantes.comprobantePedido.comprobante,
  estaCargado: state.comprobantes.comprobantePedido.estaCargado,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadComprobantePedido: (idPlataforma) => dispatch(actions.loadComprobantePedido(idPlataforma)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumenPedido);