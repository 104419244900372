import React from 'react';
import BoxInfo from '../BoxInfo/BoxInfo';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import { Grid } from '@material-ui/core';

const BoxEjecutarPaginaInfo = (props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <BoxInfo>
          <div>
            <span>Ejecute los indicadores haciendo click en el botón
              <PlayCircleFilledWhiteOutlinedIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
              en la barra superior
            </span>
          </div>           
        </BoxInfo>
      </Grid>
      <Grid item xs={12}
        style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: '650px', height: 'auto', width: '100%' }}>
          <img alt="" src={'static/images/Jalisco-Logo-Grande.png'} width='100%' />
        </div>
      </Grid>
    </Grid>
  );
};
export default BoxEjecutarPaginaInfo;