// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaComparacionVentasDelVendedorPorSeleccion__TablasComparacionComprobantesContainer__27IMx {\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n\r\n.TablaComparacionVentasDelVendedorPorSeleccion__SorteableHeaderTitle__13cdN {\r\n  cursor: pointer;\r\n  transition: 0.5s;\r\n  -webkit-user-select: none;\r\n  -ms-user-select: none;\r\n  user-select: none;\r\n}\r\n\r\n.TablaComparacionVentasDelVendedorPorSeleccion__SorteableHeaderTitle__13cdN:hover {\r\n  transition: 0.5s;\r\n  color: rgba(0, 0, 0, 0.54);\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TablasComparacionComprobantesContainer": "TablaComparacionVentasDelVendedorPorSeleccion__TablasComparacionComprobantesContainer__27IMx",
	"SorteableHeaderTitle": "TablaComparacionVentasDelVendedorPorSeleccion__SorteableHeaderTitle__13cdN"
};
export default ___CSS_LOADER_EXPORT___;
