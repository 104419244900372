import React from 'react';
import { connect } from 'react-redux';
import TablaComprobantes from '../TablaComprobantes/TablaComprobantes';
import * as actions from "../../../../store/actions";

const TablaFacturasPendientes = (props) => {
  const {
    comprobantes,
    loading,
    estaCargado,
    comprobantesPorCliente,
    loadingPorCliente,
    estaCargadoPorCliente,
    inClientInfo,
  } = props;  

  const data = () => {
    let listaComprobantes = inClientInfo
    ? estaCargadoPorCliente ? comprobantesPorCliente : []
    : estaCargado ? comprobantes : [];

    return listaComprobantes && listaComprobantes.map((comprobante) => {
      return {
        fecha: comprobante.fecha,
        comprobante: comprobante.comprobante,
        referencia: comprobante.referencia,
        numeroExterno: comprobante.numeroExterno,
        monto: comprobante.monto && comprobante.monto.toFixed(2),
        idComproba: comprobante.idComproba,
        idSistema: comprobante.idSistema,
        idSucursal: comprobante.idSucursal,
        clienteNombre: comprobante.clienteNombre,
        clienteCodigo: comprobante.clienteCodigo,
        montoPendiente: comprobante.montoPendiente && comprobante.montoPendiente.toFixed(2),
        cobranza: comprobante.cobranza,
      };
    });
  };

  return (
    <TablaComprobantes 
      data={data()} 
      loading={inClientInfo ? loadingPorCliente : loading}
      minHeight='300px'
      maxHeight='380px'
      onFiltersClick={() => props.onShowFiltersModal()}
      inClientInfo={inClientInfo}
      tituloTabla={ "Facturas pendientes" }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    comprobantes: state.ventas.comprobantesFacturasPendientes.comprobantes,
    loading: state.ventas.comprobantesFacturasPendientes.loading,
    estaCargado: state.ventas.comprobantesFacturasPendientes.estaCargado,
    comprobantesPorCliente: state.ventas.comprobantesFacturasPendientes.comprobantesPorCliente,
    loadingPorCliente: state.ventas.comprobantesFacturasPendientes.loadingPorCliente,
    estaCargadoPorCliente: state.ventas.comprobantesFacturasPendientes.estaCargadoPorCliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Facturas pendientes', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaFacturasPendientes);