import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { urlPortal } from "../../assets/shared/urlApps";
import LightTooltip from "../UI/LightTooltip/LightTooltip";
import HomeIcon from "@material-ui/icons/Home";
import { IconButton, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex", 
    alignItems: "center",
    justifyContent: 'flex-start',
    width: "100%",
  },
  tooltip: {
    textAlign: "left",
    font: "normal normal normal 14px/19px Roboto",
    color: "8D8C8C",
  },
  icon: {
    color: "white",
    fontSize: "1.8rem",
    cursor: "pointer",
    paddingTop: 0,
    paddingBottom: 0
  },
  iconMobile: {    
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      color: 'white',
    }
  },
}));

const MenuUsuario = (props) => {
  const classes = useStyles();

  const onItemClick = () => {
    sessionStorage.setItem('seccionNombre', '');
    return urlPortal();
  };

  return (
    <div className={classes.root} onClick={() => onItemClick()}>
      <LightTooltip
        arrow
        title={
          <Typography className={classes.tooltip} >
            Ir al Portal
          </Typography>
        }
        placement="bottom"
      >
        <IconButton aria-label="close" className={classes.icon}>
          <HomeIcon />
        </IconButton>
      </LightTooltip>
      <Typography className={classes.iconMobile}>
        Ir al Portal
      </Typography>
    </div>
  );
};

export default MenuUsuario;