import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { pathNamePages } from '../../assets/shared/pathnames';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import LightTooltip from '../UI/LightTooltip/LightTooltip';
import { Typography } from '@material-ui/core';
import { storageClientId, storageVendedorId } from '../../assets/shared/sessionData';

const ButtonEjecucionPagina = (props) => {
  const {
    fotoDelDia,
    home,
    cliente,
    catalogo,
    tiemposReq,
    analisisVentas,
  } = pathNamePages;

  const {
    ejecucionFotoDelDia,
    ejecucionClientes,
    ejecucionCatalogo,
    ejecucionAnalisisVentas,
    paginaRender,
  } = props; 

  const ocultarBoton = useCallback((pathRender) => {
    (pathRender === home || pathRender === fotoDelDia) && setBotonOculto(ejecucionFotoDelDia);
    pathRender === cliente && setBotonOculto(ejecucionClientes);
    pathRender === catalogo && setBotonOculto(ejecucionCatalogo);
    pathRender === analisisVentas && setBotonOculto(ejecucionAnalisisVentas);
  }, [
    ejecucionFotoDelDia, 
    ejecucionClientes, 
    ejecucionCatalogo, 
    ejecucionAnalisisVentas,
    fotoDelDia, 
    home, 
    cliente, 
    catalogo, 
    analisisVentas,
  ]);

  useEffect(() => {
    ocultarBoton(paginaRender);
  }, [paginaRender, ocultarBoton]);

  const [botonOculto, setBotonOculto] = useState(false);

  const isValid = (item) => {
    return item !== null && item !== `null` && item !== undefined && item !== '' && item !== 'select';
  }

  const ejecutarPagina = () => {
    const path = global.window.location.pathname;

    const vendedorId = storageVendedorId();
    const clienteId = storageClientId();
    const vendedorIsValid = isValid(vendedorId);
    const clienteIsValid = isValid(clienteId);

    path === tiemposReq && ejecutarYOcultarBotonLocal('ejecutarTiemposReq', tiemposReq);

    path === home &&
      (vendedorIsValid
        ? ejecutarYOcultarBotonLocal('ejecutarFotoDelDia', home)
        : alert('Debe seleccionar un vendedor antes de ejecutar los indicadores'));

    path === fotoDelDia &&
      (vendedorIsValid
        ? ejecutarYOcultarBotonLocal('ejecutarFotoDelDia', fotoDelDia)
        : alert('Debe seleccionar un vendedor antes de ejecutar los indicadores'));

    path === cliente &&
      (vendedorIsValid
        ? (clienteIsValid
            ? ejecutarYOcultarBotonLocal('ejecutarClientes', cliente)
            : alert('Debe seleccionar un cliente antes de ejecutar los indicadores'))
        : alert('Debe seleccionar un vendedor antes de ejecutar los indicadores'));          

    path === catalogo && 
      (vendedorIsValid
        ? ejecutarYOcultarBotonLocal('ejecutarCatalogo', catalogo)
        : alert('Debe seleccionar un vendedor antes de visualizar el catálogo'));

    path === analisisVentas &&
      (vendedorIsValid
        ? ejecutarYOcultarBotonLocal('ejecutarAnalisisVentas', analisisVentas)
        : alert('Debe seleccionar un vendedor antes de ejecutar los indicadores'));
  };

  const ejecutarYOcultarBotonLocal = (ejecucionKey, paginaKey) => {
    const path = global.window.location.pathname;
    path === paginaKey && props[ejecucionKey]();
    setBotonOculto(true);
  };

  return (
    !botonOculto && (
      <LightTooltip
        arrow
        title={
          <Typography style={{ textAlign: 'left', font: 'normal normal normal 14px/19px Roboto', color: '8D8C8C' }}>
            Ejecutar página
          </Typography>
        }
        placement="bottom"
      >
        <PlayCircleFilledWhiteOutlinedIcon
          onClick={() => ejecutarPagina()}
          style={{ color: 'white', fontSize: '1.8rem', cursor: 'pointer', marginRight: '10px' }}
        />
      </LightTooltip>
    )
  );
};

const mapStateToProps = (state) => ({
  ejecucionFotoDelDia: state.ejecucionPaginas.ejecucionFotoDelDia,
  ejecucionClientes: state.ejecucionPaginas.ejecucionClientes,
  ejecucionCatalogo: state.ejecucionPaginas.ejecucionCatalogo,
  ejecucionTiemposReq: state.ejecucionPaginas.ejecucionTiemposReq,
  ejecucionAnalisisVentas: state.ejecucionPaginas.ejecucionAnalisisVentas,
  paginaRender: state.ejecucionPaginas.paginaRender,
  clientSelected: state.clientes.clienteById.clientSelected,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ejecutarFotoDelDia: () => dispatch(actions.ejecutarFotoDelDia(true)),
    ejecutarClientes: () => dispatch(actions.ejecucionClientes(true)),
    ejecutarCatalogo: () => dispatch(actions.ejecucionCatalogo(true)),
    ejecutarTiemposReq: () => dispatch(actions.ejecutarTiemposReq(true)),
    ejecutarAnalisisVentas: () => dispatch(actions.ejecutarAnalisisVentas(true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonEjecucionPagina);