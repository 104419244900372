// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalGlobal__TitleDialogContent__22zAt {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-right: 5px;\r\n}\r\n\r\n.ModalGlobal__TitleDialogTexto__1NgHd {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  padding-left: 10px;\r\n  padding-right: 5px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleDialogContent": "ModalGlobal__TitleDialogContent__22zAt",
	"TitleDialogTexto": "ModalGlobal__TitleDialogTexto__1NgHd"
};
export default ___CSS_LOADER_EXPORT___;
