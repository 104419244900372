import React, { useContext } from "react";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Buton from "../../UI/Button/Button";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import { CarritoContext } from '../../../store/contexts/carrito';
import { Fragment } from "react";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 4,
    top: 10,
    padding: '0 4px',
    borderRadius: '50%',
    height: '40px',
    minWidth: '40px',
    backgroundColor: '#a0a0a0',
  },
}))(Badge);

const IrCarritoButton = (props) => {
  const {
    onIrAPedido
  } = props;

  const { 
    itemCount,
  } = useContext(CarritoContext);

  const customButon = () => {
    return (
      <Buton onClick={() => onIrAPedido()}>
        {<ShoppingCartIcon style={{ marginRight: "10px" }} />}
        Ir al Pedido {" "}
      </Buton>
    );
  }

  return itemCount > 0 ? (
    <StyledBadge badgeContent={itemCount} color="primary">
      {customButon()}
    </StyledBadge >
  ) : (
    <Fragment>
      {customButon()}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IrCarritoButton);