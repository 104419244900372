import * as actionTypes from "./actionTypes";
import axiosVendedores from "../../axios-vendedores";

export const loadChangeLogs = () => {
  return (dispatch) => {
    dispatch(loadChangeLogsStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axiosVendedores
      .get(`/changeLogs`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadChangeLogsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadChangeLogsFail(error));
      });
  };
};

export const clearTableChangeLogs = () => {
  return {
    type: actionTypes.CLEAR_CHANGE_LOGS,
  };
};

const loadChangeLogsStart = () => {
  return {
    type: actionTypes.LOAD_CHANGE_LOGS_START,
  };
};

const loadChangeLogsSuccess = (changeLogs) => {
  return {
    type: actionTypes.LOAD_CHANGE_LOGS_SUCCESS,
    changeLogs,
  };
};

const loadChangeLogsFail = (error) => {
  return {
    type: actionTypes.LOAD_CHANGE_LOGS_FAIL,
    error,
  };
};

export const createChangeLog = (newChangeLog) => {
  return (dispatch) => {
    dispatch(executeCreateChangeLog(true));
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axiosVendedores
      .post(`/changeLogs`, newChangeLog, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadChangeLogs());
        dispatch(executeCreateChangeLog(false));
      })
      .catch((error) => {
        dispatch(executeCreateChangeLog(false));
      });
  };
};

const executeCreateChangeLog = (loading) => {
  return {
    type: actionTypes.EXECUTE_CREATE_CHANGE_LOG,
    loading,
  };
};

export const updateChangeLog = (updatedChangeLog) => {
  return (dispatch) => {
    dispatch(executeUpdateChangeLog(true));

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axiosVendedores
      .put(
        `/changeLogs/?idChangeLog=${updatedChangeLog.id}`,
        updatedChangeLog,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadChangeLogs());

        dispatch(executeUpdateChangeLog(false));
      })
      .catch((error) => {
        dispatch(executeUpdateChangeLog(false));
      });
  };
};

const executeUpdateChangeLog = (loading) => {
  return {
    type: actionTypes.EXECUTE_UPDATE_CHANGE_LOG,
    loading,
  };
};

export const deleteChangeLog = (changeLogId) => {
  return (dispatch) => {
    dispatch(executeDeleteChangeLog(true));

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axiosVendedores
      .delete(`/changeLogs/?idChangeLog=${changeLogId}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadChangeLogs());
        dispatch(executeDeleteChangeLog(false));
      })
      .catch((error) => {
        dispatch(executeDeleteChangeLog(false));
      });
  };
};

const executeDeleteChangeLog = (loading) => {
  return {
    type: actionTypes.EXECUTE_UPDATE_CHANGE_LOG,
    loading,
  };
};
