import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import TextChart from "../../../Charts/TextChart";

const TextoPedidosPendientes = (props) => {
  const {
    onLoadChart,
    cantidad,
    loading,
    loadingPorCliente,
    clientSelected,
    inClientInfo,
    estaCargado,
    cantidadPorCliente,
    error,
  } = props;

  const vendedorSelected = sessionStorage.getItem("vendedorId");
  const chartSinDatos = error || (!inClientInfo && !vendedorSelected);

  useEffect(() => {
    if (!inClientInfo && !estaCargado && vendedorSelected) {
      onLoadChart();
    }
  }, [onLoadChart, inClientInfo, estaCargado, vendedorSelected]);

  return (
    <TextChart
      title={`Cantidad de pedidos pendientes`}
      bigAmount={`${inClientInfo ? cantidadPorCliente : cantidad}`}
      noClient={!clientSelected && inClientInfo}
      loading={inClientInfo ? loadingPorCliente : loading}
      imgSinDatos={error || chartSinDatos}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.pedidosPendientes.loading,
    error: state.ventas.pedidosPendientes.error,
    loadingPorCliente: state.ventas.pedidosPendientes.loadingPorCliente,
    estaCargado: state.ventas.pedidosPendientes.estaCargado,
    cantidad: state.ventas.pedidosPendientes.cantidad,
    cantidadPorCliente: state.ventas.pedidosPendientes.cantidadPorCliente,
    total: state.ventas.pedidosPendientes.total,
    clientSelected: state.clientes.clienteById.clientSelected,
    clienteId: state.clientes.clienteById.cliente.idCliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idCliente) => dispatch(actions.loadPedidosPendientes(idCliente)),
    onUnmountChart: () => dispatch(actions.unmountClienteById()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextoPedidosPendientes);
