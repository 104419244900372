import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  articulosStockPrecio: {
    articulos: [],
    loading: false,
    error: null,
    countTotal: -1,
  },
  articulosStockPrecioPorCliente: {
    articulos: [],
    loading: false,
    error: null,
    countTotal: 0,
  },
  paginacionArticulos: {
    pageSize: 50,
    pageNumber: 0,
  },
  paginacionArticulosPorCliente: {
    pageSize: 50,
    pageNumber: 0,
  },
  filtrosArticulos: {
    busqueda: "",
    codigoBarras: "",
    marcaId: "",
    rubroId: "",
    subrubroId: "",
    listaPrecioId: "",
    depositoId: "",
    withStock: false,
  },
  filtrosArticulosPorCliente: {
    busqueda: "",
    codigoBarras: "",
    marcaId: "",
    rubroId: "",
    subrubroId: "",
    listaPrecioId: "",
    depositoId: "",
    withStock: false,
  },
  marcas: {
    marcas: [],
    loading: false,
    error: null,
  },
  rubros: {
    rubros: [],
    loading: false,
    error: null,
  },
  subrubros: {
    subrubros: [],
    loading: false,
    error: null,
  },
  listaPrecios: {
    listaPrecios: [],
    loading: false,
    error: null,
    estaCargado: false,
  },
  listaPreciosPorCliente: {
    listaPrecios: [],
    loading: false,
    error: null,
    estaCargado: false,
  },
  depositos: {
    depositos: [],
    loading: false,
    error: null,
  },
  depositosPorCliente: {
    depositos: [],
    loading: false,
    error: null,
  },
  images: {
    images: [],
    result: false,
    loading: false,
    error: null,
  },
  searchArticulosStockPrecio: {
    articulos: [],
    loading: false,
    error: null,
    countTotal: 0,
  },
  searchArticulosStockPrecioPorCliente: {
    articulos: [],
    loading: false,
    error: null,
    countTotal: 0,
  },
};

//#region load Articulos stock precio
const loadArticulosStockPrecioStart = (state, inClientInfo = false) => {
  if(inClientInfo)
    return updateObject(state, {
      articulosStockPrecioPorCliente: updateObject(state.articulosStockPrecioPorCliente, {
        loading: true,
      }),
    });
  else
    return updateObject(state, {
      articulosStockPrecio: updateObject(state.articulosStockPrecio, {
        loading: true,
      }),
    });
};

const loadArticulosStockPrecioSuccess = (state, articulos, countTotal, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      articulosStockPrecioPorCliente: updateObject(state.articulosStockPrecioPorCliente, {
        articulos,
        countTotal,
        loading: false,
      }),
    });

  }else{
    return updateObject(state, {
      articulosStockPrecio: updateObject(state.articulosStockPrecio, {
        articulos,
        countTotal,
        loading: false,
      }),
    });

  }
};

const loadArticulosStockPrecioFail = (state, error, inClientInfo = false) => {

  if(inClientInfo){
    return updateObject(state, {
      articulosStockPrecioPorCliente: updateObject(state.articulosStockPrecioPorCliente, {
        error,
        loading: false,
      }),
    });

  }else{
    return updateObject(state, {
      articulosStockPrecio: updateObject(state.articulosStockPrecio, {
        error,
        loading: false,
      }),
    });
    
  }
};

const clearArticulosStockPrecio = (state, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      articulosStockPrecioPorCliente: updateObject(state.articulosStockPrecioPorCliente, {
        articulos: [],
        loading: false,
        error: null,
        countTotal: -1,
      }),
    });

  }else{
    return updateObject(state, {
      articulosStockPrecio: updateObject(state.articulosStockPrecio, {
        articulos: [],
        loading: false,
        error: null,
        countTotal: -1,
      }),
    });
    
  }
};
//#endregion

//#region load Articulos Búsqueda
const loadArticulosBusquedaStart = (state, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      articulosStockPrecioPorCliente: updateObject(state.articulosStockPrecioPorCliente, {
        loading: true,
      }),
    });

  }else{
    return updateObject(state, {
      articulosStockPrecio: updateObject(state.articulosStockPrecio, {
        loading: true,
      }),
    });
    
  }
};

const loadArticulosBusquedaSuccess = (state, articulos, countTotal, busqueda, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      articulosStockPrecioPorCliente: updateObject(state.articulosStockPrecioPorCliente, {
        articulos,
        countTotal,
        loading: false,
      }),
    });
  }else{
    return updateObject(state, {
      articulosStockPrecio: updateObject(state.articulosStockPrecio, {
        articulos,
        countTotal,
        loading: false,
      }),
    });
  }
};

const loadArticulosBusquedaFail = (state, error, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      articulosStockPrecioPorCliente: updateObject(state.articulosStockPrecioPorCliente, {
        error,
        loading: false,
      }),
    });
  }else{
    return updateObject(state, {
      articulosStockPrecio: updateObject(state.articulosStockPrecio, {
        error,
        loading: false,
      }),
    });
    
  }
};

const clearArticulosBusqueda = (state, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      articulosStockPrecioPorCliente: updateObject(state.articulosStockPrecioPorCliente, {
        articulos: [],
        loading: false,
        error: null,
        countTotal: -1,
      }),
    });

  }else{
    return updateObject(state, {
      articulosStockPrecio: updateObject(state.articulosStockPrecio, {
        articulos: [],
        loading: false,
        error: null,
        countTotal: -1,
      }),
    });
    
  }
};
//#endregion

//#region load Articulos Código de Barras
const clearArticulosCodigoBarras = (state, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      articulosStockPrecioPorCliente: updateObject(state.articulosStockPrecioPorCliente, {
        articulos: [],
        loading: false,
        error: null,
        countTotal: 0,
      }),
    });

  }else{
    return updateObject(state, {
      articulosStockPrecio: updateObject(state.articulosStockPrecio, {
        articulos: [],
        loading: false,
        error: null,
        countTotal: 0,
      }),
    });
    
  }
};
//#endregion

//#region search Articulos stock precio
const searchArticulosStockPrecioStart = (state, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      searchArticulosStockPrecioPorCliente: updateObject(state.searchArticulosStockPrecioPorCliente, {
        loading: true,
      }),
    });
  }else{
    return updateObject(state, {
      searchArticulosStockPrecio: updateObject(state.searchArticulosStockPrecio, {
        loading: true,
      }),
    });
    
  }
};

const searchArticulosStockPrecioSuccess = (state, articulos, countTotal, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      searchArticulosStockPrecioPorCliente: updateObject(state.searchArticulosStockPrecioPorCliente, {
        articulos,
        countTotal,
        loading: false,
      }),
    });

  }else{
    return updateObject(state, {
      searchArticulosStockPrecio: updateObject(state.searchArticulosStockPrecio, {
        articulos,
        countTotal,
        loading: false,
      }),
    });
    
  }
};

const searchArticulosStockPrecioFail = (state, error, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      searchArticulosStockPrecioPorCliente: updateObject(state.searchArticulosStockPrecioPorCliente, {
        error,
        loading: false,
      }),
    });

  }else{
    return updateObject(state, {
      searchArticulosStockPrecio: updateObject(state.searchArticulosStockPrecio, {
        error,
        loading: false,
      }),
    });
    
  }
};

const clearSearchArticulosStockPrecio = (state, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      searchArticulosStockPrecioPorCliente: updateObject(state.searchArticulosStockPrecioPorCliente, {
        articulos: [],
        loading: false,
        error: null,
        countTotal: -1,
      }),
    });

  }else{
    return updateObject(state, {
      searchArticulosStockPrecio: updateObject(state.searchArticulosStockPrecio, {
        articulos: [],
        loading: false,
        error: null,
        countTotal: -1,
      }),
    });
    
  }
};
//#endregion

//#region Cambiar filtros articulos
const cambiarFiltroMarca = (state, marcaId, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      filtrosArticulosPorCliente: updateObject(state.filtrosArticulosPorCliente, {
        marcaId,
      }),
    });

  }else{
    return updateObject(state, {
      filtrosArticulos: updateObject(state.filtrosArticulos, {
        marcaId,
      }),
    });
    
  }
};

const cambiarFiltroRubro = (state, rubroId, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      filtrosArticulosPorCliente: updateObject(state.filtrosArticulosPorCliente, {
        rubroId,
      }),
    });

  }else{
    return updateObject(state, {
      filtrosArticulos: updateObject(state.filtrosArticulos, {
        rubroId,
      }),
    });
    
  }
};

const cambiarFiltroSubrubro = (state, subrubroId, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      filtrosArticulosPorCliente: updateObject(state.filtrosArticulosPorCliente, {
        subrubroId,
      }),
    });

  }else{
    return updateObject(state, {
      filtrosArticulos: updateObject(state.filtrosArticulos, {
        subrubroId,
      }),
    });
    
  }
};

const cambiarFiltroListaPrecio = (state, listaPrecioId, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      filtrosArticulosPorCliente: updateObject(state.filtrosArticulosPorCliente, {
        listaPrecioId,
      }),
    });

  }else{
    return updateObject(state, {
      filtrosArticulos: updateObject(state.filtrosArticulos, {
        listaPrecioId,
      }),
    });
    
  }
};

const cambiarFiltroDeposito = (state, depositoId, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      filtrosArticulosPorCliente: updateObject(state.filtrosArticulosPorCliente, {
        depositoId,
      }),
    });

  }else{
    return updateObject(state, {
      filtrosArticulos: updateObject(state.filtrosArticulos, {
        depositoId,
      }),
    });
    
  }
};

const cambiarFiltroStock = (state, withStock, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      filtrosArticulosPorCliente: updateObject(state.filtrosArticulosPorCliente, {
        withStock,
      }),
    });

  }else{
    return updateObject(state, {
      filtrosArticulos: updateObject(state.filtrosArticulos, {
        withStock,
      }),
    });
    
  }
};

const cambiarFiltroBusqueda = (state, busqueda, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      filtrosArticulosPorCliente: updateObject(state.filtrosArticulosPorCliente, {
        busqueda,
      }),
    });

  }else{
    return updateObject(state, {
      filtrosArticulos: updateObject(state.filtrosArticulos, {
        busqueda,
      }),
    });
    
  }
};

const cambiarFiltroCodigoBarras = (state, codigoBarras, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      filtrosArticulosPorCliente: updateObject(state.filtrosArticulosPorCliente, {
        codigoBarras,
      }),
    });

  }else{
    return updateObject(state, {
      filtrosArticulos: updateObject(state.filtrosArticulos, {
        codigoBarras,
      }),
    });
    
  }
};

const clearFiltroArticulos = (state, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      filtrosArticulosPorCliente: updateObject(state.filtrosArticulosPorCliente, {
        busqueda: "",
        codigoBarras: "",
        marcaId: "",
        rubroId: "",
        subrubroId: "",
        listaPrecioId: "",
        depositoId: "",
        withStock: false,
      }),
    });

  }else{
    return updateObject(state, {
      filtrosArticulos: updateObject(state.filtrosArticulos, {
        busqueda: "",
        codigoBarras: "",
        marcaId: "",
        rubroId: "",
        subrubroId: "",
        listaPrecioId: "",
        depositoId: "",
        withStock: false,
      }),
    });
    
  }
}

//#endregion

//#region load Marcas
const loadMarcasStart = (state) => {
  return updateObject(state, {
    marcas: updateObject(state.marcas, {
      loading: true,
    }),
  });
};

const loadMarcasSuccess = (state, marcas) => {
  return updateObject(state, {
    marcas: updateObject(state.marcas, {
      marcas,
      loading: false,
    }),
  });
};

const loadMarcasFail = (state, error) => {
  return updateObject(state, {
    marcas: updateObject(state.marcas, {
      error,
      loading: false,
    }),
  });
};
//#endregion

//#region load Rubros
const loadRubrosStart = (state) => {
  return updateObject(state, {
    rubros: updateObject(state.rubros, {
      loading: true,
    }),
  });
};

const loadRubrosSuccess = (state, rubros) => {
  return updateObject(state, {
    rubros: updateObject(state.rubros, {
      rubros,
      loading: false,
    }),
  });
};

const loadRubrosFail = (state, error) => {
  return updateObject(state, {
    rubros: updateObject(state.rubros, {
      error,
      loading: false,
    }),
  });
};
//#endregion

//#region load Subrubros
const loadSubrubrosStart = (state) => {
  return updateObject(state, {
    subrubros: updateObject(state.subrubros, {
      loading: true,
    }),
  });
};

const loadSubrubrosSuccess = (state, subrubros) => {
  return updateObject(state, {
    subrubros: updateObject(state.subrubros, {
      subrubros,
      loading: false,
    }),
  });
};

const loadSubrubrosFail = (state, error) => {
  return updateObject(state, {
    subrubros: updateObject(state.subrubros, {
      error,
      loading: false,
    }),
  });
};
//#endregion

//#region load Lista Precios
const loadListaPreciosStart = (state, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      listaPreciosPorCliente: updateObject(state.listaPreciosPorCliente, {
        loading: true,
        estaCargado: false,
      }),
    });

  }else{
    return updateObject(state, {
      listaPrecios: updateObject(state.listaPrecios, {
        loading: true,
        estaCargado: false,
      }),
    });
  }
};

const loadListaPreciosSuccess = (state, listaPrecios, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      listaPreciosPorCliente: updateObject(state.listaPreciosPorCliente, {
        listaPrecios,
        loading: false,
        estaCargado: true,
      }),
    });

  }else{
    return updateObject(state, {
      listaPrecios: updateObject(state.listaPrecios, {
        listaPrecios,
        loading: false,
        estaCargado: true,
      }),
    });
    
  }
};

const loadListaPreciosFail = (state, error, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      listaPreciosPorCliente: updateObject(state.listaPreciosPorCliente, {
        error,
        loading: false,
        estaCargado: true,
      }),
    });

  }else{
    return updateObject(state, {
      listaPrecios: updateObject(state.listaPrecios, {
        error,
        loading: false,
        estaCargado: true,
      }),
    });
    
  }
};
//#endregion

//#region load Depositos
const loadDepositosStart = (state, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      depositosPorCliente: updateObject(state.depositosPorCliente, {
        loading: true,
      }),
    });
  }else{
    return updateObject(state, {
      depositos: updateObject(state.depositos, {
        loading: true,
      }),
    });
  }
};

const loadDepositosSuccess = (state, depositos, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      depositosPorCliente: updateObject(state.depositosPorCliente, {
        depositos,
        loading: false,
      }),
    });

  }else{
    return updateObject(state, {
      depositos: updateObject(state.depositos, {
        depositos,
        loading: false,
      }),
    });    
  }
};

const loadDepositosFail = (state, error, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      depositosPorCliente: updateObject(state.depositosPorCliente, {
        error,
        loading: false,
      }),
    });

  }else{
    return updateObject(state, {
      depositos: updateObject(state.depositos, {
        error,
        loading: false,
      }),
    });
    
  }
};
//#endregion

//#region paginacion articulos
const paginacionArticulos = (state, pageNumber, pageSize, inClientInfo = false) => {
  if(inClientInfo){
    return updateObject(state, {
      paginacionArticulosPorCliente: updateObject(state.paginacionArticulosPorCliente, {
        pageNumber,
        pageSize,
      }),
    });

  }else{
    return updateObject(state, {
      paginacionArticulos: updateObject(state.paginacionArticulos, {
        pageNumber,
        pageSize,
      }),
    });
    
  }
};
//#endregion

//#region Agregar Imagenes
const addImagesStart = (state) => {
  return updateObject(state, {
    images: updateObject(state.images, {
      loading: true,
    }),
  });
};

const addImagesSuccess = (state, images) => {
  return updateObject(state, {
    images: updateObject(state.images, {
      images,
      loading: false,
    }),
  });
};

const addImagesFail = (state, error) => {
  return updateObject(state, {
    images: updateObject(state.images, {
      error,
      loading: false,
    }),
  });
};
//#endregion

//#region Remover Imagenes
const removeImagesStart = (state) => {
  return updateObject(state, {
    images: updateObject(state.images, {
      loading: true,
    }),
  });
};

const removeImagesSuccess = (state, result) => {
  return updateObject(state, {
    images: updateObject(state.images, {
      result,
      loading: false,
    }),
  });
};

const removeImagesFail = (state, error) => {
  return updateObject(state, {
    images: updateObject(state.images, {
      error,
      loading: false,
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ARTICULOS_STOCK_PRECIO_START:
      return loadArticulosStockPrecioStart(state, action.inClientInfo);
    case actionTypes.LOAD_ARTICULOS_STOCK_PRECIO_SUCCESS:
      return loadArticulosStockPrecioSuccess(state, action.articulos, action.countTotal, action.inClientInfo);
    case actionTypes.LOAD_ARTICULOS_STOCK_PRECIO_FAIL:
      return loadArticulosStockPrecioFail(state, action.error, action.inClientInfo);
    case actionTypes.CLEAR_ARTICULOS_STOCK_PRECIO:
      return clearArticulosStockPrecio(state, action.inClientInfo);

    case actionTypes.LOAD_ARTICULOS_BUSQUEDA_START:
      return loadArticulosBusquedaStart(state, action.inClientInfo);
    case actionTypes.LOAD_ARTICULOS_BUSQUEDA_SUCCESS:
      return loadArticulosBusquedaSuccess(state, action.articulos, action.countTotal, action.busqueda, action.inClientInfo);
    case actionTypes.LOAD_ARTICULOS_BUSQUEDA_FAIL:
      return loadArticulosBusquedaFail(state, action.error, action.inClientInfo);
    case actionTypes.CLEAR_ARTICULOS_BUSQUEDA:
      return clearArticulosBusqueda(state, action.inClientInfo);

    case actionTypes.CLEAR_ARTICULOS_CODIGO_BARRAS:
      return clearArticulosCodigoBarras(state, action.inClientInfo);

    case actionTypes.SEARCH_ARTICULOS_STOCK_PRECIO_START:
      return searchArticulosStockPrecioStart(state, action.inClientInfo);
    case actionTypes.SEARCH_ARTICULOS_STOCK_PRECIO_SUCCESS:
      return searchArticulosStockPrecioSuccess(state, action.articulos, action.countTotal, action.inClientInfo);
    case actionTypes.SEARCH_ARTICULOS_STOCK_PRECIO_FAIL:
      return searchArticulosStockPrecioFail(state, action.error, action.inClientInfo);
    case actionTypes.CLEAR_SEARCH_ARTICULOS_STOCK_PRECIO:
      return clearSearchArticulosStockPrecio(state, action.inClientInfo);

    case actionTypes.CAMBIAR_FILTRO_MARCA:
      return cambiarFiltroMarca(state, action.marcaId, action.inClientInfo);
    case actionTypes.CAMBIAR_FILTRO_RUBRO:
      return cambiarFiltroRubro(state, action.rubroId, action.inClientInfo);
    case actionTypes.CAMBIAR_FILTRO_SUBRUBRO:
      return cambiarFiltroSubrubro(state, action.subrubroId, action.inClientInfo);
    case actionTypes.CAMBIAR_FILTRO_LISTA_PRECIO:
      return cambiarFiltroListaPrecio(state, action.listaPrecioId, action.inClientInfo);
    case actionTypes.CAMBIAR_FILTRO_DEPOSITO:
      return cambiarFiltroDeposito(state, action.depositoId, action.inClientInfo);
    case actionTypes.CAMBIAR_FILTRO_STOCK:
      return cambiarFiltroStock(state, action.withStock, action.inClientInfo);
    case actionTypes.CAMBIAR_FILTRO_BUSQUEDA:
      return cambiarFiltroBusqueda(state, action.busqueda, action.inClientInfo);
    case actionTypes.CAMBIAR_FILTRO_CODIGO_BARRAS:
      return cambiarFiltroCodigoBarras(state, action.codigoBarras, action.inClientInfo);

    case actionTypes.LOAD_MARCAS_START:
      return loadMarcasStart(state);
    case actionTypes.LOAD_MARCAS_SUCCESS:
      return loadMarcasSuccess(state, action.marcas);
    case actionTypes.LOAD_MARCAS_FAIL:
      return loadMarcasFail(state, action.error);

    case actionTypes.LOAD_RUBROS_START:
      return loadRubrosStart(state);
    case actionTypes.LOAD_RUBROS_SUCCESS:
      return loadRubrosSuccess(state, action.rubros);
    case actionTypes.LOAD_RUBROS_FAIL:
      return loadRubrosFail(state, action.error);

    case actionTypes.LOAD_SUBRUBROS_START:
      return loadSubrubrosStart(state);
    case actionTypes.LOAD_SUBRUBROS_SUCCESS:
      return loadSubrubrosSuccess(state, action.subrubros);
    case actionTypes.LOAD_SUBRUBROS_FAIL:
      return loadSubrubrosFail(state, action.error);

    case actionTypes.LOAD_LISTA_PRECIOS_START:
      return loadListaPreciosStart(state, action.inClientInfo);
    case actionTypes.LOAD_LISTA_PRECIOS_SUCCESS:
      return loadListaPreciosSuccess(state, action.listaPrecios, action.inClientInfo);
    case actionTypes.LOAD_LISTA_PRECIOS_FAIL:
      return loadListaPreciosFail(state, action.error, action.inClientInfo);
    
    case actionTypes.LOAD_DEPOSITOS_START:
      return loadDepositosStart(state, action.inClientInfo);
    case actionTypes.LOAD_DEPOSITOS_SUCCESS:
      return loadDepositosSuccess(state, action.depositos, action.inClientInfo);
    case actionTypes.LOAD_DEPOSITOS_FAIL:
      return loadDepositosFail(state, action.error, action.inClientInfo);

    case actionTypes.ADD_IMAGES_START:
      return addImagesStart(state);
    case actionTypes.ADD_IMAGES_SUCCESS:
      return addImagesSuccess(state, action.images);
    case actionTypes.ADD_IMAGES_FAIL:
      return addImagesFail(state, action.error);

    case actionTypes.REMOVE_IMAGES_START:
      return removeImagesStart(state);
    case actionTypes.REMOVE_IMAGES_SUCCESS:
      return removeImagesSuccess(state, action.result);
    case actionTypes.REMOVE_IMAGES_FAIL:
      return removeImagesFail(state, action.error);

    case actionTypes.PAGINACION_ARTICULOS:
      return paginacionArticulos(state, action.pageNumber, action.pageSize, action.inClientInfo);

    case actionTypes.CLEAR_FILTROS_ARTICULOS:
      return clearFiltroArticulos(state, action.inClientInfo);

    default:
      return state;
  }
};

export default reducer;
