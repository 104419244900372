import React from 'react';
import { withRouter } from 'react-router-dom';

import classes from './Styles/TextChartCard.css';
import Spinner from '../UI/Spinner/Spinner';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import Tooltip from '@material-ui/core/Tooltip';
import { formatNumber } from '../../assets/shared/formatNumber';
import IndDeshabilitado from '../../assets/images/IndicadorDeshabilitado-Texto.png';
import SinResultado from '../../assets/images/SinResultados-Texto.png';
import { storageMonedaSigno } from '../../assets/shared/sessionData';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton } from '@material-ui/core';

const TextChart = (props) => {
  let percIncrease = Math.round(((props.bigAmount / props.smallAmount - 1) * 100 + Number.EPSILON) * 100) / 100;
  const diferencia = (props.bigAmount - props.smallAmount).toLocaleString('es-AR', {
    style: 'currency',
    currency: 'ARS'
  });

  if (isNaN(percIncrease) || percIncrease === Infinity) {
    percIncrease = 0;
  }

  const cssClasses = [classes.TextChartCard];
  if (props.border && !props.noClient && props.bigAmount >= props.smallAmount) {
    props.comparacionInvertida ? cssClasses.push(classes.BorderRed) : cssClasses.push(classes.BorderGreen);
  }
  if (props.border && !props.noClient && props.bigAmount < props.smallAmount) {
    props.comparacionInvertida ? cssClasses.push(classes.BorderGreen) : cssClasses.push(classes.BorderRed);
  }

  const labelOnClickHandler = (redirectPath) => {
    if (redirectPath) window.open(redirectPath);
  };

  const textoGrande = props.bigAmount !== undefined && (
    <Tooltip
      style={{ textAlign: 'left', marginTop: '5px' }}
      title={props.bigAmountTooltip ? props.bigAmountTooltip : ''}
    >
      <div
        style={{
          fontWeight: 'bold',
          marginBottom: '5px',
          marginTop: '0px',
          cursor: props.bigAmoutClickRedirect || props.cursorPointer ? 'pointer' : 'default'
        }}
        className={classes.TextoGrande}
        onClick={() => labelOnClickHandler(props.bigAmoutClickRedirect)}
      >
        {`${
          props.bigCurrency
            ? (props.currencySimbol != null && props.currencySimbol !== 'undefined')
              ? `${props.currencySimbol} ${formatNumber(props.disabled ? 0 : props.bigAmount)}` 
              : `${storageMonedaSigno()} ${formatNumber(props.disabled ? 0 : props.bigAmount)}`
            : `${props.bigAmount}`
        }`}
      </div>
    </Tooltip>
  );

  const textoChico = 
    props.smallAmount !== undefined && props.smallAmountTooltip ? (
      <Tooltip title={props.smallAmountTooltip ? props.smallAmountTooltip : ''}>
        <div
          style={{
            marginTop: '0px',
            cursor: props.smallAmoutClickRedirect || props.cursorPointer ? 'pointer' : 'default',
            paddingRight: '10px'
          }}
          onClick={() => labelOnClickHandler(props.smallAmoutClickRedirect)}
          className={classes.TextoChico}
        >
          {`${props.smallAmountTooltip}: ${
            props.smallCurrency
              ? `${storageMonedaSigno()} ${formatNumber(props.disabled ? 0 : props.smallAmount)}`
              : `${props.smallAmount}`
          } `}
        </div>
      </Tooltip>
    ) : props.smallText ? (
      props.smallTextTooltip ? (
        <Tooltip title={props.smallTextTooltip}>
          <div className={classes.TextoChico}>{props.smallText}</div>
        </Tooltip>
      ) : (
        <div className={classes.TextoChico}>{props.smallText}</div>
      )
    ) : (
      ""
    );

  const flechaUp = (
    <TrendingUpIcon
      fontSize="large"
      style={props.border ? { color: 'white' } : { color: props.comparacionInvertida ? 'red' : 'green' }}
    />
  );
  const flechaDown = (
    <TrendingDownIcon
      fontSize="large"
      style={props.border ? { color: 'white' } : { color: props.comparacionInvertida ? 'green' : 'red' }}
    />
  );

  const flechaFlat = <TrendingFlatIcon fontSize="large" style={props.border && { color: 'white' }} />;

  const mostrarTextoComparacion =
    (props.porcentaje || props.diferencia) &&
    props.smallAmount !== undefined &&
    props.bigAmount !== undefined &&
    props.smallAmount !== 0;
  let iconoSubidaBajada = null;
  let diferenciaOPorcentaje = null;
  if (mostrarTextoComparacion) {
    iconoSubidaBajada = percIncrease === 0 ? flechaFlat : percIncrease > 0 ? flechaUp : flechaDown;

    diferenciaOPorcentaje = props.diferencia ? diferencia : `${Math.abs(percIncrease)}%`;
  }

  return (
    <div className={cssClasses.join(' ')}>
      <div className={classes.CardTitle}>
        <p style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>{props.title}</p>
        {props.onFiltersClick && !props.disabled && (
          <Tooltip title="Ver filtros del indicador">
            <IconButton size="small" onClick={() => props.onFiltersClick()}>
              <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {props.disabled ? (
        <img
          alt="Indicador Inhabilitado"
          className={classes.IndDeshabilitado}
          src={props.customDisabledImage ? props.customDisabledImage : IndDeshabilitado}
        />
      ) : props.loading ? (
        <Spinner size="small" color={props.colorSpinner} />
      ) : props.imgSinDatos ? (
        <img src={SinResultado} width='100%' alt="Indicador sin resutado" className={classes.IndDeshabilitado} />
      ) : (
        <div
          style={{ display: 'flex', flexFlow: 'row', cursor: props.onBodyClick && 'pointer' }}
          onClick={() => props.onBodyClick && props.onBodyClick()}
        >
          <div className={classes.textos} style={{ width: '100%' }}>
            {textoGrande}
            {textoChico}
          </div>
          {mostrarTextoComparacion && (
            <div className={classes.IconoDiferenciaOPorcentaje}>
              {iconoSubidaBajada}
              {diferenciaOPorcentaje}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(TextChart);