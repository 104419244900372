import React, {Fragment, useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

import Dialog from '../../UI/Dialog/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../../UI/Button/Button';

import InflacionDesactivado from '../../../assets/images/InflacionDesactivado.png';
import InflacionActivado from '../../../assets/images/InflacionActivado.png';
import TablaInflacion from './TablaInflacion';
import LightTooltip from '../../UI/LightTooltip/LightTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 10px", 
    display: "flex", 
    alignItems: "center",
    justifyContent: 'flex-start',
  },
  tooltip: {
    textAlign: "left",
    font: "normal normal normal 14px/19px Roboto",
    color: "8D8C8C",
  },
  icon: {
    color: "white",
    fontSize: "1.8rem",
    cursor: "pointer",
    marginRight: "10px",
    paddingTop: 0,
    paddingBottom: 0
  },
  iconMobile: {    
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      color: 'white',
    }
  },
}));

const ButtonTomarEnCuentaInflacion = (props) => {
  const classes = useStyles();
  const { onCambiarAjustaPorInflacionClick, ajustaInflacion, puedeModificarInflacion } = props;
  const [showModal, setShowModal] = useState(false); 
  const [consideraInflacion, setConsideraInflacion] = useState(ajustaInflacion); 
  
  const onChangeInflacion = () => 
  {
      setConsideraInflacion(!consideraInflacion); 
  }
  const onGuardarSeleccionDeInflacion = () => 
  {
    onCambiarAjustaPorInflacionClick(consideraInflacion);
    setShowModal(false);
  }
  
  const onCloseClick = () => {
    setShowModal(false);
  };

  const buttonIcon = () => {
    return (
      <div className={classes.root} onClick={() => setShowModal(true)}>
        <LightTooltip arrow
          title={
            <Typography className={classes.tooltip}>
              Tomar en cuenta inflación
            </Typography>
          }
          placement="bottom"
        >
          <IconButton size="small">
            <img alt="" src={ajustaInflacion && puedeModificarInflacion ? InflacionActivado : InflacionDesactivado} />
          </IconButton>
        </LightTooltip>                
        <Typography className={classes.iconMobile}>Tomar en cuenta inflación</Typography>
      </div>
    )
  }

  return (
    <Fragment>
      {buttonIcon()}
      {showModal && (<Dialog
          show={showModal}
          title={
          <div style={{ width: '98%', textAlign: 'right' }}>
            <IconButton aria-label="close"
            onClick={() => onCloseClick()} >
              <CloseIcon />
            </IconButton>
          </div>}
          onClose={() => onCloseClick()}
          body={
            <div>
              <div>
                {puedeModificarInflacion ? 
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={consideraInflacion}
                        onChange = {() => onChangeInflacion()}
                      />
                    }
                    label={'Tomar en cuenta inflación'}
                  /> :
                  null 
                }
              </div>
              <div>
                <TablaInflacion >
                </TablaInflacion>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
                {puedeModificarInflacion ? 
                  <Button onClick={() => onGuardarSeleccionDeInflacion()}>Aceptar</Button> : 
                  null
                }
              </div>
            </div>
          }
          >
    </Dialog>)}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  ajustaInflacion: state.auth.ajustaInflacion,
  loadingExecuteCambiarAjustaPorInflacion: state.configuraciones.executeCambiarAjustaPorInflacion
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCambiarAjustaPorInflacionClick: (ajustaInflacion) => dispatch(actions.CambiarAjustaPorInflacion(ajustaInflacion)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonTomarEnCuentaInflacion);
