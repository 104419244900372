// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaInflacionMesAMes__TablaInflacionMesAMes__26vt- {\r\n    display: flex;\r\n    justify-content: center;\r\n    width: 100%;\r\n  }\r\n  \r\n  .TablaInflacionMesAMes__TablaInflacionMesAMes__26vt- button {\r\n    padding: 7px;\r\n  }\r\n  \r\n  @media (max-width: 1367px) {\r\n    .TablaInflacionMesAMes__TablaInflacionMesAMes__26vt- {\r\n      max-height: 743px;\r\n    }\r\n  }\r\n  \r\n  @media (max-width: 752px) {\r\n    .TablaInflacionMesAMes__TablaInflacionMesAMes__26vt- {\r\n      max-height: 400px;\r\n    }\r\n  }\r\n  ", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TablaInflacionMesAMes": "TablaInflacionMesAMes__TablaInflacionMesAMes__26vt-"
};
export default ___CSS_LOADER_EXPORT___;
