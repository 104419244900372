import React, { Fragment, useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { CarritoContext } from '../../store/contexts/carrito';
import { Grid, Divider, Typography, Card, CardHeader, CardContent, IconButton } from "@material-ui/core";
import ItemDetallePedido from './ItemDetallePedido';
import CloseIcon from "@material-ui/icons/Close";
import Buton from "../UI/Button/Button";
import TablaAgregarArticulo from "./TablaAgregarArticulo";
import Dialog from "../UI/Dialog/Dialog";
import { esValido } from "../../assets/shared/utility";
import { errorListaDePreciosVacia } from "../../assets/shared/errorMessages";

const useStyles = makeStyles({  
  root: {
    width: '100%',
  },
  title: {
    fontSize: 'large',
    fontWeight: 'bold',
  },
  titleContainer: {
    marginBottom: '20px',
  },
  table: {
    minWidth: 650,
  },
  header:{
    width: '100%',  
    textAlign: 'center',
    backgroundColor: '#e0e0e0',
    marginLeft: '0px',
    marginRight: '0px',
    paddingInline: '5px',
    paddingBlock: '10px'
  },
  text: {
    fontSize: 'medium',
    fontWeight: 'bold',
    textAlign: 'center',
  }, 
  headerText: {
    fontSize: 'small',
    fontWeight: 'bold',
    textAlign: 'left',
  }, 
  headerNumeric: {
    fontSize: 'small',
    fontWeight: 'bold',
    textAlign: 'right',
  },
  content:{
    paddingLeft: '0px',
    paddingRight: '2px',
    maxHeight: '450px', 
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  input:{
    width: '100%',
  },
  buttons:{
    width: '100%',
  },
  titleTexto: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
});

const DetallePedido = (props) => {  
  const classes = useStyles();

  const { 
    itemCount,
    addArticulo, 
    removeArticulo,
    update,
    updateCantidad,
    carritoItems,
    updateCarrito,
  } = useContext(CarritoContext);

  const [articulos, setArticulos] = useState([]);
  const [agregarArticulo, setAgregarArticulo] = useState(false);

  useEffect(() =>{
    if(articulos === null || articulos === undefined 
      || articulos.length === 0 || articulos.length !== itemCount || update){
      setArticulos(carritoItems);
    }
  }, [articulos, carritoItems, itemCount, update]);  

  const detallePedidoList = () => {   

    return (
      <Fragment>
        <Card className={classes.root}>
          <CardHeader className={classes.header}
            title={detallePedidoHeader()}
          />
          <Divider />
          <CardContent className={classes.content}>
            {detallePedidoItems(articulos)}
          </CardContent>
        </Card>
      </Fragment>
    );
  };

  const detalleActionsHeader = () => {
    return (
      <Grid 
        container
        spacing={1}
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Grid item xs={6} md={2} lg={2}>
          <Buton
            onClick={() => handleShowAgregarArticulos()}
            style={{ width: "100%", padding: "4px" }}
            className={classes.buttons}
            color="primary"
          >
            Agregar
          </Buton>
        </Grid>
      </Grid>
    );
  }

  const detallePedidoHeader = () => {
    return (
      <Fragment>
        <Grid 
          container 
          spacing={1} 
          direction="row"
          justify="space-between"
          alignItems="center">
          <Grid item xs={1} md={1} xl={1}>
            <Typography className={classes.headerText}>
              {"Nro."}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography className={classes.headerText}>
              {"Código"}
            </Typography>
          </Grid>         
          <Grid item xs={2} md={3} xl={3}>
            <Typography className={classes.headerText}>
              {"Nombre"}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography className={classes.headerNumeric}>
              {"Cantidad"}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography className={classes.headerNumeric}>
              {"Precio Unit."}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography className={classes.headerNumeric}>
              {"Bonificación"}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography className={classes.headerNumeric}>
              {"Precio Bonif."}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography className={classes.headerNumeric}>
              {"% Alic. Iva"}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography className={classes.headerNumeric}>
              {"Total"}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  const detallePedidoItems = (articulos) => {
    if (articulos === undefined || articulos.length === 0) {
      return <div></div>;
    } else {
      return articulos.map((articulo, index) => (
        <ItemDetallePedido
          key={articulo.idArticulo + index}
          index={index}
          articulo={articulo}
          add={addArticulo}
          remove={removeArticulo} 
          updateCantidad={updateCantidad}
          updateCarrito={updateCarrito}
          carritoItems={carritoItems}
        />
      ));
    }
  };

  const handleShowAgregarArticulos = () => {
    setAgregarArticulo(true);
  }

  const agregarArticulosDialog = () => {
    return (
      <TablaAgregarArticulo cerrarModal={() => setAgregarArticulo(false)} />
    );
  }

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid 
          item xs={12} md={12}
          className={classes.titleContainer}
        >
          <Typography className={classes.title}>
            {'Detalle'}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12}
          style={{ textAlign:'end' }}
        >
          {detalleActionsHeader()}
        </Grid>
        <Grid item xs={12} md={12}>
          {detallePedidoList()}
        </Grid>
      </Grid>
      {agregarArticulo && (
        <Dialog
          fullWidth
          maxWidth={"md"}
          title={
            <div style={{ width: "100%" }}>
              <div className={classes.titleTexto}>
                <Typography style={{ fontSize: "large" }}>Agregar artículos</Typography>
                <IconButton aria-label="close" onClick={() => setAgregarArticulo(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
              {!esValido(props.filtroListaPrecio) && 
                <Typography 
                  style={{ color: 'red', fontSize: 'small'}}
                  variant="subtitle2"
                  align="left"
                >
                  {errorListaDePreciosVacia}
                </Typography>
              }
            </div>
          }
          show={agregarArticulo}
          body={agregarArticulosDialog()}
          onClose={() => setAgregarArticulo(false)}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    filtroListaPrecio: state.articulos.filtrosArticulosPorCliente.listaPrecioId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetallePedido);