import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "../../../store/actions";

import DataGrid, { Column, MasterDetail } from "devextreme-react/data-grid";

import DetailTiemposReq from "../DetailTiemposReq/DetailTiemposReq";
import Spinner from "../../UI/Spinner/Spinner";

const TableTiemposReq = (props) => {
  const { indicadores, loading, onLoadTiemposRequest } = props;

  useEffect(() => {
    if (indicadores.length === 0) {
      onLoadTiemposRequest();
    }
  }, []);

  const data = () => {
    return indicadores.map((indicador) => ({
      ID: indicador.id,
      nombre: indicador.nombre,
      ultimoTiempoService: `${indicador.ultimoTiempoService} s`,
      ultimaFecha: indicador.ultimaFecha,
    }));
  };

  return (
    <div style={{ textAlign: loading && "center" }}>
      {loading ? (
        <Spinner />
      ) : (
        <DataGrid
          id="grid-container"
          dataSource={data()}
          keyExpr="ID"
          showBorders={true}
        >
          <Column dataField="nombre" caption="Indicador" />
          <Column
            dataField="ultimaFecha"
            caption="Fecha"
            dataType="datetime"
            format={"dd/MM/yyyy HH:mm:ss"}
          />
          <Column
            dataField="ultimoTiempoService"
            caption="Tiempo Api Service"
            dataType="datetime"
            format={"dd/MM/yyyy HH:mm:ss"}
          />

          <MasterDetail enabled={true} component={DetailTiemposReq} />
        </DataGrid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    indicadores: state.configuraciones.loadTiemposRequest.indicadores,
    loading: state.configuraciones.loadTiemposRequest.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadTiemposRequest: () => dispatch(actions.loadTiemposRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableTiemposReq);
