import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ConfirmacionPedido from '../../components/Pedido/ConfirmacionPedido';
import * as actions from "../../store/actions";
import { CarritoContext } from "../../store/contexts/carrito";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  titulo:{
    fontSize: 'x-large',
    textTransform: 'uppercase',
  },
  sectionTitulo:{
    marginBottom: '10px',
    marginTop: '10px',
  },
  sectionConfirmacion: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    marginBottom: '10px',
    marginTop: '10px',
  },
  sectionResumen: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
  },
  buttons:{
    width: '100%',
  },
  buttonsContainer:{
    display: 'flex',
    alignItems: 'self-end',
    backgroundColor: 'transparent',
    justifyContent: 'flex-end',
  },
  buttonsSections:{
    width: '100%',
    marginTop: '10px',
    marginBottom: '100px',
  },
  paper:{
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    padding: '20px',
  },
  title:{
    fontSize: "large", 
    fontWeight: 'bold',
  },
  labels:{
    fontSize: "medium", 
  },
  row:{
    display: 'flex',
    justifyContent: "space-between",
    alignItems:"center",
  },
}));

const Confirmacion = (props) => {
  const {
    onIrACatalogo,
  } = props;

  const classes = useStyles();
  const [decimalesCantidad, setDecimalesCantidad] = useState(0);

  const { 
    comprobante, 
    comprobanteCargado,
    itemCount,
    resumen,
  } = useContext(CarritoContext);

  useEffect(() => {
    if(comprobanteCargado && comprobante !== null && comprobante !== undefined){
      setDecimalesCantidad(comprobante.decimalesCantidad);
    }
  }, [comprobante, comprobanteCargado]);

  const resumenPedido = () => {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography className={classes.title}>
              {'Resumen'}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12} className={classes.row}>
            <Typography className={classes.labels}>
              {'Total Items'}
            </Typography>
            <Typography className={classes.labels}>
              {itemCount && itemCount.toFixed(decimalesCantidad)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.row}>
            <Typography className={classes.labels}>
              {'Subtotal'}
            </Typography>
            <Typography className={classes.labels}>
              {resumen.subTotal1 && resumen.subTotal1.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.row}>
            <Typography className={classes.labels}>
              {`Bonificación 1 (${resumen.porcBonif1 > 0 ? resumen.porcBonif1.toFixed(2): (0.00).toFixed(2)} %)`}
            </Typography>
            <Typography className={classes.labels}>
            {resumen.bonificacion1 > 0 ? resumen.bonificacion1.toFixed(2) : (0.00).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.row}>
            <Typography className={classes.labels}>
            {`Bonificación 2 (${resumen.porcBonif2 > 0 ? resumen.porcBonif2.toFixed(2) : (0.00).toFixed(2)} %)`}
            </Typography>
            <Typography className={classes.labels}>
              {resumen.bonificacion2 > 0 ? resumen.bonificacion2.toFixed(2) : (0.00).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.row}>
            <Typography className={classes.labels}>
              {'Sub Total'}
            </Typography>
            <Typography className={classes.labels}>
              {resumen.subTotal2 && resumen.subTotal2.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.row}>
            <Typography className={classes.labels}>
              {'Impuestos'}
            </Typography>
            <Typography className={classes.labels}>
              {resumen.impuestos !== undefined && resumen.impuestos.total !== undefined ? resumen.impuestos.total.toFixed(2) : 0.00}
            </Typography>
          </Grid>
          <Grid item  xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12} className={classes.row}>
            <Typography className={classes.title}>
              {'Total'}
            </Typography>
            <Typography className={classes.title}>
              {resumen.total && resumen.total.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} className={classes.sectionTitulo}>
        <Typography className={classes.titulo}>
          Confirmación
        </Typography>
        <Divider />
      </Grid>
      <Grid item 
        xs={12} md={12} xl={12} 
        className={classes.sectionConfirmacion}
      >
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6} md={8} xl={8}>
              <ConfirmacionPedido onIrACatalogo={onIrACatalogo} />
            </Grid>            
            <Grid item container xs={6} md={4} xl={4}>
              <Grid item xs={1} md={1} xl={1}>
                <Divider orientation='vertical' />
              </Grid>
              <Grid 
                item 
                xs={11} md={11} xl={11}
                className={classes.sectionResumen}
              >
                {resumenPedido()}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    estaCargado: state.comprobantes.comprobantePedido.estaCargado,
    comprobante: state.comprobantes.comprobantePedido.comprobante,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadComprobantePedido: (idPlataforma) => dispatch(actions.loadComprobantePedido(idPlataforma)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmacion);