import axios from "axios";
import { baseUrlVendedores } from "./assets/shared/urlApps";

const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 50;
let PENDING_REQUESTS = 0;

const configInstance = () => {
  let instance = axios.create({
    //baseURL: 'http://gausssistemas.serveftp.com:8030/api'
    baseURL: baseUrlVendedores(),
    //baseURL: 'https://dashboard-gauss-api.azurewebsites.net/api'
  });

  const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
  const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

  instance.defaults.headers.common['Authorization'] = authStr;
  instance.defaults.headers.common['Id-Empresa'] = idEmpresa;

  return instance;
}

const instance = configInstance();

instance.interceptors.request.use((config) => {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

/**
 * Axios Response Interceptor
 */
instance.interceptors.response.use(
  (response) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  (error) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);

export default instance;
