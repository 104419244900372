import React from "react";
import { connect } from "react-redux";

import Notifier from "../../components/UI/Notifier/Notifier";
import { withRouter } from "react-router-dom";
import SoloLogSuperior from "../../assets/images/SoloLogSuperior.png";
import ToolbarComponents from "../../components/ToolbarComponents/ToolbarComponents";
import Drawer from "../../components/UI/Drawer";
import ListaFiltrosPorIndicadorModal from '../../components/Configuraciones/ListaFiltrosPorIndicadorModal/ListaFiltrosPorIndicadorModal';
import ModalGlobal from '../../components/UI/ModalGlobal/ModalGlobal';
import EnviarChart from '../../components/UI/EnviarChart/EnviarChart';
import BackButton from '../../components/UI/BackButton/BackButton';
import BackButtonModal from '../../components/UI/BackButton/BackButtonModal';

const layout = (props) => {
  return (
    <React.Fragment>
      <BackButton />
      <Notifier />
      <Drawer
        isAuth={props.isAuth}
        nombreEmpresa={sessionStorage.getItem('empresaNombre')}
        logo={SoloLogSuperior}
        itemsToolbar={<ToolbarComponents />}
      >
        {props.children}
      </Drawer>
      <ModalGlobal />
      <ListaFiltrosPorIndicadorModal />
      <EnviarChart />
      <BackButtonModal/>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.token !== null,
  };
};

export default withRouter(connect(mapStateToProps)(layout));
