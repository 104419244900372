import * as actions from '../../../store/actions';
import store from "../../../store";

export const enviarPorMailOption = (chartRef, tituloChart = '') => {
  const title = typeof tituloChart === 'string' ? tituloChart : '';
  return {
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="20px" height="20px" style="margin-left: 10px;" fill="#828080">' +
            '<path d="M0 0h24v24H0V0z" fill="none"/>' +
            '<path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/>' +
          '</svg>',
    title: "Enviar por mail",
    class: "",
    click: () => {
      store.dispatch(actions.showSendChartModal(true, chartRef, title));
    }
  };
}