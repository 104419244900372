import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  chequesRechazados: {
    monto: 0,
    cantidadCheques: 0,
    loading: false,
    error: null,
  },
  chequesEnCartera: {
    monto: 0,
    cantidadCheques: 0,
    loading: false,
    error: null,
  },
  listaChequesRechazados: {
    cheques: [],
    loading: false,
    estaCargado: false,
    error: null,
  },
  listaChequesEnCartera: {
    cheques: [],
    loading: false,
    estaCargado: false,
    error: null,
  },
  comprobantesCheques: {
    comprobantes: [],
    loading: false,
    estaCargado: false,
    error: null,
  },
};

//#region loadChequesRechazados
const loadChequesRechazadosStart = (state) => {
  return updateObject(state, {
    chequesRechazados: updateObject(state.chequesRechazados, {
      loading: true,
    }),
  });
};

const loadChequesRechazadosSuccess = (state, monto, cantidadCheques) => {
  return updateObject(state, {
    chequesRechazados: updateObject(state.chequesRechazados, {
      loading: false,
      monto,
      cantidadCheques,
      error: null,
    }),
  });
};

const loadChequesRechazadosFail = (state, error) => {
  return updateObject(state, {
    chequesRechazados: updateObject(state.chequesRechazados, {
      monto: 0,
      cantidadCheques: 0,
      loading: false,
      error,
    }),
  });
};

const clearChequesRechazados = (state) => {
  return updateObject(state, {
    chequesRechazados: updateObject(state.chequesRechazados, {
      monto: 0,
      cantidadCheques: 0,
      loading: false,
      error: null,
    }),
  });
};
//#endregion

//#region loadListadoChequesRechazados
const loadListadoChequesRechazadosStart = (state) => {
  return updateObject(state, {
    listaChequesRechazados: updateObject(state.listaChequesRechazados, {
      loading: true,
      estaCargado: false,
    }),
  });
};

const loadListadoChequesRechazadosSuccess = (state, cheques) => {
  return updateObject(state, {
    listaChequesRechazados: updateObject(state.listaChequesRechazados, {
      loading: false,
      cheques,
      error: null,
      estaCargado: true,
    }),
  });
};

const loadListadoChequesRechazadosFail = (state, error) => {
  return updateObject(state, {
    listaChequesRechazados: updateObject(state.listaChequesRechazados, {
      cheques: [],
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};

const clearListadoChequesRechazados = (state) => {
  return updateObject(state, {
    listaChequesRechazados: updateObject(state.listaChequesRechazados, {
      cheques: [],
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

//#region loadChequesEnCartera
const loadChequesEnCarteraStart = (state) => {
  return updateObject(state, {
    chequesEnCartera: updateObject(state.chequesEnCartera, {
      loading: true,
    }),
  });
};

const loadChequesEnCarteraSuccess = (state, monto, cantidadCheques) => {
  return updateObject(state, {
    chequesEnCartera: updateObject(state.chequesEnCartera, {
      loading: false,
      monto,
      cantidadCheques,
      error: null,
    }),
  });
};

const loadChequesEnCarteraFail = (state, error) => {
  return updateObject(state, {
    chequesEnCartera: updateObject(state.chequesEnCartera, {
      monto: 0,
      cantidadCheques: 0,
      loading: false,
      error,
    }),
  });
};

const clearChequesEnCartera = (state) => {
  return updateObject(state, {
    chequesEnCartera: updateObject(state.chequesEnCartera, {
      monto: 0,
      cantidadCheques: 0,
      loading: false,
      error: null,
    }),
  });
};
//#endregion

//#region loadListadoChequesEnCartera
const loadListadoChequesEnCarteraStart = (state) => {
  return updateObject(state, {
    listaChequesEnCartera: updateObject(state.listaChequesEnCartera, {
      loading: true,
      estaCargado: false,
    }),
  });
};

const loadListadoChequesEnCarteraSuccess = (state, cheques) => {
  return updateObject(state, {
    listaChequesEnCartera: updateObject(state.listaChequesEnCartera, {
      loading: false,
      cheques,
      error: null,
      estaCargado: true,
    }),
  });
};

const loadListadoChequesEnCarteraFail = (state, error) => {
  return updateObject(state, {
    listaChequesEnCartera: updateObject(state.listaChequesEnCartera, {
      cheques: [],
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};

const clearListadoChequesEnCartera = (state) => {
  return updateObject(state, {
    listaChequesEnCartera: updateObject(state.listaChequesEnCartera, {
      cheques: [],
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

//#region loadComprobantesCheques
const loadComprobantesChequesStart = (state) => {
  return updateObject(state, {
    comprobantesCheques: updateObject(state.comprobantesCheques, {
      loading: true,
      estaCargado: false,
    }),
  });
};

const loadComprobantesChequesSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesCheques: updateObject(state.comprobantesCheques, {
      loading: false,
      comprobantes,
      error: null,
      estaCargado: true,
    }),
  });
};

const loadComprobantesChequesFail = (state, error) => {
  return updateObject(state, {
    comprobantesCheques: updateObject(state.comprobantesCheques, {
      comprobantes: [],
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};

const clearComprobantesCheques = (state) => {
  return updateObject(state, {
    comprobantesCheques: updateObject(state.comprobantesCheques, {
      comprobantes: [],
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

//#region line cheques en cartera
const lineChequesEnCarteraStart = (state) => {
  return updateObject(state, {
    lineChequesEnCartera: updateObject(state.lineChequesEnCartera, {
      loading: true,
      error: null,
      cheques: []
    })
  });
};

const lineChequesEnCarteraSuccess = (state, cheques, totalCheques) => {
  return updateObject(state, {
    lineChequesEnCartera: updateObject(state.lineChequesEnCartera, {
      loading: false,
      cheques,
      error: null,
      totalCheques,
      estaCargado: true
    })
  });
};

const lineChequesEnCarteraFail = (state, error) => {
  return updateObject(state, {
    lineChequesEnCartera: updateObject(state.lineChequesEnCartera, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearLineChequesEnCartera = (state) => {
  return updateObject(state, {
    lineChequesEnCartera: updateObject(state.lineChequesEnCartera, {
      loading: false,
      error: null,
      cheques: [],
      totalCheques: 0,
      estaCargado: false
    })
  });
};
//#endregion

//#region line cheques rechazados
const lineChequesRechazadosStart = (state) => {
  return updateObject(state, {
    lineChequesRechazados: updateObject(state.lineChequesRechazados, {
      loading: true,
      error: null,
      cheques: []
    })
  });
};

const lineChequesRechazadosSuccess = (state, cheques, totalCheques) => {
  return updateObject(state, {
    lineChequesRechazados: updateObject(state.lineChequesRechazados, {
      loading: false,
      cheques,
      error: null,
      totalCheques,
      estaCargado: true
    })
  });
};

const lineChequesRechazadosFail = (state, error) => {
  return updateObject(state, {
    lineChequesRechazados: updateObject(state.lineChequesRechazados, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearLineChequesRechazados = (state) => {
  return updateObject(state, {
    lineChequesRechazados: updateObject(state.lineChequesRechazados, {
      loading: false,
      error: null,
      cheques: [],
      totalCheques: 0,
      estaCargado: false
    })
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_CHEQUES_RECHAZADOS_START:
      return loadChequesRechazadosStart(state);
    case actionTypes.LOAD_CHEQUES_RECHAZADOS_SUCCESS:
      return loadChequesRechazadosSuccess(state, action.monto, action.cantidadCheques);
    case actionTypes.LOAD_CHEQUES_RECHAZADOS_FAIL:
      return loadChequesRechazadosFail(state, action.error);
    case actionTypes.CLEAR_CHEQUES_RECHAZADOS:
      return clearChequesRechazados(state);

    case actionTypes.LOAD_LISTADO_CHEQUES_RECHAZADOS_START:
      return loadListadoChequesRechazadosStart(state);
    case actionTypes.LOAD_LISTADO_CHEQUES_RECHAZADOS_SUCCESS:
      return loadListadoChequesRechazadosSuccess(state, action.cheques);
    case actionTypes.LOAD_LISTADO_CHEQUES_RECHAZADOS_FAIL:
      return loadListadoChequesRechazadosFail(state, action.error);
    case actionTypes.CLEAR_LISTADO_CHEQUES_RECHAZADOS:
      return clearListadoChequesRechazados(state);

    case actionTypes.LOAD_CHEQUES_EN_CARTERA_START:
      return loadChequesEnCarteraStart(state);
    case actionTypes.LOAD_CHEQUES_EN_CARTERA_SUCCESS:
      return loadChequesEnCarteraSuccess(state, action.monto, action.cantidadCheques);
    case actionTypes.LOAD_CHEQUES_EN_CARTERA_FAIL:
      return loadChequesEnCarteraFail(state, action.error);
    case actionTypes.CLEAR_CHEQUES_EN_CARTERA:
      return clearChequesEnCartera(state);  

    case actionTypes.LOAD_LISTADO_CHEQUES_EN_CARTERA_START:
      return loadListadoChequesEnCarteraStart(state);
    case actionTypes.LOAD_LISTADO_CHEQUES_EN_CARTERA_SUCCESS:
      return loadListadoChequesEnCarteraSuccess(state, action.cheques);
    case actionTypes.LOAD_LISTADO_CHEQUES_EN_CARTERA_FAIL:
      return loadListadoChequesEnCarteraFail(state, action.error);
    case actionTypes.CLEAR_LISTADO_CHEQUES_EN_CARTERA:
      return clearListadoChequesEnCartera(state);  

    case actionTypes.LOAD_COMPROBANTES_CHEQUES_START:
      return loadComprobantesChequesStart(state);
    case actionTypes.LOAD_COMPROBANTES_CHEQUES_SUCCESS:
      return loadComprobantesChequesSuccess(state, action.comprobantes);
    case actionTypes.LOAD_COMPROBANTES_CHEQUES_FAIL:
      return loadComprobantesChequesFail(state, action.error);
    case actionTypes.CLEAR_COMPROBANTES_CHEQUES:
      return clearComprobantesCheques(state);
      
    case actionTypes.LINE_CHEQUES_EN_CARTERA_START:
      return lineChequesEnCarteraStart(state);
    case actionTypes.LINE_CHEQUES_EN_CARTERA_SUCCESS:
      return lineChequesEnCarteraSuccess(state, action.cheques, action.totalCheques);
    case actionTypes.LINE_CHEQUES_EN_CARTERA_FAIL:
      return lineChequesEnCarteraFail(state, action.error);
    case actionTypes.CLEAR_LINE_CHEQUES_EN_CARTERA:
      return clearLineChequesEnCartera(state);
  
    case actionTypes.LINE_CHEQUES_RECHAZADOS_START:
      return lineChequesRechazadosStart(state);
    case actionTypes.LINE_CHEQUES_RECHAZADOS_SUCCESS:
      return lineChequesRechazadosSuccess(state, action.cheques, action.totalCheques);
    case actionTypes.LINE_CHEQUES_RECHAZADOS_FAIL:
      return lineChequesRechazadosFail(state, action.error);
    case actionTypes.CLEAR_LINE_CHEQUES_RECHAZADOS:
      return clearLineChequesRechazados(state);

    default:
      return state;
  }
};

export default reducer;
