import React, { useEffect, useState, useCallback } from "react";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import classes from "./CatalogoVendedor.css";
import Spinner from "../../UI/Spinner/Spinner";
import Buton from "../../UI/Button/Button";
import ArticuloCard from "../ArticuloCard/ArticuloCard";
import TablePagination from "@material-ui/core/TablePagination";
import { storageListaPreciosId } from "../../../assets/shared/sessionData";
import { Grid, IconButton, Tooltip, TextField, Checkbox, FormGroup, FormControlLabel, FormControl,
  InputLabel, Select, MenuItem } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  options:{
    fontSize: 'small',
  },
  search:{
    width: "100%",
    '& .MuiInputBase-input': {
      fontSize: 'small',
    },
    '& .MuiInputLabel-root':{
      fontSize: 'small',
    },
    '& .MuiInputLabel-animated':{
      fontSize: 'medium',
    }
  },
}));

const CatalogoVendedor = (props) => {
  const classes2 = useStyles();
  //#region Props
  const { handleBusquedaChange, handleFiltroStockChange, onLoadMarcas, onLoadRubros, onLoadSubrubros, onLoadListaPrecios, onLoadDepositos, onClearFiltros } = props;
  const { onLoadArticulos, onPaginationChange, storageListaPreciosId, onSeleccionarListaPrecio} = props;
  const { articulos, countTotal, loading, filtroBusqueda, filtroNombre, filtroMarca, filtroRubro, filtroSubrubro, filtroListaPrecio, filtroDeposito, filtroStock, paginationState } = props;

  //#endregion 

  //#region Filtros
  const [loadOnInit, setLoadOnInit] = useState(true); 
  const [inSearch, setInSearch] = useState(false);
  const [aplicarFiltro, setAplicarFiltro] = useState(false);
  const [busqueda, setBusqueda] = useState(filtroBusqueda);
  const [marca, setMarca] = useState(filtroMarca);
  const [rubro, setRubro] = useState(filtroRubro);
  const [subrubro, setSubrubro] = useState(filtroSubrubro);
  const [listaPrecio, setListaPrecio] = useState(filtroListaPrecio ? filtroListaPrecio : storageListaPreciosId);
  const [deposito, setDeposito] = useState(filtroDeposito ? filtroDeposito : "TODOS");
  const [withStock, setWithStock] = useState(filtroStock);

  const loadArticulos = useCallback((busquedaValue, conStock = withStock, limit = paginationState.pageSize, offset = 0, next = true) => {
    const listaPrecioId = '';
    const depositoId = deposito === "TODOS" ? '' : deposito;

    onLoadArticulos(conStock, "id_articulo", busquedaValue, marca, rubro, subrubro, listaPrecioId, depositoId, limit, offset, next);
  }, [deposito, marca, onLoadArticulos, paginationState.pageSize, rubro, subrubro, withStock]);

  const onBusquedaChange = (value) => {
    if(busqueda !== value)
      setAplicarFiltro(true);
    
    setBusqueda(value);
    handleBusquedaChange(value, true);
  }

  const onMarcaChange = (value) => {
    if(marca !== value)
      setAplicarFiltro(true);

    setMarca(value);
    props.onSeleccionarMarca(value);
  }

  const onRubroChange = (value) => {
    if(rubro !== value)
      setAplicarFiltro(true);

    setRubro(value);
    props.onSeleccionarRubro(value);
  }

  const onSubRubroChange = (value) => {
    if(subrubro !== value)
      setAplicarFiltro(true);

      setSubrubro(value);
    props.onSeleccionarSubrubro(value);
  }

  const onListaPrecioChange = (value) => {
    if(listaPrecio !== value)
      setAplicarFiltro(true);

    setListaPrecio(value);
    onSeleccionarListaPrecio(value);
  }

  const onDepositoChange = (value) => {
    if(deposito !== value)
      setAplicarFiltro(true);

    setDeposito(value);
    props.onSeleccionarDeposito(value);
  }

  const onStockCheckBoxChange = useCallback((value) => {
    if(withStock !== value)
      setAplicarFiltro(true);

    setWithStock(value);
    handleFiltroStockChange(value, true);
  }, [handleFiltroStockChange, withStock]);

  const onBuscarArticulosClick = () => {
    loadArticulos(busqueda);
    onPaginationChange(0, paginationState.pageSize);
    setInSearch(true);
  };

  const onCancelarClick = () => {
    onClearFiltros(true); 
    setBusqueda('');
    setMarca('');
    setRubro('');
    setSubrubro('');
    setListaPrecio('');    
    setDeposito('TODOS');
    setWithStock(false);  
    setInSearch(false);
    setAplicarFiltro(false);
    onLoadArticulos(false, "id_articulo", null, null, null, null, null, null, paginationState.pageSize, 0, true);
    onPaginationChange(0, paginationState.pageSize);
  };

  //#endregion
  
  //#region Pagination

  const handleChangePage = (e, newPage) => {
    onPaginationChange(newPage, paginationState.pageSize);
    const next = newPage > paginationState.pageNumber;
    const offset = articulos.length === 0 ? 0 : next ? articulos[articulos.length - 1].articulo.idArticulo : articulos[0].articulo.idArticulo;
    const limit = paginationState.pageSize;    
    const busquedaValue = busqueda !== null && busqueda !== undefined && busqueda !== "" ? busqueda : filtroNombre;

    loadArticulos(busquedaValue, withStock, limit, offset, next);        
  };

  const handleChangeRowsPerPage = (event) => {
    onPaginationChange(0, event.target.value);
    const limit = event.target.value;
    const offset = 0;
    const busquedaValue = busqueda !== null && busqueda !== undefined && busqueda !== "" ? busqueda : filtroNombre;

    loadArticulos(busquedaValue, withStock, limit, offset); 
  };

  //#endregion

  //#region UseEffect
  useEffect(() => {
    onLoadMarcas();
    onLoadRubros();  
    onLoadSubrubros();
    onLoadListaPrecios();
    onLoadDepositos();      
  }, [onLoadMarcas, onLoadRubros, onLoadSubrubros, onLoadListaPrecios, onLoadDepositos]);

  useEffect(() => {
    if(filtroListaPrecio === null || filtroListaPrecio === undefined || filtroListaPrecio === ''){
      if(storageListaPreciosId !== null && storageListaPreciosId !== undefined && storageListaPreciosId !== ''){       
        onSeleccionarListaPrecio(storageListaPreciosId);
        setListaPrecio(storageListaPreciosId);
      } 
    }else{
      setListaPrecio(filtroListaPrecio);
    }
  }, [storageListaPreciosId, filtroListaPrecio, onSeleccionarListaPrecio]);

  useEffect(() => {
    if(loadOnInit){       
      const isSearch = busqueda !== '' || marca !== '' || rubro !== '' || subrubro !== '';
      setInSearch(isSearch);
      setAplicarFiltro(isSearch);
         
      const busquedaValue = busqueda !== null && busqueda !== undefined && busqueda !== "" ? busqueda : filtroNombre;

      loadArticulos(busquedaValue); 
      setLoadOnInit(false);
    }
  }, [loadOnInit, onLoadArticulos, paginationState.pageSize]);

  //#endregion

  return (
    <Grid container spacing={1} className={classes.CatalogoContainer}>
      <Grid item xs={12} container className={classes.CatalogoFiltros}>
        <Grid item xs={12} sm={12} md={9} 
          container spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={6} sm={6} md={2}>
            <Tooltip placement="right-start" title="Búsqueda por 'Código', 'Nombre' o 'Código de barras' de artículo, marca, rubro o subrubro">
              <TextField
                type="search"
                label="Búsqueda"
                value={busqueda}
                className={classes2.search}
                onChange={(e) => onBusquedaChange(e.target.value)}
              />
            </Tooltip>
          </Grid>            
          <Grid item xs={4} sm={4} md={2}>
            <SelectFilter
              label="Marca"
              value={marca}
              items={props.marcas}
              loading={props.loadingMarcas}
              onChange={onMarcaChange} 
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <SelectFilter
              label="Rubro"
              value={rubro}
              items={props.rubros}
              loading={props.loadingRubro}
              onChange={onRubroChange} 
            />          
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <SelectFilter
              label="Subrubro"
              value={subrubro}
              items={props.subrubros}
              loading={props.loadingSubrubros}
              onChange={onSubRubroChange} 
            />
          </Grid>              
          <Grid item xs={6} sm={4} md={2}>
            <SelectFilter
              label="Lista de Precios"
              value={listaPrecio}
              items={props.listaPrecios}
              loading={props.loadingListaPrecios}
              onChange={onListaPrecioChange} 
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <SelectFilter
              label="Depósito"
              value={deposito}
              items={props.depositos}
              loading={props.loadingDepositos}
              onChange={onDepositoChange}
              todos
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3} 
          container spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >        
          <Grid item xs={6} sm={2} md={6}>
            <StockCheckBox
              value={withStock}
              onChange={onStockCheckBoxChange}
            />
          </Grid>
          <Grid item xs={6} sm={2} md={6}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}
          >
            <Buton
              color="primary"
              style={{
                margin: "5px auto 5px auto",
                backgroundImage: aplicarFiltro && "linear-gradient(45deg, #fbb040, #f15a29)",
                width: '100%',
              }} 
              size="small"
              startIcon={<SearchIcon />}
              onClick={() => onBuscarArticulosClick()}
              disabled={!aplicarFiltro}     
            >
              Buscar
            </Buton> 
            {(inSearch || aplicarFiltro) && (
              <Tooltip title="Limpiar filtros">
                <IconButton onClick={() => onCancelarClick()}>
                  <CancelIcon style={{ color: "red", opacity: 0.5 }} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.CatalogoArticulos}>
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Spinner />
          </div>
        ) : (
          <div style={{ marginBottom: "10px" }}>
            {!articulos || articulos.length === 0 ? (
              <ArticuloCard cardSinResultado />
            ) : (
              articulos.map((data, index) => (
                <ArticuloCard
                  key={data.articulo.idArticulo + index}
                  index={index}
                  articulo={data.articulo}
                  listasDePrecios={data.precios}
                  listaStock={data.stock}
                  inClientInfo={false}
                />
              ))
            )}

            <TablePagination
              style={{ backgroundColor: "white" }}
              component="div"
              count={countTotal}
              page={paginationState.pageNumber}
              onChangePage={handleChangePage}
              rowsPerPage={paginationState.pageSize}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Grid>
    </Grid> 
  )
}

const SelectFilter = (props) => {
  const { label, value, items, loading, onChange, todos } = props;
  const [filtro, setFiltro] = useState(value);

  const handleOnChange = (e) => {
    onChange(e.target.value);
    setFiltro(e.target.value);
  };

  useEffect(() => {
    if(filtro !== value)
      setFiltro(value);
  }, [filtro, value, setFiltro]);

  return loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div className={classes.rootFormControl}>
      <FormControl className={classes.rootControl} >
        <InputLabel id="inputLabel">{label}</InputLabel>
        <Select
          className={classes.select}
          labelId="label"
          id="select"
          value={filtro}
          placeholder={label}
          onChange={(e) => handleOnChange(e)}
        >
          {todos 
            ? (
              <MenuItem 
                className={classes.options}
                key={"TODOS"} 
                value={"TODOS"}>
                TODOS
              </MenuItem>
            ) : (
              <MenuItem 
                className={classes.options}
                key={"SF"} 
                value={""}>
                SIN FILTRO
              </MenuItem>
            )
          }
          {items.map((item, index) => (
            <MenuItem
              className={classes.options}
              key={index} 
              value={item.id}>
              {item.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

const StockCheckBox = (props) => {
  const classes = useStyles();
  const { value, onChange } = props;

  return (
    <FormControl component="fieldset" className={classes.gridFieldset}>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="start"
          control={<Checkbox 
            checked={value}
            value='withStock'
            onChange={(e) => onChange(e.target.checked)}
            color="primary" />}
          label="Con Stock"
          labelPlacement="start"
        />
      </FormGroup>
    </FormControl>
  );
}

const mapStateToProps = (state) => {
  return {
    clientSelected: state.clientes.clienteById.clientSelected,
    ejecucionCatalogo: state.ejecucionPaginas.ejecucionCatalogo,
    
    articulos: state.articulos.articulosStockPrecio.articulos,
    loading: state.articulos.articulosStockPrecio.loading,
    countTotal: state.articulos.articulosStockPrecio.countTotal,
    
    estaCargado: state.comprobantes.comprobantePedido.estaCargado,
    comprobante: state.comprobantes.comprobantePedido.comprobante,
    clienteCabecera: state.clientes.clienteCabeceraById.cliente,
    
    marcas: state.articulos.marcas.marcas,
    loadingMarcas: state.articulos.marcas.loading,
    filtroMarca: state.articulos.filtrosArticulos.marcaId,
    
    rubros: state.articulos.rubros.rubros,
    loadingRubro: state.articulos.rubros.loading,
    filtroRubro: state.articulos.filtrosArticulos.rubroId,

    subrubros: state.articulos.subrubros.subrubros,
    loadingSubrubros: state.articulos.subrubros.loading,
    filtroSubrubro: state.articulos.filtrosArticulos.subrubroId,
    
    listaPrecios: state.articulos.listaPrecios.listaPrecios,
    loadingListaPrecios: state.articulos.listaPrecios.loading,
    filtroListaPrecio: state.articulos.filtrosArticulos.listaPrecioId,
    
    depositos: state.articulos.depositos.depositos,
    loadingDepositos: state.articulos.depositos.loading,
    filtroDeposito: state.articulos.filtrosArticulos.depositoId, 

    error: state.articulos.articulosStockPrecio.error,
    filtroBusqueda: state.articulos.filtrosArticulos.busqueda,
    paginationState: state.articulos.paginacionArticulos,
    filtroStock: state.articulos.filtrosArticulos.withStock,
    storageListaPreciosId: storageListaPreciosId(),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleBusquedaChange: (busqueda) => dispatch(actions.cambiarFiltroBusqueda(busqueda, false)),
    handleFiltroStockChange: (withStock) => dispatch(actions.cambiarFiltroStock(withStock, false)),
    onLoadArticulos: (soloStockPositivo, orden, busqueda, marcaId, rubroId, subrubroId, listaPrecioId, depositoId, limit, offSet, next) => {
      dispatch(actions.loadArticulosBusqueda(soloStockPositivo, orden, busqueda, marcaId, rubroId, subrubroId, listaPrecioId, depositoId, limit, offSet, next, false));
    },
    onLoadMarcas: () => dispatch(actions.loadMarcas()),
    onSeleccionarMarca: (marcaId) => dispatch(actions.cambiarFiltroMarca(marcaId, false)),
    onLoadRubros: () => dispatch(actions.loadRubros()),
    onSeleccionarRubro: (rubroId) => dispatch(actions.cambiarFiltroRubro(rubroId, false)),
    onLoadSubrubros: () => dispatch(actions.loadSubrubros()),
    onSeleccionarSubrubro: (subrubroId) => dispatch(actions.cambiarFiltroSubrubro(subrubroId, false)),
    onLoadListaPrecios: () => dispatch(actions.loadListaPrecios(false)),
    onSeleccionarListaPrecio: (listaPrecioId) => dispatch(actions.cambiarFiltroListaPrecio(listaPrecioId, false)),
    onLoadDepositos: () => dispatch(actions.loadDepositos(false)),
    onSeleccionarDeposito: (depositoId) => dispatch(actions.cambiarFiltroDeposito(depositoId, false)),
    onPaginationChange: (pageNumber, pageSize) => dispatch(actions.paginacionArticulos(pageNumber, pageSize, false)),
    onClearFiltros: () => {
      dispatch(actions.clearArticulosCodigoBarras(false));
      dispatch(actions.cambiarFiltroBusqueda("", false));
      dispatch(actions.cambiarFiltroMarca("", false));
      dispatch(actions.cambiarFiltroRubro("", false));
      dispatch(actions.cambiarFiltroSubrubro("", false));
      dispatch(actions.cambiarFiltroStock(false, false));
      dispatch(actions.paginacionArticulos(0, 50, false)); 
      dispatch(actions.clearArticulosBusqueda(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoVendedor);