import React from "react";
import { connect } from "react-redux";

import TextChart from "../../../Charts/TextChart";

const TextoCreditoDisponible = (props) => {
  const { clientSelected, cliente } = props;

  const credDisponible =
    clientSelected &&
    cliente.cuentaCorriente.creditoMaximo - cliente.deuda.importe;

  return (
    <TextChart
      title={`Crédito disponible`}
      bigAmount={credDisponible < 0 ? 0 : credDisponible}
      noClient={!clientSelected}
      bigCurrency
      loading={false}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    clientSelected: state.clientes.clienteById.clientSelected,
    cliente: state.clientes.clienteById.cliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoCreditoDisponible);
