// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CatalogoCliente__CatalogoContainer__2jy6Z {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.CatalogoCliente__CatalogoFiltros__2CB68 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 10px !important;\r\n}\r\n\r\n.CatalogoCliente__CatalogoArticulos__10aKY {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n  margin-top: 20px;\r\n}\r\n\r\n.CatalogoCliente__CatalogoIrPedido__2hzWP {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: flex-end;\r\n}\r\n\r\n.CatalogoCliente__MuiFormGroup-root__1zmEk .CatalogoCliente__MuiFormGroup-row__yjAjg {\r\n  padding-top: 10px;\r\n}\r\n\r\n.CatalogoCliente__root__2XWyV {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: space-around;\r\n  overflow: hidden;\r\n}\r\n\r\n.CatalogoCliente__rootFormControl__2_t83 {\r\n  display: flex; \r\n  align-items: center;\r\n}\r\n\r\n.CatalogoCliente__rootControl__ch0CK {\r\n  margin: 0px 10px 0px 10px; \r\n  color: black;\r\n  width: 100%;\r\n}\r\n\r\n.CatalogoCliente__select__18bph {\r\n  min-width: 120 !important; \r\n  color: black; \r\n  font-size: small !important;\r\n  text-transform: uppercase !important;\r\n}\r\n\r\n.CatalogoCliente__options__1dyAn {\r\n  font-size: small !important;\r\n  text-transform: uppercase !important;\r\n}\r\n\r\n.CatalogoCliente__gridContainer__27cU9 {\r\n  display: flex; \r\n  align-items: center;\r\n}\r\n\r\n.CatalogoCliente__gridItem__3wjQT {\r\n  width: 100%;\r\n}\r\n\r\n.CatalogoCliente__gridElement__fGBto {\r\n  width: 100%;\r\n}\r\n\r\n.CatalogoCliente__gridFieldset__1VLzu {\r\n  align-self: flex-end; \r\n  padding-top: 10px;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CatalogoContainer": "CatalogoCliente__CatalogoContainer__2jy6Z",
	"CatalogoFiltros": "CatalogoCliente__CatalogoFiltros__2CB68",
	"CatalogoArticulos": "CatalogoCliente__CatalogoArticulos__10aKY",
	"CatalogoIrPedido": "CatalogoCliente__CatalogoIrPedido__2hzWP",
	"MuiFormGroup-root": "CatalogoCliente__MuiFormGroup-root__1zmEk",
	"MuiFormGroup-row": "CatalogoCliente__MuiFormGroup-row__yjAjg",
	"root": "CatalogoCliente__root__2XWyV",
	"rootFormControl": "CatalogoCliente__rootFormControl__2_t83",
	"rootControl": "CatalogoCliente__rootControl__ch0CK",
	"select": "CatalogoCliente__select__18bph",
	"options": "CatalogoCliente__options__1dyAn",
	"gridContainer": "CatalogoCliente__gridContainer__27cU9",
	"gridItem": "CatalogoCliente__gridItem__3wjQT",
	"gridElement": "CatalogoCliente__gridElement__fGBto",
	"gridFieldset": "CatalogoCliente__gridFieldset__1VLzu"
};
export default ___CSS_LOADER_EXPORT___;
