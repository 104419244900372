import React from "react";
import { connect } from "react-redux";
import EmpresaSelectorButton from "../../components/UI/EmpresaSelector/EmpresaSelectorButton";
import classes from "./Catalogo.css";
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';
import { storageMultiempresa } from "../../assets/shared/sessionData";
import CatalogoVendedor from "../../components/Catalogo/CatalogoVendedor/CatalogoVendedor";

const Catalogo = (props) => {
  const {
    ejecucionCatalogo,
  } = props;

  const isMultiemp = storageMultiempresa() === 'true';

  return (
    <div className={classes.CatalogoContainer}>
      {isMultiemp && <EmpresaSelectorButton setEmpresasessionStorage />}
      {!ejecucionCatalogo ? (
          <BoxEjecutarPaginaInfo />
        ) : (
          <CatalogoVendedor />
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ejecucionCatalogo: state.ejecucionPaginas.ejecucionCatalogo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Catalogo);