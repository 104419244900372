import * as actionTypes from "./actionTypes";
import axiosVendedores from "../../axios-vendedores";
import { baseUrlDashboard, urlPortal } from "../../assets/shared/urlApps";
import { storageEmpresaId } from "../../assets/shared/sessionData";

export const login = (token) => {
  return (dispatch) => {
    dispatch(authStart());
    axiosVendedores
      .get(`/Token/CheckToken?aplicacionId=3`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status !== 200) logout();

        const user = response.data;
        console.log({ user });

        sessionStorage.setItem("isAdmin", user.isAdmin);
        sessionStorage.setItem("fechaFija", user.fechaFija);
        sessionStorage.setItem("expirationDate", user.expiration);
        sessionStorage.setItem("multiempresa", user.tieneMultiempresa);
        sessionStorage.setItem("empresaId", user.empresaPorDefectoId);
        sessionStorage.setItem("empresaNombre", user.empresaPorDefectoNombre);
        sessionStorage.setItem("userEmail", user.email);
        sessionStorage.setItem("monedaId", user.monedaId);
        sessionStorage.setItem("isVendedor", user.perfil.vendedor);
        sessionStorage.setItem(
          "monedaSigno",
          user.monedaSigno ? user.monedaSigno.trim() : ""
        );
        sessionStorage.setItem("token", user.token);
        sessionStorage.setItem("vendedorId", user.vendedorId);

        dispatch(setUserInfo(user));
        dispatch(authSuccess(user.token));
        dispatch(checkAuthTimeout(24 * 60 * 60));

        if (user && user.fechaFija) {
          const fechaFija = new Date(user.fechaFija);
          dispatch(updateFechaVentasDelMesAnioDelVendedor(fechaFija));
          dispatch(updateFechaVentasDelMesAnioDeVendedores(fechaFija));
        }
      })
      .catch((error) => {
        urlPortal();
        if (error.response) dispatch(authFail(error.response.data));
        else
          dispatch(authFail("Se produjo un error conectando con el servidor"));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    dispatch(viewTokenIframe());

    const token = sessionStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(sessionStorage.getItem("expirationDate"));

      if (expirationDate > new Date()) {
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      } else dispatch(logout());
    }
  };
};

const viewTokenIframe = () => {
  return (dispatch) => {
    window.onload = () => {
      // Defining helper frame's window object.
      let helperFrameWindow =
        document.getElementById("helperFrame").contentWindow;

      let getUserId = () => {
        // Sending post message to read data from helper frame
        helperFrameWindow.postMessage("getUserId", "*");
      };
      // Capture incoming messages from helper frame
      window.addEventListener("message", (event) => {
        if (typeof event.data === "string") {
          if (event.data !== "sinToken") {
            const verifiDiferentToken =
              sessionStorage.getItem("token") !== event.data;
            dispatch(checkToken(event.data, verifiDiferentToken));
            if (verifiDiferentToken) window.location.reload();
          } else {
            dispatch(logout());
            window.location.reload();
          }
        }
      });
      getUserId();
    };
  };
};

const checkToken = (token, diferentToken, idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(authStart());

    if (diferentToken) {
      sessionStorage.clear();
    }

    axiosVendedores
      .get(
        `${baseUrlDashboard()}token/CheckToken?aplicacionId=3${
          idEmpresa ? `&empresaId=${idEmpresa}` : ""
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if (response) {
          if (response.status !== 200) logout();
          const user = response.data;
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("expirationDate", user.expiration);
          sessionStorage.setItem("userEmail", user.email);
          sessionStorage.setItem("isAdmin", user.isAdmin);
          sessionStorage.setItem("tieneVendedor", user.tieneVendedor);
          sessionStorage.setItem("fechaFija", user.fechaFija);
          sessionStorage.setItem("multiempresa", user.tieneMultiempresa);
          sessionStorage.setItem("monedaId", user.monedaId);
          sessionStorage.setItem("monedaSigno", user.monedaSigno.trim());
          sessionStorage.setItem("isVendedor", user.perfil.vendedor);

          if (diferentToken) {
            sessionStorage.setItem("empresaId", user.empresaPorDefectoId);
            sessionStorage.setItem(
              "empresaNombre",
              user.empresaPorDefectoNombre
            );
            sessionStorage.setItem("vendedorId", user.vendedorId);
          }

          dispatch(authSuccess(user.token));
          dispatch(setUserInfo(user));
          dispatch(checkAuthTimeout(24 * 60 * 60));

          if (user && user.fechaFija) {
            const fechaFija = new Date(user.fechaFija);
            dispatch(updateFechaVentasDelMesAnioDelVendedor(fechaFija));
            dispatch(updateFechaVentasDelMesAnioDeVendedores(fechaFija));
          }
        }
      })
      .catch((error) => {
        if (error.response) dispatch(authFail(error.response.data));
        else
          dispatch(authFail("Se produjo un error conectando con el servidor"));
      });
  };
};

const checkAuthTimeout = (expiresIn) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expiresIn * 1000);
  };
};

export const logout = () => {
  sessionStorage.clear();
  return { type: actionTypes.AUTH_LOGOUT };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userEmail) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
    userEmail,
  };
};

const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

const setUserInfo = (user) => {
  sessionStorage.setItem("userInfo", JSON.stringify(user));

  return {
    type: actionTypes.SET_USER_INFO,
    user,
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.AUTH_SET_REDIRECT_PATH,
    error: path,
  };
};

const updateFechaVentasDelMesAnioDelVendedor = (fechaFija) => {
  return {
    type: actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DEL_VENDEDOR,
    mes: fechaFija.getMonth(),
    anio: fechaFija.getFullYear(),
  };
};

const updateFechaVentasDelMesAnioDeVendedores = (fechaFija) => {
  return {
    type: actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_VENDEDORES,
    mes: fechaFija.getMonth(),
    anio: fechaFija.getFullYear(),
  };
};
