import { ArticuloModel, ImpuestoModel } from ".";

export default class PedidoModel {
  usuarioId;
  empresaId;
  plataformaId;
  comprobaId;
  clienteId;
  fecha;
  fechaEntrega;
  horarioEntrega;
  fechaVencimiento;
  clienteId;
  datosFacturacion;
  impBonif1;
  impBonif2;
  porcBonif1;
  porcBonif2;
  subtotalSinBonif;
  subtotalConBonif;
  impuestos;
  total;
  detalle;
  contacto;
  leyenda1;
  leyenda2;
  leyenda3;
  leyenda4;
  leyenda5;
  leyenda6;
  leyenda7;
  leyenda8;
  vendedor;
  vendedorId;
  vendedorMail;
  comision;

  setFromCliente(cliente){
    if(cliente !== null && cliente !== undefined){
      this.fecha = cliente.fecha;
      this.fechaVencimiento = cliente.vencimiento;
      this.fechaEntrega = cliente.vencimiento;
      this.horarioEntrega = cliente.horarioEntrega;
      this.clienteId = cliente.idCliente;
    }
  }

  setFromComprobante(comprobante){
    if(comprobante !== null && comprobante !== undefined){
      this.plataformaId = "JL";
      this.comprobaId = comprobante.idComprobante;
    }
  }

  setFromResumen(resumen){
    if(resumen !== null && resumen !== undefined){
      this.impBonif1 = resumen.bonificacion1;
      this.impBonif2 = resumen.bonificacion2;
      this.porcBonif1 = resumen.porcBonif1;
      this.porcBonif2 = resumen.porcBonif2;
      this.subtotalSinBonif = resumen.subTotal1;
      this.subtotalConBonif = resumen.subTotal2;
      this.total = resumen.total;
    }
  }

  setFromCarrito(carrito, resumen, comprobante) {
    if(carrito !== null && carrito !== undefined){
      let articulos = [];

      carrito.forEach(item => {
        var articulo = new ArticuloModel();
        articulo.setFromArticulo(item);
        articulos.push(articulo);
      });

      this.detalle = articulos;

      if(resumen !== null && resumen !== undefined){
        let impuestos = [];

        resumen.listaImpuestos.forEach(item => {
          const impuesto = new ImpuestoModel();
          impuesto.setFromItem(item);

          if(!comprobante.liquidaIva){
            impuesto.importeIva = 0;
          }

          impuestos.push(impuesto);
        });

        this.impuestos = impuestos;
      }else{
        this.impuestos = [];
      }
    }
  }

  setFromObservaciones(item){
    this.leyenda1 = item.leyenda1;
    this.leyenda2 = item.leyenda2;
    this.leyenda3 = item.leyenda3;
    this.leyenda4 = item.leyenda4;
    this.leyenda5 = item.leyenda5;
    this.leyenda6 = item.leyenda6;
    this.leyenda7 = item.leyenda7;
    this.leyenda8 = item.leyenda8;
  }

  setDatosFacturacion(item){
    this.datosFacturacion = item;
  }

  setImpuestos(items){
    this.impuestos = items;
  }

  setDetalle(items){
    this.detalle = items;
  }

  setContacto(item){
    this.contacto = item;
  }
}