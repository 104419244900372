import React, { useState, useEffect , Fragment, useCallback } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import NoPhoto from "../../../assets/images/NoPhoto.svg";
import Dialog from "../../UI/Dialog/Dialog";

import Carousel from "react-material-ui-carousel";
import { Divider, Grid, Button } from "@material-ui/core";
import ArticuloImages from "../ArticuloImage/ArticuloImages";

import AgregarCarritoItem from "../AgregarCarritoButton/AgregarCarritoItem";
import CustomPopover from '../../UI/Popover/CustomPopover';
import { isEven, addTaxToPrice, removeTaxFromPrice } from '../../../assets/shared/formatNumber';

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: 'white',
  },
  root2: {
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: '#f2f2f2',
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  cardHeader: {
    paddingBlock: '5px',
    paddingInline: '10px',
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatarNoPhoto: {
    border: "1px solid #525252b0",
    padding: "10px",
    borderRadius: "5px",
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: "-6px",
    opacity: "0.6",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title1: {
    fontSize: "small",
    textTransform: 'uppercase',
    color: 'dimgrey',
  },
  title2: {
    fontSize: "small",
    textTransform: 'capitalize',
    color: 'darkgray',
  },
  nuevoOferta: {
    color: "#42dab5",
    padding: "2px 10px 2px 10px",
    border: "1px solid #42dab5",
    borderRadius: "5px",
    marginLeft: "20px",
  },
  precioStock: {
    marginRight: "20px",
    fontSize: "medium",
    textTransform: 'capitalize',
    color: 'darkgray',
  },
  listasPrecioStock: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
    paddingTop: 5,
  },
  listasPrecioStockContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "2px",
    opacity: "0.9",
    backgroundColor: "darkgray",
    minWidth: '150px',
  },
  listasPrecioStockTitle: {
    textAlign: "-webkit-left",
  },
  listasPrecioStockInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "2px",
    paddingRigth: "2px",
    minWidth: '150px',
  },
  sinResultado: {
    textAlign: "center",
    fontSize: "x-large",
  },
  avatares: {
    borderRadius: 5,
    width: theme.spacing(7),
    height: theme.spacing(7),
    cursor: "pointer",
  },
  popOverText: {
    fontSize: 'smaller', 
    textTransform: 'uppercase',
  }
}));

const ArticuloCard = (props) => {
  const {
    index,
    clienteCabecera,
    articulo,
    listasDePrecios,
    listaStock,
    cardSinResultado,
    isNuevo,
    isOferta,
    comprobante,
    estaCargado,
    inClientInfo,
  } = props;

  const [filtroListaPrecio, setFiltroListaPrecio] = useState('');
  const [filtroDeposito, setFiltroDeposito] = useState('');
  
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [images, setImages] = useState([]);
  const [showImages, setShowImages] = useState(false);  
  const [showUploadImages, setShowUploadImages] = useState(false);
  const [priceList, setPriceList] = useState("");
  const [precio, setPrecio] = useState(0);
  const [precioIva, setPrecioIva] = useState(0);
  const [stockList, setStockList] = useState("");
  const [stock, setStock] = useState(0);
  const [ivaIncluido, setIvaIncluido] = useState(false);
  const [decimalesPrecio, setDecimalesPrecio] = useState(2);
  const [decimalesCantidad, setDecimalesCantidad] = useState(0);
  const [enterosPrecio, setEnterosPrecio] = useState(12);
  const [enterosCantidad, setEnterosCantidad] = useState(5);

  const calculateStock = useCallback((filtro) => {
    let value = 0;

    if(listaStock != null && listaStock !== undefined){
      if(filtro === "TODOS"){        
        listaStock.forEach(item => {
          value += item.saldo;
        });
      }else{
        let item = listaStock.find(list => list.depositoId === filtro);
        value = item !== null && item !== undefined ? item.saldo : 0;
      }
    }

    setStock(value);
  }, [listaStock])

  const calcularPrecios = useCallback((filtro, articulo) => {
    let porcentajeIva = articulo.porcentajeIva;
    let valorPrecio = 0;
    let valorPrecioIva = 0;

    if(listasDePrecios !== null && listasDePrecios !== undefined){
      let item = listasDePrecios.find(list => list.listaDePreciosId === filtro);

      if(item !== null && item !== undefined) {
        if(item.ivaIncluido){
          valorPrecio = removeTaxFromPrice(item.monto, porcentajeIva, decimalesPrecio);
          valorPrecioIva = item.monto;
        }else{
          valorPrecio = item.monto;
          valorPrecioIva = addTaxToPrice(item.monto, porcentajeIva, decimalesPrecio);
        }

        articulo.precioLista = item.monto;
      }else{
        valorPrecio = 0;
        valorPrecioIva = 0;
      }
    }
    
    setPrecio(valorPrecio);
    setPrecioIva(valorPrecioIva);  
  }, [listasDePrecios, decimalesPrecio, ]);

  useEffect(() => {
    setFiltroListaPrecio(inClientInfo ? props.filtroListaPrecioPorCliente : props.filtroListaPrecio);
  }, [inClientInfo, props.filtroListaPrecioPorCliente, props.filtroListaPrecio]);

  useEffect(() => {
    setFiltroDeposito(inClientInfo ? props.filtroDepositoPorCliente : props.filtroDeposito);
  }, [inClientInfo, props.filtroDepositoPorCliente, props.filtroDeposito]);

  useEffect(() => {
    if(priceList !== filtroListaPrecio){      
      setPriceList(filtroListaPrecio);

      if(clienteCabecera !== null && clienteCabecera !== undefined){
        let listaCliente = clienteCabecera.listasDePrecios.find(list => list.id === filtroListaPrecio);

        if(listaCliente !== null && listaCliente !== undefined){
          setIvaIncluido(listaCliente.ivaIncluido);
        }
      }

      if(articulo !== null && articulo !== undefined){
        calcularPrecios(filtroListaPrecio, articulo);
      }
    }

    if(stockList !== filtroDeposito){
      calculateStock(filtroDeposito);
      setStockList(filtroDeposito);
    }

    if(images.length === 0 && articulo !== undefined){
      setImages(articulo.imagenes);
    }

    if(estaCargado && comprobante !== null && comprobante !== undefined){
      setDecimalesPrecio(comprobante.decimalesPrecio);
      setDecimalesCantidad(comprobante.decimalesCantidad);
      setEnterosPrecio(comprobante.enterosPrecio);
      setEnterosCantidad(comprobante.enterosCantidad);
    }
  }, [
    comprobante,
    estaCargado,
    clienteCabecera,
    priceList,
    listasDePrecios, 
    filtroListaPrecio, 
    stockList, 
    filtroDeposito, 
    images.length, 
    articulo, 
    calcularPrecios, 
    calculateStock,
  ]);

  const handleExpandClick = () => {
    //setExpanded(!expanded);
  };

  const textTaxPriceToolTip = () =>{
    return (
      <div className={classes.listasPrecioStockContent}>              
        {listasDePrecios && listasDePrecios.map((listaPrecio, index) => (
          <Grid container
            spacing={1} 
            key={listaPrecio.listaDePreciosId + index}
            className={classes.listasPrecioStockInfo}>
            <Grid item xs={9} sm={9}>
              <Typography className={classes.popOverText}>
                {listaPrecio.listaDePrecios}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Typography className={classes.popOverText} style={{ textAlign: 'right' }}>
                {`${listaPrecio.signo} ${(
                  ivaIncluido 
                    ? listaPrecio.monto 
                    : addTaxToPrice(listaPrecio.monto, articulo.porcentajeIva, decimalesPrecio)
                ).toFixed(decimalesPrecio)}`}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }

  const priceTaxWithToolTip = () => {
    let textField = `Con IVA: $${precioIva && precioIva.toFixed(decimalesPrecio)}`;
    let textPopover = textTaxPriceToolTip();

    return (
      <CustomPopover textField={textField} textPopover={textPopover} />
    );    
  }

  const textPriceToolTip = () =>{
    return (
      <div className={classes.listasPrecioStockContent}>              
        {listasDePrecios && listasDePrecios.map((listaPrecio, index) => (
          <Grid container
            spacing={1} 
            key={listaPrecio.listaDePreciosId + index}
            className={classes.listasPrecioStockInfo}>
            <Grid item xs={9} sm={9}>
              <Typography className={classes.popOverText}>
                {listaPrecio.listaDePrecios}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Typography className={classes.popOverText} style={{ textAlign: 'right' }}>
                {`${listaPrecio.signo} ${(
                  ivaIncluido 
                    ? removeTaxFromPrice(listaPrecio.monto, articulo.porcentajeIva, decimalesPrecio) 
                    : listaPrecio.monto
                ).toFixed(decimalesPrecio)}`}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }

  const priceWithToolTip = () => {
    let textField = `Sin IVA: $${precio && precio.toFixed(decimalesPrecio)}`;
    let textPopover = textPriceToolTip();

    return (
      <CustomPopover textField={textField} textPopover={textPopover} />
    );    
  }

  const textStockToolTip = () =>{
    return (
      <div className={classes.listasPrecioStockContent}>              
        {listaStock && listaStock.map((stock, index) => (
          <Grid container 
            key={stock.depositoId + index} 
            spacing={1} 
            className={classes.listasPrecioStockInfo}>
            <Grid item xs={10} sm={10}>
              <Typography className={classes.popOverText}>
                {stock.deposito}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2}>
              <Typography className={classes.popOverText}>
                {stock.saldo.toFixed(decimalesCantidad)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }

  const stockWithToolTip = () => {
    let textField = `Stock: ${stock && stock.toFixed(decimalesCantidad)}`;
    let textPopover = textStockToolTip();

    return (
      <CustomPopover textField={textField} textPopover={textPopover} />
    );    
  }

  const avatarGenerador = () => {
    if (images.length === 0) {
      return (
        <img
          className={classes.avatarNoPhoto}
          onClick={() => setShowUploadImages(true)}
          alt=""
          src={images.length === 0 ? NoPhoto : images}
        />
      );
    } else {
      return (
        <Avatar
          classes={{ root: classes.avatares }}
          onClick={() => setShowImages(true)}
          variant="square"
          alt="imgArt"
          src={images[0].url}
        />
      );
    }
  };

  const uploadImages = () => {
    return (
      <ArticuloImages 
        images={images}
        idArticulo={articulo.idArticulo}
        updateImages={onUpdateImages}
      />
    )
  }

  const onUpdateImages = (newImages) => {
    articulo.imagenes = newImages;
    setImages(newImages);
  }

  const onEditarImagenes = () => {
    setShowImages(false); 
    setShowUploadImages(true);
  }

  const imagesCarousel = () => {
    return (
      <Grid container>
        <Grid item container xs={12} md={12} xl={12}>
          <Grid item xs={6} md={10} xl={11}></Grid>
          <Grid item xs={6} md={2} xl={1}>
            <Button
              variant="outlined" 
              color="default" 
              size="small"
              onClick={() => onEditarImagenes()}
            >
              <span>Editar</span>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <Carousel
            autoPlay={false}
            indicators={images.length !== 1}
            navButtonsAlwaysInvisible={images.length === 1}
            interval={500}
            animation="slide"
            index={0}
          >
            {images.map((imagen, index) => {
              return (
                <img
                  style={{
                    height: window.screen.width <= 1366 ? "630px" : "750px",
                  }}
                  key={index}
                  alt={index}
                  src={imagen.url}
                />
              );
            })}
          </Carousel>
        </Grid>
      </Grid>
      
    );
  };

  return (
    <Fragment>
      <Card
        style={{ marginBottom: expanded && "10px" }}
        className={isEven(index) ? classes.root1 : classes.root2 }
      >
        <CardHeader
          className={classes.cardHeader}
          avatar={cardSinResultado ? undefined : avatarGenerador()}
          title={
            cardSinResultado ? (
              <Typography className={classes.sinResultado}>
                Búsqueda sin resultado
              </Typography>
            ) : (
              <Grid container spacing={1} className={classes.title}>
                <Grid item container xs={10} md={10} className={classes.title}>   
                  <Grid container item spacing={1}>
                    <Grid item xs={6} md={2}>
                      <Typography className={classes.title1}>
                      {articulo.idArticulo.trimEnd()}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={5}>
                      <Typography className={classes.title1}>
                            {articulo.nombre.trimEnd()}
                          </Typography>
                      {isNuevo && (
                      <Typography
                              className={classes.nuevoOferta}
                              variant="button"
                            >
                              Nuevo
                            </Typography>
                          )}
                          {isOferta && (
                            <Typography
                              className={classes.nuevoOferta}
                              variant="button"
                            >
                              Oferta
                            </Typography>
                          )}
                        </Grid>
                    <Grid item xs={12} md={5} container>
                      <Grid item md={4}>
                        {stockWithToolTip()}
                      </Grid>
                      <Grid item md={4}>
                        {priceWithToolTip()}
                      </Grid>
                      <Grid item md={4}>
                        {priceTaxWithToolTip()}
                      </Grid>
                    </Grid>
                  </Grid>
                    
                  <Grid container item>
                    <Grid item xs={12} md={3}>
                      <Typography className={classes.title2}>
                        {`Marca: ${articulo.marca && articulo.marca.toUpperCase().trimEnd()}`} 
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography className={classes.title2}>
                        {`Rubro: ${articulo.rubro && articulo.rubro.toUpperCase().trimEnd()}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={4}>
                      <Typography className={classes.title2}>
                        {`Subrubro: ${articulo.subrubro && articulo.subrubro.toUpperCase().trimEnd()}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={1}>
                      <Typography className={classes.title2}>
                        {`IVA: ${articulo.porcentajeIva && articulo.porcentajeIva.toFixed(2)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} md={2} className={classes.title}>
                  {inClientInfo && <AgregarCarritoItem articulo={articulo} listasDePrecios={listasDePrecios} /> } 
                </Grid>
              </Grid>
            )
          }
        />
        <Collapse
          in={expanded}
          style={{ textAlign: "-webkit-center" }}
          timeout="auto"
          unmountOnExit
        >
          <CardContent className={classes.listasPrecioStock}>            
          </CardContent>
        </Collapse>
      </Card>
      {!expanded && <Divider light />}
      {showImages && (
        <Dialog
          show={showImages}
          body={imagesCarousel()}
          onClose={() => setShowImages(false)}
        />
      )}
      {showUploadImages && (
        <Dialog
          show={showUploadImages}
          body={uploadImages()}
          onClose={() => setShowUploadImages(false)}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  filtroListaPrecio: state.articulos.filtrosArticulos.listaPrecioId, 
  filtroListaPrecioPorCliente: state.articulos.filtrosArticulosPorCliente.listaPrecioId, 
  filtroDeposito: state.articulos.filtrosArticulos.depositoId,
  filtroDepositoPorCliente: state.articulos.filtrosArticulosPorCliente.depositoId,
  clienteCabecera: state.clientes.clienteCabeceraById.cliente,
  comprobante: state.comprobantes.comprobantePedido.comprobante,
  estaCargado: state.comprobantes.comprobantePedido.estaCargado,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ArticuloCard);