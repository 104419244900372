import { storageMonedaSigno } from './sessionData';

export const formatNumber = (value) => {
  return Intl.NumberFormat('de-DE', {
    minimumFractionDigits: '2',
    maximumFractionDigits: '2'
  }).format(value);
};

export const roundAccurately = (number, decimalPlaces) => {
  if(number === null || number === undefined){
    return 0;
  }

  let value = parseFloat(number);
  return Number(Math.round(value+'e'+decimalPlaces)+'e-'+decimalPlaces);
}

export const isEven = (value) => {
  if (value%2 === 0)
    return true;
  else
    return false;
}

export const addTaxToPrice = (price, tax, decimals = 2) => {
  let num = price <= 0 ? 0 : price + (price * tax / 100);
  let value = roundAccurately(num, decimals);

  return value;
};

export const removeTaxFromPrice = (price, tax, decimals = 2) => {
  let num = price <= 0 ? price : price - (price * tax / 100);
  let value = roundAccurately(num, decimals);

  return value;
}; 

export const formatTwoIntegerDigits = (value) => {
  return Intl.NumberFormat('de-DE', {
    minimumIntegerDigits: 2
  }).format(value);
};

export const formatNumberWithMonedaSigno = (value, simbolo) => {
  var formatedNumber = 0;
  if(simbolo !== undefined)
    formatedNumber = `${simbolo} ${formatNumber(value)}`
  else
    formatedNumber = `${storageMonedaSigno()} ${formatNumber(value)}`

  return formatedNumber
};