import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import ColumnChart from '../../../Charts/ColumnChart';
import moment from 'moment';
import { IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SeleccionePeriodos from '../../../../assets/images/SeleccionePeriodos.png';
import TablaComparacionVentasDelVendedorPorSeleccion from '../TablaComparacionVentasDelVendedorPorSeleccion/TablaComparacionVentasDelVendedorPorSeleccion';

const BarComparacionPeriodosDeVentaDelVendedor = (props) => {
  const { ventasComparar, loading, ventasDe, vendedorSelected } = props;

  useEffect(() => {
    onLimpiarChartClick();
  }, [sessionStorage.getItem('empresaId'), ventasDe, vendedorSelected]);

  const onLimpiarChartClick = () => {
    props.onLimpiarComparacionClick();
  };

  return (
    <Fragment>
      {(ventasComparar.length > 0 || loading) && (
        <ColumnChart
          title={
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}
            >
              <div>{`Comparativa de ventas por ${props.ventasDe.toLowerCase()} entre dos periodos seleccionados`}</div>
              {ventasComparar.length > 0 && (
                <div style={{ paddingRight: '10px' }}>
                  <Tooltip title="Limpiar comparación">
                    <IconButton onClick={() => onLimpiarChartClick()} size="small">
                      <CloseIcon fontSize="default" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          }
          customSinDataImg={SeleccionePeriodos}
          customSinDataImgWidth={300}
          series={
            ventasComparar.length > 0 &&
            ventasComparar
              .sort((a, b) => {
                return new Date(a.dateComplete) - new Date(b.dateComplete);
              })
              .map((venta) => {
                return {
                  name: moment(venta.dateComplete).subtract(1, 'month').format('MM/YYYY'),
                  data: venta.ventas.map((ventasDetail) => ventasDetail.monto)
                };
              })
          }
          categories={
            ventasComparar.length > 0 &&
            ventasComparar[0].ventas.map((venta) => {
              return venta.nombre.trim().split(' ');
            })
          }
          //onPorcionClick={(config) => ventasComparar.length === 2 && onBarClick(config)}
          loading={loading}
          imgSinDatos={ventasComparar.length === 0}
          height={350}
          width={'100%'}
          xaxisLabelsShow
          xaxisLabelsTrim={false}
          tooltipXShow
          legendStyleFontSize={'10px'}
          customMarginTop={'0px'}
          legendShowForSingleSeries
          legendShowForNullSeries
          legendShowForZeroSeries
          legendOffSetY={20}
          legendHeight={50}
          maxWidthImgSinResult={330}
          legendPosition={'bottom'}
        />
      )}
      <div id="#divider" style={{ height: '10px' }} />
      {ventasComparar.length === 2 && (
        <TablaComparacionVentasDelVendedorPorSeleccion
          ventasComparar={ventasComparar}
          loadDetallesKey={props.loadDetallesKey}
          ventasDe={ventasDe}
          dataSelectionId={props.dataSelectionId}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    vendedorSelected: state.vendedores.vendedorSelected
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowDetalleCategoriaModal: (show, title, body) => dispatch(actions.showGlobalModal(show, title, body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarComparacionPeriodosDeVentaDelVendedor);