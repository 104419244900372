import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from "@material-ui/core";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { CarritoContext } from "../../store/contexts/carrito";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({  
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  title:{
    fontSize: "large", 
    fontWeight: 'bold',
  },
  icon: {
    fontSize: '70px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    marginBottom: '10px',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '50px',
    "& > *": {
      margin: theme.spacing(1),
      color: "white",
    },
  },
  button: {
    width: '100%',
  },
  labels:{
    fontSize: "x-large",
    fontWeight: 'bold', 
  },
  row:{
    display: 'flex',
    justifyContent: "space-between",
    alignItems:"center",
  }
}));

const ConfirmacionPedido = (props) => {
  const classes = useStyles();
  
  const {
    pedido,
    error,
    onIrACatalogo,
  } = props;

  const [numeroPedido, setNumeroPedido] = useState();

  const { 
    clearCarrito,
  } = useContext(CarritoContext);

  const onContinuar = () => {
    clearCarrito();
    onIrACatalogo();
  }

  useEffect(() => {
    if(numeroPedido === null || numeroPedido === undefined)
    {
      if(pedido !== null && pedido !== undefined){ 
        setNumeroPedido(pedido.nroReferencia);
      }
    }
  }, [numeroPedido, error, pedido]);

  const envioSuccess = () => {
    return (
      <Grid container className={classes.root}>
        <Grid item
          xs={12} md={12} xl={12}
          className={classes.iconContainer}
        >
          <CheckCircleOutlinedIcon className={classes.icon} />
        </Grid>
        <Grid item
          xs={12} md={12} xl={12}
          className={classes.labelContainer}
        >
          <Typography className={classes.labels}>
            Pedido enviado!!!
          </Typography>
        </Grid>
        <Grid item
          xs={12} md={12} xl={12}
          className={classes.labelContainer}
        >
          <Typography className={classes.labels}>
            Pedido Nro: {numeroPedido}
          </Typography>
        </Grid>
        <Grid item
          xs={6} md={4} xl={2}
          className={classes.buttonContainer}
        >
          <Button
            className={classes.button}
            variant="contained" 
            color="primary" 
            size="small"
            onClick={() => onContinuar()}
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    );
  }

  const envioError = () => {
    return (
      <Grid container className={classes.root}>
        <Grid item
          xs={12} md={12} xl={12}
          className={classes.iconContainer}
        >
          <CheckCircleOutlinedIcon className={classes.icon} />
        </Grid>
        <Grid item
          xs={12} md={12} xl={12}
          className={classes.labelContainer}
        >
          <Typography className={classes.labels}>
            Ocurrió un error al enviar el pedido!!!
          </Typography>
        </Grid>
        <Grid item
          xs={12} md={12} xl={12}
          className={classes.labelContainer}
        >
        </Grid>
        <Grid item
          xs={6} md={4} xl={2}
          className={classes.buttonContainer}
        >
          <Button
            className={classes.button}
            variant="contained" 
            color="primary" 
            size="small"
            onClick={() => onContinuar()}
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    );
  }

  return error
    ? (
      <Fragment>{envioError()}</Fragment>
    ) : (
      <Fragment>{envioSuccess()}</Fragment>
    )
};

const mapStateToProps = (state) => {
  return {
    pedido: state.comprobantes.sendPedido.pedido,  
    error: state.comprobantes.sendPedido.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmacionPedido);