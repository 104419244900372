// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__MuiTooltip-popper__DPUds {\r\n  z-index: 1150 !important;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  padding: 0;\r\n  font-family: 'Roboto';\r\n}\r\n\r\n/*apexcharts-legend apexcharts-align-left position-right*/\r\n.index__apexcharts-legend__1c0Vf{\r\n  background-color: red !important;\r\n}\r\n\r\n::-webkit-scrollbar {\r\n  width: 8px;\r\n}\r\n\r\n/* Track */\r\n::-webkit-scrollbar-track {\r\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\r\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\r\n  border-radius: 10px;\r\n}\r\n\r\n/* Handle */\r\n::-webkit-scrollbar-thumb {\r\n  border-radius: 10px;\r\n  background: rgba(78, 78, 78, 0.8);\r\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);\r\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n::-webkit-scrollbar-thumb:window-inactive {\r\n  background: rgba(63, 63, 63, 0.4);\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MuiTooltip-popper": "index__MuiTooltip-popper__DPUds",
	"apexcharts-legend": "index__apexcharts-legend__1c0Vf"
};
export default ___CSS_LOADER_EXPORT___;
