import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../assets/shared/sessionData';
import { formatNumber, isEven } from '../../../../assets/shared/formatNumber';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import classes from './TablaComparacionVentasDelVendedorPorSeleccion.css';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MTableBodyRow } from 'material-table';
import TablaComparacionVentasDelVendedorDeLaSeleccion from '../TablaComparacionVentasDelVendedorDeLaSeleccion/TablaComparacionVentasDelVendedorDeLaSeleccion';
import TablaComparacionVentasToExel from '../TablaComparacionVentasDelVendedorDeLaSeleccion/TablaComparacionPeriodosDeVentasDelVendedorToExcel';
import { compareByAsc, compareByDesc } from '../../../../assets/shared/sorting';

const TablaComparacionVentasDelVendedorPorSeleccion = (props) => {
  const { fechasComparar = [], dataSelectionId, ventasComparar, loadDetallesKey, ventasDe } = props;
  const [tableState, setTableState] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: 'item',
    direction: 'asc'
  });
  const [searchState, setSearchState] = useState('');

  useEffect(() => {
    tableState.push(ventasComparar[0].ventas);
    armarComparacion(ventasComparar[1].ventas);
  }, []);

  const columns = [
    {
      title: '',
      field: 'nombre',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      }
    },
    {
      title: `Monto`,
      field: 'monto1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto1)}`
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion1',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion1 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion1)} %`)
    },
    {
      title: `Monto`,
      field: 'monto2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto2)}`
    },
    {
      title: `Porc. Participación`,
      field: 'porcParticipacion2',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (rowData.porcParticipacion2 === '-' ? '-' : `${formatNumber(rowData.porcParticipacion2)} %`)
    },
    {
      title: `Dif. Montos`,
      field: 'difMontos',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.difMontos)}`
    },
    {
      title: 'Porc. Comparación',
      field: 'porcComparacion',
      align: 'right',
      cellStyle: {
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      render: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {`${formatNumber(rowData.porcComparacion)} % `}
          {rowData.porcComparacion === 0 || rowData.porcComparacion === 'NaN' ? (
            <TrendingFlatIcon style={{ color: 'black', width: '25px', marginLeft: '5px' }} />
          ) : rowData.porcComparacion > 0 ? (
            <TrendingUpIcon style={{ color: 'green', width: '25px', marginLeft: '5px' }} />
          ) : (
            <TrendingDownIcon style={{ color: 'red', width: '25px', marginLeft: '5px' }} />
          )}
        </div>
      )
    }
  ];

  const armarComparacion = (arrayAComparar) => {
    var arrayNuevo = [];

    tableState[0].forEach((ventasCompleto) => {
      const verify = arrayAComparar.filter((venta) => venta.id === ventasCompleto.id);
      if (verify.length === 0) {
        arrayNuevo.push({ ...ventasCompleto, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    arrayAComparar.map((venta) => {
      return arrayNuevo.push(venta);
    });

    arrayAComparar.forEach((ventasOrdenadas) => {
      const verify = tableState[0].filter((venta) => venta.id === ventasOrdenadas.id);

      if (verify.length === 0) {
        tableState[0].push({ ...ventasOrdenadas, monto: 0, porcentajeDeParticipacion: 0 });
      }
    });

    var orderArrayNuevo = arrayNuevo.sort((a, b) => a.nombre.localeCompare(b.nombre));
    var orderTableState = tableState[0].sort((a, b) => a.nombre.localeCompare(b.nombre));

    setTableState([
      { dateComplete: ventasComparar[0].dateComplete, ventasDetallada: orderTableState },
      { dateComplete: ventasComparar[1].dateComplete, ventasDetallada: orderArrayNuevo }
    ]);
  };

  const data = () => {
    const formatTableData = [];
    let totalMonto1 = 0;
    let totalMonto2 = 0;

    const filterTableState =
      tableState.length === 2
        ? [
            {
              ...tableState[0],
              ventasDetallada: tableState[0].ventasDetallada.filter(
                (ventDet) =>
                  ventDet.nombre.toLowerCase().includes(searchState) || ventDet.id.toLowerCase().includes(searchState)
              )
            },
            {
              ...tableState[1],
              ventasDetallada: tableState[1].ventasDetallada.filter(
                (ventDet) =>
                  ventDet.nombre.toLowerCase().includes(searchState) || ventDet.id.toLowerCase().includes(searchState)
              )
            }
          ]
        : [];

    filterTableState.map((data, key) => {
      if (key === 0) {
        return data.ventasDetallada.map((ventaDetallada, index) => {
          var ventasDetalldasDelIndex = tableState[1].ventasDetallada[index];

          const ventasDelIndex = ventasDetalldasDelIndex
            ? tableState[1].ventasDetallada[index]
            : { mes: 0, anio: 0, id: '0', nombre: 'undefined', monto: 0, porcentajeDeParticipacion: 0 };

          totalMonto1 += ventaDetallada.monto;
          totalMonto2 += ventasDelIndex.monto;

          return formatTableData.push({
            id: ventaDetallada.id,
            nombre: ventaDetallada.nombre,
            monto1: ventaDetallada.monto,
            porcParticipacion1: ventaDetallada.porcentajeDeParticipacion,
            monto2: ventasDelIndex.monto,
            porcParticipacion2: ventasDelIndex.porcentajeDeParticipacion,
            difMontos: calcularDif(ventaDetallada.monto, ventasDelIndex.monto),
            porcComparacion: calcularPorc(ventaDetallada.monto, ventasDelIndex.monto)
          });
        });
      } else {
        return undefined;
      }
    });

    formatTableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    tableState.length > 0 &&
      formatTableData.push({
        itemId: null,
        nombre: 'Total',
        monto1: totalMonto1,
        porcParticipacion1: '-',
        monto2: totalMonto2,
        porcParticipacion2: '-',
        difMontos: calcularDif(totalMonto1, totalMonto2),
        porcComparacion: calcularPorc(totalMonto1, totalMonto2)
      });

    return formatTableData;
  };
  const calcularPorc = (value1, value2) => {
    if (value1 === 0 && value2 !== 0) {
      return 100;
    }
    if (value2 === 0 && value1 !== 0) {
      return -100;
    }
    if (value1 === 0 && value2 === 0) {
      return 0;
    }

    const calculo = (value2 / value1 - 1) * 100;
    if (isNaN(calculo)) {
      return 0;
    } else {
      return calculo;
    }
  };

  const calcularDif = (value1, value2) => {
    return value2 - value1;
  };

  const onSort = (sortKey) => {
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
  };

  const handleSearchChange = (e) => {
    setSearchState(e.target.value.toLowerCase());
  };

  const headersBackgroundColor = 'rgb(250, 250, 250)';

  const tableRef = useRef();

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
        <div
          style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '10px', marginLeft: '20px' }}
        >{`Comparativa de ventas por ${props.ventasDe.toLowerCase()} entre dos periodos seleccionados`}</div>
        <TextField
          id="input-with-icon-textfield"
          placeholder="Buscar"
          onChange={(e) => handleSearchChange(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        <TablaComparacionVentasToExel
          ventasPor={`${ventasDe}`}
          comprobantes={data()}
          fechas={[
            moment(ventasComparar[0].dateComplete).subtract(1, 'month').format('MM/YYYY'),
            moment(ventasComparar[1].dateComplete).subtract(1, 'month').format('MM/YYYY')
          ]}
        />
      </div>
      <Table
        columns={columns}
        tableRef={tableRef}
        data={data()}
        noBoxShadow
        components={{
          Header: (props) => {
            return (
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  ></TableCell>

                  <TableCell
                    style={{ paddingBottom: '5px', width: window.screen.width < 1367 ? '265px' : '410px' }}
                  ></TableCell>
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px',
                      border: 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    colSpan={2}
                    align="center"
                  >
                    {moment(ventasComparar[0].dateComplete).subtract(1, 'month').format('MM/YYYY')}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '60px',
                      border: 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    colSpan={2}
                    align="center"
                  >
                    {moment(ventasComparar[1].dateComplete).subtract(1, 'month').format('MM/YYYY')}
                  </TableCell>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '160px' }}
                    align="center"
                  ></TableCell>
                  <TableCell style={{ paddingBottom: '5px', width: '170px' }}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingTop: '5px', paddingBottom: '5px', width: '10px' }}
                    align="left"
                  ></TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('nombre')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      width: window.screen.width < 1367 ? '265px' : '410px',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="left"
                  >
                    {`${ventasDe}`}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto1')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderLeft: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Monto
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion1')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderRight: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Porc. Participación
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto2')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderLeft: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Monto
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacion2')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderRight: fechasComparar.length === 2 && 'solid 1px #e0e0e0',
                      backgroundColor: headersBackgroundColor
                    }}
                    align="right"
                  >
                    Porc. Participación
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('difMontos')}
                    style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                    align="right"
                  >
                    Dif. Montos
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcComparacion')}
                    style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: headersBackgroundColor }}
                    align="right"
                  >
                    Porc. Comparación
                  </TableCell>
                </TableRow>
              </TableHead>
            );
          },
          Row: (props) => {
            return (
              <MTableBodyRow
                {...props}
                detailPanel={
                  !props.data.id
                    ? [{ disabled: true, render: props.detailPanel[0].render, tooltip: props.detailPanel[0].tooltip }]
                    : props.detailPanel
                }
              />
            );
          }
        }}
        detailPanel={[
          {
            disabled: false,
            render: (rowData) => {
              return (
                <TablaComparacionVentasDelVendedorDeLaSeleccion
                  categoria={rowData}
                  fechasComparar={[ventasComparar[0].dateComplete, ventasComparar[1].dateComplete]}
                  titleCategory={rowData.nombre}
                  loadDetallesKey={loadDetallesKey}
                  ventasDe={ventasDe}
                  dataSelectionId={dataSelectionId}
                />
              );
            }
          }
        ]}
        customOptions={{
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          maxBodyHeight: 630,
          showTitle: false,
          tableLayout: 'fixed',
          toolbar: false,
          rowStyle: (rowData, config) => {
            return {
              cursor: 'auto',
              backgroundColor: (!rowData.id && '#A3CCE8') || (!isEven(rowData.tableData.id) && '#f2f2f2')
            };
          },
          cellStyle: (cellData) => ({
            fontWeight: cellData === 'Total' && 'bolder'
          })
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaComparacionVentasDelVendedorPorSeleccion);
