import * as actionTypes from "./actionTypes";
import axiosVendedores from "../../axios-vendedores";

export const loadArticulosStockPrecio = (
  articulo,
  marcaId,
  rubroId,
  subrubroId,
  limit,
  offSet,
  inClientInfo = false
) => {
  return (dispatch) => {
    dispatch(loadArticulosStockPrecioStart(inClientInfo));

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

    axiosVendedores
      .get(
        `/articulos/ArticulosStockPrecio?idEmpresa=${idEmpresa}${
          articulo ? `&articulo=${articulo}` : ""
        }${marcaId ? `&marcaId=${marcaId}` : ""}${
          rubroId ? `&rubroId=${rubroId}` : ""
        }${subrubroId ? `&subrubroId=${subrubroId}` : ""}${
          limit ? `&limit=${limit}` : ""
        }${offSet ? `&offset=${offSet}` : ""}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const totalCount = response.data[0] ? response.data[0].total : 0;
        dispatch(loadArticulosStockPrecioSuccess(response.data, totalCount, inClientInfo));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadArticulosStockPrecioFail(error, inClientInfo));
          } else {
            dispatch(loadArticulosStockPrecio(inClientInfo));
          }
        } else {
          dispatch(loadArticulosStockPrecioFail(error,inClientInfo));
        }
      });
  };
};

export const clearArticulosStockPrecio = (inClientInfo = false) => {
  return {
    type: actionTypes.CLEAR_ARTICULOS_STOCK_PRECIO,
    inClientInfo
  };
};

const loadArticulosStockPrecioStart = (inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_ARTICULOS_STOCK_PRECIO_START,
    inClientInfo
  };
};

const loadArticulosStockPrecioSuccess = (articulos, countTotal, inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_ARTICULOS_STOCK_PRECIO_SUCCESS,
    articulos,
    countTotal,
    inClientInfo
  };
};

const loadArticulosStockPrecioFail = (error, inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_ARTICULOS_STOCK_PRECIO_FAIL,
    error,
    inClientInfo
  };
};

export const loadArticulosBusqueda = (
  soloStockPositivo, 
  orden, 
  busqueda,
  marcaId,
  rubroId,
  subrubroId,
  listaPrecioId, 
  depositoId,
  limit=50, 
  offSet=0,
  next=true,
  inClientInfo = false) => {
  return (dispatch) => {
    dispatch(loadArticulosBusquedaStart(inClientInfo));

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10); 
    
    const url = `/articulos/Busqueda?idEmpresa=${idEmpresa}&next=${next}${
      soloStockPositivo ? `&soloStockPositivo=${soloStockPositivo}` : ""}${
      orden ? `&orden=${orden}` : ""}${
      busqueda ? `&busqueda=${busqueda}` : ""}${
      marcaId ? `&marcaId=${marcaId}` : ""}${
      rubroId ? `&rubroId=${rubroId}` : ""}${
      subrubroId ? `&subrubroId=${subrubroId}` : ""}${
      listaPrecioId ? `&listaPrecioId=${listaPrecioId}` : ""}${
      depositoId ? `&depositoId=${depositoId}` : ""}${
      limit ? `&limit=${limit}` : "" }${
      offSet ? `&offset=${offSet}` : "" }`;
    axiosVendedores
      .get(url, { headers: { Authorization: authStr } })
      .then((response) => {
        const totalCount = response.data[0] ? response.data[0].total : 0;
        dispatch(loadArticulosBusquedaSuccess(response.data, totalCount, busqueda, inClientInfo));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadArticulosBusquedaFail(error, inClientInfo));
          } else {
            dispatch(loadArticulosBusqueda(soloStockPositivo, orden, busqueda, marcaId, 
              rubroId, subrubroId, listaPrecioId, depositoId, limit, offSet, inClientInfo));
          }
        } else {
          dispatch(loadArticulosBusquedaFail(error, inClientInfo));
        }
      });
  };
};

export const clearArticulosBusqueda = (inClientInfo = false) => {
  return {
    type: actionTypes.CLEAR_ARTICULOS_BUSQUEDA,
    inClientInfo
  };
};

const loadArticulosBusquedaStart = (inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_ARTICULOS_BUSQUEDA_START,
    inClientInfo
  };
}

const loadArticulosBusquedaSuccess = (articulos, countTotal, busqueda, inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_ARTICULOS_BUSQUEDA_SUCCESS,
    articulos,
    countTotal,
    busqueda,
    inClientInfo
  };
}

const loadArticulosBusquedaFail = (error, inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_ARTICULOS_BUSQUEDA_FAIL,
    error,
    inClientInfo
  };
}

export const loadArticulosCodigoBarras = (codigoBarras, limit, offSet, inClientInfo = false) => {
  return (dispatch) => {
    dispatch(loadArticulosBusquedaStart(inClientInfo));

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

    axiosVendedores
      .get(
        `/articulos/CodigoBarras?${
          `codigoBarras=${codigoBarras}&idEmpresa=${idEmpresa}`}${
            limit ? `&limit=${limit}` : "" }${
            offSet ? `&offset=${offSet}` : "" }`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const totalCount = response.data[0] ? response.data[0].total : 0;
        dispatch(loadArticulosBusquedaSuccess(response.data, totalCount, inClientInfo));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadArticulosBusquedaFail(error, inClientInfo));
          } else {
            dispatch(loadArticulosBusqueda(inClientInfo));
          }
        } else {
          dispatch(loadArticulosBusquedaFail(error, inClientInfo));
        }
      });
  };
}

export const clearArticulosCodigoBarras = (inClientInfo = false) => {
  return {
    type: actionTypes.CLEAR_ARTICULOS_CODIGO_BARRAS,
    inClientInfo
  };
};

export const searchArticulosStockPrecio = (articulo, success, inClientInfo = false) => {
  return (dispatch) => {
    dispatch(searchArticulosStockPrecioStart(inClientInfo));

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axiosVendedores
      .get(
        `/articulos/ArticulosStockPrecio?articulo=${articulo}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const totalCount = response.data[0] ? response.data[0].total : 0;
        dispatch(searchArticulosStockPrecioSuccess(response.data, totalCount, inClientInfo));
        return success(response.data, articulo, totalCount);
      })
      .catch((error) => {
        dispatch(searchArticulosStockPrecioFail(error, inClientInfo));
      });
  };
};

export const clearSearchArticulosStockPrecio = (inClientInfo = false) => {
  return {
    type: actionTypes.CLEAR_SEARCH_ARTICULOS_STOCK_PRECIO,
    inClientInfo
  };
};

const searchArticulosStockPrecioStart = (inClientInfo = false) => {
  return {
    type: actionTypes.SEARCH_ARTICULOS_STOCK_PRECIO_START,
  };
};

const searchArticulosStockPrecioSuccess = (articulos, countTotal, inClientInfo = false) => {
  return {
    type: actionTypes.SEARCH_ARTICULOS_STOCK_PRECIO_SUCCESS,
    articulos,
    countTotal,
    inClientInfo
  };
};

const searchArticulosStockPrecioFail = (error, inClientInfo = false) => {
  return {
    type: actionTypes.SEARCH_ARTICULOS_STOCK_PRECIO_FAIL,
    error,
    inClientInfo
  };
};

export const paginacionArticulos = (pageNumber, pageSize, inClientInfo = false) => {
  return {
    type: actionTypes.PAGINACION_ARTICULOS,
    pageNumber,
    pageSize,
    inClientInfo
  };
};

export const loadMarcas = () => {
  return (dispatch) => {
    dispatch(loadMarcasStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosVendedores
      .get(`/articulos/marcas`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadMarcasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadMarcasFail(error));
          } else {
            dispatch(loadMarcas());
          }
        } else {
          dispatch(loadMarcasFail(error));
        }
      });
  };
};

const loadMarcasStart = () => {
  return {
    type: actionTypes.LOAD_MARCAS_START,
  };
};

const loadMarcasSuccess = (marcas) => {
  return {
    type: actionTypes.LOAD_MARCAS_SUCCESS,
    marcas,
  };
};

const loadMarcasFail = (error) => {
  return {
    type: actionTypes.LOAD_MARCAS_FAIL,
    error,
  };
};

export const loadRubros = () => {
  return (dispatch) => {
    dispatch(loadRubrosStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosVendedores
      .get(`/articulos/Rubros`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadRubrosSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadRubrosFail(error));
          } else {
            dispatch(loadRubros());
          }
        } else {
          dispatch(loadRubrosFail(error));
        }
      });
  };
};

const loadRubrosStart = () => {
  return {
    type: actionTypes.LOAD_RUBROS_START,
  };
};

const loadRubrosSuccess = (rubros) => {
  return {
    type: actionTypes.LOAD_RUBROS_SUCCESS,
    rubros,
  };
};

const loadRubrosFail = (error) => {
  return {
    type: actionTypes.LOAD_RUBROS_FAIL,
    error,
  };
};

export const loadSubrubros = () => {
  return (dispatch) => {
    dispatch(loadSubrubrosStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosVendedores
      .get(`/articulos/Subrubros`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadSubrubrosSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadSubrubrosFail(error));
          } else {
            dispatch(loadSubrubros());
          }
        } else {
          dispatch(loadSubrubrosFail(error));
        }
      });
  };
};

const loadSubrubrosStart = () => {
  return {
    type: actionTypes.LOAD_SUBRUBROS_START,
  };
};

const loadSubrubrosSuccess = (subrubros) => {
  return {
    type: actionTypes.LOAD_SUBRUBROS_SUCCESS,
    subrubros,
  };
};

const loadSubrubrosFail = (error) => {
  return {
    type: actionTypes.LOAD_SUBRUBROS_FAIL,
    error,
  };
};

export const loadListaPrecios = (inClientInfo = false) => {
  return (dispatch) => {
    dispatch(loadListaPreciosStart(inClientInfo));

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosVendedores
      .get(`/articulos/ListaPrecios`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadListaPreciosSuccess(response.data, inClientInfo));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadListaPreciosFail(error));
          } else {
            dispatch(loadListaPrecios(inClientInfo));
          }
        } else {
          dispatch(loadListaPreciosFail(error, inClientInfo));
        }
      });
  };
};

const loadListaPreciosStart = (inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_LISTA_PRECIOS_START,
    inClientInfo
  };
};

const loadListaPreciosSuccess = (listaPrecios, inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_LISTA_PRECIOS_SUCCESS,
    listaPrecios,
    inClientInfo
  };
};

const loadListaPreciosFail = (error, inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_LISTA_PRECIOS_FAIL,
    error,
    inClientInfo
  };
};

export const loadDepositos = (inClientInfo = false) => {
  return (dispatch) => {
    dispatch(loadDepositosStart(inClientInfo));

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));    
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

    axiosVendedores
      .get(`/articulos/Depositos?idEmpresa=${idEmpresa}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadDepositosSuccess(response.data, inClientInfo));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadDepositosFail(error, inClientInfo));
          } else {
            dispatch(loadDepositos(inClientInfo));
          }
        } else {
          dispatch(loadDepositosFail(error, inClientInfo));
        }
      });
  };
};

const loadDepositosStart = (inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_DEPOSITOS_START,
    inClientInfo
  };
};

const loadDepositosSuccess = (depositos, inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_DEPOSITOS_SUCCESS,
    depositos,
    inClientInfo
  };
};

const loadDepositosFail = (error, inClientInfo = false) => {
  return {
    type: actionTypes.LOAD_DEPOSITOS_FAIL,
    error,
    inClientInfo
  };
};

export const addImages = (data) => {
  return (dispatch) => {
    dispatch(addImagesStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': authStr
    }

    axiosVendedores
      .post(`/articulos/AddImages?idEmpresa=${idEmpresa}`, data, {
        headers: headers,
      })
      .then((response) => {
        dispatch(addImagesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(addImagesFail(error));
      });
  };
};

const addImagesStart = () => {
  return {
    type: actionTypes.ADD_IMAGES_START,
  };
};

const addImagesSuccess = (images) => {
  return {
    type: actionTypes.ADD_IMAGES_SUCCESS,
    images,
  };
};

const addImagesFail = (error) => {
  return {
    type: actionTypes.ADD_IMAGES_FAIL,
    error,
  };
};

export const removeImages = (data) => {
  return (dispatch) => {
    dispatch(removeImagesStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': authStr
    }

    axiosVendedores
      .post(`/articulos/RemoveImages`, data, {
        headers: headers,
      })
      .then((response) => {
        dispatch(removeImagesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(removeImagesFail(error));
      });
  };
};

const removeImagesStart = () => {
  return {
    type: actionTypes.REMOVE_IMAGES_START,
  };
};

const removeImagesSuccess = (result) => {
  return {
    type: actionTypes.REMOVE_IMAGES_SUCCESS,
    result,
  };
};

const removeImagesFail = (error) => {
  return {
    type: actionTypes.REMOVE_IMAGES_FAIL,
    error,
  };
};

export const cambiarFiltroMarca = (marcaId, inClientInfo = false) => {
  return {
    type: actionTypes.CAMBIAR_FILTRO_MARCA,
    marcaId,
    inClientInfo
  };
};

export const cambiarFiltroRubro = (rubroId, inClientInfo = false) => {
  return {
    type: actionTypes.CAMBIAR_FILTRO_RUBRO,
    rubroId,
    inClientInfo
  };
};

export const cambiarFiltroSubrubro = (subrubroId, inClientInfo = false) => {
  return {
    type: actionTypes.CAMBIAR_FILTRO_SUBRUBRO,
    subrubroId,
    inClientInfo
  };
};

export const cambiarFiltroListaPrecio = (listaPrecioId, inClientInfo = false) => {
  return {
    type: actionTypes.CAMBIAR_FILTRO_LISTA_PRECIO,
    listaPrecioId,
    inClientInfo
  };
};

export const cambiarFiltroDeposito = (depositoId, inClientInfo = false) => {
  return {
    type: actionTypes.CAMBIAR_FILTRO_DEPOSITO,
    depositoId,
    inClientInfo
  };
};

export const cambiarFiltroStock = (withStock, inClientInfo = false) => {
  return {
    type: actionTypes.CAMBIAR_FILTRO_STOCK,
    withStock,
    inClientInfo
  };
}

export const cambiarFiltroBusqueda= (busqueda, inClientInfo = false) => {
  return {
    type: actionTypes.CAMBIAR_FILTRO_BUSQUEDA,
    busqueda,
    inClientInfo
  };
}

export const cambiarFiltroCodigoBarras = (codigoBarras, inClientInfo = false) => {
  return {
    type: actionTypes.CAMBIAR_FILTRO_CODIGO_BARRAS,
    codigoBarras,
    inClientInfo
  };
}

export const clearFiltrosArticulos = (inClientInfo = false) => {
  return {
    type: actionTypes.CLEAR_FILTROS_ARTICULOS,
    inClientInfo
  };
}