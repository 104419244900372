export default class ArticuloModel {
  articuloId;
  nombre;
  cantidad;
  precioUnitarioSinBonif;
  precioUnitarioConBonif;
  total;
  porcBonif;
  precioLista;

  setFromArticulo(item){
    this.articuloId = item.idArticulo && item.idArticulo.trimEnd();
    this.nombre = item.nombre && item.nombre.trimEnd();
    this.cantidad = item.cantidad;
    this.precioUnitarioSinBonif = item.precio;
    this.precioUnitarioConBonif = item.precioBonificado;
    this.total = item.total;
    this.porcBonif = item.bonificacion;
    this.precioLista = item.precioLista;
  }
}