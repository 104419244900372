import React, { useEffect, useState, createRef, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CardCliente from "../../components/Indicadores/clientes/CardCliente/CardCliente";
import TextoUltimaCompra from "../../components/Indicadores/clientes/TextoUltimaCompra/TextoUltimaCompra";
import EstadoPedidosPendientes from "../../components/Indicadores/ventas/PieEstadoPedidosPendientes/PieEstadoPedidosPendientes";
import TextoPedidosPendientes from "../../components/Indicadores/ventas/TextoPedidosPendientes/TextoPedidosPendientes";
import TextoPedidosPendientesComparativo from "../../components/Indicadores/ventas/TextoPedidosPendientesComparativo/TextoPedidosPendientesComparativo";
import TextoVentasMensuales from "../../components/Indicadores/ventas/TextoVentasMensuales/TextoVentasMensuales";
import { connect } from "react-redux";
import TextoSaldoCliente from "../../components/Indicadores/clientes/SaldoCliente/TextoSaldoCliente/TextoSaldoCliente";
import * as actions from "../../store/actions";
import { Grid } from "@material-ui/core";
import PieFacturasPendientes from "../../components/Indicadores/ventas/PieFacturasPendientes/PieFacturasPendientes";
import TablaFacturasPendientes from "../../components/Indicadores/ventas/TablaFacturasPendientes/TablaFacturasPendientes";
import TablaPedidosPendientes from "../../components/Indicadores/ventas/TablaPedidosPendientes/TablaPedidosPendientes";
import TextoChequesRechazados from "../../components/Indicadores/cheques/TextoChequesRechazados/TextoChequesRechazados";
import TextoCantidadVentas from "../../components/Indicadores/ventas/TextoCantidadVentas/TextoCantidadVentas";
import LineEvolucionVentasDelVendedor from "../../components/Indicadores/vendedores/LineEvolucionVentasDelVendedor/LineEvolucionVentasDelVendedor";
import { storageClientId, storageVendedorId, storageMonedaId, storageMonedaSigno } from "../../assets/shared/sessionData";
import TextoCreditoDisponible from "../../components/Indicadores/clientes/TextoCreditoDisponible/TextoCreditoDisponible";
import TextoCreditoMaximo from "../../components/Indicadores/clientes/TextoCreditoMaximo/TextoCreditoMaximo";
import TableComprobantesCtaCte from "../../components/Indicadores/clientes/CuentaCorriente/TableComprobantes/TableComprobantesCtaCte";
import TextoChequesEnCartera from "../../components/Indicadores/cheques/TextoChequesEnCartera/TextoChequesEnCartera";
import Dialog from '../../components/UI/Dialog/Dialog';
import LineChequesEnCartera from "../../components/Indicadores/cheques/LineCheques/LineChequesEnCartera";
import TablaChequesEnCartera from "../../components/Indicadores/cheques/TablaCheques/TablaChequesEnCartera";
import TablaChequesRechazados from "../../components/Indicadores/cheques/TablaCheques/TablaChequesRechazados";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px', 
    }
  },
  containerSaldoCheques: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px',
      paddingLeft: '9px',
      marginTop: '5px', 
    }
  },
  containerCreditoSaldoCheques: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px',
      paddingLeft: '0px',
    }
  },
  containerCuentaCorriente: {
    marginTop: '10px', 
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px',
      paddingLeft: '0px',
      marginTop: '5px', 
    },  
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw',
    }
  },
  combinedChartsCard: {
    display: 'inline',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      margin: '5px 0px 0px 0px',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      backgroundColor: 'white',
      boxShadow: '0px 3px 6px #00000029',
      paddingRight: '0px'
    }
  },
  gridItem: {   
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },
  gridItemSaldoCheques: {   
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingInline: '5px'
    }
  },
  combinedChartsCardPie: {  
    [theme.breakpoints.down('md')]: {
      marginBottom: '10px',
    },
  },
  combinedChartsCardTabla: {  
    [theme.breakpoints.down('md')]: {
      marginTop: '10px',
    },
  },
  tablaDeuda: {  
    margin: '0px',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '5px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '5px 0px 5px 0px',
    }
  },
  pieDeuda: {
    margin: '0px',
    [theme.breakpoints.down('md')]: {
      marginTop: '5px',
      marginBottom: '10px',
      backgroundColor: 'white',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      boxShadow: '0px 3px 6px #00000029',
    }
  },
  tablaSeleccion: {  
    marginTop: '5px',
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },
  lineEvolucion: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px', 
      paddingLeft: '9px',
      minHeight: '310px',
    },
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }    
  },
  ordenesDeCompra: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '10px', 
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '5px',
    } 
  },
  cardProveedor: { 
    [theme.breakpoints.up('sm')]: {
      marginBottom: '10px', 
    },
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },  
  creditoSaldoCheques: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px', 
      marginTop: '5px',
    },
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }    
  },
}));

const EstadoCliente = (props) => {
  sessionStorage.setItem('seccionNombre', 'Cliente');    
  const inPhone = window.screen.width <= 750;
  const classes = useStyles(); 

  const {  onLoadChart, ejecucionClientes, estaCargadoPedidos, estaCargadoFacturas, loadingPedidos, loadingFacturas } = props;

  const idVendedor = storageVendedorId();
  const idCliente = storageClientId();
  const saldoRef = createRef();
  const [dialogState, setDialogState] = useState({ show: false, content: '' });
  
  const [monedaPresentacion] = useState({
    id: storageMonedaId(),
    signo: storageMonedaSigno(),
  });
  
  const showContent = () => {
    switch (dialogState.content) {
      case 'EnCartera':
        return <Fragment>
          <LineChequesEnCartera />
          <br/>
          <TablaChequesEnCartera />
        </Fragment>;
      case 'Rechazados':
        return <Fragment>
          <TablaChequesRechazados />
        </Fragment>;
      default:
        break;
    }
  };

  const onShowDialogClick = (content) => {
    setDialogState({ show: true, content: content });
  };

  const handleCloseDialog = () => {
    setDialogState({ show: false, content: '' });
  };

  useEffect(() => {
    if(ejecucionClientes && idCliente !== null && idCliente !== undefined 
      && !estaCargadoPedidos && !estaCargadoFacturas && !loadingPedidos && !loadingFacturas){
      onLoadChart(idVendedor, idCliente);
    }
  }, [idCliente, idVendedor, ejecucionClientes, onLoadChart, estaCargadoPedidos, estaCargadoFacturas,]);

  const renderFila1 = () => {
    return (
      <Grid item xs={12} md={12} lg={12}
        container className={classes.container}>
        <Grid item xs={12} md={3} lg={3} container>
          <Grid item className={classes.cardProveedor}>
            <CardCliente />
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={classes.gridItem}>
            <TextoVentasMensuales inClientInfo border />
          </Grid>
          <Grid xs={12} md={12} lg={12} className={classes.ordenesDeCompra}>
          <TextoPedidosPendientesComparativo inClientInfo border />
          </Grid>
        </Grid>
        <Grid item xs={12} md={9} lg={9} container>
          <Grid item xs={12} md={12} lg={12} className={classes.lineEvolucion}>
            <LineEvolucionVentasDelVendedor 
              inClientInfo 
              ejecucionPaginas={ejecucionClientes}
              height={295}
              minHeight={295}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} container className={classes.containerSaldoCheques}>
            <Grid item xs={12} md={3} lg={3} className={classes.gridItem}>
              <TextoSaldoCliente />
            </Grid>
            <Grid item xs={12} md={3} lg={3} className={classes.gridItemSaldoCheques}>
              <TextoUltimaCompra />
            </Grid>
            <Grid item xs={12} md={3} lg={3} className={classes.gridItemSaldoCheques}>
              <TextoCantidadVentas title={`Cantidad de ventas`} inClientInfo />
            </Grid>
            <Grid item xs={12} md={3} lg={3} className={classes.gridItem}>
              <TextoPedidosPendientes inClientInfo />
            </Grid>
          </Grid>
        </Grid>
      </Grid>   
    );
  }

  const renderFila2 = () => {
    return (
      <Grid item xs={12} md={12} lg={12}
        container className={classes.combinedChartsCard} >
        <Grid item xs={12} md={3} lg={3} className={classes.combinedChartsCardPie}>
          <EstadoPedidosPendientes noBorder inClientInfo height={inPhone ? "350px" : "100%"} />
        </Grid>
        <Grid item xs={12} md={9} lg={9} className={classes.combinedChartsCardTabla}>
          <TablaPedidosPendientes  inClientInfo />
        </Grid>
      </Grid>
    )
  }

  const renderFila3 = () => {
    return (
      <Grid item xs={12} md={12} lg={12}
        container className={classes.containerCreditoSaldoCheques}>
        <Grid item xs={12} md={3} lg={3}
          container className={classes.creditoSaldoCheques}>
          <TextoCreditoMaximo />
        </Grid>
        <Grid item xs={12} md={9} lg={9}
          container className={classes.containerSaldoCheques}>
          <Grid item xs={12} md={3} lg={3} className={classes.gridItem}>
            <TextoSaldoCliente />
          </Grid>
          <Grid item xs={12} md={3} lg={3} className={classes.gridItemSaldoCheques}>
            <TextoCreditoDisponible />
          </Grid>
          <Grid item xs={12} md={3} lg={3} className={classes.gridItemSaldoCheques}>
            <TextoChequesEnCartera onBodyClick={() => onShowDialogClick('EnCartera')} />
          </Grid>
          <Grid item xs={12} md={3} lg={3} className={classes.gridItem}>
            <TextoChequesRechazados onBodyClick={() => onShowDialogClick('Rechazados')} />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderFila4 = () => {
    return (
      <Grid item xs={12} md={12} lg={12}
        container className={classes.combinedChartsCard}>
        <Grid item xs={12} md={3} lg={3} className={classes.combinedChartsCardPie}>
          <PieFacturasPendientes noBorder inClientInfo height={inPhone ? "350px" : "100%"} />
        </Grid>
        <Grid item xs={12} md={9} lg={9} className={classes.combinedChartsCardTabla}>
          <TablaFacturasPendientes inClientInfo />
        </Grid>
      </Grid>
    )
  }

  const renderFila5 = () => {
    return (
      <Grid item xs={12} md={12} lg={12} className={classes.containerCuentaCorriente}>
        <TableComprobantesCtaCte monedaPresentacion={monedaPresentacion} maxHeight={450} />
      </Grid>      
    )
  }

  return (
    <Fragment>
      <Grid container>
        {renderFila1()}
        {renderFila2()}
        {renderFila3()}
        {renderFila4()}
        {renderFila5()}
      </Grid>      
      <Dialog
        show={dialogState.show}
        onClose={handleCloseDialog}
        body={<div style={{ minWidth: !inPhone && '500px' }}>{showContent()}</div>}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {    
    ejecucionClientes: state.ejecucionPaginas.ejecucionClientes,
    estaCargadoPedidos: state.ventas.comprobantesPedidosPendientes.estaCargadoPorCliente,
    estaCargadoFacturas: state.ventas.comprobantesFacturasPendientes.estaCargadoPorCliente,
    loadingPedidos: state.ventas.comprobantesPedidosPendientes.loading,
    loadingFacturas: state.ventas.comprobantesFacturasPendientes.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idVendedor, idCliente) => {
      dispatch(actions.loadClienteById(idCliente, () => {}));
      dispatch(actions.loadVentasMesComparativo(idCliente));
      dispatch(actions.loadPedidosPendientes(idCliente));
      dispatch(actions.loadPedidosMesComparativo(idCliente));
      dispatch(actions.loadFacturasDelMesInteranual(idCliente));
      dispatch(actions.loadUltimaCompraByCliente(idCliente));
      dispatch(actions.loadFacturasPendientes(idCliente));
      dispatch(actions.loadEstadosPedidosPendientes(idCliente));
      dispatch(actions.loadEvolucionDeVentasMensualDelVendedor(idVendedor, idCliente));
      dispatch(actions.loadVentasPorVendedor(idCliente));
      dispatch(actions.loadChequesEnCartera(idCliente));
      dispatch(actions.loadChequesRechazados(idCliente));
      dispatch(actions.loadListadoChequesEnCartera(idCliente));
      dispatch(actions.loadListadoChequesRechazados(idCliente));
      dispatch(actions.loadLineChequesEnCartera(idCliente));
      dispatch(actions.loadComprobantesPedidosPendientes(idCliente));
      dispatch(actions.loadComprobantesFacturasPendientes(idCliente));
      dispatch(actions.loadCuentaCorrienteCliente(idCliente));
    },    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EstadoCliente);