import * as actionTypes from './actionTypes';

export const cambioPaginaRender = (page) => {
  return {
    type: actionTypes.CAMBIO_PAGINA_RENDER,
    page
  };
};

export const ejecutarFotoDelDia = (ejecucion) => {
  return {
    type: actionTypes.EJECUTAR_FOTO_DEL_DIA,
    ejecucion
  };
};

export const ejecucionClientes = (ejecucion) => {
  return {
    type: actionTypes.EJECUTAR_CLIENTES,
    ejecucion
  };
};

export const ejecucionCatalogo = (ejecucion) => {
  return {
    type: actionTypes.EJECUTAR_CATALOGO,
    ejecucion
  };
};

export const ejecutarTiemposReq = (ejecucion) => {
  return {
    type: actionTypes.EJECUTAR_TIEMPOS_REQ,
    ejecucion
  };
};

export const ejecutarAnalisisVentas = (ejecucion) => {
  return {
    type: actionTypes.EJECUTAR_ANALISIS_VENTAS,
    ejecucion
  };
};