export default class ImpuestoModel {
  alicuotaIvaId;
  baseImponible;
  porcentaje;
  importeIva;

  setFromArticulo(articulo){
    this.alicuotaIvaId = articulo.idAlicuotaIva;
    this.baseImponible = articulo.baseImponible;
    this.porcentaje = articulo.porcentajeIva;
    this.importeIva = articulo.importeIva;
  }

  setFromItem(item){
    let base = item.baseImponible && item.baseImponible.toFixed(2);
    let porc = item.porcentaje && item.porcentaje.toFixed(2);
    let importe = item.importeIva && item.importeIva.toFixed(2);
    this.alicuotaIvaId = item.alicuotaIvaId;
    this.baseImponible = Number(base);
    this.porcentaje = Number(porc);
    this.importeIva = Number(importe);
  }
}