import axios from "axios";
import { baseUrlDashboard } from "./assets/shared/urlApps";

const instance = axios.create({
  //baseURL: 'http://gausssistemas.serveftp.com:8030/api'
  baseURL: baseUrlDashboard(),
  //baseURL: 'https://dashboard-gauss-api.azurewebsites.net/api'
});

export default instance;
