import React from "react";
import Avatar from "@material-ui/core/Avatar";

import "./AvatarUsuarioLog.css";

const AvatarUsuarioLog = () => {
  const email = sessionStorage.getItem("userEmail");
  var email_analizado = email.split("@");

  return (
    <Avatar style={{ backgroundColor: "#09D4FF" }}>
      {email_analizado
        .map((item) => item.charAt(0).toUpperCase())
        .slice(0, 2)
        .join("")}
    </Avatar>
  );
};

export default AvatarUsuarioLog;
