import React, { useRef } from 'react';

import Chart from 'react-apexcharts';
import Spinner from '../UI/Spinner/Spinner';
import { withRouter } from 'react-router-dom';

import SinResultados from '../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../assets/images/IndicadorDeshabilitado.png';

import classes from './Styles/SmallChartCard.css';
import { formatNumber } from '../../assets/shared/formatNumber';
import { storageMonedaSigno } from '../../assets/shared/sessionData';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton, Tooltip } from '@material-ui/core';
import { colorsChart } from '../../assets/shared/colorsCharts';
import { enviarPorMailOption } from '../UI/EnviarChart/EnviarChartOptions';

const DonutChart = (props) => {
  let chartRef = useRef(null);

  const options = {
    chart: {
      id: 'donut-chart',
      type: 'donut',
      toolbar: {
        show: true,
        tools: {
          download: true,
          customIcons: [
            enviarPorMailOption(chartRef, props.title),
          ]
        }
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          if (props.redirect) {
            const queryParams = props.queryParamsGetter
              ? props.queryParamsGetter(config.seriesIndex, config.dataPointIndex)
              : '';

            props.history.push(props.redirect + queryParams);
          }
        }
      },
      zoom: {
        enabled: false
      }
    },
    colors: props.colors ? props.colors : colorsChart,
    tooltip: {
      y: {
        formatter: function (val) {
          return `${storageMonedaSigno()} ${formatNumber(val)}`;
        }
      }
    },
    legend: {
      position: props.legendPosition ? props.legendPosition : 'bottom',
      offsetY: 0,
    },
    labels: props.labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          }
        }
      }
    ]
  };

  return (
    <div
      ref={chartRef}
      disabled={props.disabled}
      className={props.noBorder ? classes.SmallChartCardNoBorder : classes.SmallChartCard}
      style={{ height: '100%', width: '100%', minHeight: '300px', maxHeight: '380px' }}
    >
      <div style={{ display: 'flex', margin: 'auto', flexDirection: 'column', textAlign: 'left', marginLeft: '20px' }}>
        <div className={classes.TitleCard}>
          <p style={{ fontWeight: 'bold', marginBottom: '1px' }}>{props.title}</p>
          {props.onFiltersClick && !props.disabled && (
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onFiltersClick()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {props.subTitle && <p style={{ marginTop: '1px', fontSize: '12px' }}>{props.subTitle}</p>}
      </div>
      {props.children}
      {props.disabled ? (
        <img alt="Indicador deshabilitado" className={classes.imgIndicadorDeshabilitado} src={IndicadorDeshabilitado} />
      ) : props.loading ? (
        <Spinner />
      ) : props.imgSinDatos ? (
        <img alt="Sin resultados" src={SinResultados} style={{ maxWidth: props.maxWidthImgSinResult }} />
      ) : (
        <Chart
          className={classes.TitleCard}
          options={options}
          series={props.series}
          type="donut"
          height={props.height}
        />
      )}
    </div>
  );
};

export default withRouter(DonutChart);