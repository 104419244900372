import React, { useRef } from 'react';

import Chart from 'react-apexcharts';
import Spinner from '../UI/Spinner/Spinner';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton, Tooltip } from '@material-ui/core';

import classes from './Styles/BigChartCard.css';
import SinResultados from '../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../assets/images/IndicadorDeshabilitado.png';
import '../../index.css';

import { formatNumber } from '../../assets/shared/formatNumber';
import { storageMonedaSigno } from '../../assets/shared/sessionData';
import { colorsChart } from '../../assets/shared/colorsCharts';
import { enviarPorMailOption } from '../UI/EnviarChart/EnviarChartOptions';

const ColumnChart = (props) => {
  let chartRef = useRef(null);

  const options = {
    series: props.series,
    chart: {
      type: 'bar',   
      toolbar: {
        show: true,
        tools: {
          customIcons: [
            enviarPorMailOption(chartRef, props.title),
          ]
        },
      },
      zoom: props.disableZoom
        ? undefined
        : {
            enabled: true,
            type: 'xy',
            autoScaleYaxis: false,
            zoomedArea: {
              fill: {
                color: '#90CAF9',
                opacity: 0.4
              },
              stroke: {
                color: '#0D47A1',
                opacity: 0.4,
                width: 1
              }
            }
          },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          if (props.onPorcionClick) {
            props.onPorcionClick(config);
          }
        }
      }
    },
    colors: props.colors ? props.colors : colorsChart,
    fill: {
      colors: [
        function ({ value, seriesIndex, w }) {
          return w.globals.colors[seriesIndex];
        }
      ]
    },
    tooltip: {
      fillSeriesColor: true,
      x: {
        show: props.tooltipXShow
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    grid: { show: true },
    labels: props.categories,
    legend: {
      fontSize: '14px',
      offsetY: props.legendOffSetY ? props.legendOffSetY : -5,
      height: props.legendHeight,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: props.legendPosition
    },
    yaxis: {
      forceNiceScale: false,
      labels: {
        formatter: function (val) {
          return `${storageMonedaSigno()} ${formatNumber(val)}`;
        }
      }
    },
    xaxis: {
      labels: {
        show: props.xaxisLabelsShow,
        trim: props.xaxisLabelsTrim,
        rotateAlways: props.xaxisLabelsRorateAlways,
        maxHeight: props.xaxisLabelsMaxHeight,
        style: {
          fontSize: props.legendStyleFontSize,
          cssClass: props.legendStyleCssClass,
          fontFamily: 'Roboto'
        }
      },
      categories: props.ids ? props.ids : [],
      tooltip: {
        enabled: false
      },
      tickPlacement: props.disableZoom ? undefined : 'on'
    }
  };

  return (
    <div
      ref={chartRef}
      className={classes.BigChartCard}
      style={{
        height: props.height + 50,
        width: props.width
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginLeft: '20px' }}>
        <div className={classes.TitleCard}>
          <div style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '14px', width: '100%' }}>{props.title}</div>
          {props.onFiltersClick && !props.disabled && (
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onFiltersClick()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {props.subTitle && <p style={{ marginTop: '1px', fontSize: '12px' }}>{props.subTitle}</p>}
        {props.children}
      </div>
      {props.disabled ? (
        <img alt="Indicador deshabilitado" style={{ alignSelf: 'center' }} src={IndicadorDeshabilitado} />
      ) : props.loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      ) : props.imgSinDatos ? (
        <div style={{ textAlign: 'center' }}>
          <img
            alt="Sin resultados"
            src={props.customSinDataImg ? props.customSinDataImg : SinResultados}
            style={{ maxWidth: props.customSinDataImgWidth ? props.customSinDataImgWidth : '400px' }}
          />
        </div>
      ) : (
        <div style={{ marginTop: props.customMarginTop ? props.customMarginTop : '-25px' }}>
          <Chart options={options} series={options.series} type="bar" height={props.height} />
        </div>
      )}
    </div>
  );
};

export default ColumnChart;