import React from 'react';
import ReactExport from 'react-data-export';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@material-ui/core';
import { storageFechaFija, storageMonedaSigno } from '../../../../assets/shared/sessionData';
import { formatNumber } from '../../../../assets/shared/formatNumber';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TablaComparacionVentasToExcel = (props) => {
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value, isTotal) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
      fill: isTotal && { fgColor: { rgb: 'FFD3D3D3' } }
    };
  };

  const formatData = () => {
    var sumMonto1 = 0;
    var sumMonto2 = 0;
    var sumDifMontos = 0;
    var formatData = [];
    props.comprobantes.map((comprobante) => {
      sumMonto1 = sumMonto1 += comprobante.monto1;
      sumMonto2 = sumMonto2 += comprobante.monto2;
      sumDifMontos = sumDifMontos += comprobante.difMontos;
      const inTotal = !comprobante.id;
      return formatData.push([
        {
          value: comprobante.nombre,
          style: { font: { sz: '10', bold: inTotal }, fill: inTotal && { fgColor: { rgb: 'FFD3D3D3' } } }
        },
        {
          value: `${storageMonedaSigno()} ${formatNumber(comprobante.monto1)}`,
          style: cellsStyleVerifNegative(comprobante.monto1, inTotal)
        },
        {
          value: inTotal ? '-' : `${formatNumber(comprobante.porcParticipacion1)} %`,
          style: cellsStyleVerifNegative(comprobante.porcParticipacion1, inTotal)
        },
        {
          value: `${storageMonedaSigno()} ${formatNumber(comprobante.monto2)}`,
          style: cellsStyleVerifNegative(comprobante.monto2, inTotal)
        },
        {
          value: inTotal ? '-' : `${formatNumber(comprobante.porcParticipacion2)} %`,
          style: cellsStyleVerifNegative(comprobante.porcParticipacion2, inTotal)
        },
        {
          value: `${storageMonedaSigno()} ${formatNumber(comprobante.difMontos)}`,
          style: cellsStyleVerifNegative(comprobante.difMontos, inTotal)
        },
        {
          value: `${formatNumber(comprobante.porcComparacion)} %`,
          style: cellsStyleVerifNegative(comprobante.porcComparacion, inTotal)
        }
      ]);
    });

    return formatData;
  };

  const multiDataSet = [
    {
      xSteps: 1,
      columns: [
        { title: props.fechas[0] },
        { title: '' /*style: { border: { right: { style: 'thick', color: 'FF000000' } } }*/ },
        { title: props.fechas[1] },
        { title: '' }
      ],
      data: []
    },
    {
      columns: [
        {
          title: props.ventasPor,
          width: { wch: 40 },
          style: {
            font: { sz: '12', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } }
          }
        },
        {
          title: `Monto`,
          width: { wpx: 100 },
          style: headersStyle
        },
        { title: `Porc. Participación`, width: { wpx: 150 }, style: headersStyle },
        {
          title: `Monto`,
          width: { wpx: 100 },
          style: headersStyle
        }, //char width
        { title: `Porc. Participación`, width: { wpx: 150 }, style: headersStyle },
        { title: `Dif. Montos`, width: { wpx: 100 }, style: headersStyle },
        { title: `Porc. Comparación`, width: { wpx: 150 }, style: headersStyle }
      ],
      data: formatData()
    }
  ];

  return (
    <ExcelFile
      filename={`Analisis por ${props.ventasPor.toLowerCase()} al ${storageFechaFija()}`}
      element={
        <Tooltip title="Descargar en excel">
          <IconButton style={{ marginRight: '10px' }} size="small">
            <img alt="" src={IconConvertToExcel} height={'33px'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet dataSet={multiDataSet} name={`Análisis por ${props.ventasPor.toLowerCase()}`} />
    </ExcelFile>
  );
};

export default TablaComparacionVentasToExcel;
