import React, { Fragment, useState } from "react";
import Pedido from "../Pedido/Pedido";
import Confirmacion from "../Pedido/Confirmacion";
import CatalogoCliente from "../../components/Catalogo/CatalogoCliente/CatalogoCliente";

const NuevoPedido = (props) => {
  const [catalogoActivo, setCatalogoActivo] = useState(true);
  const [pedidoActivo, setPedidoActivo] = useState(false);
  const [confirmacionActivo, setConfirmacionActivo] = useState(false);

  const onIrAPedido = () => {
    setCatalogoActivo(false);
    setPedidoActivo(true);
    setConfirmacionActivo(false);
  }

  const onIrACatalogo = () => {
    setCatalogoActivo(true);
    setPedidoActivo(false);
    setConfirmacionActivo(false);
  }

  const onIrAConfirmacion = () => {
    setCatalogoActivo(false);
    setPedidoActivo(false);
    setConfirmacionActivo(true);
  }

  return catalogoActivo 
    ? <CatalogoCliente
        ejecucionClientes={props.ejecucionClientes}
        onIrAPedido={() => onIrAPedido()}
      />
    : pedidoActivo 
      ? <Pedido 
          onIrACatalogo={onIrACatalogo}
          onIrAConfirmacion={() => onIrAConfirmacion()}
        />
      : confirmacionActivo
        ? <Confirmacion 
            onIrACatalogo={() => onIrACatalogo()}
          />
        : <Fragment />
}

export default NuevoPedido;