import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import axiosVendedores from '../../axios-vendedores';
import { storageEmpresaId, storageListaPreciosIdCliente } from "../../assets/shared/sessionData";

function BuscarArticulosHook(articulo, marca, rubro, subrubro, offset, reset, onSuccess) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [resultado, setResultado] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [limit] = useState(10);

  useEffect(() => {
    setResultado([]);
  }, []);

  const sendQuery = useCallback((articulo, marca, rubro, subrubro, offset) => {
    const buscar = (articulo !== null && articulo !== undefined && articulo !== '')
    || (marca !== null && marca !== undefined && marca !== '')    
    || (rubro !== null && rubro !== undefined && rubro !== '')
    || (subrubro !== null && subrubro !== undefined && subrubro !== '');

    if(buscar){
      const CancelToken = axios.CancelToken;
      let cancel;
  
      setIsLoading(true);
      setError(false);
  
      const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
      const idEmpresa = storageEmpresaId();
      const idListaPrecio = storageListaPreciosIdCliente();
      const offsetParam = offset ? `&offset=${offset.trimEnd()}` : '';
      const marcaParam = marca ? `&marcaId=${marca}` : '';
      const rubroParam = rubro ? `&rubroId=${rubro}` : '';
      const subrubroParam = subrubro ? `&subrubroId=${subrubro}` : '';
  
      axiosVendedores
        .get(
          `/articulos/BusquedaArticuloPedido?idEmpresa=${idEmpresa}&busqueda=${articulo
          }&listaPrecioId=${idListaPrecio}${marcaParam}${rubroParam}${subrubroParam}&limit=${limit}${offsetParam}&next=true`,
          {
            headers: { Authorization: authStr },
            cancelToken: new CancelToken((c) => (cancel = c))
          }
        )
        .then((response) => {
          const totalCount = response.data ? response.data.total : 0;
          const articulos = response.data ? response.data.articulos : [];

          if(reset)
            setResultado(articulos);
          else
            setResultado((prev) => { return [...new Set([...prev, ...articulos])]; });

          setHasMore(totalCount <= limit ? false : resultado.length < totalCount);
          setIsLoading(false);
          onSuccess();
        })
        .catch((err) => {
          if (axios.isCancel(err)) return;
          setError(err);
        });
  
      return () => cancel();
    }else{
      setIsLoading(false);
      setError(false);
      setResultado([]);
      setHasMore(false);
    }
  }, [articulo, offset]);

  useEffect(() => {
    sendQuery(articulo, marca, rubro, subrubro, offset);
  }, [articulo, marca, rubro, subrubro, offset, sendQuery]);

  const data = { isLoading, error, resultado, hasMore };
  return data;
}

export default BuscarArticulosHook;