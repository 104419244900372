// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultipleEmails__input__2E9CZ {\r\n  width: 100%;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.MultipleEmails__input__2E9CZ:focus {\r\n  outline: none;\r\n}\r\n\r\n.MultipleEmails__input__2E9CZ.MultipleEmails__hasError__1_OAt {\r\n  border-color: red;\r\n}\r\n\r\n.MultipleEmails__error__SKO_K {\r\n  margin: 0;\r\n  font-size: 90%;\r\n  color: red;\r\n}\r\n\r\n.MultipleEmails__tagItem__10q3k {\r\n  background-color: #d4d5d6;\r\n  display: inline-block;\r\n  font-size: 14px;\r\n  border-radius: 30px;\r\n  height: 30px;\r\n  padding: 0 4px 0 1rem;\r\n  display: inline-flex;\r\n  align-items: center;\r\n  margin: 0 0.3rem 0.3rem 0;\r\n}\r\n\r\n.MultipleEmails__tagItem__10q3k > .MultipleEmails__button__1kV1N {\r\n  background-color: white;\r\n  width: 22px;\r\n  height: 22px;\r\n  border-radius: 50%;\r\n  border: none;\r\n  cursor: pointer;\r\n  font: inherit;\r\n  margin-left: 10px;\r\n  font-weight: bold;\r\n  padding: 0;\r\n  line-height: 1;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "MultipleEmails__input__2E9CZ",
	"hasError": "MultipleEmails__hasError__1_OAt",
	"error": "MultipleEmails__error__SKO_K",
	"tagItem": "MultipleEmails__tagItem__10q3k",
	"button": "MultipleEmails__button__1kV1N"
};
export default ___CSS_LOADER_EXPORT___;
