import React from "react";
import { connect } from "react-redux";
import TextChart from "../../../Charts/TextChart";

const TextoChequesRechazados = (props) => {
  const {
    monto,
    cantidadCheques,
    loading,
    inClientInfo,
    clientSelected,
    error,
  } = props;

  return (
    <TextChart
      title={`Cheques rechazados`}
      bigAmount={monto}
      smallAmount={cantidadCheques}
      smallAmountTooltip={`Cant. cheques`}
      bigCurrency
      noClient={!clientSelected && inClientInfo}
      loading={loading}
      imgSinDatos={error}
      onBodyClick={() => props.onBodyClick()}
      cursorPointer
    />
  );
};

const mapStateToProps = (state) => {
  return {
    monto: state.cheques.chequesRechazados.monto,
    cantidadCheques: state.cheques.chequesRechazados.cantidadCheques,
    loading: state.cheques.chequesRechazados.loading,
    error: state.cheques.chequesRechazados.error,
    clientSelected: state.clientes.clienteById.clientSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoChequesRechazados);
