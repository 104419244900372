import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import TextChart from "../../../Charts/TextChart";

import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc,
} from "../../../../assets/shared/fechas";

const TextoPedidosPendientesComparativo = (props) => {
  const {
    onLoadChart,
    totalMesAct,
    totalMesAnt,
    loading,
    loadingPorCliente,
    estaCargado,
    totalMesActualPorCliente,
    totalMesAnteriorPorCliente,
    inClientInfo,
    clientSelected,
    error,
  } = props;

  const vendedorSelected = sessionStorage.getItem("vendedorId");
  const chartSinDatos = error || (!inClientInfo && !vendedorSelected);

  useEffect(() => {
    if (!inClientInfo && !estaCargado && vendedorSelected) {
      onLoadChart();
    }
  }, [onLoadChart, inClientInfo, estaCargado, vendedorSelected]);

  const fechaTitulo = `${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()}`;

  return (
    <TextChart
      title={`Pedidos del mes ${fechaTitulo}`}
      bigAmount={inClientInfo ? totalMesActualPorCliente : totalMesAct}
      bigAmountTooltip={`Período ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} `}
      smallAmount={inClientInfo ? totalMesAnteriorPorCliente : totalMesAnt}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} al ${fechaFinPeriodoMesAnteriorFunc()}`}
      loading={inClientInfo ? loadingPorCliente : loading}
      noClient={!clientSelected && inClientInfo}
      imgSinDatos={chartSinDatos}
      cursorPointer={!clientSelected || chartSinDatos}
      bigCurrency
      smallCurrency
      porcentaje
      border={props.border && !props.error && !chartSinDatos}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.pedidosMesComparativo.loading,
    loadingPorCliente: state.ventas.pedidosMesComparativo.loadingPorCliente,
    error: state.ventas.pedidosMesComparativo.error,
    clientSelected: state.clientes.clienteById.clientSelected,
    estaCargado: state.ventas.pedidosMesComparativo.estaCargado,
    transMesAnt: state.ventas.pedidosMesComparativo.transMesAnt,
    totalMesAnt: state.ventas.pedidosMesComparativo.totalMesAnt,
    transMesAct: state.ventas.pedidosMesComparativo.transMesAct,
    totalMesAct: state.ventas.pedidosMesComparativo.totalMesAct,
    porcTrans: state.ventas.pedidosMesComparativo.porcTrans,
    porcTotal: state.ventas.pedidosMesComparativo.porcTotal,
    totalMesActualPorCliente: state.ventas.pedidosMesComparativo.totalMesActualPorCliente,
    totalMesAnteriorPorCliente: state.ventas.pedidosMesComparativo.totalMesAnteriorPorCliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChart: (idCliente) => dispatch(actions.loadPedidosMesComparativo(idCliente)),
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Pedidos del mes', ['pedido']))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextoPedidosPendientesComparativo);