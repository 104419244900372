import React from 'react';
import Dialog from '../../UI/Dialog/Dialog';

const ModalQueryReq = (props) => {
  return (
    <Dialog show={props.show} body={<div style={{ maxWidth: '1000px' }}>{props.query}</div>} onClose={props.onClose} />
  );
};

export default ModalQueryReq;
