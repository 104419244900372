import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import authReducer from "./store/reducers/auth";
import snackbarReducer from "./store/reducers/snackbar";
import ventasReducer from "./store/reducers/ventas";
import vendedoresReducer from "./store/reducers/vendedores";
import clientesReducer from "./store/reducers/clientes";
import chequesReducer from "./store/reducers/cheques";
import configuracionesReducer from "./store/reducers/configuraciones";
import empresasReducer from "./store/reducers/empresas";
import changeLogsReducer from "./store/reducers/changeLogs";
import articulosReducer from "./store/reducers/articulos";
import ultimasActualizacionesReducer from "./store/reducers/ultimasActualizaciones";
import modalsReducer from "./store/reducers/modals";
import monedasReducer from "./store/reducers/monedas";
import comprobantesReducer from "./store/reducers/comprobantes";
import ejecucionPaginasReducer from './store/reducers/ejecucionPaginas';

const appReducer = combineReducers({
  auth: authReducer,
  snackbar: snackbarReducer,
  ventas: ventasReducer,
  vendedores: vendedoresReducer,
  clientes: clientesReducer,
  cheques: chequesReducer,
  configuraciones: configuracionesReducer,
  empresas: empresasReducer,
  changeLogs: changeLogsReducer,
  articulos: articulosReducer,
  ultimasActualizaciones: ultimasActualizacionesReducer,
  monedas: monedasReducer,
  modals: modalsReducer,
  comprobantes: comprobantesReducer,
  ejecucionPaginas: ejecucionPaginasReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;