import * as actionTypes from "./actionTypes";
import axiosDashboard from "../../axios-dashboard";
import idIndicadores from "../../assets/shared/idIndicadores";
import { storageEmpresaId, storageMonedaId } from "../../assets/shared/sessionData";

export const loadChequesRechazados = (idCliente) => {
  return (dispatch) => {
    dispatch(loadChequesRechazadosStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosDashboard
      .get(
        `/cheques/ChequesRechazados?idCliente=${idCliente}&idEmpresa=${parseInt(
          sessionStorage.getItem("empresaId"),
          10
        )}&indicadorId=${idIndicadores.chequesRechazados}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const { monto, cantidadCheques } = response.data;
        if (response.status === 204) {
          dispatch(loadChequesRechazadosSuccess(0, 0));
        } else {
          dispatch(loadChequesRechazadosSuccess(monto, cantidadCheques));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadChequesRechazadosFail(error));
          } else {
            dispatch(loadChequesRechazados(idCliente));
          }
        } else {
          dispatch(loadChequesRechazadosFail(error));
        }
      });
  };
};

export const clearChequesRechazados = () => {
  return {
    type: actionTypes.CLEAR_CHEQUES_RECHAZADOS,
  };
};

const loadChequesRechazadosStart = () => {
  return {
    type: actionTypes.LOAD_CHEQUES_RECHAZADOS_START,
  };
};

const loadChequesRechazadosSuccess = (monto, cantidadCheques) => {
  return {
    type: actionTypes.LOAD_CHEQUES_RECHAZADOS_SUCCESS,
    monto,
    cantidadCheques,
  };
};

const loadChequesRechazadosFail = (error) => {
  return {
    type: actionTypes.LOAD_CHEQUES_RECHAZADOS_FAIL,
    error,
  };
};

export const loadListadoChequesRechazados = (idCliente) => {
  return (dispatch) => {
    dispatch(loadListadoChequesRechazadosStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));    
    const idEmpresa = storageEmpresaId();
    const idMoneda = storageMonedaId();

    axiosDashboard
      .get(
        `/cheques/Detalle?idMoneda=${idMoneda}&idCliente=${idCliente}&propios=${false}&rechazados=${true}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.listadoChequesRechazados}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {        
        dispatch(loadListadoChequesRechazadosSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadListadoChequesRechazadosFail(error));
          } else {
            dispatch(loadListadoChequesRechazados(idCliente));
          }
        } else {
          dispatch(loadListadoChequesRechazadosFail(error));
        }
      });
  };
};

export const clearListadoChequesRechazados = () => {
  return {
    type: actionTypes.CLEAR_LISTADO_CHEQUES_RECHAZADOS,
  };
};

const loadListadoChequesRechazadosStart = () => {
  return {
    type: actionTypes.LOAD_LISTADO_CHEQUES_RECHAZADOS_START,
  };
};

const loadListadoChequesRechazadosSuccess = (cheques) => {
  return {
    type: actionTypes.LOAD_LISTADO_CHEQUES_RECHAZADOS_SUCCESS,
    cheques,
  };
};

const loadListadoChequesRechazadosFail = (error) => {
  return {
    type: actionTypes.LOAD_LISTADO_CHEQUES_RECHAZADOS_FAIL,
    error,
  };
};

export const loadChequesEnCartera = (idCliente) => {
  return (dispatch) => {
    dispatch(loadChequesEnCarteraStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosDashboard
      .get(
        `/cheques/ChequesEnCarteraTotal?idCliente=${idCliente}&idEmpresa=${parseInt(
          sessionStorage.getItem("empresaId"),
          10
        )}&indicadorId=${idIndicadores.chequesEnCartera}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        let monto = 0;
        let cantidadCheques = 0;
        response.data.map((cheque) => {
          monto += cheque.monto;
          cantidadCheques += cheque.cantidadCheques;
        });
        if (response.status === 204) {
          dispatch(loadChequesEnCarteraSuccess(0, 0));
        } else {
          dispatch(loadChequesEnCarteraSuccess(monto, cantidadCheques));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadChequesEnCarteraFail(error));
          } else {
            dispatch(loadChequesEnCartera(idCliente));
          }
        } else {
          dispatch(loadChequesEnCarteraFail(error));
        }
      });
  };
};

export const clearChequesEnCartera = () => {
  return {
    type: actionTypes.CLEAR_CHEQUES_EN_CARTERA,
  };
};

const loadChequesEnCarteraStart = () => {
  return {
    type: actionTypes.LOAD_CHEQUES_EN_CARTERA_START,
  };
};

const loadChequesEnCarteraSuccess = (monto, cantidadCheques) => {
  return {
    type: actionTypes.LOAD_CHEQUES_EN_CARTERA_SUCCESS,
    monto,
    cantidadCheques,
  };
};

const loadChequesEnCarteraFail = (error) => {
  return {
    type: actionTypes.LOAD_CHEQUES_EN_CARTERA_FAIL,
    error,
  };
};

export const loadListadoChequesEnCartera = (idCliente) => {
  return (dispatch) => {
    dispatch(loadListadoChequesEnCarteraStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idEmpresa = storageEmpresaId();
    const idMoneda = storageMonedaId();

    axiosDashboard
      .get(
        `/cheques/Detalle?idMoneda=${idMoneda}&idCliente=${idCliente}&propios=${false}&rechazados=${false}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.listadoChequesEnCartera}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadListadoChequesEnCarteraSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadListadoChequesEnCarteraFail(error));
          } else {
            dispatch(loadListadoChequesEnCartera(idCliente));
          }
        } else {
          dispatch(loadListadoChequesEnCarteraFail(error));
        }
      });
  };
};

export const clearListadoChequesEnCartera = () => {
  return {
    type: actionTypes.CLEAR_LISTADO_CHEQUES_EN_CARTERA,
  };
};

const loadListadoChequesEnCarteraStart = () => {
  return {
    type: actionTypes.LOAD_LISTADO_CHEQUES_EN_CARTERA_START,
  };
};

const loadListadoChequesEnCarteraSuccess = (cheques) => {
  return {
    type: actionTypes.LOAD_LISTADO_CHEQUES_EN_CARTERA_SUCCESS,
    cheques,
  };
};

const loadListadoChequesEnCarteraFail = (error) => {
  return {
    type: actionTypes.LOAD_LISTADO_CHEQUES_EN_CARTERA_FAIL,
    error,
  };
};

export const clearComprobantesCheques = () => {
  return {
    type: actionTypes.CLEAR_COMPROBANTES_CHEQUES,
  };
};

export const loadComprobantesCheques = (idEmpresa, rechazados) => {
  return (dispatch) => {
    dispatch(loadComprobantesChequesStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const headers = { 'Authorization': authStr, 'Id-Empresa': idEmpresa, }

    axiosDashboard
      .get(
        `/cheques/comprobantes?idEmpresa=${idEmpresa}&rechazados=${rechazados}&idIndicador=${idIndicadores.comprobantesCheques}`,
        {
          headers: headers,
        })
      .then((response) => {
        dispatch(loadComprobantesChequesSuccess(response.data));
      })
      .catch((error) => {  
        if (error.response && error.response.data){          
          console.log(error.response.data);
        }
        
        dispatch(loadComprobantesChequesFail(error));
      });
  };
};

const loadComprobantesChequesStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_CHEQUES_START,
  };
};

const loadComprobantesChequesSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_CHEQUES_SUCCESS,
    comprobantes,
  };
};

const loadComprobantesChequesFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_CHEQUES_FAIL,
    error,
  };
};

export const loadLineChequesEnCartera = (idCliente) => {
  return (dispatch) => {
    dispatch(loadLineChequesEnCarteraStart());
    
    axiosDashboard
      .get(
        `/cheques/ChequesEnCarteraEvolutivo?idMoneda=${storageMonedaId()}&idCliente=${
          idCliente}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.lineChequesEnCartera}`
      )
      .then((response) => {
        let formatChequesEnCartera = [];
        let totalCheques = 0.0;
        response.data.map(
          (chequeVencido) =>
            chequeVencido.estado === 'Vencido' &&
            chequeVencido.chequesMensuales.length !== 0 &&
            formatChequesEnCartera.push({
              valor: chequeVencido.chequesMensuales[0].monto,
              mes: chequeVencido.estado
            })
        );

        response.data.map(
          (chequeAVencer) =>
            chequeAVencer.estado === 'A vencer' &&
            chequeAVencer.chequesMensuales.map((chequeMensual) =>
              formatChequesEnCartera.push({
                valor: chequeMensual.monto,
                mes: chequeMensual.mes,
                anio: chequeMensual.anio
              })
            )
        );

        formatChequesEnCartera.map((cheque) => {
          return (totalCheques += cheque.valor);
        });
        dispatch(loadLineChequesEnCarteraSuccess(formatChequesEnCartera, totalCheques));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadLineChequesEnCarteraFail(error));
          } else {
            //dispatch(loadLineChequesEnCartera(idCliente));
          }
        } else {
          dispatch(loadLineChequesEnCarteraFail(error));
        }
      });
  };
};

export const clearLineChequesEnCartera = () => {
  return {
    type: actionTypes.CLEAR_LINE_CHEQUES_EN_CARTERA
  };
};

const loadLineChequesEnCarteraStart = () => {
  return {
    type: actionTypes.LINE_CHEQUES_EN_CARTERA_START
  };
};

const loadLineChequesEnCarteraSuccess = (cheques, totalCheques) => {
  return {
    type: actionTypes.LINE_CHEQUES_EN_CARTERA_SUCCESS,
    cheques,
    totalCheques
  };
};

const loadLineChequesEnCarteraFail = (error) => {
  return {
    type: actionTypes.LINE_CHEQUES_EN_CARTERA_FAIL,
    error
  };
};

export const loadLineChequesRechazados = (idCliente) => {
  return (dispatch) => {
    dispatch(loadLineChequesRechazadosStart());

    axiosDashboard
      .get(
        `/cheques/ChequesRechazadosEvolutivo?idMoneda=${storageMonedaId()}&idCliente=${
          idCliente}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.lineChequesRechazados}`
      )
      .then((response) => {
        let formatChequesRechazados = [];
        let totalCheques = 0.0;
        response.data.map(
          (chequeVencido) =>
            chequeVencido.estado === 'Vencido' &&
            chequeVencido.chequesMensuales.length !== 0 &&
            formatChequesRechazados.push({
              valor: chequeVencido.chequesMensuales[0].monto,
              mes: chequeVencido.estado
            })
        );

        response.data.map(
          (chequeAVencer) =>
            chequeAVencer.estado === 'A vencer' &&
            chequeAVencer.chequesMensuales.map((chequeMensual) =>
              formatChequesRechazados.push({
                valor: chequeMensual.monto,
                mes: chequeMensual.mes,
                anio: chequeMensual.anio
              })
            )
        );

        formatChequesRechazados.map((cheque) => {
          return (totalCheques += cheque.valor);
        });
        dispatch(loadLineChequesRechazadosSuccess(formatChequesRechazados, totalCheques));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadLineChequesRechazadosFail(error));
          } else {
            dispatch(loadLineChequesRechazados(idCliente));
          }
        } else {
          dispatch(loadLineChequesRechazadosFail(error));
        }
      });
  };
};

export const clearLineChequesRechazados = () => {
  return {
    type: actionTypes.CLEAR_LINE_CHEQUES_RECHAZADOS
  };
};

const loadLineChequesRechazadosStart = () => {
  return {
    type: actionTypes.LINE_CHEQUES_RECHAZADOS_START
  };
};

const loadLineChequesRechazadosSuccess = (cheques, totalCheques) => {
  return {
    type: actionTypes.LINE_CHEQUES_RECHAZADOS_SUCCESS,
    cheques,
    totalCheques
  };
};

const loadLineChequesRechazadosFail = (error) => {
  return {
    type: actionTypes.LINE_CHEQUES_RECHAZADOS_FAIL,
    error
  };
};