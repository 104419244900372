import React from "react";
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function NumberFormatCustom(props) {
  const { inputRef, name, onChange, max, min, decimalScale, ...other } = props;

  const withValueLimit = ({ floatValue }) => {
    if(min !== null && min !== undefined && max !== null && max !== undefined){
      return floatValue >= min && floatValue <= max;
    }

    return true;
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={false}
      isNumericString={true}
      allowNegative={false}
      decimalScale={decimalScale}
      fixedDecimalScale={true}
      isAllowed={withValueLimit}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumberFormatCustom;