import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";
import { fechaActualFunc } from '../../assets/shared/fechas';

const initialState = {
  vendedorSelected: {
    idVendedor: '',
    nombre: '',
    mail: '',
    comision: 0,
    bonificacionMaxima: 0,
  },  
  loadingCambiarVendedor: false,
  dataEvolucionVentasDelVendedorSelected: 0,
  dataVentasDelMesDelVendedorPorSelected: 'categoriaDeCliente',
  dataVentasDelMesDeVendedoresPorSelected: 'categoriaDeCliente',
  fechaVentasDelMesAnioDelVendedor: {
    mes: fechaActualFunc().getMonth(),
    anio: fechaActualFunc().getFullYear()
  },
  fechaVentasDelMesAnioDeVendedores: {
    mes: fechaActualFunc().getMonth(),
    anio: fechaActualFunc().getFullYear()
  },
  vendedores: {
    vendedores: [],
    loading: false,
    error: null,
    estaCargado: false,
  },
  comisionComparativo: {
    loading: false,
    mesActual: 0,
    mesAnterior: 0,
    porcentajeDiferencia: 0,
    error: null,
    estaCargado: false,
  },
  comisionEvolutivo: {
    loading: false,
    error: null,
    comisiones: [],
    meses: [],
    estaCargado: false,
  },
  evolucionVentasMensualDelVendedor: {
    ventas: [],
    ventasDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    ventasPorCliente: [],
    ventasDataTablePorCliente: [],
    totalPorCliente: 0,
    loadingPorCliente: false,
    estaCargadoPorCliente: false,
    error: null
  },
  ventasDelMesPorCategoriaDeClienteDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorMarcaDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorRubroDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorSubrubroDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorProvinciasDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  ventasDelMesPorZonasDelVendedor: {
    ventas: [],
    ventasNegativo: [],
    ventasDataTable: [],
    fecha: '',
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  evolucionVentasDelVendedorPorCategoriaDeCliente: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDelVendedorPorProvincia: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDelVendedorPorZonas: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDelVendedorPorMarca: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDelVendedorPorRubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasDelVendedorPorSubrubro: {
    ventas: [],
    fechas: [],
    total: 0,
    loading: false,
    error: null,
    estaCargado: false
  },
  evolucionVentasMensualDeVendedores: {
    ventas: [],
    ventasDataTable: [],
    totalVentas: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
};

//#region loadVendedores
const loadVendedoresStart = (state) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: true,
    }),
  });
};

const loadVendedoresSuccess = (state, vendedores) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: false,
      vendedores,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadVendedoresFail = (state, error) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: false,
      error,
    }),
  });
};
//#endregion

//#region loadComisionesComparativo
const loadComisionesComparativoStart = (state) => {
  return updateObject(state, {
    comisionComparativo: updateObject(state.comisionComparativo, {
      loading: true,
    }),
  });
};

const loadComisionesComparativoSuccess = (
  state,
  mesActual,
  mesAnterior,
  porcentajeDiferencia
) => {
  return updateObject(state, {
    comisionComparativo: updateObject(state.comisionComparativo, {
      loading: false,
      mesActual,
      mesAnterior,
      porcentajeDiferencia,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadComisionesComparativoFail = (state, error) => {
  return updateObject(state, {
    comisionComparativo: updateObject(state.comisionComparativo, {
      loading: false,
      error,
    }),
  });
};

const clearComisionesComparativo = (state) => {
  return updateObject(state, {
    comisionComparativo: updateObject(state.comisionComparativo, {
      loading: false,
      mesActual: 0,
      mesAnterior: 0,
      porcentajeDiferencia: 0,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

//#region loadComisionesEvolutivo
const loadComisionesEvolutivoStart = (state) => {
  return updateObject(state, {
    comisionEvolutivo: updateObject(state.comisionEvolutivo, {
      loading: true,
    }),
  });
};

const loadComisionesEvolutivoSuccess = (state, comisiones, meses) => {
  return updateObject(state, {
    comisionEvolutivo: updateObject(state.comisionEvolutivo, {
      loading: false,
      estaCargado: true,
      comisiones,
      meses,
      error: null,
    }),
  });
};

const loadComisionesEvolutivoFail = (state, error) => {
  return updateObject(state, {
    comisionEvolutivo: updateObject(state.comisionEvolutivo, {
      loading: false,
      error,
    }),
  });
};

const clearComisionesEvolutivo = (state) => {
  return updateObject(state, {
    comisionEvolutivo: updateObject(state.comisionEvolutivo, {
      loading: false,
      error: null,
      comisiones: [],
      meses: [],
      estaCargado: false,
    }),
  });
};
//#endregion

//#region load evolucion de ventas
const loadEvolucionDeVentasMensualDelVendedorStart = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      ventas: [],
      ventasDataTable: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadEvolucionDeVentasMensualDelVendedorPorClienteStart = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      ventasPorCliente: [],
      ventasDataTablePorCliente: [],
      totalPorCliente: 0,
      loadingPorCliente: true,
      estaCargadoPorCliente: false,
      error: null
    })
  });
};

const loadEvolucionDeVentasMensualDelVendedorSuccess = (state, ventas, ventasDataTable, total) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      ventas,
      ventasDataTable,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionDeVentasMensualDelVendedorPorClienteSuccess = (state, ventas, ventasDataTable, total) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      ventasPorCliente: ventas,
      ventasDataTablePorCliente: ventasDataTable,
      totalPorCliente: total,
      loadingPorCliente: false,
      estaCargadoPorCliente: true
    })
  });
};

const loadEvolucionDeVentasMensualDelVendedorFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const loadEvolucionDeVentasMensualDelVendedorPorClienteFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      loadingPorCliente: false,
      errorPorCliente: error,
      estaCargadoPorCliente: true
    })
  });
};

const clearEvolucionDeVentasMensualDelVendedor = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      ventas: [],
      ventasDataTable: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};

const clearEvolucionDeVentasMensualDelVendedorPorCliente = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDelVendedor: updateObject(state.evolucionVentasMensualDelVendedor, {
      ventasPorCliente: [],
      ventasDataTablePorCliente: [],
      totalPorCliente: 0,
      loadingPorCliente: false,
      estaCargadoPorCliente: false,
      error: null
    })
  });
};
//#endregion

//#region load evolucion ventas por categoria de cliente
const loadVentasDelMesPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorCategoriaDeClienteDelVendedor: updateObject(state.ventasDelMesPorCategoriaDeClienteDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadVentasDelMesPorCategoriaDeClienteSuccess = (state, ventas, ventasNegativo, ventasDataTable, fecha, total) => {
  return updateObject(state, {
    ventasDelMesPorCategoriaDeClienteDelVendedor: updateObject(state.ventasDelMesPorCategoriaDeClienteDelVendedor, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorCategoriaDeClienteDelVendedor: updateObject(state.ventasDelMesPorCategoriaDeClienteDelVendedor, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    ventasDelMesPorCategoriaDeClienteDelVendedor: updateObject(state.ventasDelMesPorCategoriaDeClienteDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load  ventas por Marca del vendedor
const loadVentasDelMesPorMarcaDelVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorMarcaDelVendedor: updateObject(state.ventasDelMesPorMarcaDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadVentasDelMesPorMarcaDelVendedorSuccess = (state, ventas, ventasNegativo, ventasDataTable, fecha, total) => {
  return updateObject(state, {
    ventasDelMesPorMarcaDelVendedor: updateObject(state.ventasDelMesPorMarcaDelVendedor, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorMarcaDelVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorMarcaDelVendedor: updateObject(state.ventasDelMesPorMarcaDelVendedor, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorMarcaDelVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesPorMarcaDelVendedor: updateObject(state.ventasDelMesPorMarcaDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load  ventas por Rubro
const loadVentasDelMesPorRubroDelVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorRubroDelVendedor: updateObject(state.ventasDelMesPorRubroDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadVentasDelMesPorRubroDelVendedorSuccess = (state, ventas, ventasNegativo, ventasDataTable, fecha, total) => {
  return updateObject(state, {
    ventasDelMesPorRubroDelVendedor: updateObject(state.ventasDelMesPorRubroDelVendedor, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorRubroDelVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorRubroDelVendedor: updateObject(state.ventasDelMesPorRubroDelVendedor, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorRubroDelVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesPorRubroDelVendedor: updateObject(state.ventasDelMesPorRubroDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load  ventas por Subrubro
const loadVentasDelMesPorSubrubroDelVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorSubrubroDelVendedor: updateObject(state.ventasDelMesPorSubrubroDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadVentasDelMesPorSubrubroDelVendedorSuccess = (
  state,
  ventas,
  ventasNegativo,
  ventasDataTable,
  fecha,
  total
) => {
  return updateObject(state, {
    ventasDelMesPorSubrubroDelVendedor: updateObject(state.ventasDelMesPorSubrubroDelVendedor, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorSubrubroDelVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorSubrubroDelVendedor: updateObject(state.ventasDelMesPorSubrubroDelVendedor, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearVentasDelMesPorSubrubroDelVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesPorSubrubroDelVendedor: updateObject(state.ventasDelMesPorSubrubroDelVendedor, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load ventas del mes por provincias del vendedor
const loadVentasDelMesPorProvinciasDelVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorProvinciasDelVendedor: updateObject(state.ventasDelMesPorProvinciasDelVendedor, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorProvinciasDelVendedorSuccess = (state, ventas, ventasNegativo, fecha, total) => {
  return updateObject(state, {
    ventasDelMesPorProvinciasDelVendedor: updateObject(state.ventasDelMesPorProvinciasDelVendedor, {
      ventas,
      ventasNegativo,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorProvinciasDelVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorProvinciasDelVendedor: updateObject(state.ventasDelMesPorProvinciasDelVendedor, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDelMesPorProvinciasDelVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesPorProvinciasDelVendedor: updateObject(state.ventasDelMesPorProvinciasDelVendedor, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load ventas del mes por zonas
const loadVentasDelMesPorZonasDelVendedorStart = (state) => {
  return updateObject(state, {
    ventasDelMesPorZonasDelVendedor: updateObject(state.ventasDelMesPorZonasDelVendedor, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};

const loadVentasDelMesPorZonasDelVendedorSuccess = (state, ventas, ventasNegativo, ventasDataTable, fecha, total) => {
  return updateObject(state, {
    ventasDelMesPorZonasDelVendedor: updateObject(state.ventasDelMesPorZonasDelVendedor, {
      ventas,
      ventasNegativo,
      ventasDataTable,
      fecha,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadVentasDelMesPorZonasDelVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasDelMesPorZonasDelVendedor: updateObject(state.ventasDelMesPorZonasDelVendedor, {
      error,
      loading: false,
      estaCargado: true,
      fecha: ''
    })
  });
};

const clearVentasDelMesPorZonasDelVendedor = (state) => {
  return updateObject(state, {
    ventasDelMesPorZonasDelVendedor: updateObject(state.ventasDelMesPorZonasDelVendedor, {
      ventas: [],
      ventasNegativo: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null,
      fecha: ''
    })
  });
};
//#endregion

//#region load evolucion ventas por categoria de cliente
const loadEvolucionVentasDelVendedorPorCategoriaDeClienteStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDelVendedorPorCategoriaDeCliente,
      {
        ventas: [],
        fechas: [],
        total: 0,
        loading: true,
        error: null,
        estaCargado: false
      }
    )
  });
};

const loadEvolucionVentasDelVendedorPorCategoriaDeClienteSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDelVendedorPorCategoriaDeCliente,
      {
        ventas,
        fechas,
        total,
        loading: false,
        estaCargado: true
      }
    )
  });
};

const loadEvolucionVentasDelVendedorPorCategoriaDeClienteFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDelVendedorPorCategoriaDeCliente,
      {
        error,
        loading: false,
        estaCargado: true
      }
    )
  });
};

const clearEvolucionVentasDelVendedorPorCategoriaDeCliente = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorCategoriaDeCliente: updateObject(
      state.evolucionVentasDelVendedorPorCategoriaDeCliente,
      {
        ventas: [],
        fechas: [],
        total: 0,
        loading: false,
        error: null,
        estaCargado: false
      }
    )
  });
};
//#endregion

//#region load evolucion ventas por Provincias
const loadEvolucionVentasDelVendedorPorProvinciasStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorProvincia: updateObject(state.evolucionVentasDelVendedorPorProvincia, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDelVendedorPorProvinciasSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorProvincia: updateObject(state.evolucionVentasDelVendedorPorProvincia, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDelVendedorPorProvinciasFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorProvincia: updateObject(state.evolucionVentasDelVendedorPorProvincia, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDelVendedorPorProvincias = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorProvincia: updateObject(state.evolucionVentasDelVendedorPorProvincia, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Zonas
const loadEvolucionVentasDelVendedorPorZonasStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorZonas: updateObject(state.evolucionVentasDelVendedorPorZonas, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDelVendedorPorZonasSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorZonas: updateObject(state.evolucionVentasDelVendedorPorZonas, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDelVendedorPorZonasFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorZonas: updateObject(state.evolucionVentasDelVendedorPorZonas, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDelVendedorPorZonas = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorZonas: updateObject(state.evolucionVentasDelVendedorPorZonas, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Marca
const loadEvolucionVentasDelVendedorPorMarcaStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorMarca: updateObject(state.evolucionVentasDelVendedorPorMarca, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDelVendedorPorMarcaSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorMarca: updateObject(state.evolucionVentasDelVendedorPorMarca, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDelVendedorPorMarcaFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorMarca: updateObject(state.evolucionVentasDelVendedorPorMarca, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDelVendedorPorMarca = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorMarca: updateObject(state.evolucionVentasDelVendedorPorMarca, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Rubro
const loadEvolucionVentasDelVendedorPorRubroStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorRubro: updateObject(state.evolucionVentasDelVendedorPorRubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDelVendedorPorRubroSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorRubro: updateObject(state.evolucionVentasDelVendedorPorRubro, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDelVendedorPorRubroFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorRubro: updateObject(state.evolucionVentasDelVendedorPorRubro, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDelVendedorPorRubro = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorRubro: updateObject(state.evolucionVentasDelVendedorPorRubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion ventas por Subrubro
const loadEvolucionVentasDelVendedorPorSubrubroStart = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorSubrubro: updateObject(state.evolucionVentasDelVendedorPorSubrubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: true,
      error: null,
      estaCargado: false
    })
  });
};

const loadEvolucionVentasDelVendedorPorSubrubroSuccess = (state, ventas, fechas, total) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorSubrubro: updateObject(state.evolucionVentasDelVendedorPorSubrubro, {
      ventas,
      fechas,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionVentasDelVendedorPorSubrubroFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorSubrubro: updateObject(state.evolucionVentasDelVendedorPorSubrubro, {
      error,
      loading: false,
      estaCargado: true
    })
  });
};

const clearEvolucionVentasDelVendedorPorSubrubro = (state) => {
  return updateObject(state, {
    evolucionVentasDelVendedorPorSubrubro: updateObject(state.evolucionVentasDelVendedorPorSubrubro, {
      ventas: [],
      fechas: [],
      total: 0,
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region load evolucion de ventas
const loadEvolucionDeVentasMensualDeVendedoresStart = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDeVendedores: updateObject(state.evolucionVentasMensualDeVendedores, {
      ventas: [],
      ventasDataTable: [],
      totalVentas: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadEvolucionDeVentasMensualDeVendedoresSuccess = (state, ventas, ventasDataTable, totalVentas) => {
  return updateObject(state, {
    evolucionVentasMensualDeVendedores: updateObject(state.evolucionVentasMensualDeVendedores, {
      ventas,
      ventasDataTable,
      totalVentas,
      loading: false,
      estaCargado: true
    })
  });
};

const loadEvolucionDeVentasMensualDeVendedoresFail = (state, error) => {
  return updateObject(state, {
    evolucionVentasMensualDeVendedores: updateObject(state.evolucionVentasMensualDeVendedores, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearEvolucionDeVentasMensualDeVendedores = (state) => {
  return updateObject(state, {
    evolucionVentasMensualDeVendedores: updateObject(state.evolucionVentasMensualDeVendedores, {
      ventas: [],
      ventasDataTable: [],
      totalVentas: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_VENDEDORES_START:
      return loadVendedoresStart(state);
    case actionTypes.LOAD_VENDEDORES_SUCCESS:
      return loadVendedoresSuccess(state, action.vendedores);
    case actionTypes.LOAD_VENDEDORES_FAIL:
      return loadVendedoresFail(state, action.error);

    case actionTypes.LOAD_COMISION_COMPARATIVO_START:
      return loadComisionesComparativoStart(state);
    case actionTypes.LOAD_COMISION_COMPARATIVO_SUCCESS:
      return loadComisionesComparativoSuccess(
        state,
        action.mesActual,
        action.mesAnterior,
        action.porcentajeDiferencia
      );
    case actionTypes.LOAD_COMISION_COMPARATIVO_FAIL:
      return loadComisionesComparativoFail(state, action.error);
    case actionTypes.CLEAR_COMISION_COMPARATIVO:
      return clearComisionesComparativo(state);

    case actionTypes.LOAD_COMISION_EVOLUTIVO_START:
      return loadComisionesEvolutivoStart(state);
    case actionTypes.LOAD_COMISION_EVOLUTIVO_SUCCESS:
      return loadComisionesEvolutivoSuccess(
        state,
        action.comisiones,
        action.meses
      );
    case actionTypes.LOAD_COMISION_EVOLUTIVO_FAIL:
      return loadComisionesEvolutivoFail(state, action.error);
    case actionTypes.CLEAR_COMISION_EVOLUTIVO:
      return clearComisionesEvolutivo(state);

    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_START:
      return loadEvolucionDeVentasMensualDelVendedorStart(state);
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_SUCCESS:
      return loadEvolucionDeVentasMensualDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasDataTable,
        action.totalVentas
      );
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_FAIL:
      return loadEvolucionDeVentasMensualDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_VENTAS_DEL_VENDEDOR:
      return clearEvolucionDeVentasMensualDelVendedor(state);

    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE_START:
      return loadEvolucionDeVentasMensualDelVendedorPorClienteStart(state);
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE_SUCCESS:
      return loadEvolucionDeVentasMensualDelVendedorPorClienteSuccess(
        state,
        action.ventas,
        action.ventasDataTable,
        action.totalVentas
      );
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE_FAIL:
      return loadEvolucionDeVentasMensualDelVendedorPorClienteFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE:
      return clearEvolucionDeVentasMensualDelVendedorPorCliente(state);

    case actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_START:
      return loadVentasDelMesPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorCategoriaDeClienteSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR:
      return clearVentasDelMesPorCategoriaDeCliente(state);
  
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_START:
      return loadVentasDelMesPorMarcaDelVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorMarcaDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorMarcaDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR:
      return clearVentasDelMesPorMarcaDelVendedor(state);
  
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_START:
      return loadVentasDelMesPorRubroDelVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorRubroDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorRubroDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR:
      return clearVentasDelMesPorRubroDelVendedor(state);
  
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_START:
      return loadVentasDelMesPorSubrubroDelVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorSubrubroDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorSubrubroDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR:
      return clearVentasDelMesPorSubrubroDelVendedor(state);
  
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_START:
      return loadVentasDelMesPorProvinciasDelVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorProvinciasDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorProvinciasDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR:
      return clearVentasDelMesPorProvinciasDelVendedor(state);
  
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_START:
      return loadVentasDelMesPorZonasDelVendedorStart(state);
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_SUCCESS:
      return loadVentasDelMesPorZonasDelVendedorSuccess(
        state,
        action.ventas,
        action.ventasNegativo,
        action.ventasDataTable,
        action.fecha,
        action.total
      );
    case actionTypes.LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_FAIL:
      return loadVentasDelMesPorZonasDelVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR:
      return clearVentasDelMesPorZonasDelVendedor(state);

    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_START:
      return loadEvolucionVentasDelVendedorPorCategoriaDeClienteStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_SUCCESS:
      return loadEvolucionVentasDelVendedorPorCategoriaDeClienteSuccess(
        state,
        action.ventas,
        action.fechas,
        action.total
      );
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_FAIL:
      return loadEvolucionVentasDelVendedorPorCategoriaDeClienteFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE:
      return clearEvolucionVentasDelVendedorPorCategoriaDeCliente(state);
  
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_START:
      return loadEvolucionVentasDelVendedorPorProvinciasStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_SUCCESS:
      return loadEvolucionVentasDelVendedorPorProvinciasSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_FAIL:
      return loadEvolucionVentasDelVendedorPorProvinciasFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS:
      return clearEvolucionVentasDelVendedorPorProvincias(state);
  
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_START:
      return loadEvolucionVentasDelVendedorPorZonasStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_SUCCESS:
      return loadEvolucionVentasDelVendedorPorZonasSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_FAIL:
      return loadEvolucionVentasDelVendedorPorZonasFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS:
      return clearEvolucionVentasDelVendedorPorZonas(state);
  
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_START:
      return loadEvolucionVentasDelVendedorPorMarcaStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_SUCCESS:
      return loadEvolucionVentasDelVendedorPorMarcaSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_FAIL:
      return loadEvolucionVentasDelVendedorPorMarcaFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA:
      return clearEvolucionVentasDelVendedorPorMarca(state);
  
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_START:
      return loadEvolucionVentasDelVendedorPorRubroStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_SUCCESS:
      return loadEvolucionVentasDelVendedorPorRubroSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_FAIL:
      return loadEvolucionVentasDelVendedorPorRubroFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO:
      return clearEvolucionVentasDelVendedorPorRubro(state);
  
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_START:
      return loadEvolucionVentasDelVendedorPorSubrubroStart(state);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_SUCCESS:
      return loadEvolucionVentasDelVendedorPorSubrubroSuccess(state, action.ventas, action.fechas, action.total);
    case actionTypes.LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_FAIL:
      return loadEvolucionVentasDelVendedorPorSubrubroFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO:
      return clearEvolucionVentasDelVendedorPorSubrubro(state);
  
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_START:
      return loadEvolucionDeVentasMensualDeVendedoresStart(state);
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_SUCCESS:
      return loadEvolucionDeVentasMensualDeVendedoresSuccess(
        state,
        action.ventas,
        action.ventasDataTable,
        action.totalVentas
      );
    case actionTypes.LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_FAIL:
      return loadEvolucionDeVentasMensualDeVendedoresFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_VENTAS_DE_VENDEDORES:
      return clearEvolucionDeVentasMensualDeVendedores(state);

    case actionTypes.SELECCION_VENDEDOR_ANALISIS_VENDEDORES:
      return { ...state, vendedorSelected: action.vendedor };

    case actionTypes.EXECUTE_CAMBIAR_VENDEDOR_LOADING:
      return { ...state, loadingCambiarVendedor: action.loadingCambiarVendedor };
  
    case actionTypes.SELECCION_DATA_VENTAS_DEL_MES_DEL_VENDEDOR_POR:
      return { ...state, dataVentasDelMesDelVendedorPorSelected: action.selectionId };
  
    case actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DEL_VENDEDOR:
      return { ...state, fechaVentasDelMesAnioDelVendedor: { mes: action.mes, anio: action.anio } };
  
    case actionTypes.SELECCION_DATA_VENTAS_DEL_MES_DE_VENDEDORES_POR:
      return { ...state, dataVentasDelMesDeVendedoresPorSelected: action.selectionId };
  
    case actionTypes.SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_VENDEDORES:
      return { ...state, fechaVentasDelMesAnioDeVendedores: { mes: action.mes, anio: action.anio } };

    default:
      return state;
  }
};

export default reducer;