import * as actionTypes from "./actionTypes";

export const showSaldoCuentaCorrienteModal = (show, inClientInfo) => {
  return {
    type: actionTypes.SHOW_CUENTA_CORRIENTE_MODAL,
    show,
    inClientInfo,
  };
};

export const showGlobalModal = (show, title, body, footer, disableCloseButton, fullWidth, maxWidth) => {
  return {
    type: actionTypes.SHOW_GLOBAL_MODAL,
    show,
    title,
    body,
    footer,
    disableCloseButton,
    fullWidth,
    maxWidth
  };
};

export const changeGlobalModalBody = (newBody) => {
  return {
    type: actionTypes.CHANGE_GLOBAL_MODAL_BODY,
    newBody
  };
};

export const showListFiltersModal = (show, title, filterTypes) => {
  return {
    type: actionTypes.SHOW_LIST_FILTERS_MODAL,
    show,
    title,
    filterTypes
  };
};

export const showSendChartModal = (show, chartRef, tituloChart = '') => { 
  return {
    type: actionTypes.SHOW_SEND_CHART_MODAL,
    show,
    chartRef,
    tituloChart,
  };
};

export const showBackButtonModal = (show) => { 
  return {
    type: actionTypes.SHOW_BACK_BUTTON_MODAL,
    show
  };
};