import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import Dialog from '../../UI/Dialog/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import classes from './ListaFiltrosPorIndicadorModal.css';
import { storageEmpresaId } from '../../../assets/shared/sessionData';
import Spinner from '../../UI/Spinner/Spinner';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ListaFiltrosPorIndicadorModal = (props) => {
  const {
    filterTypes,
    ventasEstaCargado,
    onLoadFiltrosVentas,
    pedidosEstaCargado,
    onLoadFiltrosPedidos,
    chequesEstaCargado,
    onLoadFiltrosCheques,
  } = props;

  const venta = filterTypes.includes('venta');
  const pedido = filterTypes.includes('pedido');
  const chequesCartera = filterTypes.includes('chequesCartera');
  const chequesRechazados = filterTypes.includes('chequesRechazados');

  useEffect(() => {
    if (venta && !ventasEstaCargado) onLoadFiltrosVentas();
    if (pedido && !pedidosEstaCargado) onLoadFiltrosPedidos();
    if (chequesCartera) onLoadFiltrosCheques(false);
    if (chequesRechazados) onLoadFiltrosCheques(true);
  }, [ 
    venta, 
    pedido,
    chequesCartera,
    chequesRechazados,
    filterTypes,
    ventasEstaCargado,
    onLoadFiltrosVentas,
    pedidosEstaCargado,
    onLoadFiltrosPedidos,
    chequesEstaCargado,
    onLoadFiltrosCheques
   ]);

  const filtrarSeleccionados = (loading, datas, columnTitle) => {
    const filtrosSeleccionados = datas.filter((data) => data.estaSeleccionado === false);

    const listarFiltros = [];
    for (var i = 0; i <= filtrosSeleccionados.length / 12; i++) {
      listarFiltros.push(filtrosSeleccionados.slice(i * 12, i * 12 + 12));
    }

    return (
      <Accordion classes={{ root: classes.AccordionRoot }} square>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={columnTitle}>
          <Typography variant="button">{columnTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Spinner personalSize="20px" />
          ) : filtrosSeleccionados.length === 0 ? (
            'No hay filtros activos'
          ) : (
            listarFiltros.map((filtros, key) => {
              return (
                <div key={key} style={{ marginLeft: key !== 0 && '15px' }}>
                  {filtros.map((filtro, index) => {
                    return <div key={index}>{filtro.nombre}</div>;
                  })}
                </div>
              );
            })
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const body = () => {
    return (
      <div>
        {venta && filtrarSeleccionados(props.ventasLoading, props.ventasFiltros, 'Filtros comprobantes de venta')}
        {pedido && filtrarSeleccionados(props.pedidosLoading, props.pedidosFiltros, 'Filtros comprobantes de pedido')}
        {chequesCartera && filtrarSeleccionados(props.pedidosLoading, props.chequesFiltros, 'Filtros cheques en cartera')}
        {chequesRechazados && filtrarSeleccionados(props.pedidosLoading, props.chequesFiltros, 'Filtros cheques rechazados')}
      </div>
    );
  };

  return (
    <Dialog
      title={
        <div classes={classes.TitleDialogContent} style={{ width: '100%' }}>
          <div className={classes.TitleDialogTexto}>
            <Typography variant="h5" style={{ paddingRight: '10px' }}>
              {props.title}
            </Typography>
            {!props.disableCloseButton && (
              <div>
                <IconButton aria-label="close" onClick={() => props.onCloseShowModal()}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
          <div className={classes.subtitleTexto}>
            <Typography variant="caption">Filtros aplicados sobre el indicador</Typography>
          </div>
        </div>
      }
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth ? props.maxWidth : false}
      show={props.show}
      body={body()}
      onClose={() => props.onCloseShowModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    show: state.modals.listFiltersModal.show,
    title: state.modals.listFiltersModal.title,
    filterTypes: state.modals.listFiltersModal.filterTypes,    

    ventasFiltros: state.configuraciones.comprobantesVentas.comprobantes,
    ventasLoading: state.configuraciones.comprobantesVentas.loading,
    ventasEstaCargado: state.configuraciones.comprobantesVentas.estaCargado,

    pedidosFiltros: state.configuraciones.comprobantesPedidos.comprobantes,
    pedidosLoading: state.configuraciones.comprobantesPedidos.loading,
    pedidosEstaCargado: state.configuraciones.comprobantesPedidos.estaCargado,

    chequesFiltros: state.cheques.comprobantesCheques.comprobantes,
    chequesLoading: state.cheques.comprobantesCheques.loading,
    chequesEstaCargado: state.cheques.comprobantesCheques.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseShowModal: () => dispatch(actions.showListFiltersModal(false, '', [])),
    onLoadFiltrosPedidos: () => dispatch(actions.loadComprobantesPedidos(storageEmpresaId())),
    onLoadFiltrosVentas: () => dispatch(actions.loadComprobantesVentas(storageEmpresaId())),
    onLoadFiltrosCheques: (rechazados) => dispatch(actions.loadComprobantesCheques(storageEmpresaId(), rechazados)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaFiltrosPorIndicadorModal);