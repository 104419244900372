import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { storageClientId, storageEmpresaId, storageVendedorId } from "../../../assets/shared/sessionData";

import * as actions from "../../../store/actions";

import Spinner from "../Spinner/Spinner";
import { Tooltip } from "@material-ui/core";

const EmpresaSelector = (props) => {
  const [empresaId, setEmpresaId] = useState(storageEmpresaId());

  const {
    cargandoIndicadores,
    onLoadVendedores,
    onSeleccionarEmpresa,
    onActualizarIndicadoresFotoDelDia,
    onUpdateUltimaActualizacionFotoDelDia,
    onLimpiarIndicadoresFotoDelDia,
    onLimpiarUltimaActualizacionFotoDelDia,
    onUpdateUltimaActualizacionCliente,
    onActualizarIndicadoresCliente,
    onLimpiarIndicadoresCliente,
    onLimpiarUltimaActualizacionCliente,
    onLimpiarCatalogo,
    onLimpiarUltimaActualizacionCatalogo,
    onLimpiarTiemposReq,
    onLimpiarUltimaActualizacionTiemposReq,
    onLimpiarChangeLogs,
    onLimpiarUltimaActualizacionChangeLogs,
    onActualizarCatalogo,
    onUpdateUltimaActualizacionCatalogo,
    onActualizarAnalisisDeVentas,
    onUpdateUltimaActualizacionAnalisisDeVentas,
    onLimpiarAnalisisDeVentas,
    onLimpiarUltimaActualizacionAnalisisDeVentas,
  } = props;

  useEffect(() => {
    if (props.empresas.length === 0) {
      props.onLoadEmpresas();
    }
  }, []);

  const pathNamePages = {
    fotoDelDia: "/fotoDelDia",
    home: "/",
    cliente: "/cliente",
    tiemposReq: "/tiemposReq",
    catalogo: "/catalogo",
    analisisventas: "/analisisventas",
    changeLogs: "/changeLogs",
  };

  const urlRef = global.window.location.pathname;
  const dateActual = () => {
    return  moment(Date.now()).format("DD/MM/YYYY HH:mm");
  }

  const onEndLoadVendedores = (response) => {
    if(response !== null && response !== undefined){
      const vendedores = response.data;

      if(vendedores.length > 0){
        sessionStorage.setItem("vendedorId", vendedores[0].idVendedor);
      }
    }
  }

  const onEmpresaClick = (empresa) => {
    sessionStorage.setItem('empresaNombre', empresa.nombre);
    onLoadVendedores(onEndLoadVendedores, empresa.id);
    onSeleccionarEmpresa(empresa.id, () => {
      setEmpresaId(storageEmpresaId());    
      actualizarIndicadoresPaginaActivaDesmontarOtras();    
    });
  };

  const actualizarIndicadoresPaginaActivaDesmontarOtras = () => {
    switch (urlRef) {
      case pathNamePages.fotoDelDia:
        return actualizarFotoDelDiaLimpiarOtros();

      case pathNamePages.home:
        return actualizarFotoDelDiaLimpiarOtros();

      case pathNamePages.cliente:
        return actualizarClienteLimpiarOtros();

      case pathNamePages.catalogo:
        return actualizarCatalogoLimpiarOtros();

      case pathNamePages.analisisventas:
        return actualizarAnalisisDeVantasLimpiarOtros();

      default:
        break;
    }
  };

  const actualizarFotoDelDiaLimpiarOtros = () => {
    onLimpiarIndicadoresCliente();
    onLimpiarUltimaActualizacionCliente();
    onLimpiarCatalogo();
    onLimpiarUltimaActualizacionCatalogo();
    onLimpiarTiemposReq();
    onLimpiarUltimaActualizacionTiemposReq();
    onLimpiarChangeLogs();
    onLimpiarUltimaActualizacionChangeLogs();
    onLimpiarAnalisisDeVentas();
    onLimpiarUltimaActualizacionAnalisisDeVentas();

    if(props.ejecucionFotoDelDia){
      onUpdateUltimaActualizacionFotoDelDia(dateActual());
      if (storageVendedorId()) {
        onActualizarIndicadoresFotoDelDia();
      } else {
        onLimpiarIndicadoresFotoDelDia();
      }
    }
  };

  const actualizarClienteLimpiarOtros = () => {
    onLimpiarIndicadoresCliente();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionCatalogo();
    onLimpiarTiemposReq();
    onLimpiarUltimaActualizacionTiemposReq();
    onLimpiarChangeLogs();
    onLimpiarUltimaActualizacionChangeLogs();
    onLimpiarAnalisisDeVentas();
    onLimpiarUltimaActualizacionAnalisisDeVentas();

    if(props.ejecucionClientes){
      onUpdateUltimaActualizacionCliente(dateActual());
      onActualizarIndicadoresCliente(storageClientId());
    }
  };

  const actualizarCatalogoLimpiarOtros = () => {
    onLimpiarIndicadoresCliente();
    onLimpiarUltimaActualizacionCliente();
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarTiemposReq();
    onLimpiarUltimaActualizacionTiemposReq();
    onLimpiarChangeLogs();
    onLimpiarUltimaActualizacionChangeLogs();
    onLimpiarAnalisisDeVentas();
    onLimpiarUltimaActualizacionAnalisisDeVentas();

    if(props.ejecucionCatalogo){
      onUpdateUltimaActualizacionCatalogo(dateActual());
      onActualizarCatalogo();
    }
  };

  const actualizarAnalisisDeVantasLimpiarOtros = () => {  
    onLimpiarIndicadoresCliente();
    onLimpiarUltimaActualizacionCliente();
    onLimpiarIndicadoresFotoDelDia();
    onLimpiarUltimaActualizacionFotoDelDia();
    onLimpiarTiemposReq();
    onLimpiarUltimaActualizacionTiemposReq();
    onLimpiarChangeLogs();
    onLimpiarUltimaActualizacionChangeLogs();
    onLimpiarCatalogo();
    onLimpiarUltimaActualizacionCatalogo();
    
    if(props.ejecucionAnalisisVentas){
      if (storageVendedorId()) {
        onActualizarAnalisisDeVentas();
      } else {
        onLimpiarAnalisisDeVentas();
      }
      onUpdateUltimaActualizacionAnalisisDeVentas(dateActual());
    }
  }

  return props.loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px', zIndex: 1200 }}>
      <div>
        {props.empresas.map((empresa, key) => (
          <Tooltip
            title="Cargando indicadores..."
            placement="right"
            arrow
            open={cargandoIndicadores && key === props.empresas.length - 1}
            key={key}
          >
            <button
              disabled={cargandoIndicadores}
              key={key}
              style={{
                margin: '0px',
                outline: 'none',
                color: empresa.id === empresaId ? '#FFFFFF' : '#CECECE',
                border: '0.5px solid #CECECE',
                textAlign: 'center',
                font: 'normal normal medium 12px/16px Roboto',
                backgroundColor: empresa.id === empresaId ? '#0CC5FF' : '#FFFFFF',
                borderRadius: '3px 0px 0px 3px',
                cursor: empresa.id !== empresaId && !cargandoIndicadores && 'pointer'
              }}
              onClick={() => empresa.id !== empresaId && onEmpresaClick(empresa)}
            >
              {empresa.nombre}
            </button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.empresas.empresas.loading,
    empresas: state.empresas.empresas.empresas,
    clienteSelectedId: state.clientes.clienteById.cliente.idCliente,    
    ejecucionFotoDelDia: state.ejecucionPaginas.ejecucionFotoDelDia,
    ejecucionClientes: state.ejecucionPaginas.ejecucionClientes,
    ejecucionCatalogo: state.ejecucionPaginas.ejecucionCatalogo,
    ejecucionTiemposReq: state.ejecucionPaginas.ejecucionTiemposReq,
    ejecucionAnalisisVentas: state.ejecucionPaginas.ejecucionAnalisisVentas,
    cargandoIndicadores:
      (state.clientes.clienteById.loading ||
      state.ventas.facturasPendientes.loading ||
      state.clientes.ultimaCompra.loading ||
      state.ventas.objetivoVentas.loading ||
      state.vendedores.comisionEvolutivo.loading ||
      state.ventas.ventasMesComparativo.loading ||
      state.ventas.ventasMesComparativo.loadingPorCliente ||
      state.ventas.pedidosPendientes.loading ||
      state.ventas.pedidosPendientes.loadingPorCliente ||
      state.ventas.pedidosMesComparativo.loading ||
      state.ventas.pedidosMesComparativo.loadingPorCliente ||
      state.ventas.estadosPedidosPendientes.loading ||
      state.ventas.estadosPedidosPendientes.loadingPorCliente ||
      state.ventas.facturasDelMesInteranual.loading ||
      state.ventas.facturasDelMesInteranual.loadingPorCliente ||
      state.ventas.pedidosDelMesInteranual.loading ||
      state.ventas.pedidosDelMesInteranual.loadingPorCliente ||
      state.cheques.chequesRechazados.loading ||
      state.ventas.ventasAlClienteEvolutivo.loading),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadEmpresas: () => dispatch(actions.loadEmpresas()),
    onSeleccionarEmpresa: (empresaId, success) =>
      dispatch(actions.seleccionarEmpresa(empresaId, success)),
    onLoadVendedores: (success, idEmpresa) => dispatch(actions.loadVendedores(success, idEmpresa)),

    //#region Foto del dia
    onActualizarIndicadoresFotoDelDia: () =>
      dispatch(actions.actualizarIndicadoresFotoDelDia()),
    onUpdateUltimaActualizacionFotoDelDia: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionFotoDelDia(fechaHora)),
    onLimpiarIndicadoresFotoDelDia: () =>
      dispatch(actions.clearIndicadoresFotoDelDia()),
    onLimpiarUltimaActualizacionFotoDelDia: () =>
      dispatch(actions.clearUltimaActualizacionFotoDelDia()),
    //#endregion

    //#region Cliente
    onActualizarIndicadoresCliente: (idCliente) =>
      dispatch(actions.actualizarIndicadoresCliente(idCliente)),
    onUpdateUltimaActualizacionCliente: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionCliente(fechaHora)),
    onLimpiarIndicadoresCliente: () =>
      dispatch(actions.clearIndicadoresCliente()),
    onLimpiarUltimaActualizacionCliente: () =>
      dispatch(actions.clearUltimaActualizacionCliente()),
    //#endregion

    //#region Catalogo
    onActualizarCatalogo: () => dispatch(actions.actualizarCatalogo()),
    onUpdateUltimaActualizacionCatalogo: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionCatalogo(fechaHora)),
    onLimpiarCatalogo: () => {
      dispatch(actions.clearCatalogo());
      dispatch(actions.clearCatalogo(true));
    },
    onLimpiarUltimaActualizacionCatalogo: () =>
      dispatch(actions.clearUltimaActualizacionCatalogo()),
    //#endregion

    //#region Timpos Req
    onActualizarTiemposReq: () => dispatch(actions.actualizarTiemposReq()),
    onUpdateUltimaActualizacionTiemposReq: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionTiemposRequest(fechaHora)),
    onLimpiarTiemposReq: () => dispatch(actions.clearTiemposReq()),
    onLimpiarUltimaActualizacionTiemposReq: () =>
      dispatch(actions.clearUltimaActualizacionTiemposRequest()),
    //#endregion

    //#region change logs
    onActualizarChangeLogs: () => dispatch(actions.actualizarChangeLogs()),
    onUpdateUltimaActualizacionChangeLogs: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionChangeLogs(fechaHora)),
    onLimpiarChangeLogs: () => dispatch(actions.clearChangeLogs()),
    onLimpiarUltimaActualizacionChangeLogs: () =>
      dispatch(actions.clearUltimaActualizacionChangeLogs()),
    //#endregion

    //#region Analisis de Ventas
    onActualizarAnalisisDeVentas: () => dispatch(actions.actualizarAnalisisVentas()),
    onUpdateUltimaActualizacionAnalisisDeVentas: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionAnalisisDeVentas(fechaHora)),
    onLimpiarAnalisisDeVentas: () => dispatch(actions.clearAnalisisVentas()),
    onLimpiarUltimaActualizacionAnalisisDeVentas: () =>
      dispatch(actions.clearUltimaActualizacionAnalisisDeVentas()),
    //#endregion
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmpresaSelector);