import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LineEvolucionVentasDelVendedor from '../../components/Indicadores/vendedores/LineEvolucionVentasDelVendedor/LineEvolucionVentasDelVendedor';
import LineEvolucionVentasDelVendedorPorSeleccion from '../../components/Indicadores/vendedores/LineEvolucionVentasDelVendedorPorSeleccion/LineEvolucionVentasDelVendedorPorSeleccion';
import PieVentasDelVendedorPorSeleccion from '../../components/Indicadores/vendedores/PieVentasDelVendedorPorSeleccion/PieVentasDelVendedorPorSeleccion';
import TablaCuboVentasDelVendedor from '../../components/Indicadores/vendedores/TablaCuboVentasDelVendedor/TablaCuboVentasDelVendedor';
import TablaVentasDelVendedorPorSeleccion from '../../components/Indicadores/vendedores/TablaVentasDelVendedorPorSeleccion/TablaVentasDelVendedorPorSeleccion';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({  
  combinedChartsCard: {
    display: 'inline',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      margin: '5px 0px 0px 0px',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      backgroundColor: 'white',
      boxShadow: '0px 3px 6px #00000029',
      paddingRight: '0px'
    }
  },
  combinedChartsCardPie: {  
    [theme.breakpoints.down('md')]: {
      marginBottom: '10px',
      padding: '8px'
    },
  },
  combinedChartsCardTabla: {  
    [theme.breakpoints.down('md')]: {
      marginTop: '10px',
      padding: '8px'
    },
  },
  lineEvolucionVentas: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  },
}));

const Ventas = (props) => {
  const classes2 = useStyles();   
  const inPhone = window.screen.width <= 750;

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={1}>
        <Grid item xs={12} lg={4}>
          <TablaCuboVentasDelVendedor ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <LineEvolucionVentasDelVendedor 
            ejecucionDePagina={props.ejecucionDePagina}
            height={370}
          />
        </Grid>
      </Grid>
      <Grid item 
        container spacing={1}
        className={classes2.combinedChartsCard}>
        <Grid item xs={12} lg={4} className={classes2.combinedChartsCardPie}>
          <PieVentasDelVendedorPorSeleccion
            noBorder={!inPhone}
            ejecucionDePagina={props.ejecucionDePagina}
            inGastos
            legendHeight={50}
            legendPosition={'bottom'}
            height={436}
            width={'100%'}
            chartType={'pie'}
          />
        </Grid>
        <Grid item xs={12} lg={8} className={classes2.combinedChartsCardTabla}>
          <TablaVentasDelVendedorPorSeleccion ejecucionDePagina={props.ejecucionDePagina} />
        </Grid>
      </Grid>
      <Grid item className={classes2.lineEvolucionVentas}>
        <LineEvolucionVentasDelVendedorPorSeleccion 
          ejecucionDePagina={props.ejecucionDePagina}
          width={'100%'}
          height={'450px'}
          chartHeight={'450px'}
          chartWidth={'99%'}
        />
      </Grid>
    </Grid>
  );
};

export default Ventas;