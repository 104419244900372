import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  empresas: {
    loading: false,
    empresas: [],
    error: null,
  },
  loadingCambiarEmpresa: false,
};

//#region loadEmpresas
const loadEmpresasStart = (state) => {
  return updateObject(state, {
    empresas: updateObject(state.empresas, {
      loading: true,
      empresas: [],
      error: null,
    }),
  });
};

const loadEmpresasSuccess = (state, empresas) => {
  return updateObject(state, {
    empresas: updateObject(state.empresas, {
      loading: false,
      empresas,
    }),
  });
};

const loadEmpresasFail = (state, error) => {
  return updateObject(state, {
    empresas: updateObject(state.empresas, {
      loading: false,
      error,
    }),
  });
};
//#endregion

////#region execute Cambiar Empresa
const cambiarEmpresa = (state, loading) => {
  return updateObject(state, {
    loadingCambiarEmpresa: loading,
  });
};
////#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_EMPRESAS_START:
      return loadEmpresasStart(state);
    case actionTypes.LOAD_EMPRESAS_SUCCESS:
      return loadEmpresasSuccess(state, action.empresas);
    case actionTypes.LOAD_EMPRESAS_FAIL:
      return loadEmpresasFail(state, action.error);

    case actionTypes.EXECUTE_CAMBIAR_EMPRESA:
      return cambiarEmpresa(state, action.loading);

    default:
      return state;
  }
};

export default reducer;
