import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';
import FormControl from '@material-ui/core/FormControl';

import Spinner from '../Spinner/Spinner';
import { makeStyles } from '@material-ui/core';
import { CarritoContext } from '../../../store/contexts/carrito';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { storageClientId } from '../../../assets/shared/sessionData';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    textAlign: 'start',
    fontSize: 'small',   
    minWidth: '250px',
  },
  input: {
    fontSize: "small",
  },
  options:{
    fontSize: 'small',
  },
}));

const ClienteSelector = (props) => {
  const {
    loading,
    onLoadClientes,
    estaCargado,
    clientes,
    onClearEjecuciones,
  } = props;

  const { 
    clearCarrito, 
  } = useContext(CarritoContext);

  const classes = useStyles();
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);

  const onSelectClienteChange = (event, cliente) => {  
    sessionStorage.setItem("listaPrecioIdCliente", "");
    sessionStorage.setItem("clienteId", null);

    if(cliente !== null && cliente !== undefined && cliente !== ''){
      let clienteId = cliente.idCliente;

      if(clienteId !== null && clienteId !== undefined)
        clienteId = clienteId.trimEnd();

      sessionStorage.setItem("clienteId", clienteId);
      props.onLoadClienteCabecera(clienteId);
    }      

    setClienteSeleccionado(cliente);
    onClearEjecuciones();
    clearCarrito();
    sessionStorage.removeItem('observacionesObject');
  };

  useEffect(() => {
    if(!estaCargado)
      onLoadClientes();
  }, [onLoadClientes, estaCargado]);

  useEffect(() => {
    const clienteId = storageClientId();

    if(clienteId === null || clienteId === `null` || clienteId === undefined || clienteId === '') {
      setClienteSeleccionado(null);
    }

    if (estaCargado && (clienteSeleccionado === null || clienteSeleccionado === undefined))
    {
      if(clienteId !== null && clienteId !== `null` && clienteId !== undefined && clienteId !== ''){
        const item = clientes.find(c => c.idCliente === clienteId);
        setClienteSeleccionado(item);
        props.onLoadClienteCabecera(clienteId);
      }
    }
  }, [clienteSeleccionado, clientes, estaCargado]);

  return loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <FormControl style={{ color: "black", width: "auto" }} >    
      <Autocomplete       
        id="auto-complete-clientes" 
        classes={{
          root: classes.root,
          input: classes.input,
          option: classes.options,
        }}
        size="small"
        autoComplete={true}
        options={clientes}
        getOptionLabel={(option) => option.nombre ? option.nombre.toUpperCase() : "" }
        renderInput={(params) => <TextField {...params} className={classes.input} placeholder="SELECCIONE CLIENTE" margin="normal" />}
        value={clienteSeleccionado && clienteSeleccionado}
        onChange={(event, newValue) => onSelectClienteChange(event, newValue)}
      />
    </FormControl>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.clientes.clientesInfoMinima.loading,
    clientes: state.clientes.clientesInfoMinima.clientes,
    estaCargado: state.clientes.clientesInfoMinima.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadClientes: () => dispatch(actions.loadClientesInfoMinima()),
    onClearEjecuciones: () => {
      dispatch(actions.ejecucionClientes(false));
      dispatch(actions.clearIndicadoresCliente());
      dispatch(actions.clearCatalogo(true));
    },
    onLoadClienteCabecera: (idCliente) => dispatch(actions.loadClienteCabeceraById(idCliente)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClienteSelector);