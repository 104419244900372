import React, { Fragment, useState, useContext, useEffect, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { CarritoContext } from '../../store/contexts/carrito';

import { Grid, TextField, Divider, Typography, Card, CardHeader, CardContent,
  Tooltip, IconButton, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from '@material-ui/icons/AddOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import { isEven } from '../../assets/shared/formatNumber';
import Spinner from "../UI/Spinner/Spinner";
import Buton from "../UI/Button/Button";
import NumberFormatCustom from '../UI/Input/NumberFormatCustom';
import BuscarArticulosHook from "./BuscarArticulosHook";
import { esValido } from "../../assets/shared/utility";
import { errorListaDePreciosVacia } from "../../assets/shared/errorMessages";

const useStyles = makeStyles({  
  root: {
    width: '100%',
  },
  root1: {
    borderRadius: 0,
    boxShadow: "none",
    width: '100%',
    backgroundColor: 'white',
    marginLeft: '0px',
    marginRight: '0px',
  },
  root2:{
    borderRadius: 0,
    boxShadow: "none",
    width: '100%',
    backgroundColor: '#f2f2f2',
    marginLeft: '0px',
    marginRight: '0px',
  },
  title: {
    fontSize: 'large',
    fontWeight: 'bold',
  },
  titleContainer: {
    marginBottom: '20px',
  },
  table: {
    minWidth: 650,
  },
  busqueda: {
    textAlign: "justify",
    fontSize: "medium",
    paddingInline: '20px'
  },
  sinResultado: {
    textAlign: "center",
    fontSize: "large",
  },
  header:{
    width: '100%',  
    textAlign: 'center',
    backgroundColor: '#e0e0e0',
    marginLeft: '0px',
    marginRight: '0px',
  },
  text: {
    fontSize: 'smaller',
    textAlign: 'left',
  },
  numeric: {
    "& .MuiOutlinedInput-input": {
      fontSize: 'smaller',
      textAlign: 'right',
    }
  },
  headerText: {
    fontSize: 'small',
    fontWeight: 'bold',
    textAlign: 'left',
  }, 
  headerNumeric: {
    fontSize: 'small',
    fontWeight: 'bold',
    textAlign: 'right',
  },
  content:{
    paddingLeft: '0px',
    paddingRight: '0px',
    maxHeight: '350px', 
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  input:{
    width: '100%',
  },
  buttons:{
    width: '100%',
  }, 
  actionIcon: {
    color: '#00BCD4'
  },
  rootFormControl: {
    display: "flex", 
    alignItems: "center"
  },
  rootControl:{
    margin: "0px 10px 0px 10px", 
    color: "black",
    width: "100%"
  },
  select:{
    minWidth: 120, 
    color: "black", 
    fontSize: 'small',
    textAlign: 'start'
  },
  options:{
    fontSize: 'small',
  },
  gridContainer: {
    display: "flex", 
    alignItems: "center"
  },
  gridItem:{
    width: "100%",
  },
  gridElement:{
    width: "100%",
  },
  gridFieldset:{
    alignSelf: "flex-end", 
    paddingTop: '10px',
  },
  search:{
    width: "100%",
    '& .MuiInputBase-input': {
      fontSize: 'small',
    },
    '& .MuiInputLabel-root':{
      fontSize: 'small',
    },
    '& .MuiInputLabel-animated':{
      fontSize: 'medium',
    }
  },
});

const TablaAgregarArticulo = (props) => { 
  const classes = useStyles();
  
  const { cerrarModal, filtroListaPrecio, comprobante } = props;
  const { addArticulo, updateCantidad, carritoItems, updateCarrito } = useContext(CarritoContext);

  const [articulos, setArticulos] = useState([]);
  const [idArticulo, setIdArticulo] = useState("");
  const [changeArticulo, setChangeArticulo] = useState(false);
  const [marca, setMarca] = useState("");
  const [changeMarca, setChangeMarca] = useState(false);
  const [rubro, setRubro] = useState("");
  const [changeRubro, setChangeRubro] = useState(false);
  const [subrubro, setSubrubro] = useState("");
  const [changeSubrubro, setChangeSubrubro] = useState(false);
  const [aplicarFiltro, setAplicarFiltro] = useState(false);
  const [reset, setReset] = useState(false);  
  const [inSearch, setInSearch] = useState(false);

  const [query, setQuery] = useState("");
  const [offset, setOffset] = useState(null);
  let { isLoading, error, resultado, hasMore } = 
    BuscarArticulosHook(query, marca, rubro, subrubro, offset, reset, () => setReset(false));

  const observer = useRef();
  const ultimoArticuloRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const last = resultado[resultado.length - 1];

          if(last !== undefined && last !== undefined){   
            setOffset(last.idArticulo);
          }
        }
      });
      if (node) observer.current.observe(node);
    }, [isLoading, hasMore, resultado]);

  useEffect(() => {
    setAplicarFiltro(changeArticulo || changeMarca || changeRubro || changeSubrubro);
  }, [changeArticulo, changeMarca, changeRubro, changeSubrubro]);

  const handleChange = (value) => {
    if(value !== null && value !== undefined && value !== '')
      value = value.toUpperCase();
    
    setChangeArticulo(value !== idArticulo);
    setIdArticulo(value);
  };

  const onSearchClick = () => {
    setReset(true);
    setQuery(idArticulo);
    setOffset('');
    setInSearch(true);
  }

  const agregarArticulo = (articulo) => {
    if(!esValido(filtroListaPrecio)){
      alert(errorListaDePreciosVacia);
      return;
    }

    articulo.agregado = true;
    let filteredArray = articulos.concat([articulo]);
    setArticulos(filteredArray);
  }

  const removerArticulo = (articulo) => {
    articulo.agregado = false;
    let filteredArticulos = articulos.filter(item => item.idArticulo !== articulo.idArticulo);
    setArticulos(filteredArticulos);
  }

  const onAgregar = () => {
    articulos.forEach(articulo => {
      const item = carritoItems.find(i => i.idArticulo === articulo.idArticulo);

      if(item !== null && item !== undefined){
        const cantidad = item.cantidad + articulo.cantidad;
        updateCantidad(item, cantidad);
      }else{
        addArticulo(articulo);
      }
    });

    updateCarrito();
    cerrarModal();
  }

  const limpiarFiltros = () => {
    setInSearch(false);
    setReset(true);
    setIdArticulo("");
    setChangeArticulo(false);
    setMarca('');
    setChangeMarca(false);
    setRubro('');
    setChangeRubro(false);
    setSubrubro('');
    setChangeSubrubro(false);
    setQuery("");
    setOffset('');
  }

  const onCancelar = () => { 
    limpiarFiltros();
    setArticulos([]);
  }

  const onCancelarClick = () => {
    limpiarFiltros();
  }

  const onMarcaChange = (value) => {
    setReset(true);
    setQuery(idArticulo);
    setOffset('');
    setChangeMarca(marca !== value);
    setMarca(value);
  }

  const onRubroChange = (value) => {
    setReset(true);
    setQuery(idArticulo);
    setOffset('');
    setChangeRubro(rubro !== value);
    setRubro(value);
  }

  const onSubRubroChange = (value) => {
    setReset(true);
    setQuery(idArticulo);
    setOffset('');
    setChangeSubrubro(subrubro !== value);
    setSubrubro(value);
  }

  const searchBar = () => {
    return (
      <Grid item xs={12} md={12}
          style={{ textAlign:'end', marginBottom: '5px' }}
          container
          spacing={1}
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item xs={10} sm={10}
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
          >
            <Grid item xs={6} sm={3}>
              <SearchFilter 
                onSearchFilterChange={handleChange}
                searchFilter={idArticulo}
                placeholder={'Búsqueda *'}
                required={true}
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <SelectFilter
                label="Marca"
                value={marca}
                items={props.marcas}
                loading={props.loadingMarcas}
                onChange={onMarcaChange} 
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <SelectFilter
                label="Rubro"
                value={rubro}
                items={props.rubros}
                loading={props.loadingRubro}
                onChange={onRubroChange} 
              />          
            </Grid>
            <Grid item xs={4} sm={3}>
              <SelectFilter
                label="Subrubro"
                value={subrubro}
                items={props.subrubros}
                loading={props.loadingSubrubros}
                onChange={onSubRubroChange} 
              />
            </Grid>          
          </Grid>
          <Grid item xs={1} sm={2}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}
          >
            <Buton
              color="primary"
              style={{
                margin: "5px auto 5px auto",
                backgroundImage: aplicarFiltro && "linear-gradient(45deg, #fbb040, #f15a29)",
                width: '100%'
              }} 
              size="small"
              startIcon={<SearchIcon />}
              onClick={() => onSearchClick()}
              disabled={!aplicarFiltro}     
            >
              Buscar
            </Buton>
            {(inSearch || aplicarFiltro) && (
              <Tooltip title="Limpiar filtros">
                <IconButton onClick={() => onCancelarClick()}>
                  <CancelIcon style={{ color: "red", opacity: 0.5 }} />
                </IconButton>
              </Tooltip>
            )} 
          </Grid>
        </Grid>
    )
  }

  const footer = () => {
    return (
      <Grid item xs={12} md={12}
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6} md={7} xl={7}>
            <Typography>
              {`Cantidad de artículos: ${articulos ? articulos.length : 0}`}
            </Typography>
          </Grid>
          <Grid           
            item xs={6} md={5} xl={5}
            container spacing={1}direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item md={6}>
              <Buton 
                style={{ width: '100%' }}
                size="small"
                disabled={articulos.length === 0}
                onClick={() => onAgregar()}     
              >
                Agregar
              </Buton>
            </Grid>
            <Grid item md={6}>
              <Buton
                style={{ width: '100%', color: 'white', backgroundColor: '#E45164' }}
                variant="outlined" 
                color="default" 
                size="small"
                onClick={() => onCancelar()}     
                >
                Cancelar
              </Buton>               
            </Grid>
          </Grid>
        </Grid>
    )
  }  

  const checkEstaAgregado = () => {
    resultado.forEach((itemResultado) => {
      const item = articulos.find(i => i.idArticulo === itemResultado.idArticulo);
      itemResultado.agregado = item !== null && item !== undefined;
    })
  }

  const listadoArticulosItems = () => {
    checkEstaAgregado();

    if (resultado === undefined || resultado.length === 0) {
      return <div></div>;
    } else {
      return resultado
      .map((articulo, index) => {
        if(resultado.length === index + 1){
          return (
            <ListadoArticulosItem
              key={index}
              index={index}
              articulo={articulo}
              addItem={() => agregarArticulo(articulo)}
              removeItem={() => removerArticulo(articulo)}
              comprobante={comprobante}
              filtroListaPrecio={filtroListaPrecio}
              precios={articulo.precios}
              reference={ultimoArticuloRef}
            />)
        }else{
          return (
            <ListadoArticulosItem
              key={index}
              index={index}
              articulo={articulo}
              addItem={() => agregarArticulo(articulo)}
              removeItem={() => removerArticulo(articulo)}
              comprobante={comprobante}
              filtroListaPrecio={filtroListaPrecio}
              precios={articulo.precios}
            />)
        }
      });
    }
  }

  return (
    <Fragment>
      <Grid container spacing={1}>
        {searchBar()}
        <Grid item xs={12} md={12}>
          <Card className={classes.root}>
            <CardHeader className={classes.header}
              title={<ListadoArticulosHeader />}
            />
            <Divider />
            <CardContent className={classes.content}>
              {!error && !isLoading && !aplicarFiltro && 
                <Typography className={classes.busqueda}>
                  Utilice el campo de "Búsqueda" para filtrar por "Código", "Nombre" o "Código de Barras" y los filtros de "Marca", "Rubro" y "Subrubro". 
                </Typography>}
              {!error && !isLoading && aplicarFiltro && resultado.length === 0 && <Typography className={classes.sinResultado}>Búsqueda sin resultado</Typography>}
              {!error && resultado.length !== 0 && listadoArticulosItems()}
              {!error && isLoading && 
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }} >
                  <Spinner personalSize="25px"/>
                  {resultado.length !== 0 && hasMore && <Typography className={classes.sinResultado} style={{ marginLeft: '10px' }}> Cargando más artículos</Typography>}
                  {resultado.length === 0 && <Typography className={classes.sinResultado} style={{ marginLeft: '10px' }}> Buscando artículos</Typography>}
                </div>}
              <div>{error && <Typography className={classes.sinResultado}>Error al obtener los artículos. Por favor, intente nuevamente. </Typography>}</div>
            </CardContent>
          </Card>
        </Grid>
        {footer()}
      </Grid>
    </Fragment>
  );
}

const ListadoArticulosHeader = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid 
        container 
        spacing={1} 
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.header}>
        <Grid item xs={1} md={1} xl={1}>
          <Typography className={classes.headerText}>
            {"Nro."}
          </Typography>
        </Grid>
        <Grid item xs={2} md={2} xl={2}>
          <Typography className={classes.headerText}>
            {"Código"}
          </Typography>
        </Grid>         
        <Grid item xs={2} md={5} xl={5}>
          <Typography className={classes.headerText}>
            {"Nombre"}
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} xl={1}>
          <Typography className={classes.headerNumeric}>
            {"Cantidad"}
          </Typography>
        </Grid>
        <Grid item xs={1} md={2} xl={2}>
          <Typography className={classes.headerNumeric}>
            {"Precio Unit."}
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} xl={1}>
        </Grid>
      </Grid>
    </Fragment>
  );
}

const ListadoArticulosItem = (props) => {
  const classes = useStyles();

  const { index, articulo, addItem, removeItem, comprobante, 
    reference, filtroListaPrecio, precios } = props;

  const [precio, setPrecio] = useState(articulo.precio);
  const [cantidad, setCantidad] = useState();
  const [decimalesPrecio, setDecimalesPrecio] = useState(2);
  const [decimalesCantidad, setDecimalesCantidad] = useState(0);
  const [enterosPrecio, setEnterosPrecio] = useState(12);
  const [enterosCantidad, setEnterosCantidad] = useState(5);

  const onCantidadChange = (value) => {
    var newValue = Number(value);
    articulo.cantidad = newValue;
    setCantidad(newValue);
  }

  const onPrecioChange = (value) => {
    var newValue = Number(value);
    articulo.precio = newValue;
    setPrecio(newValue);
  }

  useEffect(() => {  
    if(cantidad === undefined && articulo !== undefined){
      articulo.cantidad = 1;
      articulo.agregado = false;
      setCantidad(1);
    }
  }, [articulo, cantidad]);

  useEffect(() => { 
    if(articulo !== undefined && precio !== articulo.precio){
      setPrecio(articulo.precio);
    }
  }, [articulo, precio]);

  useEffect(() => { 
    if(comprobante !== null && comprobante !== undefined){   
      setDecimalesPrecio(comprobante.decimalesPrecio);
      setDecimalesCantidad(comprobante.decimalesCantidad);
      setEnterosPrecio(comprobante.enterosPrecio);
      setEnterosCantidad(comprobante.enterosCantidad);
    }
  }, [comprobante]);

  return (
    <div ref={reference}>
        <Grid 
          container 
          spacing={1} 
          direction="row"
          justify="space-between"
          alignItems="center"
          className={isEven(index) ? classes.root1 : classes.root2 }
        >
          <Grid item xs={1} md={1} xl={1}>
            <Typography  className={classes.text} style={{ textAlign: "center" }}>
              {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={2} md={2} xl={2}>
            <Typography  className={classes.text}>
              {articulo && articulo.idArticulo}
            </Typography>
          </Grid>        
          <Grid item xs={4} md={5} xl={5}>
            <Typography  className={classes.text}>
              {articulo && articulo.nombre}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} xl={1}>           
            <TextField 
              className={classes.numeric} 
              value={cantidad && cantidad.toFixed(decimalesCantidad)}
              variant="outlined"
              size="small"
              onChange={(e) => onCantidadChange(e.target.value)}
              inputProps={{ 
                name:"cantidadInput",
                maxLength: enterosCantidad + decimalesCantidad, 
                decimalScale: decimalesCantidad,
                min: 1 / 10 ** decimalesCantidad
              }}
              InputProps={{ inputComponent: NumberFormatCustom }}
            />
          </Grid>
          <Grid item xs={1} md={2} xl={2}
            style={{ textAlign: "end" }}
          >
            <TextField 
              className={classes.numeric}
              style={{ width: "70%" }}
              value={precio && precio.toFixed(decimalesPrecio)}
              variant="outlined"
              size="small"
              onChange={(e) => onPrecioChange(e.target.value)} 
              inputProps={{ 
                name:"precioInput",
                maxLength: enterosPrecio + decimalesPrecio, 
                decimalScale: decimalesPrecio,
                min: 1 / 10 ** decimalesPrecio
              }}
              InputProps={{ inputComponent: NumberFormatCustom }}
            />            
          </Grid>
          <Grid 
            item xs={2} md={1} xl={1}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            {articulo && articulo.agregado && <Grid item xs={4} md={6}>
              <Tooltip title="Cancelar" placement="top">
                <IconButton aria-label="cancelar"
                  onClick={removeItem}
                >
                  <CancelIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
            </Grid>}
            {articulo && !articulo.agregado && <Grid item xs={4} md={6}>
              <Tooltip title="Guardar" placement="top">
                <IconButton aria-label="guardar"
                  onClick={addItem}
                >
                  <AddIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
            </Grid>}         
          </Grid>
        </Grid>
      </div>
  );
};

const SearchFilter = (props) => {
  const classes = useStyles();

  const { onSearchFilterChange, searchFilter, searchLabel, required, placeholder } = props;
  const [search, setSearch] = useState(searchFilter);

  const onSearchChange = (value) => {
    setSearch(value);
    onSearchFilterChange(value);
  };

  useEffect(() => {
    if(search !== searchFilter)
      setSearch(searchFilter);
  }, [search, searchFilter, setSearch]);

  return (
    <TextField
      type="search"
      label={searchLabel}
      value={search}
      className={classes.search}
      onChange={(e) => onSearchChange(e.target.value)}
      placeholder={placeholder}
      size="small"
      required={required}
    />
  );
}

const SelectFilter = (props) => {
  const classes = useStyles();

  const { label, value, items, loading, onChange, todos } = props;
  const [filtro, setFiltro] = useState(value);

  const handleOnChange = (e) => {
    onChange(e.target.value);
    setFiltro(e.target.value);
  };

  useEffect(() => {
    if(filtro !== value)
      setFiltro(value);
  }, [filtro, value, setFiltro]);

  return loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div className={classes.rootFormControl}>
      <FormControl className={classes.rootControl} >
        <InputLabel id="inputLabel">{label}</InputLabel>
        <Select
          className={classes.select}
          labelId="label"
          id="select"
          value={filtro}
          placeholder={label}
          onChange={(e) => handleOnChange(e)}
        >
          {todos 
            ? (
              <MenuItem 
                className={classes.options}
                key={"TODOS"} 
                value={"TODOS"}>
                TODOS
              </MenuItem>
            ) : (
              <MenuItem 
                className={classes.options}
                key={"SF"} 
                value={""}>
                SIN FILTRO
              </MenuItem>
            )
          }
          {items.map((item, index) => (
            <MenuItem
              className={classes.options}
              key={index} 
              value={item.id}>
              {item.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    filtroListaPrecio: state.articulos.filtrosArticulosPorCliente.listaPrecioId,
    busquedaLoading: state.articulos.searchArticulosStockPrecio.loading,
    busquedaError: state.articulos.searchArticulosStockPrecio.error,
    estaCargado: state.comprobantes.comprobantePedido.estaCargado,
    comprobante: state.comprobantes.comprobantePedido.comprobante,
    clienteCabecera: state.clientes.clienteCabeceraById.cliente,

    marcas: state.articulos.marcas.marcas,
    loadingMarcas: state.articulos.marcas.loading,

    rubros: state.articulos.rubros.rubros,
    loadingRubro: state.articulos.rubros.loading,

    subrubros: state.articulos.subrubros.subrubros,
    loadingSubrubros: state.articulos.subrubros.loading,

    listaPrecios: state.articulos.listaPreciosPorCliente.listaPrecios,
    loadingListaPrecios: state.articulos.listaPreciosPorCliente.loading,

    depositos: state.articulos.depositosPorCliente.depositos,
    loadingDepositos: state.articulos.depositosPorCliente.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onStartBusquedaArticulo: (articulo, success) => {      
      dispatch(actions.searchArticulosStockPrecio(articulo, success)); 
    },
    onClearBusquedaArticulo: () => {
      dispatch(actions.clearSearchArticulosStockPrecio());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaAgregarArticulo);