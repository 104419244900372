import React, { useEffect, useState, useRef } from 'react';

import Chart from 'react-apexcharts';
import Spinner from '../UI/Spinner/Spinner';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton, Tooltip } from '@material-ui/core';

import classes from './Styles/BigChartCard.css';
import SinResultados from '../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../assets/images/IndicadorDeshabilitado.png';
import { formatNumber } from '../../assets/shared/formatNumber';
import locales from '../../assets/shared/locales';
import { colorsChart } from '../../assets/shared/colorsCharts';
import { enviarPorMailOption } from '../UI/EnviarChart/EnviarChartOptions';

const LineChart = (props) => {
  let chartRef = useRef(null);

  const [series, setSeries] = useState(props.series);

  useEffect(() => {
    setSeries(props.series);
  }, [props.series, props.seriesBandera]);

  const options = {
    chart: {
      defaultLocale: 'es',
      locales: locales,
      height: '100%',
      type: props.chartType,
      dropShadow: props.chartDropShadow,
      events: {
        markerClick: props.eventsMarkerClick
          ? function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
              props.eventsMarkerClick(event, chartContext, { seriesIndex, dataPointIndex, config });
            }
          : undefined,
        legendClick: props.eventsLegendClick
          ? function (chartContext, seriesIndex, config) {
              props.eventsLegendClick(chartContext, seriesIndex, config);
            }
          : undefined,
        dataPointSelection: props.eventsDataPointSelection
          ? function (event, chartContext, config) {
              props.eventsDataPointSelection(event, chartContext, config);
            }
          : undefined
      },
      toolbar: {
        show: props.toolbarShow,
        tools: {
          customIcons: [
            enviarPorMailOption(chartRef, props.title),
          ]
        },
      },
      zoom: props.disableZoom
        ? undefined
        : {
            enabled: true,
            type: props.zoomType ? props.zoomType : 'xy',
            autoScaleYaxis: false,
            zoomedArea: {
              fill: {
                color: '#90CAF9',
                opacity: 0.4
              },
              stroke: {
                color: '#0D47A1',
                opacity: 0.4,
                width: 1
              }
            }
          }
    },
    tooltip: {
      enabled: props.tooltipEnabled,
      intersect: props.eventsDataPointSelection,
      shared: !props.eventsDataPointSelection,
      custom: props.customTooltip
        ? function ({ series, seriesIndex, dataPointIndex, w }) {
            return props.customTooltip(series, seriesIndex, dataPointIndex, w);
          }
        : undefined
    },
    colors: props.colors ? props.colors : colorsChart,
    dataLabels: {
      enabled: props.dataLabelsEnabled,
      formatter: function (val, opts) {
        return props.formatDataLabels ? formatNumber(val) : val;
      }
    },
    stroke: {
      curve: props.strokeCurve,
      width: props.strokeWidth
    },
    fill: {
      type: props.fillType,
      gradient: props.fillGradient,
      opacity: props.fillOpacity
    },
    grid: {
      borderColor: props.gridBorderColor,
      row: props.gridRow
    },
    markers: {
      size: props.markersSize,
      colors: props.markersColors,
      strokeColors: props.markersStrokeColors,
      strokeWidth: props.markersStrokeWidth,
      hover: {
        size: props.markersHoverSize
      }
    },
    xaxis: {
      categories: props.xaxisCategories,
      type: props.xaxisType,
      tickAmount: props.xaxisTickAmount,
      tooltip: {
        enabled: props.xaxisTooltip
      }
    },
    yaxis: {
      min: props.yaxysMin,
      max: props.yaxysMax,
      labels: props.yaxisLabels ? props.yaxisLabels : {}
    },
    legend: {
      enabled: props.disableLegend ? false : true,
      fontSize: props.legendFontSize,
      height: props.legendHeight,
      offsetY: props.legendOffsetY,
      position: props.legendPosition,
      horizontalAlign: props.legendHorizontalAlign,
      showForSingleSeries: props.legendShowForSingleSeries,
      showForNullSeries: props.legendShowForNullSeries,
      showForZeroSeries: props.legendShowForZeroSeries,
      onItemClick: {
        toggleDataSeries: props.disableLegendOnItemClickToggle ? false : true
      },
      onItemHover: {
        highlightDataSeries: props.disableLengendOnItemHover ? false : true
      },
      formatter: function (seriesName, opts) {
        return props.legendFormatter ? props.legendFormater : seriesName;
      }
    },
    responsive: [
      {
        breakpoint: 750,
        options: {
          chart: {
            width: '100%'
          },
          yaxis: {
            labels: {
              show: false
            }
          }
        }
      }
    ]
  };

  return (
    <div
      id="customLineChart"
      ref={chartRef}
      className={classes.BigChartCard}
      style={{ width: props.width, minHeight: props.loading && '240px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          marginLeft: '20px'
        }}
      >
        <div className={classes.TitleCard}>
          <div style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '14px' }}>{props.title}</div>
          {props.middleItems && <div style={{ alignSelf: 'center' }}>{props.middleItems}</div>}
          <div style={{ alignSelf: 'center', display: 'flex' }}>
            {props.rightItems && props.rightItems}
            {props.onFiltersClick && !props.disabled && (
              <Tooltip title="Ver filtros del indicador">
                <IconButton size="small" onClick={() => props.onFiltersClick()}>
                  <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {props.subTitle && <p style={{ marginTop: '1px', fontSize: '12px' }}>{props.subTitle}</p>}
        {props.children}
      </div>
      {props.disabled ? (
        <div style={{ maxHeight: '245px' }}>
          <img alt="Indicador Inhabilitado" src={IndicadorDeshabilitado} />
        </div>
      ) : props.loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spinner progress={props.progress} progressTime={props.progressTime} />
        </div>
      ) : props.imgSinDatos || props.noClient ? (
        <div style={{ textAlign: 'center' }}>
          <img alt="Sin resultados" src={SinResultados} style={{ maxWidth: '100%' }} />
        </div>
      ) : (
        <Chart
          options={props.personalizeOptions ? props.personalizeOptions : options}
          style={{ textAlign: props.chartTextAlign ? props.chartTextAlign : 'center' }}
          series={series}
          height={props.chartHeight}
          type={props.chartType}
          width={props.chartWidth}
        />
      )}
    </div>
  );
};

export default LineChart;
