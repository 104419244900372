import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';
import LineChart from '../../../Charts/LineChart';

const LineEvolucionVentasDelVendedor = (props) => { 
  const {
    inClientInfo,
  } = props;

  return (
    <LineChart
      title={'Evolución de ventas por mes'}
      categories={inClientInfo ?
          props.ventasPorCliente && props.ventasPorCliente.map((venta) => {
            return new Date(venta.anio, venta.mes - 1, 1);
          })
        : props.ventas && props.ventas.map((venta) => {
          return new Date(venta.anio, venta.mes - 1, 1);
        })
      }
      textoTooltip='Monto'
      series={inClientInfo 
        ? props.ventasPorCliente && props.ventasPorCliente.map((venta) => venta.monto)
        : props.ventas && props.ventas.map((venta) => venta.monto)}
      width={props.width}
      loading={inClientInfo ? props.loadingPorCliente : props.loading}
      imgSinDatos={inClientInfo ? props.ventasPorCliente === 0 : props.ventas.length === 0}
      showXLabels
      labelsRotateAlways
      onFiltersClick={() => props.onShowFiltersModal()}
      height={props.height}
      minHeight={props.minHeight}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    ventas: state.vendedores.evolucionVentasMensualDelVendedor.ventas,
    loading: state.vendedores.evolucionVentasMensualDelVendedor.loading,
    ventasPorCliente: state.vendedores.evolucionVentasMensualDelVendedor.ventasPorCliente,
    loadingPorCliente: state.vendedores.evolucionVentasMensualDelVendedor.loadingPorCliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Evolución de ventas por mes', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineEvolucionVentasDelVendedor);
