import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { formatNumber, isEven } from '../../../../assets/shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno } from '../../../../assets/shared/sessionData';

import classes from './TablaVentasPorArticuloDeLaSeleccion.css';
import TablaVentasDelArticuloPorCliente from '../TablaVentasDelArticuloPorCliente/TablaVentasDelArticuloPorCliente';
import TableComprobantesVentasDelArticuloAlCliente from '../../comprobantes/TableComprobantesVentas/TableComprobantesVentasDelArticuloAlCliente';

const TablaVentasPorArticuloDeLaSeleccion = (props) => {
  const { dataSelectedId, clientesPrimero } = props;
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const mes = props.mes ? props.mes : props.itemExpanded.mes;
  const anio = props.anio ? props.anio : props.itemExpanded.anio;

  const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoría de cliente',
      onLoadKey: 'onLoadVentasPorArticulosDelCliente'
    },
    marca: {
      title: 'Marca',
      onLoadKey: 'onLoadVentasPorArticulosDeLaMarca'
    },
    rubro: {
      title: 'Rubro',
      onLoadKey: 'onLoadVentasPorArticulosDelRubro'
    },
    subrubro: {
      title: 'Subrubro',
      onLoadKey: 'onLoadVentasPorArticulosDelSubrubro'
    },
    vendedor: {
      title: 'Vendedor',
      onLoadKey: 'onLoadVentasPorArticulosDelVendedor'
    },
    provincia: {
      title: 'Provincia',
      onLoadKey: 'onLoadVentasPorArticulosDelCliente'
    },
    zona: {
      title: 'Zona',
      onLoadKey: 'onLoadVentasPorArticulosDelCliente'
    }
  };

  useEffect(() => {
    setLoading(true);
    props[dataOptions[dataSelectedId].onLoadKey](props.vendedorId, props.itemExpanded.id, mes, anio, (response) => {
      setVentasLocal(response);
      setLoading(false);
      props.endRequest && props.endRequest(response.length);
    });
  }, []);

  const columns = [
    {
      title: 'Articulo',
      field: 'nombre',
      render: (rowData) => rowData.id.trim() + ' - ' + rowData.nombre.trim(),
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: clientesPrimero ? 'rgb(220 220 220)' : 'rgb(235 235 235)'
      }
    },
    {
      title: ``,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: clientesPrimero ? 'rgb(220 220 220)' : 'rgb(235 235 235)'
      },
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: ``,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: clientesPrimero ? 'rgb(220 220 220)' : 'rgb(235 235 235)'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        nombre: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion
      };
    });
  };

  const inCategoriaDeCliente = dataSelectedId === 'categoriaDeCliente';
  const inZona = dataSelectedId === 'zona';
  const inProvincia = dataSelectedId === 'provincia';

  const verComprobantes = inCategoriaDeCliente || inZona || inProvincia;

  const tableRef = useRef();

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 39 * indexRow;
      props.endRequest && props.endRequest();
    }
  };

  return (
    <div className={classes.tablaVentasPorArticuloDeLaSeleccion}>
      <Table
        tableRef={tableRef}
        title=""
        style={{ width: '95%', marginTop: 0, marginBottom: '15px' }}
        columns={columns}
        loading={loading}
        detailPanel={(rowData) => {
          setTimeout(() => {
            autoScroll(rowData);
          }, 100);

          return verComprobantes ? (
            <TableComprobantesVentasDelArticuloAlCliente
              customWidth={'90%'}
              successRequest={() => autoScroll(rowData)}
              vendedorId={props.vendedorId}
              clienteId={props.itemExpanded.id}
              articleId={rowData.id}
              mes={mes}
              anio={anio}
            />
          ) : (
            <TablaVentasDelArticuloPorCliente
              endRequest={() => autoScroll(rowData)}
              vendedorId={props.vendedorId}
              articleId={rowData.id}
              mes={mes}
              anio={anio}
            />
          );
        }}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 275,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fechaVentasDelMes: state.vendedores.fechaVentasDelMesAnioDelVendedor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVentasPorArticulosDelCliente: (idVendedor, idCliente, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorArticuloDelCliente(idVendedor, idCliente, mes, anio, success, errorFail)),

    onLoadVentasPorArticulosDeLaMarca: (idVendedor, idMarca, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorArticuloDeLaMarca(idVendedor, idMarca, mes, anio, success, errorFail)),

    onLoadVentasPorArticulosDelRubro: (idVendedor, idRubro, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorArticuloDelRubro(idVendedor, idRubro, mes, anio, success, errorFail)),

    onLoadVentasPorArticulosDelSubrubro: (idVendedor, idSubrubro, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorArticuloDelSubrubro(idVendedor, idSubrubro, mes, anio, success, errorFail)),

    onLoadVentasPorArticulosDelVendedor: (idVendedor, idItemvendedor, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorArticuloDelVendedor(idItemvendedor, mes, anio, success, errorFail)),

    onLoadVentasPorArticulosDeLaProvincia: (idVendedor, idPrivincia, mes, anio, success, errorFail) =>
      dispatch(
        actions.loadVentasDelMesPorArticuloDeLaPrivincia(idVendedor, idPrivincia, mes, anio, success, errorFail)
      ),

    onLoadVentasPorArticulosDeLaZona: (idVendedor, idZona, mes, anio, success, errorFail) =>
      dispatch(actions.loadVentasDelMesPorArticuloDeLaZona(idVendedor, idZona, mes, anio, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasPorArticuloDeLaSeleccion);
