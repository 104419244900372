import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, Column, Button } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { connect } from "react-redux";
import eyeIcon from "../../../assets/images/eye-icon.svg";
import ModalQueryReq from "../ModalQueryReq/ModalQueryReq";

import classes from "./DetailTiemposReq.css";

const DetailTiemposReq = (props) => {
  const { tiempos } = props;
  const [queryDialogState, setQueryDialogState] = useState({
    show: false,
    content: "",
  });

  const getRequest = useCallback(() => {
    const key = props.data.key;
    return new DataSource({
      store: new ArrayStore({
        data: tiempos,
        key: "indicadorId",
      }),
      filter: ["indicadorId", "=", key],
    });
  }, [props.data.key]);

  useEffect(() => {
    getRequest();
  }, []);

  const onVerQueryClick = (e) => {
    setQueryDialogState({
      show: true,
      content: e.row.data.query.slice(10, -1),
    });
  };

  return (
    <React.Fragment>
      <DataGrid
        dataSource={getRequest(props.data.key)}
        showBorders={true}
        columnAutoWidth={true}
      >
        <Column dataField="serviceId" />
        <Column
          dataField="fecha"
          dataType="datetime"
          format={"dd/MM/yyyy HH:mm:ss"}
        />
        <Column dataField="tiempo" caption="Tiempo Api Repository" />
        <Column dataField="tiempoApiTraductora" />
        <Column dataField="tiempoQuery" />
        <Column caption="Ver Query" type="buttons">
          <Button
            cssClass={classes.eyeButton}
            icon={eyeIcon}
            onClick={(e) => onVerQueryClick(e)}
          />
        </Column>
      </DataGrid>
      {queryDialogState.show && (
        <ModalQueryReq
          show={queryDialogState.show}
          query={queryDialogState.content}
          onClose={() => setQueryDialogState({ show: false, content: "" })}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tiempos: state.configuraciones.loadTiemposRequest.tiempos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTiemposReq);
