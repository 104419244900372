import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import classes from "./NavigationItem.css";

import { Tooltip } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  listItem: {
    "&.Mui-selected": {
      background:`linear-gradient(to right, #FBB040, #F15A29)`
    },
    "&:focus": {
      background:`linear-gradient(to right, #FBB040, #F15A29)`
    },
  }
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "3px",
  },
}))(Tooltip);

const NavigationItem = (props) => {
  const classes2 = useStyles();

  const onItemClick = () => {
    sessionStorage.setItem('seccionNombre', props.title);
    props.onClickItem();
    props.cambiarPage(props.link);
  }
  
  return (
    <React.Fragment>
      <LightTooltip arrow title={props.title} placement="right">
        <ListItem
          className={classes2.listItem}
          button
          key={props.text}
          component={NavLink}
          to={props.link}
          exact={props.exact}
          activeClassName="Mui-selected"
          onClick={() => onItemClick()}
        >
          <ListItemIcon className={classes.NavigationItem}>
            {props.children}
          </ListItemIcon>
          <ListItemText
            primary={props.title}
            className={classes.NavigationItem}
          />
        </ListItem>
      </LightTooltip>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    cambiarPage: (page) => dispatch(actions.cambioPaginaRender(page))
  };
};

export default connect(null, mapDispatchToProps)(NavigationItem);