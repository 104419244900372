// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CatalogoVendedor__CatalogoContainer__35Xbo {\r\n  display: flex !important;\r\n  flex: 1 1 !important;\r\n}\r\n\r\n.CatalogoVendedor__CatalogoFiltros__234j8 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 10px !important;\r\n}\r\n\r\n.CatalogoVendedor__CatalogoArticulos__ysWl3 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n  margin-top: 20px;\r\n}\r\n\r\n.CatalogoVendedor__CatalogoIrPedido__24Trv {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: flex-end;\r\n}\r\n\r\n.CatalogoVendedor__MuiFormGroup-root__3L_i2 .CatalogoVendedor__MuiFormGroup-row__36KTY {\r\n  padding-top: 10px;\r\n}\r\n\r\n.CatalogoVendedor__root__1YwqJ {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: space-around;\r\n  overflow: hidden;\r\n}\r\n\r\n.CatalogoVendedor__rootFormControl__2CGTs {\r\n  display: flex; \r\n  align-items: center;\r\n}\r\n\r\n.CatalogoVendedor__rootControl__3IHoz {\r\n  margin: 0px 10px 0px 10px; \r\n  color: black;\r\n  width: 100%;\r\n}\r\n\r\n.CatalogoVendedor__select__1aeQM {\r\n  min-width: 120 !important; \r\n  color: black; \r\n  font-size: small !important;\r\n  text-transform: uppercase !important;\r\n}\r\n\r\n.CatalogoVendedor__options__3uAzY {\r\n  font-size: small !important;\r\n  text-transform: uppercase !important;\r\n}\r\n\r\n.CatalogoVendedor__gridContainer__1B42h {\r\n  display: flex; \r\n  align-items: center;\r\n}\r\n\r\n.CatalogoVendedor__gridItem__1i0T5 {\r\n  width: 100%;\r\n}\r\n\r\n.CatalogoVendedor__gridElement__3Kw9d {\r\n  width: 100%;\r\n}\r\n\r\n.CatalogoVendedor__gridFieldset__3I7Nk {\r\n  align-self: flex-end; \r\n  padding-top: 10px;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CatalogoContainer": "CatalogoVendedor__CatalogoContainer__35Xbo",
	"CatalogoFiltros": "CatalogoVendedor__CatalogoFiltros__234j8",
	"CatalogoArticulos": "CatalogoVendedor__CatalogoArticulos__ysWl3",
	"CatalogoIrPedido": "CatalogoVendedor__CatalogoIrPedido__24Trv",
	"MuiFormGroup-root": "CatalogoVendedor__MuiFormGroup-root__3L_i2",
	"MuiFormGroup-row": "CatalogoVendedor__MuiFormGroup-row__36KTY",
	"root": "CatalogoVendedor__root__1YwqJ",
	"rootFormControl": "CatalogoVendedor__rootFormControl__2CGTs",
	"rootControl": "CatalogoVendedor__rootControl__3IHoz",
	"select": "CatalogoVendedor__select__1aeQM",
	"options": "CatalogoVendedor__options__3uAzY",
	"gridContainer": "CatalogoVendedor__gridContainer__1B42h",
	"gridItem": "CatalogoVendedor__gridItem__1i0T5",
	"gridElement": "CatalogoVendedor__gridElement__3Kw9d",
	"gridFieldset": "CatalogoVendedor__gridFieldset__3I7Nk"
};
export default ___CSS_LOADER_EXPORT___;
