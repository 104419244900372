import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  cuentaCorrienteModal: {
    show: false,
    inClientInfo: false,
  },
  globalModal: {
    show: false,
    title: '',
    body: '',
    footer: '',
    disableCloseButton: false,
    fullWidth: false,
    maxWidth: ''
  },
  listFiltersModal: {
    show: false,
    title: '',
    filterTypes: []
  },
  sendChartModal: {
    show: false,
    chartRef: null,
    tituloChart: '',
  },
  backButtonModal: {
    show: false,
  },
};

const showCuentaCorrienteModal = (state, show, inClientInfo) => {
  return updateObject(state, {
    cuentaCorrienteModal: updateObject(state.cuentaCorrienteModal, {
      show,
      inClientInfo,
    }),
  });
};

const showGlobalModal = (state, show, title, body, footer, disableCloseButton, fullWidth, maxWidth) => {
  return updateObject(state, {
    globalModal: updateObject(state.globalModal, {
      show,
      title,
      body,
      footer,
      disableCloseButton,
      fullWidth,
      maxWidth
    })
  });
};

const changeGlobalModalBody = (state, body) => {
  return updateObject(state, {
    globalModal: updateObject(state.globalModal, {
      body
    })
  });
};

const showListFiltersModal = (state, show, title, filterTypes) => {
  return updateObject(state, {
    listFiltersModal: updateObject(state.listFiltersModal, {
      show,
      title,
      filterTypes
    })
  });
};

const showSendChartModal = (state, show, chartRef, tituloChart) => {
  return updateObject(state, {
    sendChartModal: updateObject(state.sendChartModal, {
      show,
      chartRef,
      tituloChart
    })
  });
}

const showBackButtonModal = (state, show) => {
  return updateObject(state, {
    backButtonModal: updateObject(state.backButtonModal, {
      show
    })
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_CUENTA_CORRIENTE_MODAL:
      return showCuentaCorrienteModal(state, action.show, action.inClientInfo);

    case actionTypes.SHOW_GLOBAL_MODAL:
      return showGlobalModal(
        state,
        action.show,
        action.title,
        action.body,
        action.footer,
        action.disableCloseButton,
        action.fullWidth,
        action.maxWidth
      );
    case actionTypes.CHANGE_GLOBAL_MODAL_BODY:
      return changeGlobalModalBody(state, action.newBody);
  
    case actionTypes.SHOW_LIST_FILTERS_MODAL:
      return showListFiltersModal(state, action.show, action.title, action.filterTypes);

    case actionTypes.SHOW_SEND_CHART_MODAL:
      return showSendChartModal(state, action.show, action.chartRef, action.tituloChart);

    case actionTypes.SHOW_BACK_BUTTON_MODAL:
      return showBackButtonModal(state, action.show);

    default:
      return state;
  }
};

export default reducer;
