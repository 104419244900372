import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Paper, TextField, Typography, Divider, InputAdornment } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CabeceraPedido from '../../components/Pedido/CabeceraPedido';
import DetallePedido from '../../components/Pedido/DetallePedido';
import ResumenPedido from '../../components/Pedido/ResumenPedido';
import { CarritoContext } from "../../store/contexts/carrito";
import { ContactoModel, DatosFacturacionModel, PedidoModel } from '../../models/Pedido';
import Buton from "../../components/UI/Button/Button";

import * as actions from "../../store/actions";
import { storageEmpresaId, storageUserEmail } from "../../assets/shared/sessionData";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  title:{
    fontSize: 'x-large',
    textTransform: 'uppercase',
  },
  sections:{
    marginBottom: '10px',
    marginTop: '10px',
  },
  buttonsContainer:{
    display: 'flex',
    alignItems: 'self-end',
    backgroundColor: 'transparent',
    justifyContent: 'flex-end',
  },
  buttonsSections:{
    width: '100%',
    marginTop: '10px',
    marginBottom: '100px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  paper:{
    width: '100%',
    padding: '20px',
  },
}));

const Pedido = (props) => {
  const classes = useStyles();
  
  const {
    onSendPedido,
    clienteCabecera,
    comprobante,
    estaEnviado,
    enviandoPedido,
    onIrACatalogo,
    onIrAConfirmacion,
    vendedor,
  } = props; 

  const { 
    carritoItems,
    resumen,
  } = useContext(CarritoContext);

  const observacionesDefault = {
    leyenda1: "",
    leyenda2: "",
    leyenda3: "",
    leyenda4: "",
    leyenda5: "",
    leyenda6: "",
    leyenda7: "",
    leyenda8: "",
  };

  const observacionesObject = sessionStorage.getItem('observacionesObject') 
    ? JSON.parse(sessionStorage.getItem('observacionesObject')) 
    : observacionesDefault;

  const [observaciones, setObservaciones] = useState(observacionesObject);

  const onObservacionesChange = (item) => {
    setObservaciones(item);
    sessionStorage.setItem('observacionesObject', JSON.stringify(item));
  }

  const armarPedido = () => {    
    const pedido = new PedidoModel();
    pedido.empresaId = storageEmpresaId();
    pedido.usuarioId = storageUserEmail();
    pedido.vendedor = vendedor.nombre;
    pedido.vendedorId = vendedor.idVendedor;
    pedido.vendedorMail = vendedor.mail;
    pedido.comision = vendedor.comision;
    pedido.setFromCliente(clienteCabecera);
    pedido.setFromComprobante(comprobante);
    pedido.setFromResumen(resumen);
    pedido.setFromCarrito(carritoItems, resumen, comprobante);
    pedido.setFromObservaciones(observaciones);

    const contacto = new ContactoModel();
    contacto.setFromCliente(clienteCabecera);
    pedido.setContacto(contacto);

    const datosFacturacion = new DatosFacturacionModel();
    datosFacturacion.setFromCliente(clienteCabecera);
    pedido.setDatosFacturacion(datosFacturacion);

    return pedido;
  };
  
  const onConfirmarPedidoSuccess = () => {
    sessionStorage.removeItem('observacionesObject');
    onIrAConfirmacion();
  }

  const onConfirmarPedidoError = () => {
  }

  const onConfirmarPedido = () => {
    const pedido = armarPedido();
    onSendPedido(pedido, () => onConfirmarPedidoSuccess(), () => onConfirmarPedidoError());
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} className={classes.sections}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={4} md={2} xl={2}>
          <Typography className={classes.title}>
            Pedido
          </Typography>
        </Grid>
        <Grid 
          item xs={4} md={2} xl={2}
          className={classes.buttonsContainer}
        >        
          <Buton
            style={{ width: '100%' }}
            color="primary"
            disabled={enviandoPedido}
            onClick={() => onIrACatalogo()}     
            >
            Volver al Catálogo
          </Buton>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <Divider />
        </Grid>       
      </Grid>
      <Grid item xs={12} md={12} xl={12} className={classes.sections}>
        <CabeceraPedido />
      </Grid>
      <Grid item xs={12} md={12} xl={12} className={classes.sections}>
        <Paper className={classes.paper}>
          <DetallePedido />
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} xl={12} className={classes.sections}>
        <Paper className={classes.paper}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Observaciones item={observaciones} onChange={onObservacionesChange} />
            </Grid>
            <Grid item xs={12} md={4}>
              <ResumenPedido />
            </Grid>
          </Grid>
        </Paper>        
      </Grid>
      <Grid item xs={12} md={12} className={classes.sections}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12} className={classes.buttonsSections}>
        <Botones 
          onConfirmarPedido={onConfirmarPedido}
          onIrACatalogo={onIrACatalogo}
          estaEnviado={estaEnviado}
          enviandoPedido={enviandoPedido}
        />
      </Grid>
    </Grid>
  );
};

const Observaciones = (props) => {
  const {
    item,
    onChange,
  } = props;

  const [observacionesCargadas, setObservacionesCargadas] = useState(false);
  const [leyenda1, setLeyenda1] = useState("");
  const [leyenda2, setLeyenda2] = useState("");
  const [leyenda3, setLeyenda3] = useState("");
  const [leyenda4, setLeyenda4] = useState("");
  const [leyenda5, setLeyenda5] = useState("");
  const [leyenda6, setLeyenda6] = useState("");
  const [leyenda7, setLeyenda7] = useState("");
  const [leyenda8, setLeyenda8] = useState("");

  const onLeyendaChange = useCallback((setLeyenda, value, nro) => {

    if(value.length <= 100){
      setLeyenda(value);
  
      const observaciones = {
        leyenda1: nro === 1 ? value : leyenda1,
        leyenda2: nro === 2 ? value : leyenda2,
        leyenda3: nro === 3 ? value : leyenda3,
        leyenda4: nro === 4 ? value : leyenda4,
        leyenda5: nro === 5 ? value : leyenda5,
        leyenda6: nro === 6 ? value : leyenda6,
        leyenda7: nro === 7 ? value : leyenda7,
        leyenda8: nro === 8 ? value : leyenda8,
      };
  
      onChange(observaciones);
    }
  }, [
    leyenda1, leyenda2, leyenda3, leyenda4,
    leyenda5, leyenda6, leyenda7, leyenda8,
    onChange,
  ]);

  useEffect(() => {
    if(!observacionesCargadas && item !== null && item !== undefined){
      setLeyenda1(item.leyenda1, 1);
      setLeyenda2(item.leyenda2, 2);
      setLeyenda3(item.leyenda3, 3);
      setLeyenda4(item.leyenda4, 4);
      setLeyenda5(item.leyenda5, 5);
      setLeyenda6(item.leyenda6, 6);
      setLeyenda7(item.leyenda7, 7);
      setLeyenda8(item.leyenda8, 8);

      setObservacionesCargadas(true);
    }
  }, [
    observacionesCargadas,
    item,
  ]);

  const getLeyendaField = (nro, leyenda, setLeyenda) => {
    return (
      <Grid item xs={12} md={12}>
        <TextField
          style={{ width: '100%' }}
          value={leyenda}
          size="small"
          onChange={(e) => onLeyendaChange(setLeyenda, e.target.value, nro)}
          inputProps={{ fontSize: 'revert'}}
          InputProps={{
            startAdornment: <InputAdornment position="start">{`${nro}. `} </InputAdornment>,
            fullWidth: true,
            maxLength: 100,
          }}
        />
      </Grid>
    );
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}
          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
        >
          <Typography style={{fontSize: 'large', fontWeight: 'bold', marginRight: '2px'}}>
            {'Observaciones  '}
          </Typography>
          <Typography style={{fontSize: 'smaller', marginLeft: '2px' }}>
            {'(Ingrese aquí sus observaciones sobre el pedido...)'}
          </Typography>
          <Divider />
        </Grid>
        {getLeyendaField(1, leyenda1, setLeyenda1)}
        {getLeyendaField(2, leyenda2, setLeyenda2)}
        {getLeyendaField(3, leyenda3, setLeyenda3)}
        {getLeyendaField(4, leyenda4, setLeyenda4)}
        {getLeyendaField(5, leyenda5, setLeyenda5)}
        {getLeyendaField(6, leyenda6, setLeyenda6)}
        {getLeyendaField(7, leyenda7, setLeyenda7)}
        {getLeyendaField(8, leyenda8, setLeyenda8)}
      </Grid>
    </div>
  );
}

const Botones = (props) => {  
  const {
    onConfirmarPedido,
    estaEnviado,
    enviandoPedido,
    onIrACatalogo,
  } = props;

  const { 
    itemCount,
    clearCarrito,
  } = useContext(CarritoContext);

  const onCancelarPedido = () => {
    clearCarrito();
    onIrACatalogo();
  };

  return (
    <Grid 
      container spacing={1}
      direction="row"
      justify="flex-end"
      alignItems="center"
    >
      <Grid item xs={4} md={2} xl={2} >
        <Buton
          style={{ width: '100%' }}
          color="primary"
          disabled={itemCount <= 0 || (enviandoPedido && !estaEnviado)}
          loading={(enviandoPedido && !estaEnviado)}    
          onClick={() => onConfirmarPedido()} 
        >
          Confirmar Pedido
        </Buton>
      </Grid>
      <Grid item xs={4} md={2} xl={2} >
        <Buton
          style={{ width: '100%', color: 'white', backgroundColor: '#E45164' }}
          disabled={enviandoPedido && !estaEnviado}
          onClick={() => onCancelarPedido()}
        >
          Cancelar Pedido
        </Buton>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    clienteCabecera: state.clientes.clienteCabeceraById.cliente,
    comprobante: state.comprobantes.comprobantePedido.comprobante,
    estaEnviado: state.comprobantes.sendPedido.estaEnviado,
    enviandoPedido: state.comprobantes.sendPedido.loading,
    vendedor: state.vendedores.vendedorSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendPedido: (pedido, success, error) => {
      dispatch(actions.sendPedido(pedido, success, error));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pedido);