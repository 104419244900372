import React from "react";
import Buton from "../../UI/Button/Button";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";

const AgregarCarritoButton = (props) => {
  const { onAddClick, } = props;

  return (
    <Buton
      style={{ with: "100%" }}
      size="small"
      onClick={onAddClick}
    >
      {<AddShoppingCartIcon style={{ marginRight: "10px" }} />}
      Agregar {" "}
    </Buton>
  );
};

export default AgregarCarritoButton;