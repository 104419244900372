import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  fotoDelDia: "",
  cliente: "",
  tiemposReq: "",
  catalogo: "",
  changeLogs: "",
  analisisDeVentas: "",
};

//#region Foto del dia
const updateUltimaActualizacionFotoDelDia = (state, fechaHora) => {
  return updateObject(state, {
    fotoDelDia: `Foto del día: ${fechaHora}`,
  });
};

const clearUltimaActualizacionFotoDelDia = (state) => {
  return updateObject(state, {
    fotoDelDia: ``,
  });
};
//#endregion

//#region Cliente
const updateUltimaActualizacionCliente = (state, fechaHora) => {
  return updateObject(state, {
    cliente: `Cliente: ${fechaHora}`,
  });
};

const clearUltimaActualizacionCliente = (state) => {
  return updateObject(state, {
    cliente: ``,
  });
};
//#endregion

//#region Catalogo
const updateUltimaActualizacionCatalogo = (state, fechaHora) => {
  return updateObject(state, {
    catalogo: `Catalogo: ${fechaHora}`,
  });
};

const clearUltimaActualizacionCatalogo = (state) => {
  return updateObject(state, {
    catalogo: ``,
  });
};
//#endregion

//#region Tiempos request
const updateUltimaActualizacionTiemposRequest = (state, fechaHora) => {
  return updateObject(state, {
    tiemposReq: `Tiempos request: ${fechaHora}`,
  });
};

const clearUltimaActualizacionTiemposRequest = (state) => {
  return updateObject(state, {
    analisisDeVentas: ``,
  });
};
//#endregion

//#region Tiempos request
const updateUltimaActualizacionChangeLogs = (state, fechaHora) => {
  return updateObject(state, {
    changeLogs: `Versionado: ${fechaHora}`,
  });
};

const clearUltimaActualizacionChangeLogs = (state) => {
  return updateObject(state, {
    changeLogs: ``,
  });
};
//#endregion

//#region Analisis de Ventas
const updateUltimaActualizacionAnalisisDeVentas = (state, fechaHora) => {
  return updateObject(state, {
    analisisDeVentas: `Analisis de ventas: ${fechaHora}`,
  });
};

const clearUltimaActualizacionAnalisisDeVentas = (state) => {
  return updateObject(state, {
    analisisDeVentas: ``,
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA:
      return updateUltimaActualizacionFotoDelDia(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA:
      return clearUltimaActualizacionFotoDelDia(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_CLIENTE:
      return updateUltimaActualizacionCliente(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_CLIENTE:
      return clearUltimaActualizacionCliente(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_CATALOGO:
      return updateUltimaActualizacionCatalogo(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_CATALOGO:
      return clearUltimaActualizacionCatalogo(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST:
      return updateUltimaActualizacionTiemposRequest(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST:
      return clearUltimaActualizacionTiemposRequest(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_CHANGE_LOGS:
      return updateUltimaActualizacionChangeLogs(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_CHANGE_LOGS:
      return clearUltimaActualizacionChangeLogs(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_VENTAS:
      return updateUltimaActualizacionAnalisisDeVentas(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_VENTAS:
      return clearUltimaActualizacionAnalisisDeVentas(state);

    default:
      return state;
  }
};

export default reducer;
