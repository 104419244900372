const S3_BucketName= "jalisco";
const Region= "us-east-1";
const AccessKeyId= "AKIASK2GBY2DZ6ZH2YIY";
const SecretAccessKey= "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";

export const S3Config = {
  buckeName: S3_BucketName,
  region: Region,
  accessKeyId: AccessKeyId,
  secretAccessKey: SecretAccessKey
}