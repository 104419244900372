import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formatNumber } from '../../../../assets/shared/formatNumber';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import * as actions from '../../../../store/actions';

import PieChart from '../../../Charts/PieChart';
import { storageFechaFija, storageMonedaSigno } from '../../../../assets/shared/sessionData';

import classes from './PieVentasDelVendedorPorSeleccion.css';

const PieVentasDelVendedorPorSeleccion = (props) => {
  const { dataSelectedId } = props;
  const isDisabledChart = props.estaOculto;

  const dataOptions = {
    categoriaDeCliente: {
      title: 'Categoría de cliente',
      stateKey: 'ventasPorCategoriaDeCliente',
      onLoadKey: 'onLoadVentasPorCategoriasDeCliente'
    },
    marca: {
      title: 'Marca',
      stateKey: 'ventasPorMarca',
      onLoadKey: 'onLoadVentasPorMarca'
    },
    rubro: {
      title: 'Rubro',
      stateKey: 'ventasPorRubro',
      onLoadKey: 'onLoadVentasPorRubro'
    },
    subrubro: {
      title: 'Subrubro',
      stateKey: 'ventasPorSubrubro',
      onLoadKey: 'onLoadVentasPorSubrubro'
    },
    provincia: {
      title: 'Provincia',
      stateKey: 'ventasPorProvincia',
      onLoadKey: 'onLoadVentasPorProvincia'
    },
    zona: {
      title: 'Zona',
      stateKey: 'ventasPorZona',
      onLoadKey: 'onLoadVentasPorZona'
    }
  };

  const dataSelectOptions = [
    {
      id: 'categoriaDeCliente',
      title: 'Categoría de cliente'
    },
    {
      id: 'marca',
      title: 'Marca'
    },
    {
      id: 'rubro',
      title: 'Rubro'
    },
    {
      id: 'subrubro',
      title: 'Subrubro'
    },
    {
      id: 'provincia',
      title: 'Provincia'
    },
    {
      id: 'zona',
      title: 'Zona'
    }
  ];

  const handleChangeOptionSelected = (e) => {
    e.preventDefault();
    props.onChangeOptionSelected(e.target.value);
  };

  const optionSelector = () => {
    const fechaDeDatos = props[dataOptions[dataSelectedId].stateKey].fecha;
    const loading = props[dataOptions[dataSelectedId].stateKey].loading;
    
    return (
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 400, marginRight: '15px' }}>
        <div className={classes.LabelVerPor}>
          Ver ventas del{' '}
          {loading ? '--/----' : moment(fechaDeDatos ? fechaDeDatos : storageFechaFija()).format('MM/yyyy')} por:{' '}
        </div>
        <Select
          input={<Input classes={{ input: classes.SelectRoot }} />}
          style={{ marginLeft: '5px' }}
          value={props.dataSelectedId}
          onChange={(e) => handleChangeOptionSelected(e)}
        >
          {dataSelectOptions.map((option, index) => {
            return (
              <MenuItem dense key={index} value={option.id}>
                {option.title}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  const gastosNegativos = () => {
    return (
      !isDisabledChart &&
      props[dataOptions[dataSelectedId].stateKey].ventasNegativo.map((ventaNegativo) => ({
        nombre: ventaNegativo[dataSelectedId === 'categoriaDeCliente' ? 'categoria' : 'nombre'],
        monto: ventaNegativo.monto
      }))
    );
  };

  return (
    <PieChart
      title={optionSelector()}
      disabledCss
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(props[dataOptions[dataSelectedId].stateKey].total)}`}
      series={props[dataOptions[dataSelectedId].stateKey].ventas.map((venta) => venta.monto)}
      labels={props[dataOptions[dataSelectedId].stateKey].ventas.map(
        (venta) => venta[dataSelectedId === 'categoriaDeCliente' ? 'categoria' : 'nombre']
      )}
      valoresNegativos={gastosNegativos()}
      loading={props[dataOptions[dataSelectedId].stateKey].loading}
      imgSinDatos={props[dataOptions[dataSelectedId].stateKey].ventas.length === 0}
      height={props.height}
      width={props.width}
      disabled={isDisabledChart}
      chartType={props.chartType}
      spaceBetweenChartTitle={props.spaceBetweenChartTitle}
      legendHeight={props.legendHeight}
      legendPosition={props.legendPosition}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    fecha: state.vendedores.fechaVentasDelMesAnioDelVendedor,
    dataSelectedId: state.vendedores.dataVentasDelMesDelVendedorPorSelected,
    ventasPorCategoriaDeCliente: state.vendedores.ventasDelMesPorCategoriaDeClienteDelVendedor,
    ventasPorMarca: state.vendedores.ventasDelMesPorMarcaDelVendedor,
    ventasPorProvincia: state.vendedores.ventasDelMesPorProvinciasDelVendedor,
    ventasPorRubro: state.vendedores.ventasDelMesPorRubroDelVendedor,
    ventasPorSubrubro: state.vendedores.ventasDelMesPorSubrubroDelVendedor,
    ventasPorZona: state.vendedores.ventasDelMesPorZonasDelVendedor
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selecionId) => dispatch(actions.seleccionDataVentasDelMesDelVendedorPor(selecionId)),

    onLoadVentasPorCategoriasDeCliente: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasPorCategoriaDeClienteDelVendedor(idVendedor, mes, anio)),
    onLoadVentasPorMarca: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasDelMesPorMarcaDelVendedor(idVendedor, mes, anio)),
    onLoadVentasPorRubro: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasDelMesPorRubroDelVendedor(idVendedor, mes, anio)),
    onLoadVentasPorSubrubro: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasDelMesPorSubrubroDelVendedor(idVendedor, mes, anio)),
    onLoadVentasPorProvincia: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasDelMesPorProvinciaDelVendedor(idVendedor, mes, anio)),
    onLoadVentasPorZona: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasDelMesPorZonaDelVendedor(idVendedor, mes, anio))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieVentasDelVendedorPorSeleccion);
