import * as actionTypes from "./actionTypes";
import axiosVendedores from "../../axios-vendedores";
import axiosDashboard from "../../axios-dashboard";
import idIndicadores from "../../assets/shared/idIndicadores";
import { storageEmpresaId, storageMonedaId } from "../../assets/shared/sessionData";

export const loadClientesInfoMinima = (
  idVendedor = sessionStorage.getItem("vendedorId")
) => {
  return (dispatch) => {
    dispatch(loadClientesInfoMinimaStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

    axiosVendedores
      .get(
        `/clientes/ClientesInfoMinima?idVendedor=${idVendedor}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.clientesInfoMinima}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadClientesInfoMinimaSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadClientesInfoMinimaFail(error));
          } else {
            dispatch(loadClientesInfoMinima());
          }
        } else {
          dispatch(loadClientesInfoMinimaFail(error));
        }
      });
  };
};

export const clearClientesInfoMinima = () => {
  return {
    type: actionTypes.CLEAR_CLIENTES_INFO_MINIMA,
  };
};

const loadClientesInfoMinimaStart = () => {
  return {
    type: actionTypes.LOAD_CLIENTES_INFO_MINIMA_START,
  };
};

const loadClientesInfoMinimaSuccess = (clientes) => {
  return {
    type: actionTypes.LOAD_CLIENTES_INFO_MINIMA_SUCCESS,
    clientes,
  };
};

const loadClientesInfoMinimaFail = (error) => {
  return {
    type: actionTypes.LOAD_CLIENTES_INFO_MINIMA_FAIL,
    error,
  };
};

export const loadClienteById = (idCliente, success) => {
  return (dispatch) => {
    dispatch(loadClienteByIdStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosVendedores
      .get(
        `/clientes/clienteInfoMedia?idCliente=${idCliente}&indicadorId=${
          idIndicadores.clienteInfoMedia
        }&idEmpresa=${sessionStorage.getItem("empresaId")}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadClienteByIdSucces(response.data));
        return success(Promise.resolve);
      })
      .catch((error) => {
        if (error.response && (error.response.status === 500 || error.response.status === 404)) {
          dispatch(loadClienteByIdFail(error));
        } else {
          dispatch(loadClienteById(idCliente, success));
        }
      });
  };
};

const loadClienteByIdStart = () => {
  return {
    type: actionTypes.LOAD_CLIENTE_BY_ID_START,
  };
};

const loadClienteByIdSucces = (cliente) => {
  return {
    type: actionTypes.LOAD_CLIENTE_BY_ID_SUCCESS,
    cliente,
  };
};

const loadClienteByIdFail = (error) => {
  return {
    type: actionTypes.LOAD_CLIENTE_BY_ID_FAIL,
    error,
  };
};

export const unmountClienteById = () => {
  return {
    type: actionTypes.UNMOUNT_CLIENTE_BY_ID,
  };
};

export const loadUltimaCompraByCliente = (idCliente) => {
  return (dispatch) => {
    dispatch(loadUltimaCompraByClienteStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosVendedores
      .get(
        `/clientes/ultimaCompra?idCliente=${idCliente}&indicadorId=${
          idIndicadores.ultimaCompra
        }&empresaId=${sessionStorage.getItem("empresaId")}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        if (response.status === 204) {
          dispatch(loadUltimaCompraByClienteFail(response));
        }
        const { fecha, id_Comproba, nombre, referencia, total } = response.data;
        dispatch(
          loadUltimaCompraByClienteSuccess(
            fecha,
            id_Comproba,
            nombre,
            referencia,
            total
          )
        );
      })
      .catch((error) => {
        if (error.response && (error.response.status === 500 || error.response.status === 404)) {
          dispatch(loadUltimaCompraByClienteFail(error));
        } else {
          dispatch(loadUltimaCompraByCliente(idCliente));
        }
      });
  };
};

export const clearUltimaCompraByCliente = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_COMPRA_BY_CLIENTE,
  };
};

const loadUltimaCompraByClienteStart = () => {
  return {
    type: actionTypes.LOAD_ULTIMA_COMPRA_BY_CLIENTE_START,
  };
};

const loadUltimaCompraByClienteSuccess = (
  fecha,
  idComproba,
  nombre,
  referencia,
  total
) => {
  return {
    type: actionTypes.LOAD_ULTIMA_COMPRA_BY_CLIENTE_SUCCESS,
    fecha,
    idComproba,
    nombre,
    referencia,
    total,
  };
};

const loadUltimaCompraByClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_ULTIMA_COMPRA_BY_CLIENTE_FAIL,
    error,
  };
};

export const loadClienteCabeceraById = (idCliente) => {
  return (dispatch) => {
    dispatch(loadClienteCabeceraByIdStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));    
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

    axiosVendedores
      .get(
        `/clientes/clienteInfoCabeceraPedido?idCliente=${idCliente}&idEmpresa=${idEmpresa}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadClienteCabeceraByIdSucces(response.data));
      })
      .catch((error) => {
        if (error.response && (error.response.status === 500 || error.response.status === 404)) {
          dispatch(loadClienteCabeceraByIdFail(error));
        } 
      });
  };
};

const loadClienteCabeceraByIdStart = () => {
  return {
    type: actionTypes.LOAD_CLIENTE_CABECERA_BY_ID_START,
  };
};

const loadClienteCabeceraByIdSucces = (cliente) => {
  return {
    type: actionTypes.LOAD_CLIENTE_CABECERA_BY_ID_SUCCESS,
    cliente,
  };
};

const loadClienteCabeceraByIdFail = (error) => {
  return {
    type: actionTypes.LOAD_CLIENTE_CABECERA_BY_ID_FAIL,
    error,
  };
};

export const loadCuentaCorrienteCliente = (idCliente) => {
  return (dispatch) => {
    dispatch(loadCuentaCorrienteClienteStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const idEmpresa = storageEmpresaId();
    const idMoneda = storageMonedaId();

    axiosDashboard
      .get(
        `/clientes/CuentaCorriente?idMoneda=${idMoneda}&idCliente=${idCliente}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.cuentaCorrienteCliente}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadCuentaCorrienteClienteSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadCuentaCorrienteClienteFail(error));
          } else {
            dispatch(loadCuentaCorrienteCliente(idCliente));
          }
        } else {
          dispatch(loadCuentaCorrienteClienteFail(error));
        }
      });
  };
};

export const clearCuentaCorrienteCliente = () => {
  return {
    type: actionTypes.CLEAR_CUENTA_CORRIENTE_CLIENTE,
  };
};

const loadCuentaCorrienteClienteStart = () => {
  return {
    type: actionTypes.LOAD_CUENTA_CORRIENTE_CLIENTE_START,
  };
};

const loadCuentaCorrienteClienteSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_CUENTA_CORRIENTE_CLIENTE_SUCCESS,
    comprobantes,
  };
};

const loadCuentaCorrienteClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_CUENTA_CORRIENTE_CLIENTE_FAIL,
    error,
  };
};