export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SET_REDIRECT_PATH = "AUTH_SET_REDIRECT_PATH";

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const LOAD_VENTAS_MES_COMPARATIVO_START =
  "LOAD_VENTAS_MES_COMPARATIVO_START";
export const LOAD_VENTAS_MES_COMPARATIVO_SUCCESS =
  "LOAD_VENTAS_MES_COMPARATIVO_SUCCESS";
export const LOAD_VENTAS_MES_COMPARATIVO_POR_CLIENTE_START =
  "LOAD_VENTAS_MES_COMPARATIVO_POR_CLIENTE_START";
export const LOAD_VENTAS_MES_COMPARATIVO_POR_CLIENTE_SUCCESS =
  "LOAD_VENTAS_MES_COMPARATIVO_POR_CLIENTE_SUCCESS";
export const LOAD_VENTAS_MES_COMPARATIVO_FAIL =
  "LOAD_VENTAS_MES_COMPARATIVO_FAIL";
export const CLEAR_VENTAS_MES_COMPARATIVO = "CLEAR_VENTAS_MES_COMPARATIVO";
export const CLEAR_VENTAS_MES_COMPARATIVO_POR_CLIENTE =
  "CLEAR_VENTAS_MES_COMPARATIVO_POR_CLIENTE";

export const LOAD_PEDIDOS_PENDIENTES_START = "LOAD_PEDIDOS_PENDIENTES_START";
export const LOAD_PEDIDOS_PENDIENTES_SUCCESS =
  "LOAD_PEDIDOS_PENDIENTES_SUCCESS";
export const LOAD_PEDIDOS_PENDIENTES_POR_CLIENTE_START =
  "LOAD_PEDIDOS_PENDIENTES_POR_CLIENTE_START";
export const LOAD_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS =
  "LOAD_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS";
export const LOAD_PEDIDOS_PENDIENTES_FAIL = "LOAD_PEDIDOS_PENDIENTES_FAIL";
export const CLEAR_PEDIDOS_PENDIENTES = "CLEAR_PEDIDOS_PENDIENTES";
export const CLEAR_PEDIDOS_PENDIENTES_POR_CLIENTE =
  "CLEAR_PEDIDOS_PENDIENTES_POR_CLIENTE";

export const LOAD_PEDIDOS_MES_COMPARATIVO_START =
  "LOAD_PEDIDOS_MES_COMPARATIVO_START";
export const LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS =
  "LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS";
export const LOAD_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE_START =
  "LOAD_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE_START";
export const LOAD_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE_SUCCESS =
  "LOAD_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE_SUCCESS";
export const LOAD_PEDIDOS_MES_COMPARATIVO_FAIL =
  "LOAD_PEDIDOS_MES_COMPARATIVO_FAIL";
export const CLEAR_PEDIDOS_MES_COMPARATIVO = "CLEAR_PEDIDOS_MES_COMPARATIVO";
export const CLEAR_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE =
  "CLEAR_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE";

export const LOAD_OBJETIVO_VENTAS_START = "LOAD_OBJETIVO_VENTAS_START";
export const LOAD_OBJETIVO_VENTAS_SUCCESS = "LOAD_OBJETIVO_VENTAS_SUCCESS";
export const LOAD_OBJETIVO_VENTAS_FAIL = "LOAD_OBJETIVO_VENTAS_FAIL";

export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_START =
  "LOAD_ESTADOS_PEDIDOS_PENDIENTES_START";
export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS =
  "LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS";
export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE_START =
  "LOAD_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE_START";
export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS =
  "LOAD_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS";
export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL =
  "LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL";
export const CLEAR_ESTADOS_PEDIDOS_PENDIENTES =
  "CLEAR_ESTADOS_PEDIDOS_PENDIENTES";
export const CLEAR_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE =
  "CLEAR_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE";

export const LOAD_VENDEDORES_START = "LOAD_VENDEDORES_START";
export const LOAD_VENDEDORES_SUCCESS = "LOAD_VENDEDORES_SUCCESS";
export const LOAD_VENDEDORES_FAIL = "LOAD_VENDEDORES_FAIL";

export const LOAD_COMISION_COMPARATIVO_START =
  "LOAD_COMISION_COMPARATIVO_START";
export const LOAD_COMISION_COMPARATIVO_SUCCESS =
  "LOAD_COMISION_COMPARATIVO_SUCCESS";
export const LOAD_COMISION_COMPARATIVO_FAIL = "LOAD_COMISION_COMPARATIVO_FAIL";
export const CLEAR_COMISION_COMPARATIVO = "CLEAR_COMISION_COMPARATIVO";

export const LOAD_FACTURAS_DEL_MES_INTERANUAL_START =
  "LOAD_FACTURAS_DEL_MES_INTERANUAL_START";
export const LOAD_FACTURAS_DEL_MES_INTERANUAL_SUCCESS =
  "LOAD_FACTURAS_DEL_MES_INTERANUAL_SUCCESS";
export const LOAD_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE_START =
  "LOAD_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE_START";
export const LOAD_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE_SUCCESS =
  "LOAD_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE_SUCCESS";
export const LOAD_FACTURAS_DEL_MES_INTERANUAL_FAIL =
  "LOAD_FACTURAS_DEL_MES_INTERANUAL_FAIL";
export const CLEAR_FACTURAS_DEL_MES_INTERANUAL =
  "CLEAR_FACTURAS_DEL_MES_INTERANUAL";
export const CLEAR_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE =
  "CLEAR_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE";

export const LOAD_PEDIDOS_DEL_MES_INTERANUAL_START =
  "LOAD_PEDIDOS_DEL_MES_INTERANUAL_START";
export const LOAD_PEDIDOS_DEL_MES_INTERANUAL_SUCCESS =
  "LOAD_PEDIDOS_DEL_MES_INTERANUAL_SUCCESS";
export const LOAD_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE_START =
  "LOAD_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE_START";
export const LOAD_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE_SUCCESS =
  "LOAD_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE_SUCCESS";
export const LOAD_PEDIDOS_DEL_MES_INTERANUAL_FAIL =
  "LOAD_PEDIDOS_DEL_MES_INTERANUAL_FAIL";
export const CLEAR_PEDIDOS_DEL_MES_INTERANUAL =
  "CLEAR_PEDIDOS_DEL_MES_INTERANUAL";
export const CLEAR_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE =
  "CLEAR_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE";

export const LOAD_COMISION_EVOLUTIVO_START = "LOAD_COMISION_EVOLUTIVO_START";
export const LOAD_COMISION_EVOLUTIVO_SUCCESS =
  "LOAD_COMISION_EVOLUTIVO_SUCCESS";
export const LOAD_COMISION_EVOLUTIVO_FAIL = "LOAD_COMISION_EVOLUTIVO_FAIL";
export const CLEAR_COMISION_EVOLUTIVO = "CLEAR_COMISION_EVOLUTIVO";

export const LOAD_FACTURAS_PENDIENTES_START = "LOAD_FACTURAS_PENDIENTES_START";
export const LOAD_FACTURAS_PENDIENTES_SUCCESS = "LOAD_FACTURAS_PENDIENTES_SUCCESS";
export const LOAD_FACTURAS_PENDIENTES_FAIL = "LOAD_FACTURAS_PENDIENTES_FAIL";
export const CLEAR_FACTURAS_PENDIENTES = "CLEAR_FACTURAS_PENDIENTES";

export const LOAD_FACTURAS_PENDIENTES_POR_CLIENTE_START = "LOAD_FACTURAS_PENDIENTES_START_POR_CLIENTE";
export const LOAD_FACTURAS_PENDIENTES_POR_CLIENTE_SUCCESS = "LOAD_FACTURAS_PENDIENTES_SUCCESS_POR_CLIENTE";
export const LOAD_FACTURAS_PENDIENTES_POR_CLIENTE_FAIL = "LOAD_FACTURAS_PENDIENTES_FAIL_POR_CLIENTE";
export const CLEAR_FACTURAS_PENDIENTES_POR_CLIENTE = "CLEAR_FACTURAS_PENDIENTES_POR_CLIENTE";

export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START = "LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START";
export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS = "LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS";
export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL = "LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL";
export const CLEAR_COMPROBANTES_FACTURAS_PENDIENTES = "CLEAR_COMPROBANTES_FACTURAS_PENDIENTES";

export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_START = "LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_START";
export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_SUCCESS = "LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_SUCCESS";
export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_FAIL = "LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_FAIL";
export const CLEAR_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE = "CLEAR_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE";

export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START = "LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START";
export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS = "LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS";
export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL = "LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL";
export const CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES = "CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES";

export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_START = "LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_START";
export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS = "LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS";
export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_FAIL = "LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_FAIL";
export const CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE = "CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE";

export const LOAD_CLIENTES_INFO_MINIMA_START = "LOAD_CLIENTES_INFO_MINIMA_START";
export const LOAD_CLIENTES_INFO_MINIMA_SUCCESS = "LOAD_CLIENTES_INFO_MINIMA_SUCCESS";
export const LOAD_CLIENTES_INFO_MINIMA_FAIL = "LOAD_CLIENTES_INFO_MINIMA_FAIL";
export const CLEAR_CLIENTES_INFO_MINIMA = "CLEAR_CLIENTES_INFO_MINIMA";

export const LOAD_CLIENTE_BY_ID_START = "LOAD_CLIENTE_BY_ID_START";
export const LOAD_CLIENTE_BY_ID_SUCCESS = "LOAD_CLIENTE_BY_ID_SUCCESS";
export const LOAD_CLIENTE_BY_ID_FAIL = "LOAD_CLIENTE_BY_ID_FAIL";
export const UNMOUNT_CLIENTE_BY_ID = "UNMOUNT_CLIENTE_BY_ID";

export const LOAD_CHEQUES_RECHAZADOS_START = "LOAD_CHEQUES_RECHAZADOS_START";
export const LOAD_CHEQUES_RECHAZADOS_SUCCESS = "LOAD_CHEQUES_RECHAZADOS_SUCCESS";
export const LOAD_CHEQUES_RECHAZADOS_FAIL = "LOAD_CHEQUES_RECHAZADOS_FAIL";
export const CLEAR_CHEQUES_RECHAZADOS = "CLEAR_CHEQUES_RECHAZADOS";

export const LOAD_LISTADO_CHEQUES_RECHAZADOS_START = "LOAD_LISTADO_CHEQUES_RECHAZADOS_START";
export const LOAD_LISTADO_CHEQUES_RECHAZADOS_SUCCESS = "LOAD_LISTADO_CHEQUES_RECHAZADOS_SUCCESS";
export const LOAD_LISTADO_CHEQUES_RECHAZADOS_FAIL = "LOAD_LISTADO_CHEQUES_RECHAZADOS_FAIL";
export const CLEAR_LISTADO_CHEQUES_RECHAZADOS = "CLEAR_LISTADO_CHEQUES_RECHAZADOS";

export const LOAD_CHEQUES_EN_CARTERA_START = "LOAD_CHEQUES_EN_CARTERA_START";
export const LOAD_CHEQUES_EN_CARTERA_SUCCESS = "LOAD_CHEQUES_EN_CARTERA_SUCCESS";
export const LOAD_CHEQUES_EN_CARTERA_FAIL = "LOAD_CHEQUES_EN_CARTERA_FAIL";
export const CLEAR_CHEQUES_EN_CARTERA = "CLEAR_CHEQUES_EN_CARTERA";

export const LOAD_LISTADO_CHEQUES_EN_CARTERA_START = "LOAD_LISTADO_CHEQUES_EN_CARTERA_START";
export const LOAD_LISTADO_CHEQUES_EN_CARTERA_SUCCESS = "LOAD_LISTADO_CHEQUES_EN_CARTERA_SUCCESS";
export const LOAD_LISTADO_CHEQUES_EN_CARTERA_FAIL = "LOAD_LISTADO_CHEQUES_EN_CARTERA_FAIL";
export const CLEAR_LISTADO_CHEQUES_EN_CARTERA = "CLEAR_LISTADO_CHEQUES_EN_CARTERA";

export const LOAD_CUENTA_CORRIENTE_CLIENTE_START = "LOAD_CUENTA_CORRIENTE_CLIENTE_START";
export const LOAD_CUENTA_CORRIENTE_CLIENTE_SUCCESS = "LOAD_CUENTA_CORRIENTE_CLIENTE_SUCCESS";
export const LOAD_CUENTA_CORRIENTE_CLIENTE_FAIL = "LOAD_CUENTA_CORRIENTE_CLIENTE_FAIL";
export const CLEAR_CUENTA_CORRIENTE_CLIENTE = "CLEAR_CUENTA_CORRIENTE_CLIENTE";

export const LOAD_COMPROBANTES_CHEQUES_START = "LOAD_COMPROBANTES_CHEQUES_START";
export const LOAD_COMPROBANTES_CHEQUES_SUCCESS = "LOAD_COMPROBANTES_CHEQUES_SUCCESS";
export const LOAD_COMPROBANTES_CHEQUES_FAIL = "LOAD_COMPROBANTES_CHEQUES_FAIL";
export const CLEAR_COMPROBANTES_CHEQUES = "CLEAR_COMPROBANTES_CHEQUES";

export const LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_START =
  "LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_START";
export const LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_SUCCESS =
  "LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_SUCCESS";
export const LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_FAIL =
  "LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_FAIL";
export const CLEAR_VENTAS_AL_CLIENTE_EVOLUTIVO =
  "CLEAR_VENTAS_AL_CLIENTE_EVOLUTIVO";

export const LOAD_ULTIMA_COMPRA_BY_CLIENTE_START =
  "LOAD_ULTIMA_COMPRA_BY_CLIENTE_START";
export const LOAD_ULTIMA_COMPRA_BY_CLIENTE_SUCCESS =
  "LOAD_ULTIMA_COMPRA_BY_CLIENTE_SUCCESS";
export const LOAD_ULTIMA_COMPRA_BY_CLIENTE_FAIL =
  "LOAD_ULTIMA_COMPRA_BY_CLIENTE_FAIL";
export const CLEAR_ULTIMA_COMPRA_BY_CLIENTE = "CLEAR_ULTIMA_COMPRA_BY_CLIENTE";

export const EXECUTE_POST_COMPROBANTES = "EXECUTE_POST_COMPROBANTES";

export const LOAD_COMPROBANTES_VENTAS_START = "LOAD_COMPROBANTES_VENTAS_START";
export const LOAD_COMPROBANTES_VENTAS_SUCCESS =
  "LOAD_COMPROBANTES_VENTAS_SUCCESS";
export const LOAD_COMPROBANTES_VENTAS_FAIL = "LOAD_COMPROBANTES_VENTAS_FAIL";

export const LOAD_COMPROBANTES_PEDIDOS_START =
  "LOAD_COMPROBANTES_PEDIDOS_START";
export const LOAD_COMPROBANTES_PEDIDOS_SUCCESS =
  "LOAD_COMPROBANTES_PEDIDOS_SUCCESS";
export const LOAD_COMPROBANTES_PEDIDOS_FAIL = "LOAD_COMPROBANTES_PEDIDOS_FAIL";

export const LOAD_EMPRESAS_START = "LOAD_EMPRESAS_START";
export const LOAD_EMPRESAS_SUCCESS = "LOAD_EMPRESAS_SUCCESS";
export const LOAD_EMPRESAS_FAIL = "LOAD_EMPRESAS_FAIL";

export const EXECUTE_CAMBIAR_EMPRESA = "EXECUTE_CAMBIAR_EMPRESA";

export const LOAD_TIEMPOS_REQUEST_START = "LOAD_TIEMPOS_REQUEST_START";
export const LOAD_TIEMPOS_REQUEST_SUCCESS = "LOAD_TIEMPOS_REQUEST_SUCCESS";
export const LOAD_TIEMPOS_REQUEST_FAIL = "LOAD_TIEMPOS_REQUEST_FAIL";
export const CLEAR_TIEMPOS_REQUEST = "CLEAR_TIEMPOS_REQUEST";

export const LOAD_CHANGE_LOGS_START = "LOAD_CHANGE_LOGS_START";
export const LOAD_CHANGE_LOGS_SUCCESS = "LOAD_CHANGE_LOGS_SUCCESS";
export const LOAD_CHANGE_LOGS_FAIL = "LOAD_CHANGE_LOGS_FAIL";
export const CLEAR_CHANGE_LOGS = "CLEAR_CHANGE_LOGS";

export const EXECUTE_CREATE_CHANGE_LOG = "EXECUTE_CREATE_CHANGE_LOG";
export const EXECUTE_UPDATE_CHANGE_LOG = "EXECUTE_UPDATE_CHANGE_LOG";
export const EXECUTE_DELETE_CHANGE_LOG = "EXECUTE_DELETE_CHANGE_LOG";

export const LOAD_ARTICULOS_STOCK_PRECIO_START =
  "LOAD_ARTICULOS_STOCK_PRECIO_START";
export const LOAD_ARTICULOS_STOCK_PRECIO_SUCCESS =
  "LOAD_ARTICULOS_STOCK_PRECIO_SUCCESS";
export const LOAD_ARTICULOS_STOCK_PRECIO_FAIL =
  "LOAD_ARTICULOS_STOCK_PRECIO_FAIL";
export const CLEAR_ARTICULOS_STOCK_PRECIO = "CLEAR_ARTICULOS_STOCK_PRECIO";

export const LOAD_ARTICULOS_BUSQUEDA_START = "LOAD_ARTICULOS_BUSQUEDA_START";
export const LOAD_ARTICULOS_BUSQUEDA_SUCCESS = "LOAD_ARTICULOS_BUSQUEDA_SUCCESS";
export const LOAD_ARTICULOS_BUSQUEDA_FAIL = "LOAD_ARTICULOS_BUSQUEDA_FAIL";
export const CLEAR_ARTICULOS_BUSQUEDA = "CLEAR_ARTICULOS_BUSQUEDA";

export const CLEAR_ARTICULOS_CODIGO_BARRAS = "CLEAR_ARTICULOS_CODIGO_BARRAS";

export const SEARCH_ARTICULOS_STOCK_PRECIO_START = "SEARCH_ARTICULOS_STOCK_PRECIO_START";
export const SEARCH_ARTICULOS_STOCK_PRECIO_SUCCESS = "SEARCH_ARTICULOS_STOCK_PRECIO_SUCCESS";
export const SEARCH_ARTICULOS_STOCK_PRECIO_FAIL = "SEARCH_ARTICULOS_STOCK_PRECIO_FAIL";
export const CLEAR_SEARCH_ARTICULOS_STOCK_PRECIO = "CLEAR_SEARCH_ARTICULOS_STOCK_PRECIO";

export const LOAD_MARCAS_START = "LOAD_MARCAS_START";
export const LOAD_MARCAS_SUCCESS = "LOAD_MARCAS_SUCCESS";
export const LOAD_MARCAS_FAIL = "LOAD_MARCAS_FAIL";

export const LOAD_RUBROS_START = "LOAD_RUBROS_START";
export const LOAD_RUBROS_SUCCESS = "LOAD_RUBROS_SUCCESS";
export const LOAD_RUBROS_FAIL = "LOAD_RUBROS_FAIL";

export const LOAD_SUBRUBROS_START = "LOAD_SUBRUBROS_START";
export const LOAD_SUBRUBROS_SUCCESS = "LOAD_SUBRUBROS_SUCCESS";
export const LOAD_SUBRUBROS_FAIL = "LOAD_SUBRUBROS_FAIL";

export const CAMBIAR_FILTRO_MARCA = "CAMBIAR_FILTRO_MARCA";
export const CAMBIAR_FILTRO_RUBRO = "CAMBIAR_FILTRO_RUBRO";
export const CAMBIAR_FILTRO_SUBRUBRO = "CAMBIAR_FILTRO_SUBRUBRO";
export const CAMBIAR_FILTRO_LISTA_PRECIO = "CAMBIAR_FILTRO_LISTA_PRECIO";
export const CAMBIAR_FILTRO_DEPOSITO = "CAMBIAR_FILTRO_DEPOSITO";
export const CAMBIAR_FILTRO_STOCK = "CAMBIAR_FILTRO_STOCK";
export const CAMBIAR_FILTRO_BUSQUEDA = "CAMBIAR_FILTRO_BUSQUEDA";
export const CAMBIAR_FILTRO_CODIGO_BARRAS = "CAMBIAR_FILTRO_CODIGO_BARRAS";
export const CLEAR_FILTROS_ARTICULOS = "CLEAR_FILTROS_ARTICULOS";
export const PAGINACION_ARTICULOS = "PAGINACION_ARTICULOS";

export const UPDATE_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA =
  "UPDATE_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA";
export const UPDATE_ULTIMA_ACTUALIZACION_CLIENTE =
  "UPDATE_ULTIMA_ACTUALIZACION_CLIENTE";
export const UPDATE_ULTIMA_ACTUALIZACION_CATALOGO =
  "UPDATE_ULTIMA_ACTUALIZACION_CATALOGO";
export const UPDATE_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST =
  "UPDATE_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST";
export const UPDATE_ULTIMA_ACTUALIZACION_CHANGE_LOGS =
  "UPDATE_ULTIMA_ACTUALIZACION_CHANGE_LOGS";
export const UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_VENTAS =
  "UPDATE_ULTIMA_ACTUALIZACION_ANALISIS_VENTAS";

export const CLEAR_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA =
  "CLEAR_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA";
export const CLEAR_ULTIMA_ACTUALIZACION_CLIENTE =
  "CLEAR_ULTIMA_ACTUALIZACION_CLIENTE";
export const CLEAR_ULTIMA_ACTUALIZACION_CATALOGO =
  "CLEAR_ULTIMA_ACTUALIZACION_CATALOGO";
export const CLEAR_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST =
  "CLEAR_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST";
export const CLEAR_ULTIMA_ACTUALIZACION_CHANGE_LOGS =
  "CLEAR_ULTIMA_ACTUALIZACION_CHANGE_LOGS";
export const CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_VENTAS =
  "CLEAR_ULTIMA_ACTUALIZACION_ANALISIS_VENTAS";

export const SHOW_CUENTA_CORRIENTE_MODAL = "SHOW_CUENTA_CORRIENTE_MODAL";
export const SHOW_GLOBAL_MODAL = 'SHOW_GLOBAL_MODAL';
export const CHANGE_GLOBAL_MODAL_BODY = 'CHANGE_GLOBAL_MODAL_BODY';
export const SHOW_LIST_FILTERS_MODAL = 'SHOW_LIST_FILTERS_MODAL';
export const SHOW_SEND_CHART_MODAL = 'SHOW_SEND_CHART_MODAL';
export const SHOW_BACK_BUTTON_MODAL = 'SHOW_BACK_BUTTON_MODAL';

export const LOAD_MONEDAS_START = 'LOAD_MONEDAS_START';
export const LOAD_MONEDAS_SUCCESS = 'LOAD_MONEDAS_SUCCESS';
export const LOAD_MONEDAS_FAIL = 'LOAD_MONEDAS_FAIL';

export const EXECUTE_CAMBIAR_MONEDA = 'EXECUTE_CAMBIAR_MONEDA';

export const LOAD_LISTA_PRECIOS_START = "LOAD_LISTA_PRECIOS_START";
export const LOAD_LISTA_PRECIOS_SUCCESS = "LOAD_LISTA_PRECIOS_SUCCESS";
export const LOAD_LISTA_PRECIOS_FAIL = "LOAD_LISTA_PRECIOS_FAIL";

export const LOAD_DEPOSITOS_START = "LOAD_DEPOSITOS_START";
export const LOAD_DEPOSITOS_SUCCESS = "LOAD_DEPOSITOS_SUCCESS";
export const LOAD_DEPOSITOS_FAIL = "LOAD_DEPOSITOS_FAIL";

export const ADD_IMAGES_START = "ADD_IMAGES_START";
export const ADD_IMAGES_SUCCESS = "ADD_IMAGES_SUCCESS";
export const ADD_IMAGES_FAIL = "ADD_IMAGES_FAIL";

export const REMOVE_IMAGES_START = "REMOVE_IMAGES_START";
export const REMOVE_IMAGES_SUCCESS = "REMOVE_IMAGES_SUCCESS";
export const REMOVE_IMAGES_FAIL = "REMOVE_IMAGES_FAIL";

export const LOAD_CLIENTE_CABECERA_BY_ID_START = "LOAD_CLIENTE_CABECERA_BY_ID_START";
export const LOAD_CLIENTE_CABECERA_BY_ID_SUCCESS = "LOAD_CLIENTE_CABECERA_BY_ID_SUCCESS";
export const LOAD_CLIENTE_CABECERA_BY_ID_FAIL = "LOAD_CLIENTE_CABECERA_BY_ID_FAIL";

export const LOAD_COMPROBANTE_PEDIDO_START = "LOAD_COMPROBANTE_PEDIDO_START";
export const LOAD_COMPROBANTE_PEDIDO_SUCCESS = "LOAD_COMPROBANTE_PEDIDO_SUCCESS";
export const LOAD_COMPROBANTE_PEDIDO_FAIL = "LOAD_COMPROBANTE_PEDIDO_FAIL";

export const SEND_PEDIDO_START = "SEND_PEDIDO_START";
export const SEND_PEDIDO_SUCCESS = "SEND_PEDIDO_SUCCESS";
export const SEND_PEDIDO_FAIL = "SEND_PEDIDO_FAIL";

export const EJECUTAR_FOTO_DEL_DIA = 'EJECUTAR_FOTO_DEL_DIA';
export const EJECUTAR_CLIENTES = 'EJECUTAR_CLIENTES';
export const EJECUTAR_CATALOGO = 'EJECUTAR_CATALOGO';
export const EJECUTAR_TIEMPOS_REQ = 'EJECUTAR_TIEMPOS_REQ';
export const EJECUTAR_ANALISIS_VENTAS = 'EJECUTAR_ANALISIS_VENTAS';
export const CAMBIO_PAGINA_RENDER = 'CAMBIO_PAGINA_RENDER';

export const SELECCION_VENDEDOR = 'SELECCION_VENDEDOR';
export const EXECUTE_CAMBIAR_VENDEDOR_LOADING = 'EXECUTE_CAMBIAR_VENDEDOR_LOADING';

export const CLEAR_EVOLUCION_DE_VENTAS_DEL_VENDEDOR = 'CLEAR_EVOLUCION_DE_VENTAS_DEL_VENDEDOR';
export const LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_START = 'LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_START';
export const LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_SUCCESS = 'LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_SUCCESS';
export const LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_FAIL = 'LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_FAIL';

export const CLEAR_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE = 'CLEAR_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE';
export const LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE_START = 'LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE_START';
export const LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE_SUCCESS = 'LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE_SUCCESS';
export const LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE_FAIL = 'LOAD_EVOLUCION_DE_VENTAS_DEL_VENDEDOR_POR_CLIENTE_FAIL';

export const LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_START = 'LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_START';
export const LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_SUCCESS = 'LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_SUCCESS';
export const LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_FAIL = 'LOAD_EVOLUCION_DE_VENTAS_DE_VENDEDORES_FAIL';
export const CLEAR_EVOLUCION_DE_VENTAS_DE_VENDEDORES = 'CLEAR_EVOLUCION_DE_VENTAS_DE_VENDEDORES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_START = 'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUCCESS = 'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_START = 'LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_SUCCESS = 'LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_RUBRO_DEL_MES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_START =
  'LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_SUCCESS =
  'LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_SUBRUBRO_DEL_MES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_START =
  'LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_SUCCESS =
  'LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_FAIL =
  'LOAD_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_PROVINCIA_DEL_MES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_START = 'LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_SUCCESS = 'LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_ZONA_DEL_MES';

export const LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_START =
  'LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_FAIL =
  'LOAD_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE =
  'CLEAR_VENTAS_DE_VENDEDORES_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_START = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_FAIL = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_MARCA';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_START = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_FAIL = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_RUBRO';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_START =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_FAIL =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_SUBRUBRO';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_START =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_FAIL =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_VENDEDOR';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_START =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_SUCCESS =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_FAIL =
  'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_PROVINCIA';

export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_START = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_START';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_SUCCESS = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_SUCCESS';
export const LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_FAIL = 'LOAD_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA_FAIL';
export const CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA = 'CLEAR_VENTAS_DE_VENDEDORES_DEL_MES_POR_ZONA';

export const LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_START =
  'LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_START';
export const LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_FAIL =
  'LOAD_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR = 'CLEAR_EVOLUCION_VENTAS_DE_VENDEDORES_POR_VENDEDOR';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_START =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_FAIL =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE =
  'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_START =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_FAIL =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS = 'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_PROVINCIAS';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_START = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_FAIL = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS = 'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_ZONAS';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_START = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_FAIL = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA = 'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_MARCA';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_START = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_FAIL = 'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO = 'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_RUBRO';

export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_START =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_START';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_SUCCESS =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_SUCCESS';
export const LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_FAIL =
  'LOAD_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO_FAIL';
export const CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO = 'CLEAR_EVOLUCION_VENTAS_DEL_VENDEDOR_POR_SUBRUBRO';

export const LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_START = 'LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_FAIL = 'LOAD_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_MARCA_DEL_VENDEDOR';

export const LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_START = 'LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_FAIL = 'LOAD_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_RUBRO_DEL_VENDEDOR';

export const LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_START =
  'LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_SUCCESS =
  'LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_FAIL = 'LOAD_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_SUBRUBRO_DEL_VENDEDOR';

export const LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_START =
  'LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_SUCCESS =
  'LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_FAIL =
  'LOAD_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_PROVINCIA_DEL_VENDEDOR';

export const LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_START = 'LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_START';
export const LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_SUCCESS = 'LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_FAIL = 'LOAD_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR = 'CLEAR_VENTAS_DEL_MES_POR_ZONA_DEL_VENDEDOR';

export const SELECCION_VENDEDOR_ANALISIS_VENDEDORES = 'SELECCION_VENDEDOR_ANALISIS_VENDEDORES';
export const SELECCION_DATA_VENTAS_DEL_MES_DEL_VENDEDOR_POR = 'SELECCION_DATA_VENTAS_DEL_MES_DEL_VENDEDOR_POR';
export const SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DEL_VENDEDOR = 'SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DEL_VENDEDOR';
export const SELECCION_DATA_EVOLUCION_VENTAS_DEL_VENDEDOR = 'SELECCION_DATA_EVOLUCION_VENTAS_DEL_VENDEDOR';
export const SELECCION_DATA_VENTAS_DEL_MES_DE_VENDEDORES_POR = 'SELECCION_DATA_VENTAS_DEL_MES_DE_VENDEDORES_POR';

export const LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_START = 'LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_START';
export const LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_SUCCESS = 'LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_SUCCESS';
export const LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_FAIL = 'LOAD_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR_FAIL';
export const CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR = 'CLEAR_VENTAS_POR_CATEGORIA_DE_CLIENTE_DEL_VENDEDOR';

export const LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_START = 'LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_SUCCESS = 'LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES = 'CLEAR_VENTAS_POR_VENDEDOR_POR_CATEGORIA_DE_CLIENTE_DEL_MES';

export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_START = 'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_START';
export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_SUCCESS = 'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_SUCCESS';
export const LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_FAIL = 'LOAD_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT_FAIL';
export const CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT = 'CLEAR_VENTAS_POR_VENDEDOR_POR_MARCA_DEL_MES_SUMBURST_FORMAT';

export const SELECCION_SUCURSAL_ANALISIS_DE_SUCURSAL = 'SELECCION_SUCURSAL_ANALISIS_DE_SUCURSAL';
export const SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_LA_SUCURSAL = 'SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_LA_SUCURSAL';
export const SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_VENDEDORES = 'SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_VENDEDORES';
export const SELECCION_DATA_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR = 'SELECCION_DATA_VENTAS_DEL_MES_DE_LA_SUCURSAL_POR';
export const SELECCION_DATA_VENTAS_DEL_MES_DE_SUCURSALES_POR = 'SELECCION_DATA_VENTAS_DEL_MES_DE_SUCURSALES_POR';
export const SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_SUCURSALES = 'SELECCION_FECHA_VENTAS_DEL_MES_ANIO_DE_SUCURSALES';

export const LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_START = 'LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_START';
export const LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_SUCCESS = 'LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_SUCCESS';
export const LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_FAIL = 'LOAD_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA_FAIL';
export const CLEAR_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA = 'CLEAR_VENTAS_POR_CLIENTE_DE_LA_CATEGORIA';

export const CLEAR_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE = 'CLEAR_EVOLUCION_VENTAS_POR_CATEGORIA_DE_CLIENTE';

export const LOAD_COMPROBANTES_GASTOS_START = 'LOAD_COMPROBANTES_GASTOS_START';
export const LOAD_COMPROBANTES_GASTOS_SUCCESS = 'LOAD_COMPROBANTES_GASTOS_SUCCESS';
export const LOAD_COMPROBANTES_GASTOS_FAIL = 'LOAD_COMPROBANTES_GASTOS_FAIL';

export const LOAD_COMPROBANTES_PAGOS_START = 'LOAD_COMPROBANTES_PAGOS_START';
export const LOAD_COMPROBANTES_PAGOS_SUCCESS = 'LOAD_COMPROBANTES_PAGOS_SUCCESS';
export const LOAD_COMPROBANTES_PAGOS_FAIL = 'LOAD_COMPROBANTES_PAGOS_FAIL';

export const LOAD_COMPROBANTES_COMPRAS_START = 'LOAD_COMPROBANTES_COMPRAS_START';
export const LOAD_COMPROBANTES_COMPRAS_SUCCESS = 'LOAD_COMPROBANTES_COMPRAS_SUCCESS';
export const LOAD_COMPROBANTES_COMPRAS_FAIL = 'LOAD_COMPROBANTES_COMPRAS_FAIL';

export const LOAD_COMPROBANTES_COBRANZAS_START = 'LOAD_COMPROBANTES_COBRANZAS_START';
export const LOAD_COMPROBANTES_COBRANZAS_SUCCESS = 'LOAD_COMPROBANTES_COBRANZAS_SUCCESS';
export const LOAD_COMPROBANTES_COBRANZAS_FAIL = 'LOAD_COMPROBANTES_COBRANZAS_FAIL';

export const LOAD_VENTAS_POR_VENDEDOR_START = "LOAD_VENTAS_POR_VENDEDOR_START";
export const LOAD_VENTAS_POR_VENDEDOR_SUCCESS = "LOAD_VENTAS_POR_VENDEDOR_SUCCESS";
export const LOAD_VENTAS_POR_VENDEDOR_FAIL = "LOAD_VENTAS_POR_VENDEDOR_FAIL";
export const CLEAR_VENTAS_POR_VENDEDOR = "CLEAR_VENTAS_POR_VENDEDOR";
export const LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_START = "LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_START";
export const LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_SUCCESS = "LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_SUCCESS";
export const LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_FAIL = "LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_FAIL";
export const CLEAR_VENTAS_POR_VENDEDOR_POR_CLIENTE = "CLEAR_VENTAS_POR_VENDEDOR_POR_CLIENTE";

export const SET_USER_INFO = 'SET_USER_INFO';

export const LOAD_PEDIDOS_VENCIDOS_START = 'LOAD_PEDIDOS_VENCIDOS_START';
export const LOAD_PEDIDOS_VENCIDOS_SUCCESS = 'LOAD_PEDIDOS_VENCIDOS_SUCCESS';
export const LOAD_PEDIDOS_VENCIDOS_FAIL = 'LOAD_PEDIDOS_VENCIDOS_FAIL';
export const CLEAR_PEDIDOS_VENCIDOS = 'CLEAR_PEDIDOS_VENCIDOS';

export const LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_START = 'LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_START';
export const LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_SUCCESS = 'LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_SUCCESS';
export const LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_FAIL = 'LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_FAIL';
export const CLEAR_PEDIDOS_VENCIDOS_POR_CLIENTE = 'CLEAR_PEDIDOS_VENCIDOS_POR_CLIENTE';

export const LINE_CHEQUES_EN_CARTERA_START = 'LINE_CHEQUES_EN_CARTERA_START';
export const LINE_CHEQUES_EN_CARTERA_SUCCESS = 'LINE_CHEQUES_EN_CARTERA_SUCCESS';
export const LINE_CHEQUES_EN_CARTERA_FAIL = 'LINE_CHEQUES_EN_CARTERA_FAIL';
export const CLEAR_LINE_CHEQUES_EN_CARTERA = 'CLEAR_LINE_CHEQUES_EN_CARTERA';

export const LINE_CHEQUES_RECHAZADOS_START = 'LINE_CHEQUES_RECHAZADOS_START';
export const LINE_CHEQUES_RECHAZADOS_SUCCESS = 'LINE_CHEQUES_RECHAZADOS_SUCCESS';
export const LINE_CHEQUES_RECHAZADOS_FAIL = 'LINE_CHEQUES_RECHAZADOS_FAIL';
export const CLEAR_LINE_CHEQUES_RECHAZADOS = 'CLEAR_LINE_CHEQUES_RECHAZADOS';

export const LOAD_INFLACION_START = "LOAD_INFLACION_START";
export const LOAD_INFLACION_SUCCESS = "LOAD_INFLACION_SUCCESS";
export const LOAD_INFLACION_FAIL = "LOAD_INFLACION_FAIL";

export const LOAD_INFLACION_POR_ANIO_START = "LOAD_INFLACION_POR_ANIO_START";
export const LOAD_INFLACION_POR_ANIO_SUCCESS = "LOAD_INFLACION_POR_ANIO_SUCCESS";
export const LOAD_INFLACION_POR_ANIO_FAIL = "LOAD_INFLACION_POR_ANIO_FAIL";

export const EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION = "EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION";