import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from 'react-s3';
import { S3Config } from '../../../assets/shared/awsConfigs';
import { connect } from 'react-redux';
import * as actions from "../../../store/actions";
import ImageUploading from 'react-images-uploading';
import AddIcon from '@material-ui/icons/Add';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  image:{
    width: '50px',
    height: '50px',
  },
  inputUrl:{
    width: '100%',
    '& .MuiInputBase-input': {
      height: '10px', 
    }
  },
  buttons:{
    width: '100%',
  },
  buttonsHeader:{
    display: 'flex',
    alignItems: 'self-end',
    backgroundColor: 'transparent',
    justifyContent: 'flex-end',
  },
  dragAndDrop:{
    height: '60px', 
    backgroundColor: '#c0c0c0', 
    borderRadius: '5px',
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'center',
  },
  dragAndDropLegend:{
    color: '#FFFFFF'
  }
}));

const ArticuloImageUpload = (props) => {
  const {
    idArticulo,
    artImages,
    updateImages,
    addImages,
    newImages,
    loading,
  } = props;

  const [images, setImages] = useState([]);
  const [url, setUrl] = useState('');
  const [maxNumber] = useState(10);
  const [sendRequest, setSendRequest] = useState(false);

  useEffect(() => {
    if(sendRequest && !loading){      

      if(newImages !== undefined && newImages.length > 0){
        let tempImages = artImages;

        newImages.forEach(newImage => tempImages.push(newImage));

        updateImages(tempImages);
      }
      
      setSendRequest(false);
    }

  }, [
    artImages,
    newImages, 
    sendRequest, 
    loading,
    updateImages, 
    setSendRequest]);

  const onUrlChange = (event) => {
    setUrl(event.target.value);
  }

  const onUrlAdd = () => {
    if(url === ''){
      return;
    }

    const file = {
      name: url.substring(url.lastIndexOf('/')+1),
      size: 0,
    };

    const newList = images.concat({
      id: uuidv4(),
      data_url: url,
      file,
    });
    setImages(newList);
    setUrl('');
  }

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const customUploader = (images, onSuccess) => { 
    const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);    
    
    let config = {
      bucketName: S3Config.buckeName,
      region: S3Config.region,
      accessKeyId: S3Config.accessKeyId,
      secretAccessKey: S3Config.secretAccessKey,
      dirName: `imagenes_articulos/${idEmpresa}/${idArticulo.trimEnd()}`,
    }
    
    try {    
      let uploadedImages = [];
  
      Array.from(images).forEach(image => {
        if (image.file !== null && image.file !== undefined)
        { 
          if(image.file.size <= 0){
            let uploadedImage = {
              id: 0,
              idArticulo: idArticulo,
              idEmpresa: idEmpresa,
              name: image.file.name,
              url: image.data_url,
              size: image.file.size,
              isUpload: false,
            };
  
            uploadedImages.push(uploadedImage);
          }
        }     
      });
  
      const requestData = {
        idArticulo: idArticulo,
        imagenes: uploadedImages,
      }
  
      addImages(requestData);  

      setSendRequest(true);    
  
      Array.from(images).forEach(image => {
        if (image.file !== null && image.file !== undefined)
        {
          if(image.file.size > 0){
            handleUpload(image.file, config)
              .then(data => { 
                let uploadedImage = {
                  id: 0,
                  idArticulo: idArticulo,
                  idEmpresa: idEmpresa,
                  name: image.file.name,
                  url: data.location,
                  size: image.file.size,
                  isUpload: true,
                };
  
                const requestData = {
                  idArticulo: idArticulo,
                  imagenes: [uploadedImage],
                }
          
                addImages(requestData); 

                setSendRequest(true);   
              })
              .catch(err => console.error(err));        
          }
        }     
      });

      onSuccess();
    }catch(error)    {
      console.log(error);
    }
  }
  
  const handleUpload = async (file, config) => {
    let data = await uploadFile(file, config);
    return data;
  }

  return (
    <div> 
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <AddImages 
                name={url} 
                onChange={onUrlChange} 
                onAdd={onUrlAdd} 
                onImageUpload={onImageUpload} 
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DragAndDrop 
                dragProps={dragProps} 
                isDragging={isDragging} 
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ListImages 
                images={imageList} 
                onEdit={onImageUpdate} 
                onDelete={onImageRemove} 
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <HandleImages 
                images={imageList} 
                onUpload={customUploader} 
                onImageRemoveAll={onImageRemoveAll} 
              />
            </Grid>            
          </Grid>
        )}
      </ImageUploading>
    </div>
  );
}

const AddImages = ({ name, onChange, onAdd, onImageUpload }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.buttonsHeader} container spacing={1}>
        <Grid item xs={4} sm={6}>
          <TextField             
            id={name}
            className={classes.inputUrl} 
            variant='outlined' 
            size='small' 
            value={name} 
            onChange={onChange}
            required={true}
            placeholder={'Url de la imagen'} 
          />          
        </Grid>
        <Grid item xs={4} sm={3}>
          <Button
            className={classes.buttons}
            variant="outlined" 
            color="default" 
            size="small"
            startIcon={<AddIcon />} 
            onClick={onAdd}>
              Agregar
          </Button>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Button
            className={classes.buttons}
            variant="outlined" 
            color="default" 
            size="small"
            startIcon={<AddIcon />} 
            onClick={onImageUpload}>
              Seleccionar
          </Button>
        </Grid>
      </Grid>
  );
};

const HandleImages = ({ images, onUpload, onImageRemoveAll }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.buttonsHeader} container spacing={1}>
        <Grid item xs={6} sm={3}>
          <Button
            className={classes.buttons} 
            variant="outlined" 
            color="default" 
            size="small"
            disabled={images.length <= 0} 
            startIcon={<BackupIcon />}                 
            onClick={() => onUpload(images, onImageRemoveAll)}>
            Cargar imagenes
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            className={classes.buttons} 
            variant="outlined" 
            color="default" 
            size="small" 
            disabled={images.length <= 0}
            startIcon={<DeleteIcon />} 
            onClick={onImageRemoveAll}>
            Eliminar imagenes
          </Button>
        </Grid>
      </Grid>
  );
};

const DragAndDrop = ({ dragProps, isDragging }) => {
  const classes = useStyles();

  return (
    <div {...dragProps} className={classes.dragAndDrop}>
      <span className={classes.dragAndDropLegend}>
        {isDragging ? "Suelte aquí las imagenes" : "Arrastre aquí las imagenes"}
      </span>
    </div>
  );
}

const ListImages = ({ images, onEdit, onDelete }) => {
  const classes = useStyles();

  return (
    <List>
        {images.map((image, index) => (                    
          <ListItem key={index}>
            <ListItemAvatar>
              <img className={classes.image} src={image['data_url']} alt="" />
            </ListItemAvatar>
            <ListItemText
              primary={image['file']['name']}
              secondary={image['file']['size'] > 0 ? image['file']['size'] : ''}
            />
            <ListItemSecondaryAction>
              {image['file']['size'] > 0 && (
                <IconButton onClick={() => onEdit(index)} aria-label="edit">
                  <EditIcon />
                </IconButton>
              )}
              <IconButton onClick={() => onDelete(index)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
  );
}

const mapStateToProps = (state) => ({
  newImages: state.articulos.images.images,  
  loading: state.articulos.images.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addImages: (data) => dispatch(actions.addImages(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticuloImageUpload);