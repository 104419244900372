import React, { useState, useEffect , Fragment, useCallback } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Tooltip , TextField, IconButton, Divider } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import { roundAccurately, isEven, removeTaxFromPrice } from '../../assets/shared/formatNumber';
import NumberFormatCustom from '../UI/Input/NumberFormatCustom';
import Dialog from "../UI/Dialog/Dialog";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: 0,
    boxShadow: "none",
    width: '100%',
    backgroundColor: 'white',
    marginLeft: '0px',
    marginRight: '0px',
  },
  root2:{
    borderRadius: 0,
    boxShadow: "none",
    width: '100%',
    backgroundColor: '#f2f2f2',
    marginLeft: '0px',
    marginRight: '0px',
  },
  labels: {
    fontSize: "small",
    color: 'dimgrey',
    textAlign: 'center',
  },
  labelText: {
    fontSize: "small",
    color: 'dimgrey',
    textAlign: 'left',
  },
  labelNumeric: {
    fontSize: "small",
    color: 'dimgrey',
    textAlign: 'right',
  },
  labelsArticulo:{
    fontSize: "small",
    color: 'dimgrey',
    textAlign: 'start',
  },
  text: {
    textAlign: "center",
    "& .MuiInputBase-input": {
      textAlign: 'center',
      minWidth: '35px',
      height: '18px',
    }
  },
  titleTexto: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }, 
  actionIcon: {
    color: '#00BCD4'
  }
}));

const ItemDetallePedido = (props) => {
  const classes = useStyles();

  const { 
    index,
    articulo,
    listaDePrecio,
    clienteCabecera,
    remove,
    updateCantidad, 
    updateCarrito,
    comprobante,
    carritoItems,
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [cantidad, setCantidad] = useState();
  const [precio, setPrecio] = useState();
  const [precioPrev, setPrecioPrev] = useState(0);
  const [bonificacion, setBonificacion] = useState();
  const [bonificacionPrev, setBonificacionPrev] = useState(0);
  const [precioBonificado, setPrecioBonificado] = useState(0);
  const [total, setTotal] = useState(0);
  const [porcentajeIva, setPorcentajeIva] = useState(0);  
  const [decimalesPrecio, setDecimalesPrecio] = useState(2);
  const [decimalesCantidad, setDecimalesCantidad] = useState(0);
  const [enterosPrecio, setEnterosPrecio] = useState(12);
  const [enterosCantidad, setEnterosCantidad] = useState(5);
  const [showInfo, setShowInfo] = useState(false);
  
  const calcularPrecio = useCallback(() => {
    let valorPrecio = 0;

    if(clienteCabecera !== null && clienteCabecera !== undefined){
      let itemCliente = clienteCabecera.listasDePrecios.find(list => list.id === listaDePrecio);

      if(itemCliente !== undefined && articulo !== undefined ) {
        const precioArticulo = roundAccurately(articulo.precio, decimalesPrecio);
        const precioListaArticulo = roundAccurately(articulo.precioLista, decimalesPrecio);
        let importeIva = 0;

        if(itemCliente.ivaIncluido){
          valorPrecio = removeTaxFromPrice(precioArticulo, articulo.porcentajeIva, decimalesPrecio);          
          importeIva = precioArticulo - valorPrecio;
        }else{
          valorPrecio = precioArticulo;
        }

        setPorcentajeIva(articulo.porcentajeIva);
        
        articulo.precio = valorPrecio;
        articulo.precioLista = precioListaArticulo;
        articulo.importeIva = importeIva;
      }
    }

    setPrecio(valorPrecio);
    
    return roundAccurately(valorPrecio, decimalesPrecio);
  }, [
    listaDePrecio,
    articulo,
    clienteCabecera,
    decimalesPrecio
  ]);

  const calculatePrecioBonificado = useCallback((precio, bonificacion) => {
    const num = bonificacion === null || bonificacion === undefined || bonificacion === 0
      ? precio
      : (1 - bonificacion / 100) * precio;
    
    let value = roundAccurately(num, decimalesPrecio);
    setPrecioBonificado(value);
    articulo.precioBonificado = value;

    return value;
  }, [articulo, decimalesPrecio]);
  
  const calculateTotal = useCallback((precio, cantidad) => {
    var num = cantidad !== undefined ? precio * cantidad : precio * 1;
    var value = roundAccurately(num, 2);
    setTotal(value);
    articulo.total = value;    
    articulo.baseImponible = value;
  }, [articulo]);

  const getIncluyeIva = useCallback(() => {
    let incluyeIva = false;

    if(clienteCabecera !== null && clienteCabecera !== undefined){
      let itemCliente = clienteCabecera.listasDePrecios.find(list => list.id === listaDePrecio);

      if(itemCliente !== null && itemCliente !== undefined) {
        incluyeIva = itemCliente.ivaIncluido;
      }
    }

    return incluyeIva;
  }, [
    clienteCabecera,
    listaDePrecio,
  ]);

  const updateValues = useCallback(() => {
    const precioUnit = calcularPrecio();
    const precioBonif = calculatePrecioBonificado(precioUnit, bonificacion);
    calculateTotal(precioBonif, cantidad);    
  }, [
    bonificacion,
    cantidad,
    calculatePrecioBonificado,
    calculateTotal,
    calcularPrecio,
  ]);

  const cantidadChange = useCallback(() => {
    let item = carritoItems.find(i => i.idArticulo === articulo.idArticulo);
    return item !== null && item !== undefined && item.cantidad !== cantidad;
  }, [
    cantidad,
    articulo,
    carritoItems,
  ]);

  useEffect(() => { 
    if(comprobante !== null && comprobante !== undefined){   
      getIncluyeIva();
      setDecimalesPrecio(comprobante.decimalesPrecio);
      setDecimalesCantidad(comprobante.decimalesCantidad);
      setEnterosPrecio(comprobante.enterosPrecio);
      setEnterosCantidad(comprobante.enterosCantidad);
      updateValues();
    }

    if(articulo !== null && articulo !== undefined){
      if(cantidad === null || cantidad === undefined || cantidadChange()){
        setCantidad(articulo.cantidad);
      }

      if(bonificacion === null || bonificacion === undefined) {
        setBonificacion(articulo.bonificacion);
        setBonificacionPrev(articulo.bonificacion);
      }

      if(precio === null || precio === undefined){
        setPrecio(articulo.precio);
        setPrecioPrev(articulo.precio);
      }

      updateValues();
    }
  }, [
    comprobante,
    articulo,
    cantidad,
    precio,
    bonificacion,
    calcularPrecio,
    updateValues, 
    setCantidad,
    setPrecio,
    setPrecioPrev,
    setBonificacion,
    setBonificacionPrev,
    getIncluyeIva,
    cantidadChange,
  ]);

  const onCantidadChange = (value) => {
    let newValue = Number(value);

    if(newValue > 0){
      updateCantidad(articulo, newValue);
      setCantidad(newValue);
      updateValues();
      updateCarrito();
    }
  }

  const onPrecioChange = (value) => {    
    let newValue = Number(value);
    setPrecio(newValue);
  }

  const onBonificacionChange = (value) => {
    let newValue = Number(value);
    setBonificacion(newValue);  
  }

  const onArticuloEdit = () => {
    setPrecioPrev(precio);
    setBonificacionPrev(bonificacion);
    setEditMode(true);
  }

  const onArticuloSave = () => {
    articulo.precio = precio;
    articulo.bonificacion = bonificacion;
    updateValues();
    setEditMode(false);
    updateCarrito();
  }

  const onArticuloCancel = () => {
    setPrecio(precioPrev);
    setBonificacion(bonificacionPrev);
    setEditMode(false);
  }

  const onArticuloDelete = () =>{
    remove(articulo);
  }

  const onArticuloInfo = () => {
    setShowInfo(true);
  }

  const informacionArticulo = () => {
    return (
      <div>Hello</div>
    );
  }

  const actions = () => {
    return editMode ?
      (        
        <Fragment>
          <Grid item xs={4} md={4}></Grid>
          <Grid item xs={4} md={4}>
            <Tooltip title="Cancelar" placement="top">
              <IconButton aria-label="cancelar"
                onClick={() => onArticuloCancel()}
              >
                <CancelIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4} md={4}>
            <Tooltip title="Guardar" placement="top">
              <IconButton aria-label="guardar"
                onClick={() => onArticuloSave()}
              >
                <SaveIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Fragment>
      ) : (        
        <Fragment>
          <Grid item xs={4} md={4}></Grid>
          <Grid item xs={4} md={4}>
            <Tooltip title="Editar" placement="top">
              <IconButton 
                aria-label="editar" 
                onClick={() => onArticuloEdit()}>
                <EditIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4} md={4}>
            <Tooltip title="Eliminar" placement="top">
              <IconButton 
                aria-label="eliminar"
                onClick={() => onArticuloDelete()}>
                <DeleteIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Fragment>
      );
  } 

  return (
    <Fragment>
      <Grid 
          container 
          spacing={1} 
          direction="row"
          justify="space-between"
          alignItems="center"
          className={isEven(index) ? classes.root1 : classes.root2 }
        >
          <Grid item xs={1} md={1} xl={1}>
            <Typography  className={classes.text}>
              {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography  className={classes.labelText}>
              {articulo && articulo.idArticulo && articulo.idArticulo}
            </Typography>
          </Grid>        
          <Grid item xs={2} md={3} xl={3}>
            <Typography  className={classes.labelText}>
              {articulo && articulo.nombre && articulo.nombre}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>            
            <TextField 
              className={classes.text} 
              value={cantidad}
              inputProps={{ 
                name:"cantidadInput",
                maxLength: enterosCantidad + decimalesCantidad, 
                decimalScale: decimalesCantidad,
                min: 1
              }}
              InputProps={{ inputComponent: NumberFormatCustom }}
              variant="outlined"
              size="small"
              onChange={(e) => onCantidadChange(e.target.value)} 
            />
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            {editMode && 
              <TextField 
                className={classes.text} 
                value={precio}
                inputProps={{ 
                  name:"precioInput",
                  maxLength: enterosPrecio + decimalesPrecio, 
                  decimalScale: decimalesPrecio,
                  min: 0.001
                }}
                InputProps={{ inputComponent: NumberFormatCustom }}
                variant="outlined"
                size="small"
                onChange={(e) => onPrecioChange(e.target.value)} 
              />
            }
            {!editMode &&
              <Typography  className={classes.labelNumeric}>
                {precio && precio.toFixed(decimalesPrecio)}
              </Typography>
            }            
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            {editMode && 
              <TextField
                className={classes.text} 
                value={bonificacion}
                inputProps={{ 
                  name:"bonificacionInput",
                  maxLength: enterosPrecio + 2, 
                  decimalScale: 2,
                  max: 100,
                  min: 0
                }}
                InputProps={{ inputComponent: NumberFormatCustom }}
                variant="outlined"
                size="small"
                onChange={(e) => onBonificacionChange(e.target.value)}
              />
            }
            {!editMode &&   
              <Typography  className={classes.labelNumeric}>
                {bonificacion && bonificacion.toFixed(2)}
              </Typography>
            }
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography  className={classes.labelNumeric}>
              {precioBonificado && precioBonificado.toFixed(decimalesPrecio)}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography  className={classes.labelNumeric}>
              {porcentajeIva && porcentajeIva.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} xl={1}>
            <Typography  className={classes.labelNumeric}>
              {total && total.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item container xs={2} md={1}>
            {actions()}         
          </Grid>
        </Grid>
      {showInfo && 
        <Dialog 
          fullWidth
          maxWidth={"sm"}
          title={
            <div style={{ width: "100%" }}>
              <div className={classes.titleTexto}>
                <Typography style={{ fontSize: "large" }}>{articulo.idArticulo.toUpperCase()}</Typography>
                <IconButton aria-label="close" onClick={() => setShowInfo(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
              <Divider />
            </div>
          }
          show={showInfo}
          body={informacionArticulo()}
          onClose={() => setShowInfo(false)}
        />
      }
    </Fragment>
  );
};

const mapStateToProps = (state) => ({  
  listaDePrecio: state.articulos.filtrosArticulosPorCliente.listaPrecioId, 
  clienteCabecera: state.clientes.clienteCabeceraById.cliente,
  comprobante: state.comprobantes.comprobantePedido.comprobante,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetallePedido);