import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  postComprobantes: {
    loading: false,
  },
  comprobantesVentas: {
    loading: false,
    comprobantes: [],
    error: null,
  },
  comprobantesPedidos: {
    loading: false,
    comprobantes: [],
    error: null,
  },
  loadTiemposRequest: {
    indicadores: [],
    tiempos: [],
    loading: false,
    error: null,
  },
  loadInflacion: {
    inflaciones: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadInflacionPorAnio: {
    inflaciones: [],
    loading: false,
    error: null,
    estaCargado: false
  },
};

//#region load Inflacion
const loadInflacionesStart = (state) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: true,
      inflaciones: [],
      error: null
    })
  });
};

const loadInflacionesSuccess = (state, inflaciones) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: false,
      inflaciones
    })
  });
};

const loadInflacionesFail = (state, error) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: false,
      error
    })
  });
};

const loadInflacionPorAnioStart = (state) => {
  return updateObject(state, {
    loadInflacionPorAnio: updateObject(state.loadInflacionPorAnio, {
      loading: true,
      inflaciones: [],
      error: null
    })
  });
};

const loadInflacionPorAnioSuccess = (state, inflaciones) => {
  return updateObject(state, {
    loadInflacionPorAnio: updateObject(state.loadInflacionPorAnio, {
      loading: false,
      inflaciones,
      estaCargado: true
    })
  });
};

const loadInflacionPorAnioFail = (state, error) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region postComprobantes
const executePostComprobantes = (state, loading) => {
  return updateObject(state, {
    postComprobantes: updateObject(state.postComprobantes, {
      loading,
    }),
  });
};
////#endregion

//#region load comprobantes de Ventas
const loadComprobantesVentasStart = (state) => {
  return updateObject(state, {
    comprobantesVentas: updateObject(state.comprobantesVentas, {
      loading: true,
      comprobantes: [],
      error: null,
    }),
  });
};

const loadComprobantesVentasSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesVentas: updateObject(state.comprobantesVentas, {
      loading: false,
      comprobantes,
    }),
  });
};

const loadComprobantesVentasFail = (state, error) => {
  return updateObject(state, {
    comprobantesVentas: updateObject(state.comprobantesVentas, {
      loading: false,
      error: error,
    }),
  });
};
//#endregion

//#region load comprobantes de Pedidos
const loadComprobantesPedidosStart = (state) => {
  return updateObject(state, {
    comprobantesPedidos: updateObject(state.comprobantesPedidos, {
      loading: true,
      comprobantes: [],
      error: null,
    }),
  });
};

const loadComprobantesPedidosSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesPedidos: updateObject(state.comprobantesPedidos, {
      loading: false,
      comprobantes,
    }),
  });
};

const loadComprobantesPedidosFail = (state, error) => {
  return updateObject(state, {
    comprobantesPedidos: updateObject(state.comprobantesPedidos, {
      loading: false,
      error: error,
    }),
  });
};
//#endregion

//#region load Tiempos request
const loadTiemposRequestStart = (state) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      loading: true,
    }),
  });
};

const loadTiemposRequestSuccess = (state, indicadores, tiempos) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      loading: false,
      indicadores,
      tiempos,
    }),
  });
};

const loadTiemposRequestFail = (state, error) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      loading: false,
      error,
    }),
  });
};

const clearTiemposRequest = (state) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      loading: false,
      indicadores: [],
      tiempos: [],
      error: null,
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXECUTE_POST_COMPROBANTES:
      return executePostComprobantes(state, action.loading);

    case actionTypes.LOAD_COMPROBANTES_VENTAS_START:
      return loadComprobantesVentasStart(state);
    case actionTypes.LOAD_COMPROBANTES_VENTAS_SUCCESS:
      return loadComprobantesVentasSuccess(state, action.comprobantesVentas);
    case actionTypes.LOAD_COMPROBANTES_VENTAS_FAIL:
      return loadComprobantesVentasFail(state, action.error);

    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_START:
      return loadComprobantesPedidosStart(state);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_SUCCESS:
      return loadComprobantesPedidosSuccess(state, action.comprobantesPedidos);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_FAIL:
      return loadComprobantesPedidosFail(state, action.error);

    case actionTypes.LOAD_TIEMPOS_REQUEST_START:
      return loadTiemposRequestStart(state);
    case actionTypes.LOAD_TIEMPOS_REQUEST_SUCCESS:
      return loadTiemposRequestSuccess(
        state,
        action.indicadores,
        action.tiempos
      );
    case actionTypes.LOAD_TIEMPOS_REQUEST_FAIL:
      return loadTiemposRequestFail(state, action.error);
    case actionTypes.CLEAR_TIEMPOS_REQUEST:
      return clearTiemposRequest(state);

    case actionTypes.LOAD_INFLACION_START:
      return loadInflacionesStart(state);
    case actionTypes.LOAD_INFLACION_SUCCESS:
      return loadInflacionesSuccess(state, action.inflaciones);
    case actionTypes.LOAD_INFLACION_FAIL:
      return loadInflacionesFail(state, action.error);
  
    case actionTypes.LOAD_INFLACION_POR_ANIO_START:
      return loadInflacionPorAnioStart(state);
    case actionTypes.LOAD_INFLACION_POR_ANIO_SUCCESS:
      return loadInflacionPorAnioSuccess(state, action.inflaciones);
    case actionTypes.LOAD_INFLACION_POR_ANIO_FAIL:
      return loadInflacionPorAnioFail(state, action.error);

    default:
      return state;
  }
};

export default reducer;
