export default class ContactoModel {
  nombre;
  telefono;
  email;

  setFromCliente(cliente) {
    if(cliente !== null && cliente !== undefined && cliente.contacto !== null && cliente.contacto !== undefined){
      this.nombre = cliente.contacto.nombre && cliente.contacto.nombre.trimEnd();
      this.telefono = cliente.contacto.telefono && cliente.contacto.telefono.trimEnd();
      this.email = cliente.contacto.email && cliente.contacto.email.trimEnd();
    }
  }
}