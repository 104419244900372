import * as actionTypes from "./actionTypes";
import axiosVendedores from "../../axios-vendedores";
import idIndicadores from "../../assets/shared/idIndicadores";

export const loadEmpresas = () => {
  return (dispatch) => {
    dispatch(loadEmpresasStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosVendedores
      .get(`/empresas/empresas`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadEmpresasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEmpresasFail(error));
          } else {
            dispatch(loadEmpresas());
          }
        } else {
          dispatch(loadEmpresasFail(error));
        }
      });
  };
};

const loadEmpresasStart = () => {
  return {
    type: actionTypes.LOAD_EMPRESAS_START,
  };
};

const loadEmpresasSuccess = (empresas) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_SUCCESS,
    empresas,
  };
};

const loadEmpresasFail = (error) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_FAIL,
    error,
  };
};

export const seleccionarEmpresa = (empresaId, success) => {
  return (dispatch) => {
    dispatch(executeCambiarEmpresa(true));
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosVendedores
      .put(
        `/empresas/CambiarEmpresaPorDefecto?empresaId=${empresaId}&indicadorId=${idIndicadores.cambiarEmpresa}`,
        null,
        {
          headers: {
            Authorization: authStr,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.vendedorId) {
          sessionStorage.setItem("vendedorId", response.data.vendedorId);
        }
        sessionStorage.setItem("empresaId", empresaId);
        sessionStorage.setItem('empresaNombre', response.data.empresa);
        sessionStorage.setItem("monedaId", response.data.monedaId);
        sessionStorage.setItem("monedaSigno", response.data.monedaSigno);
        return success(response.data);
      })
      .catch((error) => {
        dispatch(executeCambiarEmpresa(false));
      });
  };
};

const executeCambiarEmpresa = (loading) => {
  return {
    type: actionTypes.EXECUTE_CAMBIAR_EMPRESA,
    loading,
  };
};
