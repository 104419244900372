import React from 'react';
import { connect } from "react-redux";
import { formatNumber, isEven } from '../../../../assets/shared/formatNumber';
import { storageMonedaId, storageMonedaSigno } from '../../../../assets/shared/sessionData';
import Table from '../../../UI/Table/Table';
import { MTableHeader } from 'material-table';
import moment from 'moment';

const TableDetallesRecibo = (props) => {
  const { articulos, loading } = props;

  const headerStyles = {
    fontSize: '12px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(200, 200, 200)',
    border: 'none'
  };

  const cellStyles = {
    fontSize: '11px',
    paddingBottom: '5px',
    paddingTop: '5px',
    border: 'none'
  };

  const columns = [
    {
      title: 'Valor',
      field: 'valor',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    },
    {
      title: 'Nro. Orden',
      field: 'nroOrden',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    },
    {
      title: 'Retencion',
      field: 'retencion',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
    },
    {
      title: 'Cuenta Bancaria',
      field: 'cuentaBancaria',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
    },
    {
      title: 'Número',
      field: 'numero',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
    },
    {
      title: 'Fecha venc.',
      field: 'fechaVencimiento',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => moment(rowData).format('DD/MM/YY')
    },
    {
      title: 'Moneda',
      field: 'moneda',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
    },
    {
      title: 'Importe',
      field: 'importe',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => formatNumberWithMonedaSigno(rowData.importe)
    }
  ];

  const formatNumberWithMonedaSigno = (value) => {
    return `${storageMonedaSigno()} ${formatNumber(value)}`;
  };

  const getMoneda = () => {
    const moneda = props.monedas.find((monedaX) => monedaX.idMoneda === storageMonedaId());

    return moneda ? moneda.nombre ? moneda.nombre.trimEnd() : '' : '';
  }

  const data = () => {
    return articulos.map((articulo) => ({
      valor: articulo.valor,
      nroOrden: articulo.nroOrden,
      retencion: articulo.retencion,
      cuentaBancaria: articulo.cuentaBancaria,
      numero: articulo.numero,
      fechaVencimiento: articulo.fechaVencimiento,
      moneda: getMoneda(),
      importe: articulo.total
    }));
  };

  return (
    <Table
      title=""
      loading={loading}
      noBoxShadow
      style={{ backgroundColor: 'rgb(245, 245, 245)', border: '1px solid #acacac99', marginBottom: '10px' }}
      columns={columns}
      data={data()}
      components={{
        Header: (props) => (
          <MTableHeader {...props}>
            {(props.columns[0].tableData.width = '16%')}
            {(props.columns[1].tableData.width = '12%')}
            {(props.columns[2].tableData.width = '16%')}
            {(props.columns[3].tableData.width = '15%')}
            {(props.columns[4].tableData.width = '10%')}
            {(props.columns[5].tableData.width = '11%')}
            {(props.columns[6].tableData.width = '8%')}
            {(props.columns[7].tableData.width = '12%')}
          </MTableHeader>
        )
      }}
      customOptions={{
        grouping: false,
        draggable: false,
        search: false,
        paging: false,
        showTitle: false,
        toolbar: false,
        layout: 'fixed',
        maxBodyHeight: window.screen.width < 1367 ? 430 : 600,
        rowStyle: (rowData) => ({
          backgroundColor: ((rowData.tableData.id + 1) % 2 === 0) ? 'rgb(240, 240, 240)' : 'rgba(255, 255, 255)'
        })
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    monedas: state.monedas.monedas,
  };
};

export default connect(mapStateToProps, null)(TableDetallesRecibo);
