import React from "react";
import { connect } from "react-redux";
import { formatNumber } from "../../../../assets/shared/formatNumber";
import { storageMonedaSigno } from "../../../../assets/shared/sessionData";

import DonutChart from "../../../Charts/DonutChart";
import PieChart from "../../../Charts/PieChart";

const EstadoPedidosPendientes = (props) => {
  const {
    height,
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    width,
    legendPosition,
    loading,
    loadingPorCliente,
    clientSelected,
    inClientInfo,
    pedidos,
    pedidosPorCliente,
    total,
    totalPorCliente,
    noBorder,
  } = props;

  const chartSinDatos = pedidos.length === 0 || (pedidos.length === 1 && pedidos[0].monto <= 0);

  const chartSinDatosCliente =
    pedidosPorCliente.length === 0 ||
    (pedidosPorCliente.length === 1 && pedidosPorCliente[0].monto <= 0);

  const labels = () => {
    if (inClientInfo) {
      return pedidosPorCliente.map((pedido) => pedido.nombre);
    } else {
      return pedidos.map((pedido) => pedido.nombre);
    }
  };

  const series = () => {
    if (inClientInfo) {
      return pedidosPorCliente.map((pedido) => pedido.monto);
    } else {
      return pedidos.map((pedido) => pedido.monto);
    }
  };

  return (
    <PieChart
      labels={labels()}
      title={`Pedidos pendientes`}
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(inClientInfo ? totalPorCliente : total)}`}
      series={series()}
      legendPosition={'bottom'}
      chartType="donut"
      height={height}
      width={'100%'}
      maxWidthImgSinResult={330}
      noBorder={noBorder}
      imgSinDatos={inClientInfo ? chartSinDatosCliente : chartSinDatos}
      noClient={!clientSelected && inClientInfo}
      loading={inClientInfo ? loadingPorCliente : loading}
      legendHorizontalAlign={"left"}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    pedidos: state.ventas.estadosPedidosPendientes.pedidos,
    pedidosPorCliente: state.ventas.estadosPedidosPendientes.pedidosPorCliente,
    loading: state.ventas.estadosPedidosPendientes.loading,
    loadingPorCliente: state.ventas.estadosPedidosPendientes.loadingPorCliente,
    total: state.ventas.estadosPedidosPendientes.total,
    totalPorCliente: state.ventas.estadosPedidosPendientes.totalPorCliente,
    estaCargado: state.ventas.estadosPedidosPendientes.estaCargado,
    clientSelected: state.clientes.clienteById.clientSelected,
    clienteId: state.clientes.clienteById.cliente.idCliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EstadoPedidosPendientes);
