import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  ventasMesComparativo: {
    montoMesActual: 0,
    montoMesAnterior: 0,
    montoMesActualPorCliente: 0,
    montoMesAnteriorPorCliente: 0,
    loading: false,
    loadingPorCliente: false,
    error: null,
    estaCargado: false,
  },
  pedidosPendientes: {
    cantidad: 0,
    total: 0,
    prom30Dias: 0,
    cantidadPorCliente: 0,
    totalPorCliente: 0,
    prom30DiasPorCliente: 0,
    loading: false,
    loadingPorCliente: false,
    error: null,
    estaCargado: false,
  },
  pedidosMesComparativo: {
    transMesAnt: 0,
    totalMesAnt: 0,
    transMesAct: 0,
    totalMesAct: 0,
    porcTrans: 0,
    porcTotal: 0,
    loading: false,
    loadingPorCliente: false,
    error: null,
    estaCargado: false,
    totalMesActualPorCliente: 0,
    totalMesAnteriorPorCliente: 0,
  },
  objetivoVentas: {
    objetivo: 0,
    montoActual: 0,
    porcentaje: 0,
    loading: false,
    error: null,
    estaCargado: false,
  },
  estadosPedidosPendientes: {
    pedidos: [],
    loading: false,
    loadingPorCliente: false,
    error: null,
    estaCargado: false,
    pedidosPorCliente: [],
    total: 0,
    totalPorCliente: 0,
  },
  facturasDelMesInteranual: {
    periodoActual: [],
    periodoAnterior: [],
    meses: [],
    loading: false,
    loadingPorCliente: false,
    error: null,
    estaCargado: false,
    periodoActualPorCliente: [],
    periodoAnteriorPorCliente: [],
    mesesPorCliente: [],
  },
  pedidosDelMesInteranual: {
    periodoActual: [],
    periodoAnterior: [],
    meses: [],
    loading: false,
    loadingPorCliente: false,
    error: null,
    estaCargado: false,
    periodoActualPorCliente: [],
    periodoAnteriorPorCliente: [],
    mesesPorCliente: [],
  },
  facturasPendientes: {
    facturas: [],
    facturasPorCliente: [],
    totalFacturas: 0,
    totalFacturasPorCliente: 0,
    loading: false,
    loadingPorCliente: false,
    estaCargado: false,
    estaCargadoPorCliente: false,
    error: null,
    errorPorCliente: null,
  },
  ventasAlClienteEvolutivo: {
    ventas: [],
    meses: [],
    loading: false,
    error: null,
  },
  comprobantesFacturasPendientes: {
    comprobantes: [],
    loading: false,
    estaCargado: false,
    error: null,
    comprobantesPorCliente: [],
    loadingPorCliente: false,
    estaCargadoPorCliente: false,
  },
  comprobantesPedidosPendientes: {
    comprobantes: [],
    loading: false,
    estaCargado: false,
    error: null,
    comprobantesPorCliente: [],
    loadingPorCliente: false,
    estaCargadoPorCliente: false,
  },  
  ventasPorVendedor: {
    error: null,
    estaCargado: false,
    loading: false,
    unidadesVendidas: 0,
    ventas: 0,
    cantidad: 0,
    loadingPorCliente: false,
    unidadesVendidasPorCliente: 0,
    ventasPorCliente: 0,
    cantidadPorCliente: 0,
  },
  pedidosVencidos: {
    loading: false,
    estaCargado: false,
    cantidad: 0,
    importe: 0,
    loadingPorCliente: false,
    estaCargadoPorCliente: false,
    cantidadPorCliente: 0,
    importePorCliente: 0,
    error: null,
  },
};

//#region loadVentasMesComparativo
const loadVentasMesComparativoStart = (state) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      loading: true,
    }),
  });
};

const loadVentasMesComparativoPorClienteStart = (state) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      loadingPorCliente: true,
    }),
  });
};

const loadVentasMesComparativoSuccess = (
  state,
  montoMesActual,
  montoMesAnterior
) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      montoMesActual,
      montoMesAnterior,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadVentasMesComparativoPorClienteSuccess = (
  state,
  montoMesActualPorCliente,
  montoMesAnteriorPorCliente
) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      montoMesActualPorCliente,
      montoMesAnteriorPorCliente,
      loadingPorCliente: false,
    }),
  });
};

const loadVentasMesComparativoFail = (state, error) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      loading: false,
      loadingPorCliente: false,
      error,
    }),
  });
};

const clearVentasMesComparativo = (state) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      montoMesActual: 0,
      montoMesAnterior: 0,
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};

const clearVentasMesComparativoPorCliente = (state) => {
  return updateObject(state, {
    ventasMesComparativo: updateObject(state.ventasMesComparativo, {
      montoMesActualPorCliente: 0,
      montoMesAnteriorPorCliente: 0,
      loadingPorCliente: false,
    }),
  });
};
//#endregion

//#region loadPedidosPendientes
const loadPedidosPendientesStart = (state) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      loading: true,
    }),
  });
};

const loadPedidosPendientesPorClienteStart = (state) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      loadingPorCliente: true,
    }),
  });
};

const loadPedidosPendientesSuccess = (state, cantidad, total, prom30Dias) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      cantidad,
      total,
      prom30Dias,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadPedidosPendientesPorClienteSuccess = (
  state,
  cantidadPorCliente,
  totalPorCliente,
  prom30DiasPorCliente
) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      cantidadPorCliente,
      totalPorCliente,
      prom30DiasPorCliente,
      loadingPorCliente: false,
      error: null,
    }),
  });
};

const loadPedidosPendientesFail = (state, error) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearPedidosPendientes = (state) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      cantidad: 0,
      total: 0,
      prom30Dias: 0,
      loading: false,
      error: null,
      estaCargado: false,
      cantidadPorCliente: 0,
      totalPorCliente: 0,
      prom30DiasPorCliente: 0,
      loadingPorCliente: false,
    }),
  });
};

const clearPedidosPendientesPorCliente = (state) => {
  return updateObject(state, {
    pedidosPendientes: updateObject(state.pedidosPendientes, {
      cantidadPorCliente: 0,
      totalPorCliente: 0,
      prom30DiasPorCliente: 0,
      loadingPorCliente: false,
    }),
  });
};
//#endregion

//#region loadPedidosMesComparativo
const loadPedidosMesComparativoStart = (state) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      loading: true,
    }),
  });
};

const loadPedidosMesComparativoPorClienteStart = (state) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      loadingPorCliente: true,
    }),
  });
};

const loadPedidosMesComparativoSuccess = (
  state,
  transMesAnt,
  totalMesAnt,
  transMesAct,
  totalMesAct,
  porcTrans,
  porcTotal
) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      transMesAnt,
      totalMesAnt,
      transMesAct,
      totalMesAct,
      porcTrans,
      porcTotal,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadPedidosMesComparativoPorClienteSuccess = (
  state,
  totalMesActualPorCliente,
  totalMesAnteriorPorCliente
) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      totalMesAnteriorPorCliente,
      totalMesActualPorCliente,
      loadingPorCliente: false,
      error: null,
    }),
  });
};

const loadPedidosMesComparativoFail = (state, error) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearPedidosMesComparativo = (state) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      transMesAnt: 0,
      totalMesAnt: 0,
      transMesAct: 0,
      totalMesAct: 0,
      porcTrans: 0,
      porcTotal: 0,
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};

const clearPedidosMesComparativoPorCliente = (state) => {
  return updateObject(state, {
    pedidosMesComparativo: updateObject(state.pedidosMesComparativo, {
      loadingPorCliente: false,
      error: null,
      totalMesActualPorCliente: 0,
      totalMesAnteriorPorCliente: 0,
    }),
  });
};
//#endregion

//#region loadObjetivoVentas
const loadObjetivoVentasStart = (state) => {
  return updateObject(state, {
    objetivoVentas: updateObject(state.objetivoVentas, {
      loading: true,
    }),
  });
};

const loadObjetivoVentasSuccess = (
  state,
  objetivo,
  montoActual,
  porcentaje
) => {
  return updateObject(state, {
    objetivoVentas: updateObject(state.objetivoVentas, {
      objetivo,
      montoActual,
      porcentaje,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadObjetivoVentasFail = (state, error) => {
  return updateObject(state, {
    objetivoVentas: updateObject(state.objetivoVentas, {
      error,
      loading: false,
    }),
  });
};
//#endregion

//#region loadEstadosPedidosPendientes
const loadEstadosPedidosPendientesStart = (state) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      loading: true,
    }),
  });
};

const loadEstadosPedidosPendientesPorClienteStart = (state) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      loadingPorCliente: true,
    }),
  });
};

const loadEstadosPedidosPendientesSuccess = (state, pedidos, total) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      pedidos,
      loading: false,
      estaCargado: true,
      total,
      error: null,
    }),
  });
};

const loadEstadosPedidosPendientesPorClienteSuccess = (
  state,
  pedidosPorCliente,
  totalPorCliente
) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      pedidosPorCliente,
      loadingPorCliente: false,
      totalPorCliente,
      error: null,
    }),
  });
};

const loadEstadosPedidosPendientesFail = (state, error) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearEstadosPedidosPendientes = (state) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      pedidos: [],
      loading: false,
      error: null,
      estaCargado: false,
      total: 0,
    }),
  });
};

const clearEstadosPedidosPendientesPorCliente = (state) => {
  return updateObject(state, {
    estadosPedidosPendientes: updateObject(state.estadosPedidosPendientes, {
      loadingPorCliente: false,
      pedidosPorCliente: [],
      totalPorCliente: 0,
    }),
  });
};
//#endregion

//#region loadFacturasDelMesInteranual
const loadFacturasDelMesInteranualStart = (state) => {
  return updateObject(state, {
    facturasDelMesInteranual: updateObject(state.facturasDelMesInteranual, {
      loading: true,
    }),
  });
};

const loadFacturasDelMesInteranualPorClienteStart = (state) => {
  return updateObject(state, {
    facturasDelMesInteranual: updateObject(state.facturasDelMesInteranual, {
      loadingPorCliente: true,
    }),
  });
};

const loadFacturasDelMesInteranualSuccess = (
  state,
  periodoActual,
  periodoAnterior,
  meses
) => {
  return updateObject(state, {
    facturasDelMesInteranual: updateObject(state.facturasDelMesInteranual, {
      periodoActual,
      periodoAnterior,
      meses,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadFacturasDelMesInteranualPorClienteSuccess = (
  state,
  periodoActualPorCliente,
  periodoAnteriorPorCliente,
  mesesPorCliente
) => {
  return updateObject(state, {
    facturasDelMesInteranual: updateObject(state.facturasDelMesInteranual, {
      periodoActualPorCliente,
      periodoAnteriorPorCliente,
      mesesPorCliente,
      loadingPorCliente: false,
      error: null,
    }),
  });
};

const loadFacturasDelMesInteranualFail = (state, error) => {
  return updateObject(state, {
    facturasDelMesInteranual: updateObject(state.facturasDelMesInteranual, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearFacturasDelMesInteranual = (state) => {
  return updateObject(state, {
    facturasDelMesInteranual: updateObject(state.facturasDelMesInteranual, {
      periodoActual: [],
      periodoAnterior: [],
      meses: [],
      loading: false,
      estaCargado: false,
      error: null,
      loadingPorCliente: false,
      periodoActualPorCliente: [],
      periodoAnteriorPorCliente: [],
      mesesPorCliente: [],
    }),
  });
};

const clearFacturasDelMesInteranualPorCliente = (state) => {
  return updateObject(state, {
    facturasDelMesInteranual: updateObject(state.facturasDelMesInteranual, {
      error: null,
      loadingPorCliente: false,
      periodoActualPorCliente: [],
      periodoAnteriorPorCliente: [],
      mesesPorCliente: [],
    }),
  });
};
//#endregion

//#region loadPedidosDelMesInteranual
const loadPedidosDelMesInteranualStart = (state) => {
  return updateObject(state, {
    pedidosDelMesInteranual: updateObject(state.pedidosDelMesInteranual, {
      loading: true,
    }),
  });
};

const loadPedidosDelMesInteranualPorClienteStart = (state) => {
  return updateObject(state, {
    pedidosDelMesInteranual: updateObject(state.pedidosDelMesInteranual, {
      loadingPorCliente: true,
    }),
  });
};

const loadPedidosDelMesInteranualSuccess = (
  state,
  periodoActual,
  periodoAnterior,
  meses
) => {
  return updateObject(state, {
    pedidosDelMesInteranual: updateObject(state.pedidosDelMesInteranual, {
      periodoActual,
      periodoAnterior,
      meses,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadPedidosDelMesInteranualPorClienteSuccess = (
  state,
  periodoActualPorCliente,
  periodoAnteriorPorCliente,
  mesesPorCliente
) => {
  return updateObject(state, {
    pedidosDelMesInteranual: updateObject(state.pedidosDelMesInteranual, {
      periodoActualPorCliente,
      periodoAnteriorPorCliente,
      mesesPorCliente,
      loadingPorCliente: false,
      error: null,
    }),
  });
};

const loadPedidosDelMesInteranualFail = (state, error) => {
  return updateObject(state, {
    pedidosDelMesInteranual: updateObject(state.pedidosDelMesInteranual, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearPedidosDelMesInteranual = (state) => {
  return updateObject(state, {
    pedidosDelMesInteranual: updateObject(state.pedidosDelMesInteranual, {
      periodoActual: [],
      periodoAnterior: [],
      meses: [],
      loading: false,
      estaCargado: false,
      loadingPorCliente: false,
      error: null,
      periodoActualPorCliente: [],
      periodoAnteriorPorCliente: [],
      mesesPorCliente: [],
    }),
  });
};

const clearPedidosDelMesInteranualPorCliente = (state) => {
  return updateObject(state, {
    pedidosDelMesInteranual: updateObject(state.pedidosDelMesInteranual, {
      loadingPorCliente: false,
      error: null,
      periodoActualPorCliente: [],
      periodoAnteriorPorCliente: [],
      mesesPorCliente: [],
    }),
  });
};
//#endregion

//#region loadFacturasPendientes
const loadFacturasPendientesStart = (state) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      loading: true,
    }),
  });
};

const loadFacturasPendientesPorClienteStart = (state) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      loadingPorCliente: true,
    }),
  });
};

const loadFacturasPendientesSuccess = (state, facturas, totalFacturas) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      facturas,
      totalFacturas,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadFacturasPendientesPorClienteSuccess = (state, facturasPorCliente, totalFacturasPorCliente) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      facturasPorCliente,
      totalFacturasPorCliente,
      loadingPorCliente: false,
      estaCargadoPorCliente: true,
      errorPorCliente: null,
    }),
  });
};

const loadFacturasPendientesFail = (state, error) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      loading: false,
      error,
    }),
  });
};

const loadFacturasPendientesPorClienteFail = (state, errorPorCliente) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      loadingPorCliente: false,
      errorPorCliente,
    }),
  });
};

const clearFacturasPendientes = (state) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      facturas: [],
      totalFacturas: 0,
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};

const clearFacturasPendientesPorCliente = (state) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      facturasPorCliente: [],
      totalFacturasPorCliente: 0,
      loadingPorCliente: false,
      estaCargadoPorCliente: false,
      errorPorCliente: null,
    }),
  });
};
//#endregion

//#region loadVentasAlClienteEvolutivo
const loadVentasAlClienteEvolutivoStart = (state) => {
  return updateObject(state, {
    ventasAlClienteEvolutivo: updateObject(state.ventasAlClienteEvolutivo, {
      loading: true,
    }),
  });
};

const loadVentasAlClienteEvolutivoSuccess = (state, ventas, meses) => {
  return updateObject(state, {
    ventasAlClienteEvolutivo: updateObject(state.ventasAlClienteEvolutivo, {
      ventas,
      meses,
      loading: false,
      error: null,
    }),
  });
};

const loadVentasAlClienteEvolutivoFail = (state, error) => {
  return updateObject(state, {
    ventasAlClienteEvolutivo: updateObject(state.ventasAlClienteEvolutivo, {
      ventas: [],
      meses: [],
      loading: false,
      error,
    }),
  });
};

const clearVentasAlClienteEvolutivo = (state) => {
  return updateObject(state, {
    ventasAlClienteEvolutivo: updateObject(state.ventasAlClienteEvolutivo, {
      ventas: [],
      meses: [],
      loading: false,
      error: null,
    }),
  });
};
//#endregion

//#region Comprobantes Facturas Pendientes
const loadComprobantesFacturasPendientesStart = (state) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      loading: true,
    }),
  });
};

const loadComprobantesFacturasPendientesPorClienteStart = (state) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      loadingPorCliente: true,
    }),
  });
};

const loadComprobantesFacturasPendientesSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      comprobantes,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadComprobantesFacturasPendientesPorClienteSuccess = (state, comprobantesPorCliente) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      comprobantesPorCliente,
      loadingPorCliente: false,
      estaCargadoPorCliente: true,
      error: null,
    }),
  });
};

const loadComprobantesFacturasPendientesFail = (state, error) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      loading: false,
      error,
    }),
  });
};

const loadComprobantesFacturasPendientesPorClienteFail = (state, error) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      loadingPorCliente: false,
      error,
    }),
  });
};

const clearComprobantesFacturasPendientes = (state) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      comprobantes: [],
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};

const clearComprobantesFacturasPendientesPorCliente = (state) => {
  return updateObject(state, {
    comprobantesFacturasPendientes: updateObject(state.comprobantesFacturasPendientes, {
      comprobantesPorCliente: [],
      loadingPorCliente: false,
      estaCargadoPorCliente: false,
      error: null,
    }),
  });
};

//#endregion

//#region Comprobantes Pedidos Pendientes
const loadComprobantesPedidosPendientesStart = (state) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      loading: true,
    }),
  });
};

const loadComprobantesPedidosPendientesPorClienteStart = (state) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      loadingPorCliente: true,
    }),
  });
};

const loadComprobantesPedidosPendientesSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      comprobantes,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadComprobantesPedidosPendientesPorClienteSuccess = (state, comprobantesPorCliente) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      comprobantesPorCliente,
      loadingPorCliente: false,
      estaCargadoPorCliente: true,
      error: null,
    }),
  });
};

const loadComprobantesPedidosPendientesFail = (state, error) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      loading: false,
      estaCargado: false,
      error,
    }),
  });
};

const loadComprobantesPedidosPendientesPorClienteFail = (state, error) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      loadingPorCliente: false,
      estaCargadoPorCliente: false,
      error,
    }),
  });
};

const clearComprobantesPedidosPendientes = (state) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      comprobantes: [],
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};

const clearComprobantesPedidosPendientesPorCliente = (state) => {
  return updateObject(state, {
    comprobantesPedidosPendientes: updateObject(state.comprobantesPedidosPendientes, {
      comprobantesPorCliente: [],
      loadingPorCliente: false,
      estaCargadoPorCliente: false,
      error: null,
    }),
  });
};

//#endregion

//#region loadVentasPorVendedor
const loadVentasPorVendedorStart = (state) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      loading: true,
    }),
  });
};

const loadVentasPorVendedorPorClienteStart = (state) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      loadingPorCliente: true,
    }),
  });
};

const loadVentasPorVendedorSuccess = (state, unidadesVendidas, ventas, cantidad) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      unidadesVendidas,
      ventas,
      loading: false,
      cantidad,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadVentasPorVendedorPorClienteSuccess = (state, unidadesVendidasPorCliente, ventasPorCliente, cantidadPorCliente) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      unidadesVendidasPorCliente,
      ventasPorCliente,
      cantidadPorCliente,
      loadingPorCliente: false,
      error: null,
    }),
  });
};

const loadVentasPorVendedorFail = (state, error) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearVentasPorVendedor = (state) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      unidadesVendidas: 0,
      ventas: 0,
      loading: false,
      error: null,
      estaCargado: false,
      unidadesVendidasPorCliente: 0,
      ventasPorCliente: 0,
      loadingPorCliente: false,
    }),
  });
};

const clearVentasPorVendedorPorCliente = (state) => {
  return updateObject(state, {
    ventasPorVendedor: updateObject(state.ventasPorVendedor, {
      unidadesVendidasPorCliente: 0,
      ventasPorCliente: 0,
      loadingPorCliente: false,
    }),
  });
};
//#endregion

//#region loadPedidosVencidos
const loadPedidosVencidosStart = (state) => {
  return updateObject(state, {
    pedidosVencidos: updateObject(state.pedidosVencidos, {
      loading: true,
    }),
  });
};

const loadPedidosVencidosPorClienteStart = (state) => {
  return updateObject(state, {
    pedidosVencidos: updateObject(state.pedidosVencidos, {
      loadingPorCliente: true,
    }),
  });
};

const loadPedidosVencidosSuccess = (state, cantidad, importe) => {
  return updateObject(state, {
    pedidosVencidos: updateObject(state.pedidosVencidos, {
      cantidad,
      importe,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadPedidosVencidosPorClienteSuccess = (state, cantidadPorCliente, importePorCliente) => {
  return updateObject(state, {
    pedidosVencidos: updateObject(state.pedidosVencidos, {
      cantidadPorCliente,
      importePorCliente,
      loadingPorCliente: false,
      estaCargadoPorCliente: true,
      error: null,
    }),
  });
};

const loadPedidosVencidosFail = (state, error) => {
  return updateObject(state, {
    pedidosVencidos: updateObject(state.pedidosVencidos, {
      error,
      loading: false,
      estaCargado: true,
    }),
  });
};

const loadPedidosVencidosPorClienteFail = (state, error) => {
  return updateObject(state, {
    pedidosVencidos: updateObject(state.pedidosVencidos, {
      error,
      loadingPorCliente: false,
      estaCargadoPorCliente: true,
    }),
  });
};

const clearPedidosVencidos = (state) => {
  return updateObject(state, {
    pedidosVencidos: updateObject(state.pedidosVencidos, {
      cantidad: 0,
      total: 0,
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};

const clearPedidosVencidosPorCliente = (state) => {
  return updateObject(state, {
    pedidosVencidos: updateObject(state.pedidosVencidos, {
      cantidadPorCliente: 0,
      importePorCliente: 0,
      loadingPorCliente: false,
      estaCargadoPorCliente: false,
      error: null,
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_VENTAS_MES_COMPARATIVO_START:
      return loadVentasMesComparativoStart(state);
    case actionTypes.LOAD_VENTAS_MES_COMPARATIVO_POR_CLIENTE_START:
      return loadVentasMesComparativoPorClienteStart(state);
    case actionTypes.LOAD_VENTAS_MES_COMPARATIVO_SUCCESS:
      return loadVentasMesComparativoSuccess(
        state,
        action.montoMesActual,
        action.montoMesAnterior
      );
    case actionTypes.LOAD_VENTAS_MES_COMPARATIVO_POR_CLIENTE_SUCCESS:
      return loadVentasMesComparativoPorClienteSuccess(
        state,
        action.montoMesActualPorCliente,
        action.montoMesAnteriorPorCliente
      );
    case actionTypes.LOAD_VENTAS_MES_COMPARATIVO_FAIL:
      return loadVentasMesComparativoFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_MES_COMPARATIVO:
      return clearVentasMesComparativo(state);
    case actionTypes.CLEAR_VENTAS_MES_COMPARATIVO_POR_CLIENTE:
      return clearVentasMesComparativoPorCliente(state);

    case actionTypes.LOAD_PEDIDOS_PENDIENTES_START:
      return loadPedidosPendientesStart(state);
    case actionTypes.LOAD_PEDIDOS_PENDIENTES_POR_CLIENTE_START:
      return loadPedidosPendientesPorClienteStart(state);
    case actionTypes.LOAD_PEDIDOS_PENDIENTES_SUCCESS:
      return loadPedidosPendientesSuccess(
        state,
        action.cantidad,
        action.total,
        action.prom30Dias
      );
    case actionTypes.LOAD_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS:
      return loadPedidosPendientesPorClienteSuccess(
        state,
        action.cantidadPorCliente,
        action.totalPorCliente,
        action.prom30DiasPorCliente
      );
    case actionTypes.LOAD_PEDIDOS_PENDIENTES_FAIL:
      return loadPedidosPendientesFail(state, action.error);
    case actionTypes.CLEAR_PEDIDOS_PENDIENTES:
      return clearPedidosPendientes(state);
    case actionTypes.CLEAR_PEDIDOS_PENDIENTES_POR_CLIENTE:
      return clearPedidosPendientesPorCliente(state);

    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_START:
      return loadPedidosMesComparativoStart(state);
    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE_START:
      return loadPedidosMesComparativoPorClienteStart(state);
    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS:
      return loadPedidosMesComparativoSuccess(
        state,
        action.transMesAnt,
        action.totalMesAnt,
        action.transMesAct,
        action.totalMesAct,
        action.porcTrans,
        action.porcTotal
      );
    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE_SUCCESS:
      return loadPedidosMesComparativoPorClienteSuccess(
        state,
        action.totalMesActualPorCliente,
        action.totalMesAnteriorPorCliente
      );
    case actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_FAIL:
      return loadPedidosMesComparativoFail(state, action.error);
    case actionTypes.CLEAR_PEDIDOS_MES_COMPARATIVO:
      return clearPedidosMesComparativo(state);
    case actionTypes.CLEAR_PEDIDOS_MES_COMPARATIVO_POR_CLIENTE:
      return clearPedidosMesComparativoPorCliente(state);

    case actionTypes.LOAD_OBJETIVO_VENTAS_START:
      return loadObjetivoVentasStart(state);
    case actionTypes.LOAD_OBJETIVO_VENTAS_SUCCESS:
      return loadObjetivoVentasSuccess(
        state,
        action.objetivo,
        action.montoAcual,
        action.porcentaje
      );
    case actionTypes.LOAD_OBJETIVO_VENTAS_FAIL:
      return loadObjetivoVentasFail(state, action.error);

    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_START:
      return loadEstadosPedidosPendientesStart(state);
    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE_START:
      return loadEstadosPedidosPendientesPorClienteStart(state);
    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS:
      return loadEstadosPedidosPendientesSuccess(
        state,
        action.estadosPedidosPendientes,
        action.total
      );
    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS:
      return loadEstadosPedidosPendientesPorClienteSuccess(
        state,
        action.estadosPedidosPendientesPorCliente,
        action.totalPorCliente
      );
    case actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL:
      return loadEstadosPedidosPendientesFail(state, action.error);
    case actionTypes.CLEAR_ESTADOS_PEDIDOS_PENDIENTES:
      return clearEstadosPedidosPendientes(state);
    case actionTypes.CLEAR_ESTADOS_PEDIDOS_PENDIENTES_POR_CLIENTE:
      return clearEstadosPedidosPendientesPorCliente(state);

    case actionTypes.LOAD_FACTURAS_DEL_MES_INTERANUAL_START:
      return loadFacturasDelMesInteranualStart(state);
    case actionTypes.LOAD_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE_START:
      return loadFacturasDelMesInteranualPorClienteStart(state);
    case actionTypes.LOAD_FACTURAS_DEL_MES_INTERANUAL_SUCCESS:
      return loadFacturasDelMesInteranualSuccess(
        state,
        action.periodoActual,
        action.periodoAnterior,
        action.meses
      );
    case actionTypes.LOAD_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE_SUCCESS:
      return loadFacturasDelMesInteranualPorClienteSuccess(
        state,
        action.periodoActualPorCliente,
        action.periodoAnteriorPorCliente,
        action.meses
      );
    case actionTypes.LOAD_FACTURAS_DEL_MES_INTERANUAL_FAIL:
      return loadFacturasDelMesInteranualFail(state, action.error);
    case actionTypes.CLEAR_FACTURAS_DEL_MES_INTERANUAL:
      return clearFacturasDelMesInteranual(state);
    case actionTypes.CLEAR_FACTURAS_DEL_MES_INTERANUAL_POR_CLIENTE:
      return clearFacturasDelMesInteranualPorCliente(state);

    case actionTypes.LOAD_PEDIDOS_DEL_MES_INTERANUAL_START:
      return loadPedidosDelMesInteranualStart(state);
    case actionTypes.LOAD_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE_START:
      return loadPedidosDelMesInteranualPorClienteStart(state);
    case actionTypes.LOAD_PEDIDOS_DEL_MES_INTERANUAL_SUCCESS:
      return loadPedidosDelMesInteranualSuccess(
        state,
        action.periodoActual,
        action.periodoAnterior,
        action.meses
      );
    case actionTypes.LOAD_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE_SUCCESS:
      return loadPedidosDelMesInteranualPorClienteSuccess(
        state,
        action.periodoActualPorCliente,
        action.periodoAnteriorPorCliente,
        action.meses
      );
    case actionTypes.LOAD_PEDIDOS_DEL_MES_INTERANUAL_FAIL:
      return loadPedidosDelMesInteranualFail(state, action.error);
    case actionTypes.CLEAR_PEDIDOS_DEL_MES_INTERANUAL:
      return clearPedidosDelMesInteranual(state);
    case actionTypes.CLEAR_PEDIDOS_DEL_MES_INTERANUAL_POR_CLIENTE:
      return clearPedidosDelMesInteranualPorCliente(state);

    case actionTypes.LOAD_FACTURAS_PENDIENTES_START:
      return loadFacturasPendientesStart(state);
    case actionTypes.LOAD_FACTURAS_PENDIENTES_SUCCESS:
      return loadFacturasPendientesSuccess(state, action.facturas, action.totalFacturas);
    case actionTypes.LOAD_FACTURAS_PENDIENTES_FAIL:
      return loadFacturasPendientesFail(state, action.error);
    case actionTypes.CLEAR_FACTURAS_PENDIENTES:
      return clearFacturasPendientes(state);

    case actionTypes.LOAD_FACTURAS_PENDIENTES_POR_CLIENTE_START:
      return loadFacturasPendientesPorClienteStart(state);
    case actionTypes.LOAD_FACTURAS_PENDIENTES_POR_CLIENTE_SUCCESS:
      return loadFacturasPendientesPorClienteSuccess(state, action.facturas, action.totalFacturas);
    case actionTypes.LOAD_FACTURAS_PENDIENTES_POR_CLIENTE_FAIL:
      return loadFacturasPendientesPorClienteFail(state, action.error);
    case actionTypes.CLEAR_FACTURAS_PENDIENTES_POR_CLIENTE:
      return clearFacturasPendientesPorCliente(state);

    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START:
      return loadComprobantesFacturasPendientesStart(state);
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS:
      return loadComprobantesFacturasPendientesSuccess(state, action.comprobantes);
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL:
      return loadComprobantesFacturasPendientesFail(state, action.error);
    case actionTypes.CLEAR_COMPROBANTES_FACTURAS_PENDIENTES:
      return clearComprobantesFacturasPendientes(state);

    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_START:
      return loadComprobantesFacturasPendientesPorClienteStart(state);
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_SUCCESS:
      return loadComprobantesFacturasPendientesPorClienteSuccess(state, action.comprobantes);
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE_FAIL:
      return loadComprobantesFacturasPendientesPorClienteFail(state, action.error);
    case actionTypes.CLEAR_COMPROBANTES_FACTURAS_PENDIENTES_POR_CLIENTE:
      return clearComprobantesFacturasPendientesPorCliente(state);

    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START:
      return loadComprobantesPedidosPendientesStart(state);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS:
      return loadComprobantesPedidosPendientesSuccess(state, action.comprobantes);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL:
      return loadComprobantesPedidosPendientesFail(state, action.error);
    case actionTypes.CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES:
      return clearComprobantesPedidosPendientes(state);

    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_START:
      return loadComprobantesPedidosPendientesPorClienteStart(state);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_SUCCESS:
      return loadComprobantesPedidosPendientesPorClienteSuccess(state, action.comprobantes);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE_FAIL:
      return loadComprobantesPedidosPendientesPorClienteFail(state, action.error);
    case actionTypes.CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES_POR_CLIENTE:
      return clearComprobantesPedidosPendientesPorCliente(state);

    case actionTypes.LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_START:
      return loadVentasAlClienteEvolutivoStart(state);
    case actionTypes.LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_SUCCESS:
      return loadVentasAlClienteEvolutivoSuccess(
        state,
        action.ventas,
        action.meses
      );
    case actionTypes.LOAD_VENTAS_AL_CLIENTE_EVOLUTIVO_FAIL:
      return loadVentasAlClienteEvolutivoFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_AL_CLIENTE_EVOLUTIVO:
      return clearVentasAlClienteEvolutivo(state);

    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_START:
      return loadVentasPorVendedorStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_START:
      return loadVentasPorVendedorPorClienteStart(state);
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_SUCCESS:
      return loadVentasPorVendedorSuccess(
        state,
        action.unidadesVendidas,
        action.ventas,
        action.cantidad
      );
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_POR_CLIENTE_SUCCESS:
      return loadVentasPorVendedorPorClienteSuccess(
        state,
        action.unidadesVendidas,
        action.ventas,
        action.cantidad
      );
    case actionTypes.LOAD_VENTAS_POR_VENDEDOR_FAIL:
      return loadVentasPorVendedorFail(state, action.error);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR:
      return clearVentasPorVendedor(state);
    case actionTypes.CLEAR_VENTAS_POR_VENDEDOR_POR_CLIENTE:
      return clearVentasPorVendedorPorCliente(state);

    case actionTypes.LOAD_PEDIDOS_VENCIDOS_START:
      return loadPedidosVencidosStart(state);
    case actionTypes.LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_START:
      return loadPedidosVencidosPorClienteStart(state);
    case actionTypes.LOAD_PEDIDOS_VENCIDOS_SUCCESS:
      return loadPedidosVencidosSuccess(state, action.cantidad, action.importe);
    case actionTypes.LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_SUCCESS:
      return loadPedidosVencidosPorClienteSuccess(state, action.cantidadPorCliente, action.importePorCliente);
    case actionTypes.LOAD_PEDIDOS_VENCIDOS_FAIL:
      return loadPedidosVencidosFail(state, action.error);
    case actionTypes.LOAD_PEDIDOS_VENCIDOS_POR_CLIENTE_FAIL:
      return loadPedidosVencidosPorClienteFail(state, action.error);
    case actionTypes.CLEAR_PEDIDOS_VENCIDOS:
      return clearPedidosVencidos(state);
    case actionTypes.CLEAR_PEDIDOS_VENCIDOS_POR_CLIENTE:
      return clearPedidosVencidosPorCliente(state);

    default:
      return state;
  }
};

export default reducer;
