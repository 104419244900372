import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import classes from './TablaVentasDelVendedorPorSeleccion.css';

import * as actions from '../../../../store/actions/index';
import { formatNumber, isEven } from '../../../../assets/shared/formatNumber';
import { storageMonedaSigno } from '../../../../assets/shared/sessionData';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton, Tooltip } from '@material-ui/core';
import Table from '../../../UI/Table/Table';
import TablaVentasPorClienteDeLaCategoria from '../../ventas/TablaVentasPorClienteDeLaCategoria/TablaVentasPorClienteDeLaCategoria';
import TablaVentasPorArticuloDeLaSeleccion from '../../ventas/TablaVentasPorArticuloDeLaSeleccion/TablaVentasPorArticuloDeLaSeleccion';
import WrapperTablasPorSeleccionSinComparacionToExcel from '../../ventas/WrapperTablasPorSeleccionSinComparacionToExcel/WrapperTablasPorSeleccionSinComparacionToExcel';

const TablaVentasDelVendedorPorSeleccion = (props) => {
  const { dataSelectedId, fechaVentasDelMes, vendedorSelected } = props;

  useEffect(() => {
    if (!props[dataOptions()[dataSelectedId].stateKey].estaCargado && props.ejecucionDePagina) {
      props[dataOptions()[dataSelectedId].onLoadKey](
        vendedorSelected.idVendedor,
        fechaVentasDelMes.mes + 1,
        fechaVentasDelMes.anio
      );
    }
  }, [dataSelectedId, props.ejecucionDePagina]);

  const inCategoriaDeCliente = dataSelectedId === 'categoriaDeCliente';
  const inZona = dataSelectedId === 'zona';
  const inProvincia = dataSelectedId === 'provincia';

  const verPorClientes = inCategoriaDeCliente || inZona || inProvincia;

  const dataOptions = (item) => {
    return {
      categoriaDeCliente: {
        title: 'Categoría de cliente',
        stateKey: 'ventasPorCategoriaDeCliente',
        onLoadKey: 'onLoadVentasPorCategoriasDeCliente'
      },
      marca: {
        title: 'Marca',
        stateKey: 'ventasPorMarca',
        onLoadKey: 'onLoadVentasPorMarca'
      },
      rubro: {
        title: 'Rubro',
        stateKey: 'ventasPorRubro',
        onLoadKey: 'onLoadVentasPorRubro'
      },
      subrubro: {
        title: 'Subrubro',
        stateKey: 'ventasPorSubrubro',
        onLoadKey: 'onLoadVentasPorSubrubro'
      },
      vendedor: {
        title: 'Vendedor',
        stateKey: 'ventasPorVendedor',
        onLoadKey: 'onLoadVentasPorVendedor'
      },
      provincia: {
        title: 'Provincia',
        stateKey: 'ventasPorProvincia',
        onLoadKey: 'onLoadVentasPorProvincia'
      },
      zona: {
        title: 'Zona',
        stateKey: 'ventasPorZona',
        onLoadKey: 'onLoadVentasPorZona'
      }
    };
  };

  const columns = [
    {
      title: dataOptions()[dataSelectedId].title,
      field: 'name',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      }
    },
    {
      title: `Porc. participación`,
      field: 'porcParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${formatNumber(rowData.porcParticipacion)}%`
    },
    {
      title: `Monto`,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: cellData < 0 && 'red'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#fafafa'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    }
  ];

  const data = () => {
    return props[dataOptions()[dataSelectedId].stateKey].ventas.map((venta) => {
      return {
        id: venta[inCategoriaDeCliente ? 'idCategoria' : 'id'],
        mes: venta.mes,
        anio: venta.anio,
        name: venta[inCategoriaDeCliente ? 'categoria' : 'nombre'],
        porcParticipacion: venta.porcentajeDeParticipacion,
        monto: venta.monto
      };
    });
  };

  const tableRef = useRef();

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 49 * indexRow;
    }
  };

  return (
    <div className={classes.TablaVentasPorCategoriaDeClienteContainer}>
      <div className={classes.TitleCard}>
        {!props[dataOptions()[dataSelectedId].stateKey].loading && (
          <WrapperTablasPorSeleccionSinComparacionToExcel
            ventas={data()}
            fecha={`${fechaVentasDelMes.mes}/${fechaVentasDelMes.anio}`}
            ventasDe={dataOptions()[dataSelectedId].title}
          />
        )}
        <Tooltip title="Ver filtros del indicador">
          <IconButton size="small" onClick={() => props.onShowFiltersModal()}>
            <FilterListIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <Table
        tableRef={tableRef}
        title=""
        loading={props[dataOptions()[dataSelectedId].stateKey].loading}
        columns={columns}
        data={data()}
        detailPanel={(rowData) => {
          setTimeout(() => {
            autoScroll(rowData);
          }, 100);

          return verPorClientes ? (
            <TablaVentasPorClienteDeLaCategoria
              endRequest={() => autoScroll(rowData)}
              dataSelectedId={dataSelectedId}
              vendedorId={props.vendedorSelected.idVendedor}
              itemExpanded={rowData}
            />
          ) : (
            <TablaVentasPorArticuloDeLaSeleccion
              endRequest={() => autoScroll(rowData)}
              dataSelectedId={dataSelectedId}
              vendedorId={props.vendedorSelected.idVendedor}
              itemExpanded={rowData}
            />
          );
        }}
        noBoxShadow
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 430,
          rowStyle: (rowData) => ({
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: !isEven(rowData.tableData.id) && '#f2f2f2'
          })
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fechaVentasDelMes: state.vendedores.fechaVentasDelMesAnioDelVendedor,

    dataSelectedId: state.vendedores.dataVentasDelMesDelVendedorPorSelected,

    vendedorSelected: state.vendedores.vendedorSelected,

    ventasPorCategoriaDeCliente: state.vendedores.ventasDelMesPorCategoriaDeClienteDelVendedor,
    ventasPorMarca: state.vendedores.ventasDelMesPorMarcaDelVendedor,
    ventasPorProvincia: state.vendedores.ventasDelMesPorProvinciasDelVendedor,
    ventasPorRubro: state.vendedores.ventasDelMesPorRubroDelVendedor,
    ventasPorSubrubro: state.vendedores.ventasDelMesPorSubrubroDelVendedor,
    ventasPorZona: state.vendedores.ventasDelMesPorZonasDelVendedor
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (selecionId) => dispatch(actions.seleccionDataVentasDelMesDeVendedoresPor(selecionId)),

    onLoadVentasPorCategoriasDeCliente: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasPorCategoriaDeClienteDelVendedor(idVendedor, mes, anio)),
    onLoadVentasPorMarca: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasDelMesPorMarcaDelVendedor(idVendedor, mes, anio)),
    onLoadVentasPorRubro: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasDelMesPorRubroDelVendedor(idVendedor, mes, anio)),
    onLoadVentasPorSubrubro: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasDelMesPorSubrubroDelVendedor(idVendedor, mes, anio)),
    onLoadVentasPorProvincia: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasDelMesPorProvinciaDelVendedor(idVendedor, mes, anio)),
    onLoadVentasPorZona: (idVendedor, mes, anio) =>
      dispatch(actions.loadVentasDelMesPorZonaDelVendedor(idVendedor, mes, anio)),

    onShowFiltersModal: () =>
      dispatch(actions.showListFiltersModal(true, 'Ventas detalladas por categoría de cliente', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaVentasDelVendedorPorSeleccion);
