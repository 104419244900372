import * as actionTypes from "./actionTypes";
import axiosDashboardConfig from "../../axios-dashboardConfig";
import idIndicadores from "../../assets/shared/idIndicadores";
import moment from "moment";
import { enqueueSnackbar } from './snackbar';
import { storageEmpresaId, storageToken } from '../../assets/shared/sessionData';

const executePostComprobantes = (loading) => {
  return {
    type: actionTypes.EXECUTE_POST_COMPROBANTES,
    loading,
  };
};

export const loadComprobantesVentas = () => {
  return (dispatch) => {
    dispatch(loadComprobantesVentasStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const empresaId = sessionStorage.getItem("empresaId");

    axiosDashboardConfig
      .get(
        `/Ventas/ComprobantesVentas?idEmpresa=${empresaId}&indicadorId=${idIndicadores.comprobantesVentas}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadComprobantesVentasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadComprobantesVentasFail(error));
          } else {
            dispatch(loadComprobantesVentas());
          }
        } else {
          dispatch(loadComprobantesVentasFail(error));
        }
      });
  };
};

const loadComprobantesVentasStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_VENTAS_START,
  };
};

const loadComprobantesVentasSuccess = (comprobantesVentas) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_VENTAS_SUCCESS,
    comprobantesVentas,
  };
};

const loadComprobantesVentasFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_VENTAS_FAIL,
    error,
  };
};

export const postComprobantesVentas = (comprobantes) => {
  return (dispatch) => {
    dispatch(executePostComprobantes(true));
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const empresaId = sessionStorage.getItem("empresaId");

    axiosDashboardConfig
      .post(`/ventas/ComprobantesVentas?idEmpresa=${empresaId}`, comprobantes, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(executePostComprobantes(false));

        window.location.reload();
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false));
      });
  };
};

export const loadComprobantesPedidos = () => {
  return (dispatch) => {
    dispatch(loadComprobantesPedidosStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const empresaId = sessionStorage.getItem("empresaId");

    axiosDashboardConfig
      .get(
        `/ventas/ComprobantesPedidos?empresaId=${empresaId}&indicadorId=${idIndicadores.comprobantesPedidos}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadComprobantesPedidosSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadComprobantesPedidosFail(error));
          } else {
            dispatch(loadComprobantesPedidos());
          }
        } else {
          dispatch(loadComprobantesPedidosFail(error));
        }
      });
  };
};

const loadComprobantesPedidosStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_START,
  };
};

const loadComprobantesPedidosSuccess = (comprobantesPedidos) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_SUCCESS,
    comprobantesPedidos,
  };
};

const loadComprobantesPedidosFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_FAIL,
    error,
  };
};

export const postComprobantesPedidos = (comprobantes) => {
  return (dispatch) => {
    dispatch(executePostComprobantes(true));
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const empresaId = sessionStorage.getItem("empresaId");

    axiosDashboardConfig
      .post(
        `/ventas/ComprobantesPedidos?idEmpresa=${empresaId}`,
        comprobantes,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(executePostComprobantes(false));

        window.location.reload();
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false));
      });
  };
};

export const loadTiemposRequest = () => {
  return (dispatch) => {
    dispatch(loadTiemposRequestStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosDashboardConfig
      .get(`/configuraciones/TiemposIndicadores`, {
        headers: {
          Authorization: authStr,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        let indicadores = [];
        let tiempos = [];
        response.data.map((request) => {
          indicadores.push({
            id: request.id,
            nombre: request.nombre,
            ultimoTiempoService:
              request.tiemposRequest[0].tiempoService.totalSeconds,
            ultimaFecha: request.tiemposRequest[0].fecha,
          });
          return request.tiemposRequest
            .filter((tiempoRequest) => tiempoRequest.tiempo.totalSeconds > 0)
            .map((tiempoRequest) =>
              tiempos.push({
                indicadorId: request.id,
                fecha: tiempoRequest.fecha,
                query: tiempoRequest.query,
                tiempo: `${tiempoRequest.tiempo.totalSeconds} s`,
                tiempoQuery: `${tiempoRequest.tiempoEjecucionQuery.totalSeconds} s`,
                tiempoApiTraductora: `${tiempoRequest.tiempoProcesoApiTraductora.totalSeconds} s`,
                serviceId: tiempoRequest.serviceId,
              })
            );
        });
        dispatch(loadTiemposRequestSuccess(indicadores, tiempos));
      })
      .catch((error) => {
        dispatch(loadTiemposRequestFail(error));
      });
  };
};

export const clearTableTiemposReq = () => {
  return {
    type: actionTypes.CLEAR_TIEMPOS_REQUEST,
  };
};

const loadTiemposRequestStart = () => {
  return {
    type: actionTypes.LOAD_TIEMPOS_REQUEST_START,
  };
};

const loadTiemposRequestSuccess = (indicadores, tiempos) => {
  return {
    type: actionTypes.LOAD_TIEMPOS_REQUEST_SUCCESS,
    indicadores,
    tiempos,
  };
};

const loadTiemposRequestFail = (error) => {
  return {
    type: actionTypes.LOAD_TIEMPOS_REQUEST_FAIL,
    error,
  };
};

export const cambiarFechaFija = (fechaFija) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const empresaId = sessionStorage.getItem("empresaId");
    axiosDashboardConfig
      .put(
        `/configuraciones/cambiarFechaFija?empresaId=${empresaId}`,
        fechaFija,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        sessionStorage.setItem(
          "fechaFija",
          moment(response.data).format("MM-DD-YYYY")
        );
        window.location.reload();
      })
      .catch((error) => {});
  };
};

export const CambiarAjustaPorInflacion = (ajustaInflacion) => {
  return (dispatch) => {
    dispatch(executeCambiarAjustaPorInflacion(true));
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboardConfig
      .put(
        `/configuraciones/AjustaPorInflacion?calculoConInflacion=${ajustaInflacion}`,
        {},
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        dispatch(executeCambiarAjustaPorInflacion(false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const executeCambiarAjustaPorInflacion = (loading) => {
  return {
    type: actionTypes.EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION,
    loading
  };
};

export const loadInflacion = () => {
  return (dispatch) => {
    dispatch(loadInflacionStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboardConfig
      .get(`/configuraciones/Inflacion?listaSoloUltimoAnio=true`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadInflacionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadInflacionFail(error));
      });
  };
};

const loadInflacionStart = () => {
  return {
    type: actionTypes.LOAD_INFLACION_START
  };
};

const loadInflacionSuccess = (inflaciones) => {
  return {
    type: actionTypes.LOAD_INFLACION_SUCCESS,
    inflaciones
  };
};

const loadInflacionFail = (error) => {
  return {
    type: actionTypes.LOAD_INFLACION_FAIL,
    error
  };
};

export const LoadInflacionPorAnio = () => {
  return (dispatch) => {
    dispatch(loadInflacionPorAnioStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosDashboardConfig
      .get(`/configuraciones/InflacionPorAnio`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadInflacionPorAnioSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadInflacionPorAnioFail(error));
      });
  };
};

const loadInflacionPorAnioStart = () => {
  return {
    type: actionTypes.LOAD_INFLACION_POR_ANIO_START
  };
};

const loadInflacionPorAnioSuccess = (inflaciones) => {
  return {
    type: actionTypes.LOAD_INFLACION_POR_ANIO_SUCCESS,
    inflaciones
  };
};

const loadInflacionPorAnioFail = (error) => {
  return {
    type: actionTypes.LOAD_INFLACION_POR_ANIO_FAIL,
    error
  };
};