// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Catalogo__CatalogoContainer__209m1 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.Catalogo__CatalogoFiltros__2Lz7V {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.Catalogo__CatalogoArticulos__3mD77 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n  margin-top: 20px;\r\n}\r\n\r\n.Catalogo__CatalogoIrPedido__1TZX4 {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: flex-end;\r\n}\r\n\r\n.Catalogo__MuiFormGroup-root__3Brp_ .Catalogo__MuiFormGroup-row__3cqlO {\r\n  padding-top: '10px';\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CatalogoContainer": "Catalogo__CatalogoContainer__209m1",
	"CatalogoFiltros": "Catalogo__CatalogoFiltros__2Lz7V",
	"CatalogoArticulos": "Catalogo__CatalogoArticulos__3mD77",
	"CatalogoIrPedido": "Catalogo__CatalogoIrPedido__1TZX4",
	"MuiFormGroup-root": "Catalogo__MuiFormGroup-root__3Brp_",
	"MuiFormGroup-row": "Catalogo__MuiFormGroup-row__3cqlO"
};
export default ___CSS_LOADER_EXPORT___;
