import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  loadChangeLogs: {
    changeLogs: [],
    loading: false,
    error: null,
  },
  executeCreateChangeLog: false,
  executeUpdateChangeLog: false,
  executeDeleteChangeLog: false,
};

//#region load all change logs
const loadChangeLogsStart = (state) => {
  return updateObject(state, {
    loadChangeLogs: updateObject(state.loadChangeLogs, {
      loading: true,
    }),
  });
};

const loadChangeLogsSuccess = (state, changeLogs) => {
  return updateObject(state, {
    loadChangeLogs: updateObject(state.loadChangeLogs, {
      changeLogs,
      loading: false,
    }),
  });
};

const loadChangeLogsFail = (state, error) => {
  return updateObject(state, {
    loadChangeLogs: updateObject(state.loadChangeLogs, {
      error,
      loading: false,
    }),
  });
};

const clearChangeLogs = (state) => {
  return updateObject(state, {
    loadChangeLogs: updateObject(state.loadChangeLogs, {
      changeLogs: [],
      loading: false,
      error: null,
    }),
  });
};
//#endregion

//#region Execute create change log
const executeCreateChangeLog = (state, loading) => {
  return updateObject(state, {
    executeCreateChangeLog: loading,
  });
};
//#endregion

//#region Execute update change log
const executeUpdateChangeLog = (state, loading) => {
  return updateObject(state, {
    executeUpdateChangeLog: loading,
  });
};
//#endregion

//#region Execute delete change log
const executeDeleteChangeLog = (state, loading) => {
  return updateObject(state, {
    executeDeleteChangeLog: loading,
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_CHANGE_LOGS_START:
      return loadChangeLogsStart(state);
    case actionTypes.LOAD_CHANGE_LOGS_SUCCESS:
      return loadChangeLogsSuccess(state, action.changeLogs);
    case actionTypes.LOAD_CHANGE_LOGS_FAIL:
      return loadChangeLogsFail(state, action.error);
    case actionTypes.CLEAR_CHANGE_LOGS:
      return clearChangeLogs(state);

    case actionTypes.EXECUTE_CREATE_CHANGE_LOG:
      return executeCreateChangeLog(state, action.loading);
    case actionTypes.EXECUTE_UPDATE_CHANGE_LOG:
      return executeUpdateChangeLog(state, action.loading);
    case actionTypes.EXECUTE_DELETE_CHANGE_LOG:
      return executeDeleteChangeLog(state, action.loading);

    default:
      return state;
  }
};

export default reducer;
