// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListaFiltrosPorIndicadorModal__TitleDialogContent__3whQG {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-right: 5px;\r\n}\r\n\r\n.ListaFiltrosPorIndicadorModal__TitleDialogTexto__3Rx6y {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  padding-left: 5px;\r\n}\r\n\r\n.ListaFiltrosPorIndicadorModal__subtitleTexto__2GLbM {\r\n  margin-top: -10px;\r\n  margin-bottom: 10px;\r\n  margin-right: 5px;\r\n  padding-left: 5px;\r\n}\r\n\r\n.ListaFiltrosPorIndicadorModal__AccordionRoot__2AUux {\r\n  width: 100%;\r\n  border: none;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleDialogContent": "ListaFiltrosPorIndicadorModal__TitleDialogContent__3whQG",
	"TitleDialogTexto": "ListaFiltrosPorIndicadorModal__TitleDialogTexto__3Rx6y",
	"subtitleTexto": "ListaFiltrosPorIndicadorModal__subtitleTexto__2GLbM",
	"AccordionRoot": "ListaFiltrosPorIndicadorModal__AccordionRoot__2AUux"
};
export default ___CSS_LOADER_EXPORT___;
