import * as actionTypes from '../actions/actionTypes';

const initialState = {
  ejecucionFotoDelDia: false,
  ejecucionClientes: false,
  ejecucionCatalogo: false,
  ejecucionTiemposReq: false,
  ejecucionAnalisisVentas: false,
  paginaRender: '/'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EJECUTAR_FOTO_DEL_DIA:
      return { ...state, ejecucionFotoDelDia: action.ejecucion };

    case actionTypes.EJECUTAR_CLIENTES:
      return { ...state, ejecucionClientes: action.ejecucion };

    case actionTypes.EJECUTAR_CATALOGO:
      return { ...state, ejecucionCatalogo: action.ejecucion };

    case actionTypes.EJECUTAR_TIEMPOS_REQ:
      return { ...state, ejecucionTiemposReq: action.ejecucion };

    case actionTypes.EJECUTAR_ANALISIS_VENTAS:
      return { ...state, ejecucionAnalisisVentas: action.ejecucion };

    case actionTypes.CAMBIO_PAGINA_RENDER:
      return { ...state, paginaRender: action.page };

    default:
      return state;
  }
};

export default reducer;
