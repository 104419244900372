import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classes from './AnalisisDeVentas.css';
import * as actions from "../../store/actions";

import EmpresaSelector from '../../components/UI/EmpresaSelector/EmpresaSelectorButton';
import { storageMultiempresa, storageVendedorId } from '../../assets/shared/sessionData';
import Ventas from './Ventas';
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';

const AnalisisDeVentas = (props) => {  
  sessionStorage.setItem('seccionNombre', 'Análisis de venta');
  const { ejecucionPaginas, } = props;

  const isMultiemp = storageMultiempresa() === 'true';

  return (
    <div className={classes.AnalisisVentasContainer}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: !isMultiemp && '10px'
        }}
      >
        {isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
      </div>
      {!ejecucionPaginas ? (
        <BoxEjecutarPaginaInfo />
      ) : (
        <Ventas ejecucionDePagina={ejecucionPaginas} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {    
    ejecucionPaginas: state.ejecucionPaginas.ejecucionAnalisisVentas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalisisDeVentas);