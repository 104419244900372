// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaVentasDelVendedorPorSeleccion__TablaVentasPorCategoriaDeClienteContainer__WkV6t {\r\n  max-height: 460px;\r\n  /*border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n  background-color: white;\r\n  box-shadow: 0px 3px 6px #00000029;*/\r\n  flex: 1 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.TablaVentasDelVendedorPorSeleccion__TitleCard__1gFtf {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n\r\n.TablaVentasDelVendedorPorSeleccion__SelectRoot__HbIyD {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n\r\n.TablaVentasDelVendedorPorSeleccion__LabelVerPor__21lic {\r\n  font-weight: bold;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TablaVentasPorCategoriaDeClienteContainer": "TablaVentasDelVendedorPorSeleccion__TablaVentasPorCategoriaDeClienteContainer__WkV6t",
	"TitleCard": "TablaVentasDelVendedorPorSeleccion__TitleCard__1gFtf",
	"SelectRoot": "TablaVentasDelVendedorPorSeleccion__SelectRoot__HbIyD",
	"LabelVerPor": "TablaVentasDelVendedorPorSeleccion__LabelVerPor__21lic"
};
export default ___CSS_LOADER_EXPORT___;
