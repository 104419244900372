import axios from "axios";
import { baseUrlPedidos } from "./assets/shared/urlApps";

const configInstance = () => {
  let instance = axios.create({
    baseURL: baseUrlPedidos(),
  });

  const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
  const idEmpresa = parseInt(sessionStorage.getItem("empresaId"), 10);

  instance.defaults.headers.common['Authorization'] = authStr;
  instance.defaults.headers.common['Id-Empresa'] = idEmpresa;

  return instance;
}

const instance = configInstance();

export default instance;